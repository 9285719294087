export default (state = [], action) => {
    switch (action.type) {

        case 'TEST_API_SUCCESS':
            return action.payload.data.data;

        case 'TEST_API_FAILURE':
            return action.payload.response.data.message;

        default:

            return state;
    }
};