import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useStyles } from "./style.js";
import { useHistory } from "react-router-dom";
import IconMenuItem from '../../component/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
import Modal from "../../component/modal";
import {
    updateRegionFilters,
    resetPlaybooksRegionFilters,
    updateBookmarkViews,
    fetchBookmarks,
    updateRegionSpecificConfigs,
    updateMenuItemViewState,
    updateActivePage
} from '../../redux/actions/index';
import AddBookMark from '../../assets/icons/bookmarks.png';
import management from '../../assets/icons/management.png';
import Bookmarks from "./bookmarks";
import BookmarkIcon from '@material-ui/icons/Bookmark';
import utils from "../../utils";
import './style.css';

function Bookmark(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  let history = useHistory()

  const { activeRegion, activeSubRegion, regions, updateRegion } = props;


  const bookMarkList = useSelector((state) => state.getBookmarks);
  const pbiReport = useSelector((state) => state.PowerBIReportPageSection);
  const updatePBIReport = useSelector((state) => state.updatePBIReport);


  const pathName = window.location.pathname;

  const { activeRoutes, activePage } = useSelector((state) => state.activeDynamicData);

  const [bookMarkOpen, setBookMarkOpen] = useState(false);
  const [createBookmark, setCreateBookmark] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [selectedIndex, setSelectedIndex] = useState('');
  const [closeAnchorEl, setCloseAnchorEl] = useState(false);

  useEffect(() => {
    let sessionData = JSON.parse(sessionStorage.getItem('oktaRes'));
    if (sessionData && sessionData.email) {
      let params = {
        userEmail: sessionData.email,
        pageType: "Playbooks"
      }
      dispatch(fetchBookmarks(params));
    }
  }, [dispatch]);

  useEffect(() => {
    setSelectedIndex(null);
  }, [updatePBIReport]);

  useEffect(() => {
    let bookmarkIndex = sessionStorage.getItem('presistBookmarkIndex');
    const report = sessionStorage.getItem('presistReport');
    const presistReport = !!report ? JSON.parse(report) : null;
    if (pathName === activeRoutes.key1) {
      setSelectedIndex(bookmarkIndex);
    }
    else if (pathName === "/") {
      setSelectedIndex(null);
      sessionStorage.setItem('presistBookmarkIndex', null);
      if (presistReport !== null && presistReport.type === "state") {
        sessionStorage.removeItem('presistReport');
      }
    }
  }, [activeRoutes.key1, pathName]);

  const manageBookMark = () => {
    setBookMarkOpen(true);
    setCreateBookmark(false);
    setModalTitle('Personal Bookmark');
    setCloseAnchorEl(true)
  };

  const createNew = () => {
    setBookMarkOpen(true);
    setCreateBookmark(true);
    setModalTitle('Create Bookmark');
    setCloseAnchorEl(true)
  }

  const imgStyle = {
    width: '25px',
    height: '25px'
  }

  const editBookmark = (val) => {
    setModalTitle(val);
  };

  const updateRegionSpecificCustomData = useCallback((bookmark) => {
    let region = bookmark.region; //bookmark.region  === "Global" ? "APAC" :
    let selectedRegionData = regions.filter((item) => item.region === region)[0];
    let disabledScreen = selectedRegionData['disabledScreen']
    let parentModule = selectedRegionData['businessUnits'];
    let finalRoutes = selectedRegionData['routes'];
    let parentModuleIndex = parentModule.findIndex((el) => el.key === bookmark.pageName);
    let activeParentModule = parentModuleIndex > -1 ? parentModule[parentModuleIndex] : parentModule[0]
    let childModuleIndex = activeParentModule.childModule.findIndex((el) => el.key === bookmark.pageType)
    let activePage = activeParentModule.childModule[childModuleIndex]
    if (activePage) {
      dispatch(updateActivePage(activePage))
      sessionStorage.setItem('ActivePage', JSON.stringify(activePage));
    }
    if (!!parentModule)
      dispatch(updateRegionSpecificConfigs(
        parentModule,
        finalRoutes,
        disabledScreen,
        {
          showFeedback: selectedRegionData ? selectedRegionData['showFeedback'] : false,
          msalTokenEnabled: selectedRegionData ? selectedRegionData['msalTokenEnabled'] : false,
          homePageRedirectKey: selectedRegionData ? selectedRegionData['homePageRedirectKey'] : null,
          showDashboardPage: selectedRegionData ? selectedRegionData['showDashboardPage'] : false,
          showUpdateMessage: selectedRegionData ? selectedRegionData['showUpdateMessage'] : false,
          parentPath: parentModule.key,
        },
        region))
    return { finalBusinessUnit: parentModule, finalRoutes: finalRoutes };
  }, [dispatch, regions]);

  const viewBookmark = (bookmark, selectedIndex) => {
    console.clear();
    setSelectedIndex(selectedIndex);
    let region = bookmark.region;
    let subRegion = bookmark.subRegion;
    let bookmarkState = bookmark.bookmarkState;
    let parentId;
    let childId;
    let nextPathData = updateRegionSpecificCustomData(bookmark);
    if (activeRegion !== region || subRegion !== activeSubRegion) {
      dispatch(updateRegionFilters({}));
      dispatch(resetPlaybooksRegionFilters({}));
      dispatch({ type: "GET_PLAYBOOKS_CONTENT_REQUEST" });
    }
    else if (activeRegion !== 'EMEA' && (!activeSubRegion || !subRegion)) {
      dispatch(updateRegionFilters({}));
      dispatch(resetPlaybooksRegionFilters({}));
    }
    updateRegion({ region: region, subRegion: subRegion })
    if(bookmark.otherDetails){
      let otherDetails = JSON.parse(bookmark.otherDetails);
      parentId = otherDetails.parentId;
      childId =  otherDetails.childId;
      console.log('viewBookmarkFunction',   bookmark, parentId, childId)
    }
    let activeParentModule = nextPathData['finalBusinessUnit'].filter((item) => item.key === bookmark['pageName'] || item.parentId === parentId)[0];
    let activeChildModule = activeParentModule.childModule.find((el) => el.key === bookmark['pageType'] || el.childId === childId)
    let historyParams = {
      background: "#f4f5f6",
      pageType: bookmark.pageType,
      pageName: activeChildModule ? activeChildModule['pageName'] : bookmark.pageType,
      region: region,
      sideBar: "",
      subRegion: subRegion,
      viewName: bookmark.viewName,
      activeReport: bookmark.reportSectionName,
      type: 'bookmark',
      viewIndex: bookmark.viewIndex,
      Name: bookmark.Name,
      businessUnitData: activeChildModule,
    };
    let presistState = {
      type: 'state',
      state: bookmarkState
    }
    utils.userTrackingInfo({
      region: region,
      subRegion: subRegion,
      actionType: 'Bookmark',
      pageName: activeChildModule.pageName,
      reportName: bookmark.viewName,
      pageDetails: bookmark.bookmarkName,
      globalFilters: bookmark.selectedFilters,
      groupId: bookmark.groupId,
      reportId: bookmark.reportId,
      attributeName: 'Bookmark Viewed',
      reportSection: bookmark.reportSectionName,
      reportSectionId: bookmark.reportSectionId,
      reportLink: bookmark.reportLink,
    });
    history.push({
      pathname: activeChildModule['path'],
      state: historyParams,
    });
    sessionStorage.setItem('presistReport', JSON.stringify(presistState));
    sessionStorage.setItem('presistBookmarkIndex', selectedIndex);
    dispatch(updateBookmarkViews(bookmark));
    dispatch(updateMenuItemViewState({ closeAnchorEl: true }))
    if (!!pbiReport && Object.keys(pbiReport).length > 0) {
      pbiReport && pbiReport.bookmarksManager.applyState(bookmarkState);
    }
  };

  const createInitialBookmark = () => {
    let historyParams = {
      background: "#f4f5f6",
      pageType: "Playbooks",
      region: activeRegion,
      sideBar: "",
      subRegion: activeSubRegion,
      viewName: "Strategic Pricing",
      viewIndex: 0,
    };
    history.push({
      pathname: activeRoutes.key1,
      state: historyParams,
    });

  };

  const handleModalClose = () => {
    setBookMarkOpen(false);
    setCloseAnchorEl(false);
  }

  const renderBookMarkManager = () => {
    return (
      <Modal
        open={bookMarkOpen}
        bodyElement={
          <Bookmarks
            hideSettingsWindow={(e) => handleModalClose(e)}
            props={props.props}
            activeRegion={activeRegion}
            activeSubRegion={activeSubRegion}
            createBookmark={createBookmark}
            editBookmark={editBookmark}
            pathName={pathName}
            bookMarkIndex={selectedIndex}
          />
        }
        title={modalTitle}
        size={!!createBookmark ? 'md' : 'lg'}
        hideSettingsWindow={(e) => handleModalClose(e)}
      />
    )
  };

  const renderMenuItem = () => {
    return (
      <span>
        <div>
          {/* style={Array.isArray(bookMarkList.data) && bookMarkList.data.length === 0 ? null : { overflow: "auto", height: '395px' }} */}
          {
            !!bookMarkList.loading && !!bookMarkList && Array.isArray(bookMarkList.data) && bookMarkList.data.length === 0 ?
              <MenuItem> ...Loading </MenuItem> :
              !!bookMarkList && Array.isArray(bookMarkList.data) && bookMarkList.data.length > 0 ?
                bookMarkList.data.map((item, index) =>
                  <MenuItem key={index} onClick={() => viewBookmark(item, index)}
                    className={selectedIndex == index ? 'activeBookmark' : 'bookmark'}
                  > {!!item.subRegion && item.subRegion !== 'null' ? item.region + '_' + item.subRegion : item.region} | {item.bookmarkName}</MenuItem>
                ) :
                !!bookMarkList.error && bookMarkList.message === "No Data found" ?
                  <MenuItem onClick={() => createInitialBookmark()}>{bookMarkList.userMessage}</MenuItem>
                  :
                  <MenuItem> {bookMarkList.userMessage}</MenuItem>
          }
          <Divider sx={{ my: 0.5 }} />
        </div>
        <div className={classes.flexContainer}>
          {
            activePage && activePage.createBookmark && !!pbiReport && Object.keys(pbiReport).length > 0
              ?
              <MenuItem onClick={createNew}>
                <img src={AddBookMark} alt="addbookmark" style={imgStyle} /> Create New Bookmark
              </MenuItem>
              : null
          }
          <MenuItem onClick={manageBookMark} disableRipple>
            <img src={management} alt="management" style={imgStyle} />  Manage Bookmarks
          </MenuItem>
        </div>
      </span>
    )
  }

  const bookMarkStyle = {
    fontSize: "22px",
    margin: "0 5px",
  };

  const renderBookmark = () => {
    return (
      <IconMenuItem
        ariaLabel={'bookmark of current user'}
        TypoLabel={'Bookmarks'}
        fontSize={'1rem'}
        menuId={'menu-appbar-Bookmark'}
        menuItem={renderMenuItem()}
        closeAnchorEl={closeAnchorEl}
        prefixIcon={false}
        affixIcon={false}
        disabled={false}
        ExpandMoreOutlined={true}
        renderIcon={<BookmarkIcon style={bookMarkStyle} />}
        padding={true}
      />
    )
  }

  return (
    <div id="bmodal" style={{margin : pathName === '/' ? '0px 10px 0 0' : '5px 10px 0 0' }}>
      {renderBookMarkManager()}
      {renderBookmark()}
    </div>
  );
}

export default Bookmark;

