export default (
  state = { notification: [], defaultResponse: true },
  action
) => {
  switch (action.type) {
    case "FETCH_NOTIFICATION_REQUEST":
      return {
        notification: [],
        loading: true,
        defaultResponse: true,
      };

    case "FETCH_NOTIFICATION_SUCCESS":
      return {
        notification: action.payload,
        defaultResponse: false,
        loading: false,
        success: true,
        error: false,
      };

    case "FETCH_NOTIFICATION_FAILURE":
      return {
        notification: [],
        error: true,
        defaultResponse: false,
        loading: false,
        success: false,
      };

    default:
      return state;
  }
};
