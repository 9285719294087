export default (state = {
    loading: false,
    error: false,
    responseDefault: true,
    success: false,
    message: ''
}, action) => {

    switch (action.type) {
        case 'UPDATE_SELECTED_VIEW_REQUEST':
            return {
                data: [],
                loading: true,
                error: false,
                responseDefault: true,
                success: false,
                message: ''
            }

        case 'UPDATE_SELECTED_VIEW_SUCCESS':
            return {
                data: action.payload.data,
                loading: false,
                error: false,
                responseDefault: false,
                success: true,
                message:   action.payload.data.message
            }

        case 'UPDATE_SELECTED_VIEW_FAILURE':
            return {
                loading: false,
                error: true,
                message: action.payload.response.data.message,
                userMessage: action.payload.response.data['usermessage'],
                data: [],
                responseDefault: false,
                success: false,
            }

        default:
            return state;
    }

};





