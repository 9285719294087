import React, { useEffect, useState, /*useContext*/ } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { LayoutContext } from "../layoutContent/index";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import {
    getReportContent,
    updateCustomizedViewBuilderJSON
} from '../../redux/actions/index';
import Report from '../reports/report';
import DefaultReport from '../reports/defaultReport';
import { useStyles } from "./style.js";
import cloneDeep from 'lodash/cloneDeep';
import CircularProgress from '@material-ui/core/CircularProgress';
import './style.css';


const AdditionalReports = (props) => {

    const classes = useStyles();
    const dispatch = useDispatch();
    const { activeIndex, activeSubMenu, activeSubMenuIndex, activeSubMenuHtmlTemp, activeSubMenuName, open } = props;

    const reportContent = useSelector((state) => state.getReportContent);
    const activeReport = useSelector((state) => state.activePBIReport);
    const { activeMsalToken, activeRegion, activePage, } = useSelector((state) => state.activeDynamicData);

    const errorMessage = useSelector((state) => state.getErrorMessages);
    const [noReports, setNoReport] = React.useState(null);
    const [navContentPaneEnabled, SetNavContentPaneEnabled] = useState(true);
   
    useEffect(() => {
        const noReport = errorMessage?.data?.find(item => item.errorType === "No Reports Available")
        setNoReport(noReport?.errorMessage)
    }, [errorMessage]);


    useEffect(() => {
        if (activeRegion && activePage && activePage.key !== "Playbooks") {
            const params = {
                pageType: activePage.key,
                region: activeRegion,
            }
            dispatch(getReportContent(params));

        }
    }, [dispatch, activeRegion, activePage]);

    useEffect(() => {
        if (Object.keys(activeReport).length > 0) {
            if (activeReport) {
                let { msalEnabled, customizableFlag, grouped, reportPages } = activeReport;
                let tempViewBuilderObj = cloneDeep(activeReport);
                if (msalEnabled && customizableFlag) {
                    let { htmlTemp } = tempViewBuilderObj;
                    let element = !!htmlTemp && Object.keys(htmlTemp).length > 0 && Object.keys(htmlTemp).filter(key => htmlTemp[key].type === "dashboard");
                    if (!!htmlTemp && htmlTemp !== "NULL") {
                        htmlTemp[element]['msalToken'] = activeMsalToken ? activeMsalToken : sessionStorage.getItem("msalAccessToken");
                    }
                }
                dispatch(updateCustomizedViewBuilderJSON(tempViewBuilderObj));
                 if (grouped) {
                    SetNavContentPaneEnabled(true)
                }
                else if (reportPages) {
                    SetNavContentPaneEnabled(false)
                }
            }
        }
        else {
            dispatch(updateCustomizedViewBuilderJSON({}));
        }
    }, [dispatch, activeMsalToken, activeReport]);

    const reportEmbedding = () => {
        let { loading, responseDefault, error } = reportContent;
        if (loading && responseDefault) {
            return (
                <div className={classes.position}>
                    <CircularProgress color="primary" />
                </div>
            )
        }
        else if (!loading && !responseDefault && !!error) {
            return (
                <div className={classes.serverDown}>
                    <div className="noReports"></div>
                    <div className={classes.errorMessage} >{noReports}</div>
                </div>
            )
        }
        if (Object.keys(activeReport).length > 0) {
            if (!activeReport.customizableFlag || (activeReport.htmlTemp.hasOwnProperty('type') && activeReport.htmlTemp.type === "default")) {
                return (
                    <DefaultReport
                        activeIndex={activeIndex}
                        activeSubMenuHtmlTemp={activeSubMenuHtmlTemp}
                        reportSectionId={activeSubMenu}
                        activeSubMenuIndex={activeSubMenuIndex}
                        activeSubMenuName={activeSubMenuName}
                        navContentPaneEnabled={navContentPaneEnabled ? navContentPaneEnabled:  !activeReport.activeReportPage ? true : false}
                    />
                )
            }
            else if (activeReport.customizableFlag) {
                return (
                    <Report
                        navContentPaneEnabled={navContentPaneEnabled ? navContentPaneEnabled:   !activeReport.activeReportPage ? true : false}
                        pageType={'Additional Reports'}
                        activeIndex={activeIndex}
                    />
                )
            }
        }
    }

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <Grid container item xs={ open  ? 10 :   12 }  >
                    {reportEmbedding()}
                </Grid>
            </Paper>
        </div>
    );
}
export default AdditionalReports;