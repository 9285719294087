import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import './style.css';
import apiInstance from '../../network/index';
import { toastTriggered } from '../../redux/actions/index';
import axios from 'axios';
import checkWhite from '../../assets/icons/checkWhite.svg';
import errorWhite from '../../assets/icons/errorWhite.svg';
import { Loading } from '../../assets/icons/Icons';
import utils from '../../utils/index';

const ExportFile = (props) => {
    const dispatch = useDispatch();

    const pbiReport = useSelector((state) => state.PowerBIReportPageSection);
    const playbooksSelectedRegionFilter = useSelector((state) => state.playbooksSelectedRegionFilter);
    const { activeMsalToken,  activePage, activeRegion } = useSelector((state) => state.activeDynamicData);

    const  playBookData   = useSelector((state) => state.activePBIReport);

    const [value, setValue] = useState('');
    const [currentReport, setCurrentReport] = useState(true);
    const [reportActivePage, setReportActivePage] = useState({});


    const handleSelectValue = (e) => {
        let value = e.target.value
        setValue(value)
    };

    const handleSelectCheckbox = () => {
        setCurrentReport(!currentReport);
    };

    function downloadURI(uri, name) {
        let link = document.createElement("a");
        link.download = name;
        link.href = uri;
        link.click();
    };

    useEffect(() => {
        if (!!pbiReport && Object.keys(pbiReport).length > 0) {
            pbiReport.getPages()
                .then(async (pages) => {
                    let activePage = pages.filter(function (page) {
                        return page.isActive
                    })[0];
                    setReportActivePage(activePage)
                });
        };
    }, [pbiReport]);

    const trackExportEvent = (name) => {
        const {   exportType,   subRegion } = props;
        let trackingFilter = {};
        if (activePage && activePage.globalFilter) {
            !!playbooksSelectedRegionFilter && Object.keys(playbooksSelectedRegionFilter['dataSet']).map((item) => {
                trackingFilter[item] = playbooksSelectedRegionFilter.dataSet[item]['activeItem'];
            });
        }
        let trackingObject = {
            region: activeRegion,
            actionType: 'Export',
            pageName:  activePage && activePage.pageName,
            reportName: playBookData.viewName,
            reportSection: reportActivePage.displayName,
            globalFilters: JSON.stringify(trackingFilter),
            reportSectionId: reportActivePage.name,
            reportLink: playBookData['htmlTemp']['reportLink'],
            groupId: playBookData['htmlTemp']['groupId'],
            reportId: playBookData['htmlTemp']['reportId'],
            subRegion: subRegion,
            attributeName: exportType,
            pageDetails: name
        };
        utils.userTrackingInfo(trackingObject);
    };

    const ExportTo = (headers, reportId, groupId, description, error, country) => {
        const {   pbiReport, exportType,  hideSettingsWindow, subRegion } = props;
        let toastError = [{
            id: 4759834,
            title: `Export to ${exportType} is having some issues`,
            description: error,
            backgroundColor: '#d9534f',
            percentageDownload: '100%',
            icon: errorWhite,
            status: 'error',
            autoDeleteTime: 7500,
            autoDelete: true,
            trigger: true,
        }];
        const id = Math.floor((Math.random() * 101) + 1);

        hideSettingsWindow("e");
        if (pbiReport && Object.keys(pbiReport).length > 0) {
            pbiReport.bookmarksManager.capture({ personalizeVisuals: true }).then(async (bookmark) => {
                let exportParams = {};
                exportParams.format = exportType;
                exportParams.powerBIReportConfiguration = {
                    "settings": {
                        "locale": "en-US",
                        "excludeHiddenPages": "true",
                        "timeZoneId": "India Standard Time"
                    }
                }
                if (value === "currentValues") {
                    exportParams.powerBIReportConfiguration.defaultBookmark = { state: bookmark.state }
                }
                else if (!!currentReport && value !== "defaultValues") {
                    exportParams.powerBIReportConfiguration.pages =
                        [{
                            "pageName": reportActivePage.name,
                            "bookmark": { "state": bookmark.state }
                        }]
                }
                else if (value === "defaultValues" && !!currentReport) {
                     exportParams.powerBIReportConfiguration.pages =
                        [{
                            "pageName": reportActivePage.name,
                        }]
                }
                else if (value !== "defaultValues" && !currentReport) {
                    exportParams.powerBIReportConfiguration.defaultBookmark = { state: bookmark.state }
                }
                else if (playBookData.msalEnabled) {
                    exportParams.powerBIReportConfiguration.defaultBookmark = { state: bookmark.state }
                }
                axios.post(`https://api.powerbi.com/v1.0/myorg/groups/${groupId}/reports/${reportId}/ExportTo`, exportParams,
                    {
                        headers: headers
                    })
                    .then(async (triggeredResponse) => {
                        var timer;
                        let exportId = triggeredResponse.data.id;
                        let toastProperties = [{
                            id: id,
                            title: 'Export request is in Progress',
                            description: description,
                            backgroundColor: '#5bc0de',
                            percentageDownload: '0%',
                            status: 'start',
                            icon: <Loading />,
                            autoDeleteTime: 10000000,
                            autoDelete: false,
                            trigger: true,
                        }];
                         dispatch(toastTriggered(toastProperties));

                        timer = setInterval(function () {
                            axios.get(`https://api.powerbi.com/v1.0/myorg/groups/${groupId}/reports/${reportId}/Exports/${exportId}`,
                                {
                                    headers: headers
                                })
                                .then(async (res) => {
                                    let toastProperties = [{
                                        id: id,
                                        title: 'Export request is in progress',
                                        description: description,
                                        backgroundColor: '#5bc0de',
                                        percentageDownload: `${res.data.percentComplete}%`,
                                        status: 'running',
                                        icon: <Loading />,
                                        autoDeleteTime: 10000000,
                                        autoDelete: false,
                                        trigger: true,
                                    }];
                                    dispatch(toastTriggered(toastProperties));

                                    if (res.data.status !== 'Failed' && res.data.status !== 'NotStarted' && res.data.status !== 'Running' && res.data.status === 'Succeeded') {
                                        clearInterval(timer);

                                        await axios.get(`https://api.powerbi.com/v1.0/myorg/groups/${groupId}/reports/${reportId}/Exports/${exportId}/file`,
                                            {
                                                responseType: 'blob',
                                                headers: {
                                                    Authorization: headers.Authorization
                                                }
                                            })
                                            .then(async (fileRes) => {
                                                 let fileType = exportType === "PDF" ? 'pdf' : 'pptx';
                                                var blob = new Blob([fileRes.data])
                                                 let name = `RGM ${activePage.title} ${activeRegion} ${!!subRegion && subRegion !== 'undefined' ? '- ' + subRegion : '' } ${ !!country && country !== 'undefined' ? '- ' + country : ''} - ${playBookData.viewName} ${!!currentReport ? '- ' + reportActivePage['displayName'] : ''}.${fileType}`;

                                                // create an object URL from the Blob
                                                var URL = window.URL || window.webkitURL

                                                var downloadUrl = URL.createObjectURL(blob);

                                                downloadURI(downloadUrl, name);
                                                // window.open(downloadUrl);
                                                setCurrentReport(false);
                                                let toastProperties = [{
                                                    id: id,
                                                    title: 'Export Successfully Completed',
                                                    description: `${name} file is exported successfully`,
                                                    backgroundColor: '#5cb85c',
                                                    percentageDownload: '100%',
                                                    icon: checkWhite,
                                                    status: 'downloaded',
                                                    autoDeleteTime: 5000,
                                                    autoDelete: true,
                                                    trigger: true,
                                                }];
                                                dispatch(toastTriggered(toastProperties));
                                                trackExportEvent(name);
                                            })
                                            .catch((err) => {
                                                 toast.error(
                                                    <div style={{ textAlign: 'left' }}>
                                                        <h5 className="flexContainer">  {`Export to ${exportType} is having some issues`} </h5>
                                                        {`Your report RGM Playbook - ${activeRegion} - ${playBookData.viewName} is not able exported to a ${exportType === "PDF" ? 'PDF' : 'Power Point'} file.
                                                              This might be some server issue, Please try Again.
                                                            `}
                                                    </div>,
                                                    { autoClose: 5000 }
                                                );
                                            })
                                        setCurrentReport(false);
                                    }
                                })
                                .catch((err) => {
                                   dispatch(toastTriggered(toastError));
                                })
                        }, 10000);
                    })
                    .catch((err) => {

                        // if(err.response &&  err.response.data && err.response.data.error.message){
                        //      toastError[0]["description"] = err.response.data.error.message ;
                        // }
                        dispatch(toastTriggered(toastError));
                    })
            })
        }
    }

    const exportData = () => {
        console.clear();
        const {  exportType,   hideSettingsWindow, subRegion } = props;
        let country;
        if (activePage && activePage.globalFilter) {
            country =   !!playbooksSelectedRegionFilter && playbooksSelectedRegionFilter.dataSet && playbooksSelectedRegionFilter.dataSet && (value === "currentValues" || value === "")
           ? playbooksSelectedRegionFilter.dataSet['country']['optionList'].length == 1 ? '' :  playbooksSelectedRegionFilter.dataSet['country']['activeItem']
           : playbooksSelectedRegionFilter.dataSet['country']['optionList'].length == 1 ? '' :  playbooksSelectedRegionFilter.dataSet['country']['optionList'][0];
        }

        let description = `Your report RGM  ${activeRegion} ${!!subRegion && subRegion !== 'undefined' ? '- ' + subRegion : '' }  ${playBookData && playBookData.globalFilter && country && country !== 'undefined' ? '- ' + country : ''} - ${playBookData.viewName}${!!currentReport ? ' - ' + reportActivePage['displayName'] + ',' : ','} is being exported to a ${exportType === "PDF" ? 'PDF' : 'Power Point'} file.
        This might take a few minutes. Please wait for some time.`;
        let error = `Your report RGM  ${activeRegion}  ${!!subRegion && subRegion !== 'undefined' ?  '- ' +subRegion : '' } ${playBookData && playBookData.globalFilter && country && country !== 'undefined' ? '- ' + country : ''} - ${playBookData.viewName}${!!currentReport ? ' - ' + reportActivePage['displayName'] + ',' : ','} is not able exported to a ${exportType === "PDF" ? 'PDF' : 'Power Point'} file.
        This might be some server issue, Please try Again.`
        let toastError = [{
            id: 4759834,
            title: `Export to ${exportType} is having some issues`,
            description: error,
            backgroundColor: '#d9534f',
            percentageDownload: '100%',
            icon: errorWhite,
            status: 'error',
            autoDeleteTime: 7500,
            autoDelete: true,
            trigger: true,
        }];
        const id = Math.floor((Math.random() * 101) + 1);
        let toastProperties = [{
            id: id,
            title: 'Export request is in progress',
            description: description,
            backgroundColor: '#5bc0de',
            percentageDownload: '0%',
            status: 'triggered',
            icon: <Loading />,
            autoDeleteTime: 10000000,
            autoDelete: false,
            trigger: true,
        }];
        dispatch(toastTriggered(toastProperties));
        hideSettingsWindow("e");
        if (!!playBookData && Object.keys(playBookData).length > 0) {
            const { htmlTemp, msalEnabled} = playBookData;
            let reportId, groupId;
            if(!playBookData.customizableFlag || (playBookData.htmlTemp.hasOwnProperty('type') && playBookData.htmlTemp.type === "default")){
                reportId = !!htmlTemp && htmlTemp.reportId;
                groupId = !!htmlTemp && htmlTemp.groupId;
            }
            else{
                const htmlTemp = !!playBookData && !!playBookData.htmlTemp && Object.values(playBookData.htmlTemp)
                let reportsIds = utils.checkValidPBIds(htmlTemp, playBookData.customizableFlag);
                reportId = !!reportsIds && reportsIds.reportId;
                groupId = !!reportsIds && reportsIds.groupId;
            }
            let headers = {};
            if (groupId && reportId && !msalEnabled) {
                const params = {
                    reportId: reportId,
                    groupId: groupId
                }
                apiInstance.post(`api/getEmbedToken`, params).then((tokenRes) => {
                    headers = {
                        "Content-Type": "application/json",
                        Authorization: 'Bearer ' + tokenRes.data.data.accessToken
                    };
                    ExportTo(headers, reportId, groupId, description, error, country);
                })
                    .catch((err) => {
                        dispatch(toastTriggered(toastError));
                    })
            }
            else if (msalEnabled) {
                let token =     activeMsalToken ? activeMsalToken : sessionStorage.getItem("msalAccessToken");
                headers = {
                    "Content-Type": "application/json",
                    Authorization: 'Bearer ' + token
                };
                 ExportTo(headers, reportId, groupId, description, error, country);
            }
             setCurrentReport(false);
        };
    };

    const cancelExport = () => {
        props.hideSettingsWindow("e");
    };

    return (
        <div className="container">
            <ToastContainer />
            <div className="col-12 sections my-2" id="basic_screen">
                <form className="form">
                    <div className="row">
                        <div className="col-12  col-xl-12 flexCol">
                            <label htmlFor="export" className="label">Export With</label>
                            <select name="filtervalue" id="values" className="selValue" onChange={(e) => handleSelectValue(e)}>
                                <option value="currentValues">Current Values</option>
                                {
                                    playBookData.msalEnabled ?
                                        null
                                        :
                                        <option value="defaultValues"> Default Values</option>
                                }
                            </select>
                        </div>
                        <div className="col-12  col-xl-12 form-group ">
                            <input type="checkbox" id="currentReport" name="currentReport" onChange={(e) => handleSelectCheckbox(e)} checked={currentReport} />
                            <label for="currentPage" className="label">Only export current page</label>
                        </div>
                        <div className="col-12  col-xl-12 btnAlignment">
                            <button type="button" className="pBtn" onClick={() => exportData()}>Export</button>
                            <button type="button" className="cancelBtn" onClick={() => cancelExport()}>Cancel</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
};

export default ExportFile;


