import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetehUserProfile, } from '../../redux/actions/index';
import '../Home/style.css';
import { useHistory } from "react-router-dom";

const UserProfile = () => {
    const dispatch = useDispatch();
    let history = useHistory();

    const loggedInUser = useSelector((state) => state.userProfile);
    const { activeRegion } = useSelector((state) => state.activeDynamicData);
    const oktaAuth = useSelector((state) => state.oktaAuth);
    const { user } = loggedInUser;

    const [userDetails, setTargetUser] = useState([]);


    useEffect(() => {
        if (Array.isArray(user) && user.length > 0) {
            setTargetUser(user[0]);
        }
    }, [loggedInUser, user]);

    useEffect(() => {
        let sessionData = JSON.parse(sessionStorage.getItem('oktaRes'));
        if (sessionData && sessionData.email) {
            let params = {
                email: sessionData.email,
            };
            dispatch(fetehUserProfile(params));
        }
    }, [activeRegion, dispatch]);

    const visitUsers = () => {
        history.push('/users-list'); //activeRoutes.key10
    }

    const renderTableBody = () => {
        if(userDetails && Object.keys(userDetails).length>0){
            return (
                <tbody>
                    <tr>
                        <td>Name</td>
                        <td>:</td>
                        <td>{userDetails['First Name'] + ' ' + userDetails['Last Name']}</td>
                    </tr>
                    <tr>
                        <td>Email</td>
                        <td>:</td>
                        <td>{userDetails['Email']}</td>
                    </tr>
                    <tr>
                        <td>Region</td>
                        <td>:</td>
                        <td>{userDetails['Region']}</td>
                    </tr>
                    <tr>
                        <td> KC ID</td>
                        <td>:</td>
                        <td>{userDetails[" KC ID"]}</td>
                    </tr>
                    <tr>
                        <td>Key User </td>
                        <td>:</td>
                        <td>{userDetails["Key User "]}</td>
                    </tr>
                    <tr>
                        <td>User Base Country</td>
                        <td>:</td>
                        <td>{userDetails["User Base Country"]}</td>
                    </tr>
                    <tr>
                        <td>Market</td>
                        <td>:</td>
                        <td>{userDetails["Market"]}</td>
                    </tr>
                    <tr>
                        <td>Team</td>
                        <td>:</td>
                        <td>{userDetails["Team"]}</td>
                    </tr>
                    <tr>
                        <td>Ad Groups</td>
                        <td>:</td>
                        <td style={{ textAlign: 'left' }}>
                            {
                                Array.isArray(oktaAuth.okta.groups) &&
                                oktaAuth.okta.groups.length > 0 && 
                                oktaAuth.okta.groups.filter((el)=> el.includes(process.env.REACT_APP_OKTA_ENV)).map((el) => (
                                    <li>{el}</li>
                                ))
                            }
                        </td>
                    </tr>
                </tbody>
            )
        }
    }


    return (
            <div className="main">
                <div className="card">
                    <div className="card-body" style={{paddingTop : 0 }}>
                        <div   style={{display : 'flex',    borderBottom : '1px Solid #e5e5e5'}}>
                            <h3   style={{display : 'flex',  justifyContent: 'end', width: '50%' , lineHeight: '52px'   }}>User Profile</h3>
                            {oktaAuth.adminType === "Contributor" ? (
                            <div className={"userAllignment"}>
                                {" "}
                                <span
                                    className={"addNewBoard border addModule"}
                                    onClick={visitUsers}
                                >
                                    {" "}
                                    Manage Users{" "}
                                </span>{" "}
                            </div>
                        ) : null}
                        </div>
                        <table>
                            {renderTableBody()}
                        </table>
                    </div>
                </div>
            </div>
     );
}

export default UserProfile;