import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { handleSilentLogin, loginRequest, msalConfig } from "../../component/msal/loginrequest";
import { UserAgentApplication } from "msal";
import { Report } from 'powerbi-report-component';
import { models } from 'powerbi-client';
import { useStyles } from "./style.js";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import {
    updatePowerBIReportPage,
    updatePowerBIReport,
    capturePlaybooksData,
    updateMsalTokenFlag,
    updatePlaybooksContentPages,
    toastTriggered,
    
} from '../../redux/actions/index';
import CircularProgress from '@material-ui/core/CircularProgress';
import utils from "../../utils";
import toastProperties from "../../assets/data/toastProperty";
import './style.css';

const PlaybookReport = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    let history = useHistory();

    const msalInstance = new UserAgentApplication(msalConfig);
    const { reportSectionId,    subRegion, activeIndex, navContentPaneEnabled } = props;

    const playbooksSelectedRegionFilter = useSelector((state) => state.playbooksSelectedRegionFilter);
    const powerbiToken = useSelector((state) => state.getEmbedToken);
    const bookmarkView = useSelector((state) => state.bookmarkView);
    const errorMessage = useSelector((state) => state.getErrorMessages);
    const presistApplicable = useSelector((state) => state.updatePresist);
    const ShareLinkData = useSelector((state) => state.fetchShareLinkDetails);
    const captureBookmark = useSelector((state) => state.capturePlaybookData);
    const playBookData = useSelector((state) => state.activePBIReport);
    const activeReportSection = useSelector((state) => state.activeReportSection);
     
    const [noData, setNodata] = useState(false);
    const [currentReport, setCurrentReport] = useState(null);
    const [reportIDs, setReportIDs] = useState({});
    const [embedError, setEmbedError] = React.useState(null);

    const { isMsalTokenExpired, activeRegion, activePage } = useSelector((state) => state.activeDynamicData);

    useEffect(() => {
        const PBIDown = errorMessage?.data?.find(item => item.errorType === "PBI API Down")
        setEmbedError(PBIDown?.errorMessage);
        // const noReport = errorMessage?.data?.find(item => item.errorType === "No Reports Available")
        // setNoReport(noReport?.errorMessage)
    }, [errorMessage]);

    useEffect(() => {
        if (playBookData && Object.keys(playBookData).length > 0) {
            const { htmlTemp, grouped, subReport, msalEnabled } = playBookData;
            const groupId = !!htmlTemp && htmlTemp.groupId;
            const reportId = !!htmlTemp && htmlTemp.reportId;
            console.log('checkValidIds', groupId, reportId, htmlTemp, playBookData, powerbiToken);
            if (groupId === powerbiToken.groupId && reportId === powerbiToken.reportId && !msalEnabled) {
                htmlTemp.token = powerbiToken.embedToken;
                 console.log('checkValidIds', 111, groupId, reportId, htmlTemp, playBookData);
            }
            if (groupId && reportId) {
                const params = {
                    reportId: reportId,
                    groupId: groupId,
                }
                if (Array.isArray(subReport) && subReport.length > 0) {
                    setReportIDs(params);
                    console.log('checkReportIds', 111, groupId, reportId, subReport);
                }
                else if (msalEnabled & grouped && Array.isArray(subReport) && subReport.length === 0) {
                    dispatch(updatePowerBIReport({}));
                    setReportIDs({});
                    console.log('checkReportIds', 222, groupId, reportId, subReport);
                }
                else {
                    setReportIDs(params);
                    console.log('checkReportIds', 333, groupId, reportId, subReport);
                }
                setNodata(false);
            }
            else if (!groupId && !reportId) {
                console.log('checkReportIds', 444, groupId, reportId, subReport);
                setReportIDs({});
             }
        }
     }, [dispatch,  playBookData,  powerbiToken]);

    console.log('checkReportData', activePage,  playBookData, reportIDs, activeReportSection, 'props', props, reportSectionId,  presistApplicable);

    useEffect(() => {
        if (!!playBookData && Object.keys(playBookData).length > 0) {
            const { htmlTemp } = playBookData;
            const groupId = !!htmlTemp && htmlTemp.groupId;
            const reportId = !!htmlTemp && htmlTemp.reportId;
            const reportLink = !!htmlTemp && htmlTemp.reportLink;
            let params = {
                reportId: reportId,
                groupId: groupId,
                pageType: activePage['key'],
                pageName: activePage['parentModule'],
                viewName: playBookData.viewName,
                viewIndex: activeIndex,
                reportLink: reportLink,
                reportSectionId: activeReportSection.activeSubMenu,
                reportSectionName: activeReportSection.activeSubMenuName ,
                region: activeRegion,
                subRegion: subRegion,
                otherDetails: {parentId : activePage.parentId, childId : activePage.childId}
            };
            dispatch(capturePlaybooksData(params));
        }
    }, [dispatch, playBookData, powerbiToken, activeIndex, activeRegion, subRegion, activePage, activeReportSection]);

    useEffect(() => {
        if (activePage && !!captureBookmark && (activePage['pageName'] === "Ad Hoc" || activePage['pageName'] === "Foundation Analytics")) {
            currentReport && currentReport.on("rendered", function (event) {
                currentReport.getPages().then((pages) => {
                    console.log('@CaptureShareLinkDetails', 222, pages, captureBookmark)
                    let page = pages.filter(function (page) { return page.isActive })[0];
                    if (!captureBookmark.reportSectionId && !captureBookmark.reportSectionName) {
                        captureBookmark['reportSectionId'] = page.name;
                        captureBookmark['reportSectionName'] = page.displayName;
                        dispatch(capturePlaybooksData(captureBookmark));
                    }
                    else if (captureBookmark.reportSectionId !== page.name && !captureBookmark.reportSectionName !== page.displayName) {
                        captureBookmark['reportSectionId'] = page.name;
                        captureBookmark['reportSectionName'] = page.displayName;
                        dispatch(capturePlaybooksData(captureBookmark));
                    }
                })
            })
        }
        currentReport && currentReport.on("pageChanged", function (event) {
            let page = event.detail.newPage;
            if (!!captureBookmark && !captureBookmark.reportSectionId && !captureBookmark.reportSectionName) {
                captureBookmark['reportSectionId'] = page.name;
                captureBookmark['reportSectionName'] = page.displayName;
                dispatch(capturePlaybooksData(captureBookmark));
            }
        });

    }, [captureBookmark, currentReport, activePage, dispatch]);

    useEffect(() => {
        const type = history.location.state;
        if (type === "bookmark") {
            if (!!bookmarkView && Object.keys(bookmarkView).length > 0) {
                let bookmarkState = bookmarkView.bookmarkState;
                console.log('applyingBookmarkState', bookmarkView, playBookData);
                if (bookmarkView.viewName === playBookData.viewName) {
                    currentReport && currentReport.bookmarksManager.applyState(bookmarkState);
                }
            }
        }
    }, [bookmarkView, currentReport, bookmarkView.bookmarkState, playBookData, history.location.state]);

    useEffect(() => {
        let data = sessionStorage.getItem('presistReport');
        const shareState = !!data ? JSON.parse(data) : null;
        let ShareLink = ShareLinkData && ShareLinkData.data[0];
        let appliedState = !!ShareLink ? ShareLink.shareLinkState : shareState ? shareState.state : null;
        currentReport && currentReport.bookmarksManager.applyState(appliedState);
    }, [ShareLinkData, currentReport]);

    const reportLoad = (report) => {
        dispatch(updatePowerBIReport(report));
        const data = sessionStorage.getItem('presistReport');
        const presistReport = !!data ? JSON.parse(data) : null;
        let pageName = !!presistReport ? presistReport.activeLeverReportSection : null;
        report.setPage(pageName);
    };

    const reportStyle = {
        height:  "85vh",
        fontSize: "80%",
        width: "100vw",
     };
   
    const extraSettings = {
        filterPaneEnabled: false, //true
        navContentPaneEnabled: navContentPaneEnabled, //true
        hideErrors: false, // Use this *only* when you want to override error experience i.e, use onError

        // background: models.BackgroundType.Transparent,
        //...layoutSettings, // layout config
        // ... more custom settings
    };

    const handlePageChange = async (data) => {
        const params = { data: data.newPage };
        const type = history.location.state;
        await dispatch(updatePowerBIReportPage(params));
        let trackingFilter = {};
        !!playbooksSelectedRegionFilter && Object.keys(playbooksSelectedRegionFilter['dataSet']).map((item) => {
            trackingFilter[item] = playbooksSelectedRegionFilter.dataSet[item]['activeItem'];
        });
        console.log('@userTrackingInfo',   data )
        let trackingObject = {
            region: activeRegion,
            actionType: 'Click',
            pageName: activePage['pageName'],
            reportName: playBookData.viewName,
            reportSection: data.newPage.displayName,
            reportSectionId: data.newPage.name,
            globalFilters: JSON.stringify(trackingFilter),
            reportLink: playBookData['htmlTemp']['reportLink'],
            groupId: playBookData['htmlTemp']['groupId'],
            reportId: playBookData['htmlTemp']['reportId'],
            subRegion: subRegion,
            workspaceName: playBookData.htmlTemp.workspace
        };
        if (type !== "bookmark" || type.type !== "ShareReport") {
            console.log('aimtracking', type.type)
            await utils.userTrackingInfo(trackingObject);
        }
    };

    /* *******     hide slicers    *************
      step1:get the report instance
      step2:get report pages
      step3:filter active page
      step:4:get all visulas of active page
      step5:filter visulas of type slicers
      step:apply new template to selected slicers
    *****************************************  */
    const handleSlices = (report) => {
        if (activePage['key'] === "Playbooks") {
            report.getPages().then((pages) => {
                let activePage = pages.filter(function (page) {
                    return page.isActive
                })[0];

                activePage.getFilters().then((res) => {
                    console.log("page level filters", res)
                })
                    .catch((err) => {
                        console.log("page level filters", err)
                    })

                console.log("1111_active page", pages, activePage, models.VisualContainerDisplayMode)
                activePage.getVisuals().then((visuals) => {

                    let tempVisualsLayout = {};
                    let tempPagesLayout = {};
                    visuals.map((visual, index) => {
                        if (visual.type === 'slicer') {

                            visual.getFilters().then((res) => {
                                console.log("slicer tttttt---" + index + "-----", res)
                            })
                                .catch((err) => {
                                    console.log("1111_all_vis", err)
                                })


                            visual.getSlicerState().then((res) => {
                                console.log("slicer tttttt---" + index + "-----", res)
                            })
                                .catch((err) => {
                                    console.log("1111_all_vis", err)
                                })

                            if (activePage.displayName.startsWith("Landing Page")) {
                                tempVisualsLayout[visual.name] = {
                                    x: 0,
                                    y: 0,
                                    z: 0,
                                    width: 0,
                                    height: 0,
                                    displayState: {
                                        mode: models.VisualContainerDisplayMode.Hidden,
                                    },
                                }
                            }
                        }
                    });
                    tempPagesLayout[activePage.name] = {
                        // defaultLayout: {
                        //     displayState: {
                        //         mode: models.VisualContainerDisplayMode.Visible,
                        //     },
                        // },
                        visualsLayout: tempVisualsLayout,
                    }
                    let tempLayoutSettings = {
                        layoutType: models.LayoutType.Custom,
                        customLayout: {
                            pagesLayout: tempPagesLayout,
                        },
                    };
                    report.updateSettings(tempLayoutSettings);
                });
            });
        }
        reportLoad(report);
    };

    const handleEmbedError = (data) => {

        if (data['message'] === "TokenExpired") {
            if (process.env.REACT_APP_ENVIRONMENT === "LOCAL") {
                toastProperties.error["description"] = 'MSAL Access Token is expired, Please get a new token';
                dispatch(toastTriggered([toastProperties.error]));
            }
            else if (msalInstance.getAccount()) {
                console.log('differentError', data)
                dispatch(updateMsalTokenFlag(true, "default repo"))
                handleSilentLogin(msalInstance, loginRequest);
            } else {
                history.push({
                    pathname: "/msalcallback",
                    state: {},
                });
            }
        }
        else if ((playBookData.buType === "adhoc" || playBookData.buType === "Foundation Analytics") && data['message'] === "PowerBINotAuthorizedException") {
            console.log('differentError', playBookData)
            let selections = {
                reportId: reportIDs['reportId'],
                groupId: reportIDs['groupId'],
                viewID: playBookData.viewId,
                pageType: activePage.key,
            }
            let res = {
                data: []
            }
            let payload = { ...res, selections };
            dispatch(updatePlaybooksContentPages(payload));
            dispatch(updatePowerBIReport({}));
        }
    };

    const UpdateReportPages = (report) => {
        console.log();
        const { customizableFlag, htmlTemp, msalEnabled, hiddenPages, reportPages } = playBookData;
        console.log('UpdateReportPages', 111, playBookData, customizableFlag, htmlTemp, msalEnabled, hiddenPages, reportPages);
        if (reportPages && hiddenPages) {
            dispatch(updatePowerBIReport(report));
            report.getPages().then((allPage) => {
                let data = Array.isArray(allPage) && allPage.length > 0 &&
                    allPage.filter((pages) => {
                        return pages.visibility === 0
                    });
                data.forEach((el, index) => {
                    el.order = index
                    el.activeReportPage = true;
                });
                console.log('UpdateReportPages', 222, allPage, data);
                let landingPageIndex = data.findIndex((el)=> el.displayName.startsWith('Landing'));
                if(landingPageIndex > -1){
                data.splice(landingPageIndex,1);
                }
                if (Array.isArray(data) && data.length > 0) {
                    let res = {
                        data
                    }
                    let selections = {
                        reportId: reportIDs['reportId'],
                        groupId: reportIDs['groupId'],
                        viewID: playBookData.viewId,
                        pageType: activePage.key,
                    }
                    let payload = { ...res, selections };
                    dispatch(updatePlaybooksContentPages(payload));
                }
            })
        }
    };

    const renderPowerBIDashboard = () => {
        console.log();
        const { htmlTemp, msalEnabled } = playBookData;
        if (!!playBookData && Object.keys(playBookData).length > 0) {
            let { loading, error, embedToken, responseDefault } = powerbiToken;
            console.log('renderPowerBIDashboard', 333, reportIDs, playBookData);
            if (!msalEnabled && !!loading && !error && embedToken === '') {
                console.log('renderPowerBIDashboard', 444, loading, error, embedToken);
                return (
                    <div className={classes.position}>
                        <CircularProgress color="primary" />
                    </div>
                )
            }
            else if (!msalEnabled && !loading && !!error && !responseDefault && embedToken === '') {
                console.log('renderPowerBIDashboard', 555, loading, error, responseDefault, embedToken);
                return (
                    <div className={classes.serverDown}>
                        <div className="noReports"></div>
                        <div className={classes.errorMessage}>{embedError}</div>
                    </div>
                )
            }
            else if (!msalEnabled && htmlTemp && htmlTemp.hasOwnProperty('token') && htmlTemp.token !== '') {
                console.log('msalEnabledReportIdsGroupIds', 111, playBookData, reportIDs,   htmlTemp);
                if (!!reportIDs && Object.keys(reportIDs).length > 0 && reportIDs['reportId'] === htmlTemp.reportId) {
                    let embedUrl = `https://app.powerbi.com/reportEmbed?reportId=${htmlTemp.reportId}&groupId=${htmlTemp.groupId}`;
                    return (
                        <Report
                            tokenType="Embed"
                            embedType="report"
                            accessToken={htmlTemp.token}
                            embedUrl={embedUrl}
                            embedId={htmlTemp.reportId}
                            reportMode="View"
                            groupId={htmlTemp.groupId}
                            extraSettings={extraSettings}
                            permissions="All"
                            style={reportStyle}
                            onRender={(report) => {
                                console.log('loadStatus-report onRender', report)
                                setCurrentReport(report);
                                UpdateReportPages(report);
                            }}
                            onLoad={(report) => {
                                setCurrentReport(report);
                                handleSlices(report);
                            }}
                            onError={(data) => { console.log('onError', data); }}
                            onPageChange={(data) => handlePageChange(data)}
                        />
                    )
                }
            }
            else if (msalEnabled && htmlTemp['msalToken']) {
                if (htmlTemp && !!reportIDs && htmlTemp['reportId'] && Object.keys(reportIDs).length > 0 && reportIDs['reportId'] && htmlTemp['reportId'] === reportIDs['reportId']) {
                    console.log('msalEnabledReportIdsGroupIds', 222, playBookData, reportIDs, htmlTemp);
                    let embedUrl = `https://app.powerbi.com/reportEmbed?reportId=${htmlTemp.reportId}&groupId=${htmlTemp.groupId}`;
                    return (
                        <Report
                            tokenType="access"
                            embedType="report"
                            accessToken={htmlTemp['msalToken']}
                            embedUrl={embedUrl}
                            embedId={reportIDs['reportId']}
                            groupId={reportIDs['groupId']}
                            extraSettings={extraSettings}
                            reportMode="View"
                            permissions="All"
                            style={reportStyle}
                            onRender={(report) => {
                                setCurrentReport(report);
                                UpdateReportPages(report);
                            }}
                            onError={(data) => {
                                console.log('onError', data);
                                handleEmbedError(data)
                            }}
                            onLoad={(report) => {
                                setCurrentReport(report);
                                reportLoad(report);
                            }}
                            onPageChange={(data) => handlePageChange(data)}
                        />
                    )
                }
            }
            else {
                console.log('msalEnabledReportIdsGroupIds', 333, playBookData, reportIDs,   htmlTemp);
                return <div>...Loading</div>
            }
        }
    };

    let getLandingPageNavigationFlag = () => {
        if (activePage) {
            if (!activePage['pbiLandingPageNavigation'] && Object.keys(activeReportSection).length > 0 && activeReportSection.activeSubMenu && activeReportSection.activeSubMenu.includes("Landing"))
                return (
                    <div className={classes.blockingClickLayer} />
                )
            else
                return false
        }
    };

    return (
        <div className={classes.root}>
            <Paper className={!!noData ? classes.paper1 : classes.paper}>
                <Grid container item xs={12} className={classes.flexContainer}>
                    {getLandingPageNavigationFlag()}
                    {
                        !isMsalTokenExpired ?
                            renderPowerBIDashboard()
                            :
                            <div
                                style={{
                                    width: '300px',
                                    margin: '9% auto auto',
                                    height: '200px',
                                    background: 'white',
                                    borderRadius: '10px'
                                }}
                            >
                                <div style={{ position: "relative", top: "37%", fontSize: '14px' }}>
                                    Loading...
                                    <br />
                                    Please wait, loading Required Resources.
                                </div>
                            </div>
                    }
                </Grid>
            </Paper>
        </div>
    );
}

export default PlaybookReport;
