let defaultState = {
  data: [],
  loading: true,
  error: false,
  responseDefault: true,
  success: false,
}

export default (state = defaultState, action) => {

  switch (action.type) {

    case 'UPDATE_PLAYBOOKS_CONTENT_PAGE':
    return {
      data: action.payload.data,
      loading: false,
      error: false,
      responseDefault: false,
      success: true,
      groupId: action.payload.selections.groupId,
      reportId: action.payload.selections.reportId,
    }

    case 'GET_PLAYBOOKS_CONTENT_PAGES_REQUEST':
      return {
        data: [],
        loading: true,
        error: false,
        responseDefault: true,
        success: false,
      }

    case 'GET_PLAYBOOKS_CONTENT_PAGES_SUCCESS':
      return {
        data: action.payload.data.data,
        loading: false,
        error: false,
        responseDefault: false,
        success: true,
        groupId: action.payload.selections.groupId,
        reportId: action.payload.selections.reportId
      }

    case 'GET_PLAYBOOKS_CONTENT_PAGES_FAILURE':
      return {
        ...state,
        data:[],
        message: action.payload.response.data['message'],
        userMessage: action.payload.response.data['usermessage'],
        error: true,
        responseDefault: false,
        success: false,
        loading: false,
      }

    default:
      return state;

  }

};