export default (state = [], action) => {

    switch (action.type) {

        case 'UPDATE_SIDEBAR_MENU_LIST':
            return state;

        case 'SIDEBAR_MENU_LIST':
            return action.payload;

        default:

            return state;

    }

};