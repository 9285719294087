export default (
  state = {
    quarter: [],
    loading: false,
    defaultResponse: true,
    message: "",
    userMessage: "",
  },
  action
) => {
  switch (action.type) {
    case "NEWSLETTER_QUARTER_REQUEST":
      return {
        quarter: [],
        loading: true,
        message: "",
        userMessage: "",
        defaultResponse: false,
      };

    case "NEWSLETTER_QUARTER_SUCCESS":
      return {
        quarter: action.payload,
        defaultResponse: false,
        loading: false,
        success: true,
        error: false,
        message: "",
        userMessage: "",
      };

    case "NEWSLETTER_QUARTER_FAILURE":
      return {
        quarter: [],
        error: true,
        defaultResponse: false,
        loading: false,
        success: false,
      };

    default:
      return state;
  }
};
