const defaultState = {
    data: {},
    loading: false,
    success: false,
    defaultResponse: true,
    error: null,
    message: null,
};

export default (state = defaultState, action) => {



    switch (action.type) {

        case 'DOWALOAD_APPLICATION_USAGE_DATA':
            return { ...state ,  loading: true, success: false, data: []};

        case 'DOWALOAD_APPLICATION_USAGE_DATA_SUCCESS':
            return {
                ...state,
                data: action.payload,
                defaultResponse: false,
                success: true,
                loading: false
            };

        case 'DOWALOAD_APPLICATION_USAGE_DATA_FAILURE':
            return {
                ...state,
                data: [],
                defaultResponse: false,
                error: true,
                Success: false,
                message: action.payload.response.data.message,
                userMessage: action.payload.response.data['usermessage'],
                loading: false
            };

        default:

            return state;

    }

};

