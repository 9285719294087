const defaultState = {
    childParentIds: [],
    loading: false,
    success: false,
    defaultResponse: true,
    error: null,
    message: null,
};

export default (state = defaultState, action) => {

    switch (action.type) {

        case 'CHILD_PARENT_MAPPPED_DATA_REQUEST':
            return { ...state ,  loading: true, success: false, childParentIds: []};

        case 'CHILD_PARENT_MAPPPED_DATA_SUCCESS':

            return {
                ...state,
                childParentIds: action.payload.data,
                defaultResponse: false,
                success: true,
                loading: false
            };

        case 'CHILD_PARENT_MAPPPED_DATA_FAILURE':
            return {
                ...state,
                childParentIds: [],
                defaultResponse: false,
                message: action.payload.response.data.message,
                userMessage: action.payload.response.data['usermessage'],
                loading: false,
                success: false,
                error: true
            };

        default:

            return state;

    }

};

