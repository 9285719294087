export default (state = { market: [] }, action) => {
  switch (action.type) {
    
    case "FETCH_REGION_SPECIFIC_USER_MARKET_REQUEST":
      return {
        market: [],
        loading: true,
        marketLoading :   true,
      };

    case "FETCH_REGION_SPECIFIC_USER_MARKET_SUCCESS":
      return {
        market: action.payload,
        responseDefault: false,
        loading: false,
        success: true,
        error: false,
        marketLoading :   false,
      };

    case "FETCH_REGION_SPECIFIC_USER_MARKET_FAILURE":
      return {
        market: [],
        error: true,
        responseDefault: false,
        loading: false,
        success: false,
        marketLoading : false,
       };

    default:
      return state;
  }
};
