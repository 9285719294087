export default (
    state = {
      loading: false,
      defaultResponse: true,
      mappingData: [],
      message: "",
      userMessage: "",
      error: false,
    },
    action
  ) => {
    switch (action.type) {
      case "FETCH_REGIONAL_MAPPING_DATA_REQUEST":
        return {
          ...state,
          loading: true,
          defaultResponse: false,
          mappingData: [],
          message: "",
          userMessage: "",
          error: false,
        };

      case "FETCH_REGIONAL_MAPPING_DATA_SUCCESS":
        return {
          ...state,
          mappingData: action.payload,
          Success: true,
          loading: false,
          defaultResponse: false,
          message: "",
          userMessage: "",
          error: false,
        };

      case "FETCH_REGIONAL_MAPPING_DATA_FAILURE":
        return {
          ...state,
          mappingData: [],
          Success: false,
          error: true,
          // message: action.payload.response.data["message"],
          // userMessage: action.payload.response.data["usermessage"],
          loading: false,
          defaultResponse: false,
        };

      default:
        return state;
    }
  };
