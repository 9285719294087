import React, { useEffect, useState } from "react";
import { /* useDispatch   */ useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Table from "../../component/table/index";


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 0,
    width: "100%",
  },
  paper: {
    padding: theme.spacing(2, 0),
    margin: "0 0 10px 0",
    width: "auto",
    height: "auto",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    boxShadow: "none",
    background: "#fff",
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },
  flexContainer: {
    display: "flex",
    flexDirection: "row",
    height: "100%",
  },
  flexItem: {
    display: "flex",
    flexDirection: "row",
    border: "1px solid #e5e5e5",
    borderRadius: 15,
    boxShadow: " rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
    width: "375px",
    margin: "1rem 3.2rem",
    textAlign: "center",
    lineHeight: "75px",
    fontSize: "30px",
    padding: "12px",
  },
  title: {
    margin: "1rem 20px 20px",
    textAlign: "justify",
    fontFamily: "Gilroy-Medium",
    fontSize: 14,
  },
  folderList: {
    margin: "20px 20px 0 20px",
  },
  addNew: {
    background: "#ffffff",
    color: "#0f059e",
    border: "1px solid",
    margin: "0 20px 20px 0",
    fontSize: 14,
    lineHeight: 2,
  },
}));

const adminColumns = [
  { id: "id", label: "S.No.", minWidth: 10, width: 10, align: "center" },
  {
    id: "displayName",
    label: "Report",
    minWidth: 10,
    width: 300,
    align: "left",
  },
  {
    id: "description",
    label: "Description",
    minWidth: 100,
    width: "auto",
    align: "left",
  },
  { id: 'Edit', label: 'Edit/Update', minWidth: 100, width: 'auto', align: 'left' },
  { id: 'Order', label: 'Update Position', minWidth: 100, width: 'auto', align: 'left' },
  { id: 'Delete', label: 'Delete', minWidth: 100, width: 'auto', align: 'left' },
];

const userColumns = [
  { id: "id", label: "S.No.", minWidth: 10, width: 10, align: "center" },
  {
    id: "displayName",
    label: "Report",
    minWidth: 10,
    width: 300,
    align: "left",
  },
  {
    id: "description",
    label: "Description",
    minWidth: 100,
    width: "auto",
    align: "left",
  }
];


const SelfService = (props) => {
  const classes = useStyles();

  const [errorMessage, setErrorMessage] = useState(false);
  const { data, loading, selectedTab } = props;
  const { activePage } = useSelector((state) => state.activeDynamicData);
  const oktaAuth = useSelector((state) => state.oktaAuth);
  const getPageContent = useSelector((state) => state.getPageContent);

  useEffect(() => {
    if (!!getPageContent.error) {
      setErrorMessage(getPageContent.userMessage);
    }
  }, [getPageContent]);

  const { updateSelfServiceLink,   updateLinksDisplayOrder, deleteSelfServiceLink } = props;

  const updateSelfService = (data) => {
    updateSelfServiceLink(data)
  }

  const updateLinksPosition = () => {
    updateLinksDisplayOrder(data)
  }

  const deleteSelfService = (data) =>{
    deleteSelfServiceLink(data)
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Grid
          container
          item
          xs={12}
          spacing={3}
          className={classes.flexContainer}
        >
          <Grid item xs={12}>
            <div className={classes.folderList}>
              <Table
                data={!!data ? data : []}
                loading={loading}
                type={activePage && activePage.pageName}
               columns={ oktaAuth && oktaAuth.adminType === "Contributor" ? adminColumns : userColumns }
                errorMessage={errorMessage}
                selectedTab={selectedTab}
                updateSelfService={updateSelfService}
                updateLinksPosition={updateLinksPosition}
                deleteSelfService={deleteSelfService}
              />
            </div>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default SelfService;
