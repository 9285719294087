import React from "react";
import './style.css';

export default function DotFlashing() {
  return (
    <div className="snippet" data-title=".dot-flashing">
          <div className="stage">
            <div className="dot-flashing"></div>
          </div>
    </div>
  );
}