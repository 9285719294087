import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BusinessUnit from '../businessUnit/index';
import Typography from '@material-ui/core/Typography';
import { updateShareReport } from '../../redux/actions/index';
import './style.css';

const Home = (props) => {
    const dispatch = useDispatch();
    const [region, setRegion] = useState('');
    const [subRegion, setSubRegion] = useState('');
    const [nodeDownError, setNodeDownError] = useState('');
    const [infoMsg, setInfoMsg] = useState('');
    const [timeLeft, setTimeLeft] = useState(10);

    const selectedRegion = useSelector((state) => state.getBusinessUnitsRegion);
    const selectedSubRegion = useSelector((state) => state.updatedSubRegion);
    const ShareLinkData = useSelector((state) => state.fetchShareLinkDetails);
    const regionlist = useSelector((state) => state.regions);
    const apiFailure = useSelector((state) => state.apiFailure);
    const errorMessage = useSelector((state) => state.getErrorMessages);

    let data = localStorage.getItem("NodeDown");
    const { activeMsalToken } = useSelector((state) => state.activeDynamicData);

    useEffect(() => {
        const serverNodeDown = errorMessage?.data?.find(item => item.errorType === "Unresponsive Server");
        let presistNodeDown = data !== 'undefined' ? JSON.parse(data) : null;
        let message = presistNodeDown !== null ? presistNodeDown.infoMessage : !!serverNodeDown ? serverNodeDown.infoMessage : `😞 Oops! We seem to be having some trouble right now. We are working on resolving this. Please try again in a little time`;
        let info = presistNodeDown !== null ? presistNodeDown.errorMessage : !!serverNodeDown ? serverNodeDown.errorMessage : `Do you know we have donated 270 million Huggies Diapers in Last 10 years!, Do you know 25% of Globe use one of our products everyday`;
        setNodeDownError(message);
        setInfoMsg(info);
    }, [data, errorMessage.data]);

    useEffect(() => {
        if (timeLeft === 0) {
            setTimeLeft(0);
            let params = {
                loading: true,
                defaultResponse: true,
                Success: false,
                data: [],
                error: false
            };
            sessionStorage.removeItem('presistReport');
            dispatch(updateShareReport(params));
        }

        // exit early when we reach 0
        if (!timeLeft) return;

        // save intervalId to clear the interval when the
        // component re-renders
        const intervalId = setInterval(() => {
            setTimeLeft(timeLeft - 1);
        }, 1000);

        // clear interval on re-render to avoid memory leaks
        return () => clearInterval(intervalId);
        // add timeLeft as a dependency to re-rerun the effect
        // when we update it
    }, [dispatch, timeLeft]);

    useEffect(() => {
        if (!!selectedRegion && Object.keys(selectedRegion).length > 0) {
            setRegion(selectedRegion.region);
        }
        if (!!selectedSubRegion && Object.keys(selectedSubRegion).length > 0) {
            setSubRegion(selectedSubRegion.subRegion);
        }
    }, [selectedRegion, selectedSubRegion]);

    return (
        <div>
            {
                apiFailure.message === "Network Error" || apiFailure.message === 'Request failed with status code 404' ?
                    <div className="verticalAlign">
                        <h2 >{nodeDownError}</h2>
                        <br></br>
                        <h3>{infoMsg}</h3>
                    </div>
                    :
                    regionlist.error === true ?
                        <div className="verticalAlign">
                            <h2 style={{ textAlign: "center", width: "100%", justifyContent: "center" }}>{regionlist.userMessage}</h2>
                        </div>
                        :
                        !!ShareLinkData.error && ShareLinkData.message === "No data Found for since one of the param is wrong" ?
                            <div className="sharelink" style={{ height: "75vh", justifyContent: 'center', display: 'flex', flexDirection: 'column' }}>
                                <Typography gutterBottom variant="h5" style={{ marginTop: '4rem' }}>
                                    This seems to be an issue with your link, Please confirm your link and try again
                                    <br></br>
                                    You will be redirected to RGM Analytics home in {timeLeft} sec
                                </Typography>
                            </div>
                            :
                            activeMsalToken ?
                                <BusinessUnit
                                    props={props}
                                    region={region}
                                    subRegion={subRegion}
                                />
                                :
                                <div  className="loadPosition">
                                    <div  className="loadRequired">
                                        Loading...
                                        <br />
                                        Please wait, loading Required Resources.
                                    </div>
                                </div>
            }
        </div>
    );
}

export default Home;