export default (
    state = {
        loading: false,
        defaultResponse: true,
        Success: false
    },
    action) => {

        switch (action.type) {

        case 'EDIT_BOOKMARK_REQUEST':
            return {
                ...state,
                loading: true,
                defaultResponse: true,
                Success: false,

            }

        case 'EDIT_BOOKMARK_SUCCESS':
            return {
                ...state,
                data: action.payload.data,
                Success: action.payload.Success,
                message: action.payload.message,
                loading: false,
                defaultResponse: false,

            };

        case 'EDIT_BOOKMARK_FAILURE':
            return {
                ...state,
                error: true,
                Success: false,
                message: action.payload.response.data.message,
                userMessage: action.payload.response.data['usermessage'],
                loading: false,
                defaultResponse: false,

            };

        default:

            return state;

    }

};