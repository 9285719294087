
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 0,
        width: '100%'
    },
    paper: {
        padding: '0',
        margin: '15px 0 0',
        width: 'auto',
        height: 'auto',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: "center",
        boxShadow: 'none',
        background: '#f4f6f8'
    },
    previewPaper: {
        width: 'auto',
        height: 'auto',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: "center",
        boxShadow: 'none',
        background: '#f4f6f8'
    },
    paper1: {
        padding: '0 24px',
        margin: '0 0 3rem',
        width: 'auto',
        height: 'auto',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: "center",
        boxShadow: 'none',
        background: '#f4f6f8'
    },
    backdrop: {
        zIndex: 10000,
        color: '#fff',
    },
    flexRow: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%'
    },
    flexContainer: {
        display: 'flex',
        flexDirection: 'row',
        height: '100%',
    },
    flexItem: {
        display: 'flex',
        flexDirection: 'row',
        border: '1px solid #e5e5e5',
        borderRadius: 15,
        boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
        width: '375px',
        margin: '1rem 3.2rem',
        textAlign: 'center',
        lineHeight: '75px',
        fontSize: '30px',
        padding: '12px'
    },
    noData: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        width: 'inherit',
        fontSize: '20px',
        fontWeight: '600'
    },
    imgFit: {
        objectFit: 'scale-down',
        width: '42%',
    },
    smImgFit: {
        objectFit: 'scale-down',
        width: '21%',
    },
    serverDown: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        width: 'inherit',
        fontSize: '20px',
        fontWeight: '600',
        margin: 'auto',
        '& span': {
            marginBottom: 12
        }
    },
    position: {
        position: 'absolute',
        top: '60%',
        left: '60%',
        textAlign: 'center'
    },
    blockingClickLayer: {
        position: 'fixed',
        background: '#7fffd405',
        width: '100%',
        height: '100%',
        zIndex: '1000',
    },
    top: {
       marginTop : 10
    },
    errorMessage:{
        display: "flex", justifyContent: "center" , fontSize : '20px'
    },
    activeMenuList: {
        background: "#eaf4ff",
        borderLeft: "3px solid #0054ba",
        padding: "6px 0px 0",
        color: "#505559",
        display: "flex",
        flexDirection: "column",
        "&:hover": {
          background: "#eaf4ff",
          borderLeft: "3px solid #0054ba",
        },
        "& .MuiSvgIcon-root": {
          marginTop: 8,
        },
        "& .MuiListItemText-root": {
          padding: "0px 16px",
          width: "100%",
        },
        "& .MuiList-padding": {
          paddingBottom: 0,
        },
        "& .MuiTypography-body1": {
          fontSize: "13px",

          fontWeight: "600",
          fontFamily: 'Gilroy-Medium',
          color: '#050050'
        },
        "& .MuiTypography-body2": {
          fontSize: "13px",
           fontWeight: "500",

          fontFamily: 'Gilroy-Medium',
         color: '#050050'
        },
        "& .MuiTypography-colorTextSecondary": {
          padding: "0 1rem",
          textAlign: "left",
          fontSize: 13,
          "& p": {
            padding: 0,
          },
        },
      },
      activeSubMenuList: {
        background: "#eaf4ff",
        borderLeft: "3px solid #0054ba",
        padding: "6px 0 0",
        color: "#505559",
        display: "flex",
        flexDirection: "column",
        "& .MuiTypography-body2": {
          fontSize: "13px !important",
          fontWeight: "500",
          fontFamily: 'Gilroy-Medium',
         color: '#050050'
        },
      },
      menuList: {
        background: "#ffffff",
        borderLeft: "0px solid #0054ba",
        padding: "6px 0 0 16px",
        color: "#505559",
        display: "flex",
        flexDirection: "column",
        "& .MuiListItemText-root": {
          width: "100%",
        },
        "& .MuiTypography-body1": {
          fontSize: "0.875rem",

          fontWeight: "500",
           fontFamily: 'Gilroy-Medium',
          color: '#050050'
        },
      },
}));


export { useStyles };