import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getSelectedView, updateViewBuilderJSON } from '../../redux/actions';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import cloneDeep from 'lodash/cloneDeep';
import './ViewBuilder.css';
import TabsContainer from './TabsContainer';
import TabsConfigurationOptions from './TabsConfigurationOptions';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const style = {
	'background': '',
	'borderColor': '',
	'borderStyle': '',
	'borderLeftWidth': '',
	'borderRightWidth': '',
	'borderTopWidth': '',
	'borderBottomWidth': '',
	'paddingBottpm': '',
	'paddingLeft': '',
	'paddingRight': '',
	'paddingTop': ''
};

const widgetProps = {
	text: {
		type: 'text',
		element: 'div',
		classDefault: 'textContentArea',
		classMobile: '',
		classTablet: '',
		classDesktop: '',
		style: '',
		text: '',
		innerhtml: '<p></p>',
		configOptions: {
			label: '',
			desc: '',
			style: style
		},
		children: []
	},
	button: {
		type: 'button',
		element: 'button',
		classDefault: 'btn btn-primary',
		classMobile: '',
		classTablet: '',
		classDesktop: '',
		style: '',
		text: 'button',
		innerhtml: '',
		configOptions: {
			label: '',
			desc: '',
			style: style,
			buttonText: 'Button Text'
		},
		children: []
	},
	section: {
		type: 'section',
		element: 'div',
		classDefault: 'container-fluid',
		classMobile: '',
		classTablet: '',
		classDesktop: '',
		style: '',
		text: 'Section Area',
		innerhtml: '',
		configOptions: {
			label: '',
			desc: '',
			style: style
		},
		children: []
	},
	row: {
		type: 'row',
		element: 'div',
		classDefault: 'row',
		classMobile: '',
		classTablet: '',
		classDesktop: '',
		style: '',
		text: 'Row Area',
		innerhtml: '',
		configOptions: {
			label: '',
			desc: '',
			style: style
		},
		children: []
	},
	column: {
		type: 'column',
		element: 'div',
		classDefault: 'customCol',
		classMobile: 'col-12',
		classTablet: 'col-md-6',
		classDesktop: 'col-md-6',
		style: '',
		text: 'Column Area',
		innerhtml: '',
		click: () => { },
		configOptions: {
			label: '',
			desc: '',
			style: style,
		},
		children: []
	},

	dashboard: {
		type: 'dashboard',
		element: 'div',
		classDefault: 'customCol',
		classMobile: 'col-12',
		classTablet: 'col-md-12',
		classDesktop: 'col-md-12',
		style: '',
		text: '',
		// innerhtml: '<block class="dashboard-item"><i class="fas fa-chart-area chart-icon" aria-hidden="true"></i></block>',
		innerhtml: '',
		reportId: '',
		embedToken: '',
		groupId: '',
		configOptions: {
			label: 'BI Dashboard',
			desc: '',
			style: style
		},
		click: () => { },
		children: []
	}
};
class ViewBuilder extends Component {
	constructor(props) {
		super(props);
		this.state = {
			displayModal: false,
			loading: false,
			activeBlock: {},
			activeBlockId: '',
			showTabsConfigurationOptions: false,
			viewBuilderOriginalJson: {},
		}
	}

	allowDrop(ev) {
		ev.preventDefault();
	}

	validateDrop(id, dropType) {
		if (id === dropType) {
			toast.error('Nesting of same widgets not allowed', { position: toast.POSITION.RIGHT_TOP });
			return false;
		}
		else if (dropType === 'text' || dropType === 'dashboard') {
			toast.error('Dashboard & Text widget are not allowed to have any child widget.', { position: toast.POSITION.RIGHT_TOP });
			return false;
		}
		return true;
	}

	drop(ev) {
		ev.preventDefault();
		ev.stopPropagation();
		ev.nativeEvent.stopImmediatePropagation();
		const dropId = ev.target.id;
		var id = ev.dataTransfer.getData("id");
		var dropType = ev.target.getAttribute("type")
		if (this.validateDrop(id, dropType)) {
			let tempViewBuilderObj = cloneDeep(this.props.viewBuilder);
			let counter = `content_${Math.random()}`;
			tempViewBuilderObj.viewBuilderJSON.htmlTemp[dropId].children.push(counter);
			let obj = {};
			obj.id = counter;
			obj.parent = dropId;
			Object.assign(obj, widgetProps[id]);
			tempViewBuilderObj.viewBuilderJSON.htmlTemp[counter] = obj;
		 	this.props.updateViewBuilderJSONAPI(tempViewBuilderObj)
		}
	}

	drag(ev) {
		ev.dataTransfer.setData("id", ev.target.id);
	}

	embedPowerBi(reportId) {
		this.props.getEmbedTokenAPI({
			groupId: process.env.REACT_APP_GROUP_ID,
			reportId: reportId
		}, (res, rej) => {
			if (res) {

			} else if (rej) {

			}
		});
	}

	handleDeleteOfId() {
		let tempViewBuilderObj = cloneDeep(this.props.viewBuilder);
		let parentId = tempViewBuilderObj.viewBuilderJSON.htmlTemp[this.state.activeBlockId].parent;
		//deleting refrence from parent block
		let arrOfChildrenFromParent = tempViewBuilderObj.viewBuilderJSON.htmlTemp[parentId].children;
		let index = arrOfChildrenFromParent.indexOf(this.state.activeBlockId);
		arrOfChildrenFromParent.splice(index, 1);
		delete tempViewBuilderObj.viewBuilderJSON.htmlTemp[this.state.activeBlockId];
		//deleting refrence of children from current block
		//tempViewBuilderObj.viewBuilderJSON.htmlTemp[this.state.activeBlockId].children()
	 	this.props.updateViewBuilderJSONAPI(tempViewBuilderObj)
	}

	handleWidgetClick(e, obj) {
		e.stopPropagation();
		e.nativeEvent.stopImmediatePropagation();
		let tempActiveBlock = cloneDeep(obj)
		this.setState({ viewBuilderOriginalJson: this.props.viewBuilder })
		let activeBlock = cloneDeep(this.state.activeBlock);
		activeBlock = tempActiveBlock;
		this.setState({ activeBlock: activeBlock, showTabsConfigurationOptions: true, activeBlockId: obj.id });
	}

	renderHtmlContent(obj) {
		let renderedHTML =  cloneDeep(obj.innerhtml);
		if (renderedHTML !== '') {
		 	return (
				<div className="ql-snow">
				<span className="ql-editor" dangerouslySetInnerHTML={{ __html: renderedHTML }} />
			  </div>
			  )
		}
	}

	renderBiDashboardLabel(obj) {
		if (obj.type === 'dashboard') {
			return (<div className='center'>{obj.configOptions.label}</div>)
		}
	}
	renderButton(obj) {
		if (obj.type === 'button') {
			return (<span>{obj.configOptions.buttonText}</span>)
		}
	}

	renderTextContentArea(obj) {
		if (obj.type === 'text') {

			return (<span>Text</span>)
		}
	}

	addBackgroundColor(e, obj) {
		e.stopPropagation();
		e.nativeEvent.stopImmediatePropagation();
	}

	renderChildrenOfId(id) {
		if (Object.keys(this.props.viewBuilder).length > 0) {
			if (this.props.viewBuilder.hasOwnProperty("viewBuilderJSON") && this.props.viewBuilder.viewBuilderJSON.hasOwnProperty("htmlTemp")) {
				let newJson =  cloneDeep(this.props.viewBuilder.viewBuilderJSON.htmlTemp);
				if (Object.keys(newJson).length > 0) {
					let obj =  cloneDeep(newJson[id]);
					const HtmlTag = `${obj.element}`;
					let activeClass = ''
					if (obj.id === this.state.activeBlockId) {
						activeClass = 'activeElement';
					}
					return (
						<HtmlTag className={obj.classDefault + ' ' + obj.classMobile + ' ' + obj.classTablet + ' ' + obj.classDesktop + ' ' + activeClass}
							id={obj.id}
							type={obj.type}
							style={obj.configOptions.style}
							onMouseOver={(e) => this.addBackgroundColor(e, obj)}
							onClick={(e) => this.handleWidgetClick(e, obj)}
							onDrop={(e) => { this.drop(e) }}
							onDragOver={(e) => { this.allowDrop(e) }}>
							{/*this.renderDeleteIcon(id) */}
							{this.renderHtmlContent(obj)}
							{this.renderBiDashboardLabel(obj)}
							{this.renderButton(obj)}
							{/* this.renderTextContentArea(obj) */}
							{obj.children.length > 0 ? obj.children.map((id, index) => {
								return (
									<React.Fragment key={index}>
										{this.renderChildrenOfId(id)}
									</React.Fragment>
								)
							}) : null}
						</HtmlTag>
					)
				}
			}
		}
	}

	handleClose = () => {
		this.setState({ displayModal: false });
	}
	handleSave = () => {
		if (this.state.reportId === "") {
			toast.error("Please enter a report id ", { position: toast.POSITION.RIGHT_TOP });
		} else {
			const { selectedId } = this.state;
			let json = cloneDeep(this.state.json);
			json[selectedId]["reportId"] = this.state.reportId;

			this.setState({ displayModal: false, json: json });
		}
	}

	deleteWidgetBlock(e) {
		this.handleDeleteOfId();
		this.setState({ activeBlock: {}, showTabsConfigurationOptions: false, viewBuilderOriginalJson: {} })
	}

	doneEditing() {
		this.setState({ activeBlock: {}, showTabsConfigurationOptions: false, viewBuilderOriginalJson: {} })
	}

	renderTabsContainer() {
 	if (this.state.showTabsConfigurationOptions) {
		 	return <TabsConfigurationOptions
				currentObj={this.state.activeBlock}
				id={this.state.activeBlock.id}
				deleteWidgetBlock={(e) => this.deleteWidgetBlock(e)}
				doneEditing={(e) => this.doneEditing()}
			/>
		}
		else {
		 	return <TabsContainer onDragStart={(e) => { this.drag(e) }} />;
		}
	}

	render() {
		return (
			<div className="col-12 px-0 builder_window height_100 bg-white my-3">
				{this.renderTabsContainer()}
				<div id="view_builder" className=" height_100 ">
					<div id="view_builder_content">
						<ToastContainer />
						{this.state.displayModal ?
							<Modal show={true} onHide={this.handleClose} animation={false}>
								<Modal.Header closeButton>
									<Modal.Title><i className="fas fa-chart-line mr-2"></i>Embed Power BI</Modal.Title>
								</Modal.Header>
								<Modal.Body>
									<p>Enter Power BI Report Id</p>
									<input type="text" className="form-control" value={this.state.reportId} onChange={(e) => { this.setState({ reportId: e.target.value }) }} />
								</Modal.Body>
								<Modal.Footer>
									<Button variant="secondary" onClick={this.handleClose}>
										Close
									</Button>
									<Button variant="primary" onClick={this.handleSave}>
										Save Changes
									</Button>
								</Modal.Footer>
							</Modal> : null}
						 <div className="dropGround row mx-0" id={this.props.windowType} style={{ 'height': '100%' }} >
							{this.renderChildrenOfId(0)}
							{/* View Builder Code */}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return ({
		viewBuilder: state.viewBuilderObj
	})
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		getSelectedViewAPI: getSelectedView,
		updateViewBuilderJSONAPI: updateViewBuilderJSON
	}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewBuilder);
