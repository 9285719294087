export default (state = {
    auth: false,
    okta: {},
    id_token: null,
    access_token: null,
    regions: []
}, action) => {

    switch (action.type) {

    case 'UPDATE_OKTA_AUTH_STATUS':
    return action.payload;

    default:

    return state;

    }

    };