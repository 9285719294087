export default (
  state = {
    loading: false,
    defaultResponse: true,
    releaseData: [],
    message: "",
    userMessage: "",
    error: false,
  },
  action
) => {
  switch (action.type) {
    case "RELEASE_NOTES_DATA_LIST_REQUEST":
      return {
        ...state,
        loading: true,
        defaultResponse: false,
        releaseData: [],
        message: "",
        userMessage: "",
        error: false,
      };

    case "RELEASE_NOTES_DATA_LIST_SUCCESS":
      return {
        ...state,
        releaseData: action.payload,
        Success: true,
        loading: false,
        defaultResponse: false,
        message: "",
        userMessage: "",
        error: false,
      };

    case "RELEASE_NOTES_DATA_LIST_FAILURE":
      return {
        ...state,
        title: [],
        Success: false,
        error: true,
        // message: action.payload.response.data["message"],
        // userMessage: action.payload.response.data["usermessage"],
        loading: false,
        releaseData: false,
        defaultResponse: false,
      };

    default:
      return state;
  }
};
