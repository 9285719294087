export default (state = { data: [], byDefaultResponse: true, loading: false }, action) => {
    switch (action.type) {

        case 'CREATE_NEW_RELEASE_TITLE_REQUEST':
            return {
                byDefaultResponse: false,
                loading: true,
                data: [],
            };

        case 'CREATE_NEW_RELEASE_TITLE_SUCCESS':
            return {
                byDefaultResponse: false,
                loading: false,
                data: action.payload,
                error: false,
                success: true,
                message: action.payload['message']
            };

        case 'CREATE_NEW_RELEASE_TITLE_FAILURE':
            return {
                byDefaultResponse: false,
                loading: false,
                data: [],
                error: true,
                success: false,
                message: action.payload['message']
            };

        default:
            return state;
    }
};
