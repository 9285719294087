export default (
  state = [
    {
      appliedFilter: "region",
      setValue: "userBaseCountry",
      activeValues: [],
    },
    {
      appliedFilter: "userBaseCountry",
      setValue: "team",
      activeValues: [],
    },
  ],
  action
) => {
  switch (action.type) {
    case "OPERATIONAL_ALERTS_APPLIED_FILTER_REQUEST":
      return {
        activeValues: action.payload,
      };

    default:
      return state;
  }
};
