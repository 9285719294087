export default (
    state = {
        loading: false,
        defaultResponse: true,
        Success: false,
        data: []
    },
    action) => {

        switch (action.type) {

        case 'FETCH_BOOKMARK_REQUEST':
            return {
                ...state,
                loading: true,
                defaultResponse: true,
                Success: false,
                data: []
            }

        case 'FETCH_BOOKMARK_SUCCESS':
            return {
                ...state,
                data: action.payload.data,
                Success: true,
                loading: false,
                defaultResponse: false,
             };

        case 'FETCH_BOOKMARK_FAILURE':
            return {
                ...state,
                error: true,
                Success: false,
                message: action.payload.response.data.message,
                userMessage: action.payload.response.data['usermessage'],
                loading: false,
                defaultResponse: false,
             };

        default:

            return state;

    }

};