export default ( state={data:[]},action) => {
    switch (action.type) {

        case 'GET_ERROR_MESSAGES_REQUEST':
            return {
                data: [],
                loading: true,
            };

        case 'GET_ERROR_MESSAGES_SUCCESS':
            return {

                data : action.payload,
                responseDefault: false,
                loading: false,
                success: true,
                error: false
            };

        case 'GET_ERROR_MESSAGES_FAILURE':
            return {

                    data: [],
                    error: true,
                    responseDefault: false,
                    loading: false,
                    success: false,
                    userMessage: action.payload.response.data['usermessage'],
                }
                ;

        default:

            return state;

    }

};