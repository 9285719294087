export default (
  state = { user: [], loading: false, defaultResponse: true },
  action
) => {
  switch (action.type) {
    case "USER_PROFILE_REQUEST":
      return {
        user: [],
        loading: true,
        defaultResponse: true,
      };

    case "USER_PROFILE_SUCCESS":
      return {
        user: action.payload,
        responseDefault: false,
        loading: false,
        success: true,
        error: false,
        defaultResponse: false,
      };

    case "USER_PROFILE_FAILURE":
      return {
        user: [],
        error: true,
        responseDefault: false,
        loading: false,
        success: false,
        userMessage: action.payload.response.data["usermessage"],
        defaultResponse: false,
      };

    default:
      return state;
  }
};
