export default (
    state = {
        subReportData: {},
        success: false,
        message: '',
        responseDefault: true,
        loading: false,
    },
    action) => {

    switch (action.type) {

        case 'GET_SUB_REPORT_DETAILS_REQUEST':
            return {
                ...state,
                subReportData: {},
                success: false,
                message: '',
                responseDefault: true,
                loading: true,
                error: false,
            };

        case 'GET_SUB_REPORT_DETAILS_SUCCESS':
            let tempObj = {}
            if (action.payload && action.payload.data && Array.isArray(action.payload.data) && action.payload.data.length > 0 && action.payload.message === "Success") {
                 if (action.payload.data[0]['htmlTemp'] === "NULL") {
                    tempObj = {
                        subReportData: action.payload.data[0],
                        responseDefault: false,
                        success: true,
                        loading: false,
                     }
                    tempObj.htmlTemp = {}
                }
                else if (!!action.payload.data[0]['htmlTemp'] && Object.keys(action.payload.data[0]['htmlTemp']).length > 0) {
                    tempObj = {
                        subReportData: action.payload.data[0],
                        responseDefault: false,
                        success: true,
                        loading: false,
                    }
                 }
                return tempObj;
            }
            return {
                ...state,
                tempObj,
                error: false,
            };

        case 'GET_SUB_REPORT_DETAILS_FAILURE':
            return {
                ...state,
                data: action.payload,
                message: action.payload.response.data['message'],
                userMessage: action.payload.response.data['usermessage'],
                success: false,
                responseDefault: false,
                error: true
            };

        default:
            return state;
    }
};
