import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 0,
    },
    paper: {
        padding: 0,
        margin: '0',
        width: 'auto',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: "center",
        boxShadow: 'none',
        background: '#f4f6f8'
    },
    flexRow: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%'
    },
    flexContainer: {
        display: 'flex',
        flexDirection: 'row',
    },
    flexItem: {
        display: 'flex',
        flexDirection: 'row',
        border: '1px solid #e5e5e5',
        borderRadius: 15,
        boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
        width: '375px',
        margin: '1rem 3.2rem',
        textAlign: 'center',
        lineHeight: '75px',
        fontSize: '30px',
        padding: '12px'
    },
    globalFilterContainer: {
        marginBottom: "5px",
        textAlign: "right",
        background: 'white',
        // marginTop: '10px',
    },
    filterBtn: {
        backgroundColor: "transparent",
        margin: "2px",
        border: "1px solid #a8a8a8",
    },
    formControl: {
        margin: '12px 8px',
        minWidth: 120,
        borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
        textAlign: "left",
        marginBottom: "0px",
        height: 'fit-content'
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    globalFilterDrawer: {
        width: '400px!important',

    },
    filterActionBtn: {
        position: "absolute",
        bottom: "10px",
        left: "0",
        right: "0",
        textAlign: "center",
    },
    actionBtnGrp: {
        margin: '4px',
    },
    drawerTitle: {
        background: "#fff",
        width: "100%",
        padding: "14px",
        borderBottom: "1px solid #281faa",
        color: "#281faa",
        fontWeight: "800",
        fontSize: "1.2rem",

    },
    filterList: {
        maxWidth: "90%",
        margin: "auto",
        display: 'flex',
        flexDirection: 'column',
        marginTop: "20px",
        formControl: {
            marginBottom: "26px",
        },
        "& .MuiInputLabel-formControl": {
            color: "#281faa",
        },
    },
    menuItemOption: {
        minHeight: '30px !important',
        padding: '0px !important'
    },
    filterCheckbox: {
        padding: '0px !important'
    },
    "& .MuiMenuItem-root": {
        minHeight: '30px !important',
        padding: '0px !important'
    },
    "& .MuiCheckbox-root": {
        padding: '0px 10px !important'
    },
    "& .MuiListItem-root.Mui-selected": {
        backgroundColor: '#ffffff !important'
    },
    globalFilterItemList: {
        display: 'inline-flex',
        alignItems: 'center',
    },
    '& .MuiFormLabel-root': {
        height: 'auto',
        overflow: 'hidden',
        minHeight: '1.1876em',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        width: '100px'
    },
    truncateText: {
        height: 'auto',
        overflow: 'hidden',
        minHeight: '1.1876em',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        width: '115px'
    },
    lableText: {
        height: 'auto',
        overflow: 'hidden',
        minHeight: '1.1876em',
        width: 'auto'
    },
    fontSize: {
        fontSize: '1.1rem'
    },
    disablefontSize:{
        fontSize: '1.1rem',
        PointerEvents: 'none',
        opacity: 0.3
    },
    textField: {
        '& .MuiOutlinedInput-root': {
            margin: '5px 15px'
        },
        '& .MuiOutlinedInput-input': {
            padding: "7px 12px 10px",
            height: '25px'
        }
    },
    bookmarkBtn: {
        // margin: '18px ​10px 10px !important',
        top: '20px',
        backgroundColor: '#CDA521 !important',
        flex: '0 0 auto',
        border: 'none',
        fontSize: '12px',
        padding: '4px 12px',
        height: '24px',
        lineHeight: 'normal',
        '&:hover': {
            backgroundColor: '#CDA521 !important',
        },
    },
    position: {
        position: 'absolute',
        top: '60%',
        left: '60%',
        textAlign: 'center'
    },
    errorMessage:{
        display: "flex", justifyContent: "center" , fontSize : '20px'
    },
    resetFilter :{
        margin: '12px 12px 0',
        padding: '0.3em',
        height: 'fit-content',
        fontFamily: 'Gilroy-Medium',
        fontSize: '12px !important',
        fontStyle: 'normal !important',
        fontWeight: 500,
        lineHeight: '20px !important'
    },
    globalFilter:{
        display: 'flex',
        justifyContent: 'flex-start',
        flexDirection: 'column',
        padding: '50px 25px',
        position: 'absolute',
        left: 0
    },
    filterLabel:{
        fontFamily: 'Gilroy-Medium',
        fontSize: '14px !important',
        fontStyle: 'normal !important',
        fontWeight: 500,
        lineHeight: '20px !important'
    },
    serverDown: {
        display: 'flex',
        flexDirection: 'column',
        alignItems : 'center',
        justifyContent: 'center',
        textAlign: 'center',
        width: 'inherit',
        fontSize: '20px',
        fontWeight: '600',
        '& span': {
            marginBottom: 12
        }
    },
    marginAuto:{
        margin: '0 0 0 auto'
    }
}));


export { useStyles }