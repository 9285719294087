export default (state = {}, action) => {

    switch (action.type) {

    case 'CAPTURE_PLAYBOOKS_DATA':
    return action.payload;

    default:

    return state;

    }

    };