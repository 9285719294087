export default (
  state = {
    loading: false,
    defaultResponse: true,
    Success: false,
  },
  action
) => {
  switch (action.type) {
    case "UPDATE_NEWSLETTER_REQUEST":
      return {
        ...state,
        loading: true,
        defaultResponse: true,
        Success: false,
      };

    case "UPDATE_NEWSLETTER_SUCCESS":
      return {
        ...state,
        Success: action.payload.Success,
        message: action.payload.message,
        loading: false,
        defaultResponse: false,
      };

    case "UPDATE_NEWSLETTER_FAILURE":
      return {
        ...state,
        Success: action.payload.Success,
        message: action.payload.response.data["message"],
        userMessage: action.payload.response.data["usermessage"],
        loading: false,
        defaultResponse: false,
      };

    default:
      return state;
  }
};
