import React, { useEffect } from 'react';
import { useSelector } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Spinner from '../Spinner/index';
import utils from '../../utils/index';
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from '@material-ui/icons/Edit';


export default function StickyHeadTable(props) {

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);
  const { data, loading, columns, type, errorMessage, selectedTab, updateSelfService, deleteSelfService, updateLinksPosition } = props;

  const { activeRegion, } = useSelector((state) => state.activeDynamicData);
  const useStyles = makeStyles({
    root: {
      width: '100%',
    },
    container: {
      maxHeight: 425,
      padding: 0, //0 12px
      maxWidth: 'inherit',
    },
    fontSize: {
      fontSize: '12px',
      border: '1px solid #e5e5e5'
    },
  });
  const classes = useStyles();

  useEffect(() => {
    if (!!loading) {
      setPage(0);
    }
  }, [data, loading]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const style = {
    fontSize: '12px',
    textDecoration: 'underline',
    color: '#f8af2a',
    fontStyle: 'italic'
  };

  const trackingEvents = (rowData) => {
    let trackObj = {};
    if (type === "Self Service") {
      trackObj.region = activeRegion;
      trackObj.actionType = 'Click';
      trackObj.pageName = type;
      trackObj.reportName = rowData.display_name;
      trackObj.reportLink = rowData.link;
      trackObj.attributeName = selectedTab;
      trackObj.workspaceName = rowData.workspace;
    }
    else {
      trackObj.region = activeRegion;
      trackObj.actionType = 'Click';
      trackObj.pageName = type;
      trackObj.reportLink = rowData.link;
      trackObj.reportName = rowData.display_name;
    }
    utils.userTrackingInfo(trackObj);
  }

  const updateSelfServiceLink = (row) => {
    updateSelfService(row)
  }

  const updateLinksOrder = () => {
    updateLinksPosition()
  }

  const deleteSelfServiceLink = (row) => {
    deleteSelfService(row)
  }

  const renderTableCellContent = (column, value, row) => {
    if (column.format && typeof value === 'number') {
      return (
        <TableCell key={column.id} align={column.align} className={classes.fontSize}>
          {column.format(value)}
        </TableCell>
      )
    }
    else if (column.id === "displayName") {
      return (
        <TableCell key={column.id} align={column.align} className={classes.fontSize}>
          <a onClick={() => trackingEvents(row, value, column)}
            style={style} href={row.linkForDocumentation}
            target="_blank" rel="noopener noreferrer"
          >
            {value}
          </a>
        </TableCell>
      )
    }
    else if (value) {
      return (
        <TableCell key={column.id} align={column.align} className={classes.fontSize}>
          {value}
        </TableCell>
      )
    }
    else if (column.id === "Edit") {
      return (
        <TableCell key={column.id} align={column.align} className={classes.fontSize}
          onClick={() => updateSelfServiceLink(row)}
        >
          <EditIcon />
        </TableCell>
      )
    }
    else if (column.id === "Delete") {
      return (
        <TableCell key={column.id} align={column.align} className={classes.fontSize}
          onClick={() => deleteSelfServiceLink(row)} >
          <DeleteIcon />
        </TableCell>
      )
    }
    else {
      return (
        <TableCell key={column.id} align={column.align} className={classes.fontSize}>
          <i class="fas fa-sort-amount-up cursor"
            style={{ fontSize: '15px' }}
            aria-hidden="true"
            onClick={() => updateLinksOrder(row)}
          ></i>
        </TableCell>
      )
    }
  }

  const renderTableBodyContent = () => {
    if (loading && data && Array.isArray(data) && data.length === 0) {
      return (
        <TableRow hover tabIndex={-1}>
          <TableCell style={{ textAlign: 'center' }}>
            { }
          </TableCell>
          <TableCell style={{ textAlign: 'center' }}>
            <Spinner align={'inherit'} />
          </TableCell>
          <TableCell>
            { }
          </TableCell>
        </TableRow>
      )
    }
    else if (data && Array.isArray(data) && data.length > 0) {
      return (
        data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) =>
        (
          <TableRow hover tabIndex={-1} key={row.code}>
            {
              columns.map((column) => {
                const value = row[column.id];
                return (
                  renderTableCellContent(column, value, row)
                );
              })
            }
          </TableRow>
        ))
      )
    }
    else {
      return (
        <TableRow hover role="checkbox" tabIndex={-1}>
          <TableCell style={{ textAlign: 'center' }}>
            { }
          </TableCell>
          <TableCell style={{ textAlign: 'left' }}>
            { }
          </TableCell>
          <TableCell style={{ textAlign: 'left' }}>
            {errorMessage}
          </TableCell>
        </TableRow>
      )
    }
  }

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {
                columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth, width: column.width }}
                    className={classes.fontSize}
                  >
                    {column.label}
                  </TableCell>
                ))
              }
            </TableRow>
          </TableHead>
          <TableBody>
            {
              renderTableBodyContent()
            }
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
}

