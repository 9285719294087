import React, { useEffect, createContext } from "react";
import {  useSelector } from "react-redux";
import { useStyles } from './style.js';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Sidebar from '../Sidebar/index';

const LayoutContext = createContext();
const TheLayout = ({ component }) => {
  const classes = useStyles();

  const selectedRegion = useSelector((state) => state.getBusinessUnitsRegion);
  const selectedSubRegion = useSelector((state) => state.updatedSubRegion);
  const { activeRegion } = useSelector((state) => state.activeDynamicData);

  const [subRegion, setSubRegion] = React.useState('');

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  useEffect(() => {
    if (selectedRegion && Object.keys(selectedRegion).length > 0) {
      let { subRegionList } = selectedSubRegion;
      if (subRegionList && Array.isArray(subRegionList) && subRegionList.length > 0) {
        if (selectedSubRegion && Object.keys(selectedSubRegion).length > 0) {
          setSubRegion(selectedSubRegion.subRegion);
        }
      }
      else if (subRegionList && Array.isArray(subRegionList) && subRegionList.length === 0) {
        setSubRegion(selectedSubRegion.subRegion);
      }
    }
  }, [selectedRegion, selectedSubRegion, subRegion]);


  return (
    <LayoutContext.Provider value={{ activeRegion, subRegion, }}>
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <Grid container item xs={12} className={classes.flexContainer}>
            <Grid container item xs={12} className={classes.sidebarContainer} style={{ padding: 0 }}>
              <Sidebar
                component={component}
                subRegion={subRegion}
              />
            </Grid>
          </Grid>
        </Paper>
      </div>
    </LayoutContext.Provider>
  );
}

export default TheLayout;

export { LayoutContext }