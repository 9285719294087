import React, { useEffect } from "react";
import { useOktaAuth } from "@okta/okta-react";

const NewTabOpen = (props) => {
  const { authState } = useOktaAuth();

  useEffect(() => {
    const pathName = window.location.href;
    if (pathName.length > 0) {
      let urlParams = pathName.split("?")[1];

      let presistState = {
        activeLeverIndex: Number(urlParams.split("&")[0].split("=")[1]),
        activeLever: urlParams.split("&")[1].split("=")[1].replace(/%20/g, " "),
        activeLeverReportSection: urlParams
          .split("&")[2]
          .split("=")[1]
          .replace(/%20/g, " "),
        activeLevarReportSectionName: urlParams
          .split("&")[3]
          .split("=")[1]
          .replace(/%20/g, " "),
        region: urlParams.split("&")[4].split("=")[1],
        pageType: urlParams.split("&")[5].split("=")[1].replace(/%20/g, " "),
        homepageRedirectKey: urlParams.split("&")[6].split("=")[1],
        subRegion: urlParams.split("&")[7].split("=")[1],
      };
      sessionStorage.setItem("newtabdata", JSON.stringify(presistState));
    }
  }, []);

  useEffect(() => {
    if (!!authState && !authState.isAuthenticated) {
      window.location.replace("/");
    } else if (!!authState && !!authState.isAuthenticated) {
      window.location.replace("/");
      sessionStorage.removeItem("oktaRes");
    }
  }, [authState, props, props.history]);

  return (
    <div className="share">
      <div style={{ fontSize: "20px", textAlign: "center", marginTop: "20rem" }} >
        Please wait, we are processing the command center new window report open
        request
      </div>
    </div>
  );
};

export default NewTabOpen;
