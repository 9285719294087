import React from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactQuill from "react-quill";
import EditorToolbar, { modules, formats } from "./editorToolbar";
import "react-quill/dist/quill.snow.css";

import { updateEditorData  } from "../../redux/actions";

const Editor = () => {
  const dispatch = useDispatch();

  const editorData = useSelector((state) => state.editorReleaseData);

  const { innerHtml } = editorData;

  return (
    <React.Fragment>
      <EditorToolbar />
      <ReactQuill
        theme="snow"
        value={innerHtml}
        onChange={(value) => {
            if (!!value) {
              let params = {
                innerHtml: value,
              };
              dispatch(updateEditorData(params));
            }
          }}
        placeholder={"Write something awesome..."}
        modules={modules}
        formats={formats}
      />
    </React.Fragment>
  );
};

export default Editor;


