import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import QuillEditor from "../textEditor/editor";
import Select from "../../component/Select";
import MenuList from "./menulist";
import { useStyles } from "./indexStyle";
import Button from "@material-ui/core/Button";
import releaseBook from "../../assets/icons/book.svg";
import utils from "../../utils/index";
import "react-quill/dist/quill.snow.css";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
    fetchNewsletterQuarter,
    getNewsletterData,
    updateNewsLetter,
    toastTriggered,
    editEditorData,
    updateEditorData
} from "../../redux/actions";
import toastProperties from "../../assets/data/toastProperty";

const ReleaseNotes = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    let history = useHistory();

    const [menuData, setMenuData] = useState([]);
    const [enableEditor, setEnableEditor] = useState(false);
    const [region, setRegion] = useState(null);
    const [innerHtml, setInnerHtml] = useState("");
    const [currentQuarter, setCurrentMonth] = useState("");
    const [activeNewsletter, setActiveNewsletter] = useState("");
    const [activeNewsletterId, setActiveNewsletterId] = useState("");

    const newsletterQuarter = useSelector((state) => state.newsletterQuarter);
    const newsletterData = useSelector((state) => state.newsletterData);
    const notificationRegion = useSelector((state) => state.notificationRegion);
    const selectedRegion = useSelector((state) => state.getBusinessUnitsRegion);
    const newsletterEditorData = useSelector((state) => state.editEditorData);
    const publishNewsletter = useSelector((state) => state.publishNewsletter);
    const editorData = useSelector((state) => state.editorReleaseData);

    const { quarter } = newsletterQuarter;
    const { newsletter, loading, defaultResponse } = newsletterData;

    useEffect(() => {
        const { error, responseDefault } = publishNewsletter;
        if (!responseDefault && !!error) {
            utils.toastTriggered(publishNewsletter, dispatch, true);
        }
    }, [dispatch, publishNewsletter]);


    useEffect(() => {
        dispatch(fetchNewsletterQuarter());
    }, [dispatch]);

    useEffect(() => {
        if (!!notificationRegion && Object.keys(notificationRegion).length > 0) {
            setRegion(notificationRegion.region);
        }
        else if (!!selectedRegion && Object.keys(selectedRegion).length > 0) {
            setRegion(selectedRegion.region);
            setCurrentMonth('');
        }
    }, [selectedRegion, notificationRegion]);

    useEffect(() => {
        const { state } = history.location;
        if (!!state && state.hasOwnProperty('activeQuarter') && state.hasOwnProperty('source') && state.source === "NotificationCenter") {
            setCurrentMonth(state.activeQuarter);
            let activeQuarter = state.activeQuarter;
            let editparams = {
                enableEditor: false,
                newOne: false,
            };
            dispatch(editEditorData(editparams));
            if (!!activeQuarter && !!region) {
                let params = {
                    region: region,
                    month: activeQuarter,
                };
                dispatch(getNewsletterData(params));
            }
        }
        else if (Array.isArray(quarter) && quarter.length > 0 && currentQuarter === '') {
            let selected = quarter.find(el => el.active);
            let activeQuarter = selected ? selected.quarter : quarter[0].quarter;
            setCurrentMonth(activeQuarter);
            if (!!activeQuarter && !!region) {
                let params = {
                    region: region,
                    month: activeQuarter,
                };
                dispatch(getNewsletterData(params));
            }
        };
    }, [dispatch, history.location, quarter, region]);

    const selectedMonth = (val) => {
        setCurrentMonth(val);
        let editparams = {
            enableEditor: false,
            newOne: false,
        };
        dispatch(editEditorData(editparams));
        let params = {
            region: region,
            month: val,
        };
        dispatch(getNewsletterData(params));
    };

    const selectNewsLetter = useCallback(() => {
        let data = newsletter[0].newsLetterDescription;
        setActiveNewsletterId(newsletter[0].newsLetterID)
        setActiveNewsletter(newsletter[0].newsLetterName);
        setMenuData(newsletter);
        setInnerHtml(data);
        let params = { innerHtml: data };
        dispatch(updateEditorData(params));
    }, [dispatch, newsletter]);

    const updateActiveNewsletter = useCallback((name, key) => {
        let index = Array.isArray(newsletter) && newsletter.length > 0 && newsletter.findIndex((el) => el[key] == name);
        if (index > -1) {
            let data = newsletter[index].newsLetterDescription;
            setActiveNewsletterId(newsletter[index].newsLetterID)
            setActiveNewsletter(newsletter[index].newsLetterName);
            setMenuData(newsletter);
            setInnerHtml(data);
            let params = { innerHtml: data };
            dispatch(updateEditorData(params));
        }
        else {
            selectNewsLetter()
        }
    }, [dispatch, newsletter, selectNewsLetter]);

    useEffect(() => {
        let data = '';
        const state = props.history.location.state;
        if (Array.isArray(newsletter) && newsletter.length > 0) {
            if (!!newsletterEditorData.newOne || !!newsletterEditorData.enableEditor) {
                data = newsletterEditorData.description;
                setActiveNewsletter(newsletterEditorData.newsletter);
                let index = newsletter.findIndex((el) => el.newsLetterName === newsletterEditorData.newsletter);
                if (index > -1) {
                    newsletter[index].newsLetterDescription = data;
                    setActiveNewsletterId(newsletter[index].newsLetterID)
                    setInnerHtml(data);
                    let params = { innerHtml: data };
                    dispatch(updateEditorData(params));
                }
                else {
                    selectNewsLetter();
                }
            }
            else {
                if (!!newsletterEditorData.newsletter) {
                    updateActiveNewsletter(newsletterEditorData.newsletter, 'newsLetterName');
                }
                else if (!!state && state.hasOwnProperty('activeNewsletter') && state.hasOwnProperty('source') && state.source === "NotificationCenter") {
                    updateActiveNewsletter(state.activeNewsletter, 'newsLetterID');
                }
                else {
                    selectNewsLetter();
                }
            }
            setMenuData(newsletter);
            setEnableEditor(newsletterEditorData.enableEditor);
        }
        else {
            setMenuData([]);
            setActiveNewsletter('');
        }
    }, [dispatch, newsletter, newsletterEditorData.enableEditor, newsletterEditorData.newOne, newsletterEditorData.newsletter, newsletterEditorData.description, props.history.location.state, updateActiveNewsletter, selectNewsLetter]);

    const renderHtmlContent = () => {
        if (!!innerHtml && innerHtml !== "") {
            return (
                <div className="ql-snow">
                    <span className="ql-editor" dangerouslySetInnerHTML={{ __html: innerHtml }} />
                </div>
            )
        }
        else if (!loading && !defaultResponse) {
            return (
                <div className={classes.emptyReleaseData}>
                    <div className={classes.fontStyle}>
                        Please write something awesome for  <b>  {activeNewsletter} </b>
                    </div>
                </div>
            );
        }
    };

    const trackUserActivities = (title) => {
        let trackingObject = {
            region: region,
            actionType: 'Click',
            pageName: "Other Feature",
            reportName: "Newsletter",
            reportSection: 'Newsletter Published',
            pageDetails: `${title}`,
        };
        utils.userTrackingInfo(trackingObject);
    };

    const PublishNewsletter = () => {
        const { newsletterID, newsletter, month } = newsletterEditorData;
        let params = {
            newsLetterID: newsletterID,
            updateDetails: {
                newsLetterName: newsletter,
                newsLetterDescription: editorData.innerHtml,
                month: month
            }
        }
        dispatch(updateNewsLetter(params)).then((res) => {
            trackUserActivities(newsletter)
            let index = menuData.findIndex((el) => el.newsLetterName === newsletter);
            if (index > -1) {
                menuData[index].newsLetterDescription = editorData.innerHtml;
            }
            setMenuData(menuData);
            setEnableEditor(false);
            let editparams = {
                enableEditor: false,
                newOne: false,
                newsletter: newsletter,
                description: editorData.innerHtml,
                region: region,
                newsletterID: newsletterID,
                month: month,
            };
            dispatch(editEditorData(editparams));
            toastProperties.success["description"] = res.data.message;
            dispatch(toastTriggered([toastProperties.success]));
        });
    };

    const CancelNewsletter = () => {
        setEnableEditor(false);
    };

    return (
        <div className={classes.root}>
            <AppBar position="static">
                <Toolbar  >
                    <div className={classes.documentation}>
                        {activeNewsletter ? (
                            <Typography variant="h6" className={classes.header}>
                                {activeNewsletter}
                            </Typography>
                        ) : null}
                    </div>
                    <Box className={classes.release}>
                        <Select
                            label={"Newsletter"}
                            backGround={"#3cd458"}
                            activeValue={selectedMonth}
                            divider={false}
                            dividerLabel={""}
                            data={quarter}
                            loading={newsletterQuarter.loading}
                            error={newsletterQuarter.error}
                            defaultRes={newsletterQuarter.defaultResponse}
                            activeDropdownValue={currentQuarter}
                            value={'quarter'}
                            name={'quarter'}
                        />
                    </Box>
                </Toolbar>
            </AppBar>
            <Paper className={classes.paper}>
                <Grid container item xs={12}>
                    <Grid container item xs={2} className={classes.sidebar}>
                        <Grid container item xs={12} className={classes.menuSection}>
                            <MenuList
                                data={menuData}
                                loading={newsletterData.loading}
                                error={newsletterData.error}
                                region={region}
                                activeQuarter={currentQuarter}
                                activeNewsletterId={activeNewsletterId}
                                activeNewsletter={activeNewsletter}
                            />
                        </Grid>
                    </Grid>
                    <Grid container item xs={10} className={classes.blockAlignment}>
                        {!!newsletterQuarter.loading || !!newsletterData.loading ? (
                            <div className={classes.loaderPosition}>
                                <CircularProgress color="primary" />
                                <div className="waitAlignment">Please Wait...</div>
                            </div>
                        ) : !newsletterData.loading && Array.isArray(newsletter) && newsletter.length > 0 ? (
                            <Grid
                                container
                                item
                                xs={12}
                                className={classes.alignMent}
                                style={{ padding: "10px 20px 0" }}
                            >
                                {enableEditor ? (
                                    <div className={classes.editorTitle}>
                                        {" "}
                                        <span>
                                            {" "}
                                            <img
                                                src={releaseBook}
                                                alt=""
                                                width="40px"
                                                height="40px"
                                            />{" "}
                                            Newsletter Editor{" "}
                                        </span>{" "}
                                    </div>
                                ) : null}
                                <div
                                    className={
                                        innerHtml !== "" && !enableEditor ? classes.editorInnerhtml : classes.editor
                                    }
                                >
                                    {!!enableEditor ? <QuillEditor /> : renderHtmlContent()}
                                </div>
                            </Grid>
                        ) :
                            !newsletterData.loading && !newsletterData.defaultResponse && Array.isArray(newsletter) && newsletter.length === 0 &&
                            (
                                <div className={classes.emptyReleaseData}>
                                    <div className={classes.fontStyle}>
                                        No Newsletter available for the selected region
                                    </div>
                                    <div className={classes.fontStyle}>
                                        Please Create Newsletter{" "}
                                    </div>
                                </div>
                            )
                        }
                        {enableEditor ? (
                            <Grid
                                item
                                xs={12}
                                className={classes.flexRow}
                                style={{ padding: "1rem 1rem 0 0" }}
                            >
                                <Grid item xs={9} className={classes.leftAlignMent}></Grid>
                                <Grid item xs={3} className={classes.flexRow}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        className={classes.ml05}
                                        onClick={PublishNewsletter}
                                    >
                                        Publish Newsletter
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        className={classes.ml05}
                                        onClick={CancelNewsletter}
                                    >
                                        Cancel
                                    </Button>
                                </Grid>
                            </Grid>
                        ) : null}
                    </Grid>
                </Grid>
            </Paper>
        </div>
    );
};

export default ReleaseNotes;
