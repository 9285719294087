import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 0,
    minHeight: '80vh',
    height: '80vh'
  },
  paper: {
    padding: theme.spacing(0, 0),
    margin: "1rem 0 0", //0 0 3rem 0
    width: "auto",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    boxShadow: "none",
    background: "#fff",
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },
  flexContainer: {
    display: "flex",
    flexDirection: "row",
    // border: '1px dotted red'
  },
  activeUserMessage: {
    background: "#eaf4ff",
    borderLeft: "3px solid #0054ba",
    padding: "10px 20px",
    color: "#505559",
    display: "flex",
    flexDirection: "column",
    fontSize: '12px',
    width: '100%'
  },
  flexItem: {
    display: "flex",
    flexDirection: "row",
    border: "1px solid #e5e5e5",
    borderRadius: 15,
    boxShadow: " rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
    width: "375px",
    margin: "1rem 3.2rem",
    textAlign: "center",
    lineHeight: "75px",
    fontSize: "30px",
    padding: "12px",
  },
  "& .MuiList-root": {
    width: "100% !important",
  },
  "& .MuiList-padding": {
    paddingBottom: 0,
    width: "100% !important",
  },
  activeMenuList: {
    background: "#eaf4ff",
    borderLeft: "3px solid #0054ba",
    padding: "6px 0px 0",
    color: "#505559",
    display: "flex",
    flexDirection: "column",
    "&:hover": {
      background: "#eaf4ff",
      borderLeft: "3px solid #0054ba",
    },
    "& .MuiSvgIcon-root": {
      marginTop: 8,
    },
    "& .MuiListItemText-root": {
      padding: "0px 16px",
      width: "100%",
    },
    "& .MuiList-padding": {
      paddingBottom: 0,
    },
    "& .MuiTypography-body1": {
      fontSize: "13px",
      color: "#000",
      fontWeight: "600",
    },
    "& .MuiTypography-body2": {
      fontSize: "13px",
      color: "#000",
      fontWeight: "500",
    },
    "& .MuiTypography-colorTextSecondary": {
      padding: "0 1rem",
      textAlign: "left",
      fontSize: 13,
      "& p": {
        padding: 0,
      },
    },
  },
  activeSubMenuList: {
    background: "#eaf4ff",
    borderLeft: "3px solid #0054ba",
    padding: "6px 0 0",
    color: "#505559",
    display: "flex",
    flexDirection: "column",
    "& .MuiTypography-body2": {
      fontSize: "0.875rem",
      color: "#000",
      fontWeight: "500",
    },
  },
  menuList: {
    background: "#ffffff",
    borderLeft: "0px solid #0054ba",
    padding: "6px 16px",
    color: "#505559",
    display: "flex",
    flexDirection: "column",
    "& .MuiListItemText-root": {
      width: "100%",
    },
    "& .MuiTypography-body1": {
      fontSize: "13px",
      color: "#000",
      fontWeight: "500",
    },
  },
  menuHeader: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },
  subMenuList: {
    padding: " 0 1rem",
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  linearProgress: {
    position: "relative",
    top: "40px",
    zIndex: "10000",
  },
  pageInfo: {
    background: "#fff",
    width: "95%",
    padding: "14px 0px",
    borderBottom: "1px solid #281faa",
    color: "#281faa",
    fontWeight: "800",
    fontSize: "1.2rem",
  },

}));


export { useStyles };