import thunk from 'redux-thunk';
import { createStore, applyMiddleware } from 'redux';
import reducers from './rootReducer';
// import { composeWithDevTools } from 'remote-redux-devtools';

// const composeEnhancers = composeWithDevTools({
//     realtime: true,
//     name: 'RGM',
//     hostname: 'localhost',
//     port: 3000 // the port your remotedev server is running at
//   })

//   const store = createStore(
//     reducers,
//     composeEnhancers(
//       applyMiddleware(thunk)
//     )
//   )
const store = createStore(reducers,applyMiddleware(thunk));

export default store;