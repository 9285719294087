import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 0,
    },
    paper: {
        padding: '0px',
        margin: '0 0 3rem 0',
        width: 'auto',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: "center",
        boxShadow: 'none',
        background: '#f4f6f8'
    },
    usageApp: {
        padding: '0 24px 24px',
        margin: '0 0 0rem 0',
        width: 'auto',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: "center",
        boxShadow: 'none',
        background: '#f4f6f8'
    },
    flexRow: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%'
    },
    flexContainer: {
        display: 'flex',
        flexDirection: 'row',
    },
    headContainer: {
        display: 'flex',
        flexDirection: 'row',
        background: '#fff',
        marginTop: '15px'
    },
    flexItem: {
        display: 'flex',
        flexDirection: 'row',
        border: '1px solid #e5e5e5',
        borderRadius: 15,
        boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
        width: '375px',
        margin: '1rem 3.2rem',
        textAlign: 'center',
        lineHeight: '75px',
        fontSize: '30px',
        padding: '12px'
    },
    appUsageReport: {
        width: 'inherit',
        margin: '12px 0',
        display: 'flex',
        flexDirection: 'column'
    },
    position: {
        position: 'absolute',
        top: '60%',
        left: '60%',
        textAlign: 'center'
    },
    errorMessage:{
        display: "flex", justifyContent: "center" , fontSize : '20px'
    },
    serverDown: {
        display: 'flex',
        flexDirection: 'column',
        alignItems : 'center',
        justifyContent: 'center',
        textAlign: 'center',
        width: 'inherit',
        fontSize: '20px',
        fontWeight: '600',
        margin: 'auto',
        '& span': {
            marginBottom: 12
        }
    },
    ESFeature: {
        background: '#ffffff',
        textAlign: "right",
        padding: '10px 0',
    },
}));

export { useStyles };