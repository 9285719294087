import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useStyles } from "./notificationStyle";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import { CloseIcon } from "../../assets/icons/Icons";
import {
  fetchNotification,
  clearNotifications,
  toastTriggered,
  updateNotificationRegion,
} from "../../redux/actions";
import checkWhite from "../../assets/icons/checkWhite.svg";
import Loading from "../../component/Spinner";
import SentimentSatisfiedIcon from "@material-ui/icons/SentimentSatisfied";
import utils from '../../utils/index';

let batch = {
  "Outages": "#d9534f",
  "Release Notes": "#3f51b5",
  "Data Refresh": "#1ac54d",
  "Bug Fixes": "#c6c728",
  "Newsletter": "#138496",
};

let toastProperties = {
  title: 'Success',
  description: null,
  backgroundColor: '#5cb85c',
  percentageDownload: '100%',
  icon: checkWhite,
  status: 'success',
  autoDeleteTime: 3000,
  autoDelete: true,
  trigger: true,
};

const NotificationCenter = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  let history = useHistory();
  const { activeRegion, totalNotifications, closeToggleDrawer, regions } = props;

  const { activeRoutes } = useSelector((state) => state.activeDynamicData);
  const deleteNotifications = useSelector((state) => state.clearNotifications);
  const notificationData = useSelector((state) => state.notification);

  let { notification, error, loading } = notificationData;

  useEffect(() => {
    let sessionData = JSON.parse(sessionStorage.getItem("oktaRes"));
    const { error, defaultResponse } = deleteNotifications;
    if (!!error && !defaultResponse) {
      utils.toastTriggered(deleteNotifications, dispatch, true);
      let params = { email: sessionData && sessionData.email, region: activeRegion };
      dispatch(fetchNotification(params));
    }
  }, [deleteNotifications, dispatch, activeRegion]);

  const trackUserActivities = (item) => {
      let trackingObject = {
        region: item.region,
        actionType: 'Click',
        pageName: "Other Feature",
        reportName: "Notification Center",
        reportSection:  'Notification Deleted',
        pageDetails : `${item.alertName}`,
      };
      utils.userTrackingInfo(trackingObject);
  };

  const removeNotifications = (item) => {
    let sessionData = JSON.parse(sessionStorage.getItem("oktaRes"));
    let params = {
      userAlertID: item.userAlertID,
      region: activeRegion,
      userEmail: sessionData && sessionData.email
    };
    dispatch(clearNotifications(params)).then((res) => {
      trackUserActivities(item);
      toastProperties['description'] = res.data.message;
      dispatch(toastTriggered([toastProperties]));
      let params = { email: sessionData && sessionData.email, region: activeRegion };
      dispatch(fetchNotification(params));
    })
  };

  const clearAllNotifications = () => {
    let sessionData = JSON.parse(sessionStorage.getItem("oktaRes"));
    let params = {
      userAlertID: '',
      region: activeRegion,
      userEmail: sessionData && sessionData.email
    };
    dispatch(clearNotifications(params)).then((res) => {
      let trackingObject = {
        region: activeRegion,
        actionType: 'Click',
        pageName: "Other Feature",
        reportName: "Notification Center",
        reportSection:  'Notification Deleted',
        pageDetails : `All Notification`,
      };
      utils.userTrackingInfo(trackingObject);
      toastProperties['description'] = res.data.message;
      dispatch(toastTriggered([toastProperties]));
      let params = { email: sessionData && sessionData.email, region: activeRegion };
      dispatch(fetchNotification(params));
    })
  };

  const formatDate = (item) => {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    let sd = item.startDate;
    // let ed = item.endDate;
    let sDate = new Date(sd);
    // let eDate = new Date(ed);
    let startDate = sDate.getDate();
    // let endDate = eDate.getDate();
    let startMonth = monthNames[sDate.getMonth()];
    // let endMonth = monthNames[eDate.getMonth()];
    let timeValue = item.startTime.split(":");
    var ampm = timeValue[0] >= 12 ? "PM" : "AM";
    let hours = timeValue[0] % 12;
    let time = hours ? hours : 12;
    let format =
      startMonth +
      " " +
      startDate +
      "," +
      time +
      " " +
      ampm;
    return format;
  };

  ///this peace of code needs to be handled at back-end side but due to poor mapping of database its handled in front-end.
  const getSubCategoryId = (item) => {
    let subCategory = item.featuresID[0].subCategory;
    if (Array.isArray(subCategory) && subCategory.length > 0) {
      let element = subCategory.find((el) => el.subCategory === item.features);
      if (element) {
        return element.subCategoryId;
      }
    };
  };

  const visitNotification = (item) => {
    sessionStorage.setItem('region', item.region);
    let params = { source: 'NotificationCenter' }
    let trackingObject = {
      region:  item.region,
      actionType: 'Click',
      pageName: "Other Feature",
      reportName: "Notification Center",
      reportSection : item.alertName
    };
    if (item.categoryID === '2') {
      params.region = item.region;
      params.activeRelease = item.mappingID;
      params.activeReleaseFeature = getSubCategoryId(item);
      params.releaseName = item.mappingName;
      params.ReleaseFeature = item.features;
      params.notificationId = item.alertID;
      trackingObject.pageDetails = `Redirected from Notification Center to Release Notes`;
      dispatch(updateNotificationRegion(params));
      history.push({
          pathname: activeRoutes.key13,
          state: params
        });
     }
    else if (item.categoryID === '5') {
      params.region = item.region;
      params.activeQuarter = item.mappingName;
      params.activeNewsletter = item.featuresID;
      params.Quarter = item.mappingName;
      params.Newsletter = item.features;
      params.notificationId = item.alertID;
      trackingObject.pageDetails = `Redirected from Notification Center to Newsletter`;
      dispatch(updateNotificationRegion(params));
      history.push({
          pathname: activeRoutes.key14,
          state: params
        });
    }
    closeToggleDrawer('right', false);
    utils.userTrackingInfo(trackingObject);
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Grid item xs={12} className={classes.flexContainer}>
          <div className={classes.subtitle}> NOTIFICATION CENTER </div>
          <div className={classes.title}> All Notifications </div>
        </Grid>
        <Grid xs={12} className={classes.notification}>
          {!!loading ? (
            <div className={classes.LoadDataNotif}>
              {" "}
              <Loading />{" "}
            </div>
          ) : notification.length > 0 ? (
            notification.map((item, index) => (
              <Grid xs={12} key={index} className={classes.cardBox} >
                <Grid xs={12}>
                  <Grid xs={12} className={classes.flexRow}>
                    <div
                      className={classes.triangle}
                      style={{
                        borderLeft: `15px solid ${batch[item.category]}`,
                      }}
                    ></div>
                    <div className={classes.type}> {item.alertName} </div>
                    <div
                      className={classes.removeNotifications}
                      onClick={() => removeNotifications(item)}
                    >
                      <CloseIcon />{" "}
                    </div>
                  </Grid>
                  <div className={classes.date}> {formatDate(item)} </div>
                </Grid>
                <Grid>
                  <Divider />
                  <div className={classes.desc}> {item.displayMessage} </div>
                  {
                    item.categoryID === '2' || item.categoryID === '5' ?
                      <div
                        className={classes.releaseNotes}
                        onClick={() => visitNotification(item)}
                      >  {item.subCategory}
                      </div>
                      : null
                  }
                </Grid>
              </Grid>
            ))
          ) : !loading && error ? (
            <div className={classes.noDataNotif}>
              <div className={classes.center}>
                {" "}
                <SentimentSatisfiedIcon />{" "}
              </div>
              <div className="fontWeight">You don't have any notifications</div>
            </div>
          ) : null}
        </Grid>
        {
          totalNotifications > 0 ?
            <Grid xs={12} className={classes.clearAll}>
              <button
                className={classes.clearAllbtn}
                onClick={clearAllNotifications}
              >
                {" "}
                Clear All{" "}
              </button>
            </Grid> :
            null
        }
      </Paper>
    </div>
  );
};

export default NotificationCenter;


