export default (
  state = {
    metaData: [
      {
        label: "region",
        key: "region",
        next: "userBaseCountry",
        appliedFilter: [],
        presistFilter : []
      },
      {
        label: "country",
        key: "userBaseCountry",
        next: "team",
        appliedFilter: [],
        presistFilter : []
      },
      {
        label: "team",
        key: "team",
        next: "null",
        appliedFilter: [],
        presistFilter : []
      },
      {
        label: "keyUser",
        key: "keyUser",
        next: null,
        appliedFilter: [],
        presistFilter : []
      },
    ],
  },
  action
) => {
  switch (action.type) {
    case "UPDATE_OPERATIONAL_ALERTS_FILTER_DATA_REQUEST":
      return action.payload;

    default:
      return state;
  }
};
