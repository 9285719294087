import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {
  toastTriggered,
  fetchUserSubscriptionData,
  userSubscription,
} from "../../redux/actions/index";
import Spinner from "../../component/Spinner";
import toastProperties from "../../assets/data/toastProperty";
import { useStyles } from "./alertSettingStyle";
import utils from '../../utils/index';

const AlertSetting = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  let history = useHistory();

  const [region, setRegion] = useState("");
  const [name, setName] = useState("");
  const [checked, setChecked] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [categoryIndex, setcategoryIndex] = useState(0);

  const oktaAuth = useSelector((state) => state.oktaAuth);
  const subscription = useSelector((state) => state.subscriptionData);
  const userSubcription = useSelector((state) => state.userSubcription);
  const { activeRoutes } = useSelector((state) => state.activeDynamicData);
  const selectedRegion = useSelector((state) => state.getBusinessUnitsRegion);

  useEffect(() => {
    if (!!selectedRegion && Object.keys(selectedRegion).length > 0) {
      setRegion(selectedRegion.region);
    }
  }, [selectedRegion]);

  useEffect(() => {
    if (!!userSubcription.subscription) {
      Array.isArray(subscription.data) &&
        subscription.data.map((item) => {
          item.subCategories.map((category) => {
            if (category.subCategory === name) {
              category.isSubCribed = checked;
            }
          });
          return true;
        });
    }
  }, [checked, name, subscription.data, userSubcription]);

  useEffect(() => {
    const { subscription, responseDefault, error } = userSubcription
    if (!subscription && !responseDefault && !error) {
      utils.toastTriggered(userSubcription, dispatch, true);
    }
  }, [dispatch, userSubcription]);

  useEffect(() => {
    const { error, responseDefault } = subscription;
    if (!responseDefault && !!error) {
      utils.toastTriggered(subscription, dispatch, true);
    }
  }, [dispatch, subscription]);

  useEffect(() => {
    let sessionData = JSON.parse(sessionStorage.getItem("oktaRes"));
    if (!!region) {
      let params = { email: sessionData && sessionData.email, region: region };
      dispatch(fetchUserSubscriptionData(params));
    }
  }, [dispatch, region]);

  const onChangeSubscription = (e, subscribeItem, index, categoryIndex) => {
    let name = e.target.name;
    let checked = e.target.checked;
    setChecked(checked);
    setName(name);
    setSelectedIndex(index);
    setcategoryIndex(categoryIndex);
    let sessionData = JSON.parse(sessionStorage.getItem("oktaRes"));
    let params = {
      subCategoryId: subscribeItem.subCategoryId,
      userEmail: sessionData && sessionData.email,
      isSubscribed: checked,
      region: region,
      userSubscriptionID: subscribeItem.userSubscriptionID,
    };
    dispatch(userSubscription(params))
      .then((res) => {
        toastProperties["description"] = res.data.message;
        let trackingObject = {
          region: region,
          actionType: 'Click',
          pageName: "Other Feature",
          reportName: "Alerts",
          pageDetails: `${subscribeItem.subCategory}`
        };
        if (res.data.message === "Successfully subscribed") {
          toastProperties["backgroundColor"] = "#5cb85c";
          trackingObject.reportSection = 'Subscription Subscribed';
        } else if (res.data.message === "Successfully Unsubscribed") {
          toastProperties["backgroundColor"] = "#d9534f";
          trackingObject.reportSection = 'Subscription Unsubscribed';
        }
        dispatch(toastTriggered([toastProperties.success]));
        utils.userTrackingInfo(trackingObject);
      });
  };

  const visitAlerts = () => {
    history.push(activeRoutes.key10);
    utils.userTrackingInfo({
      region: region,
      actionType: 'Click',
      pageName: 'Other Feature',
      pageDetails: `Redirected from Manage Alert to Operational Alerts`,
    });
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Grid container item md={12} className={classes.container}>
          <Grid item md={12} className={classes.flexContainer}>
            <Grid item md={12} className={classes.subscribeContainer}>
              <Grid item md={12} className={classes.optAlert}>
                <div className={classes.OperateAlert}>
                  {" "}
                  <span> Operational Alerts </span>{" "}
                </div>
                <div className={classes.DQalerts}>
                  {" "}
                  <span> DQ Alerts </span>{" "}
                </div>
              </Grid>
              <Grid item md={12} className={classes.paddingLr}>
                <Grid item md={12} className={classes.flexRow}>
                  <Grid item md={10} className={classes.notification}>
                    <div className={classes.notif}> Notification Settings </div>
                    <div className={classes.desc}>
                      Subscribe if you want to recieve notification{" "}
                    </div>
                  </Grid>
                  {oktaAuth.adminType === "Contributor" ? (
                    <Grid item md={2} className={classes.rightAlignMent}>
                      <div className={classes.manageAlerts}>
                        {" "}
                        <span
                          className={"btn addNewBoard border"}
                          onClick={visitAlerts}
                        >
                          {" "}
                          Manage Operation Alerts{" "}
                        </span>{" "}
                      </div>
                    </Grid>
                  ) : null}
                </Grid>
                <Grid md={12} className={classes.subscriptionType}>
                  {!!subscription.loading ? (
                    <div
                      style={{
                        position: "absolute",
                        left: "50%",
                        width: "50%",
                      }}
                    >
                      <Spinner />
                      <div className="waitAlignment">Please Wait...</div>
                    </div>
                  ) : Array.isArray(subscription.data) &&
                    subscription.data.length > 0 ? (
                    subscription.data.map((item, index) => (
                      <Grid
                        md={12}
                        rowkey={index}
                        className={classes.alertCategory}
                      >
                        <Grid
                          md={12}
                          rowkey={index}
                          className={classes.subCripBox}
                        >
                          <div className={classes.title}> {item.category} </div>
                          <div className={classes.desc}>
                            {item.description}{" "}
                          </div>
                        </Grid>
                        <Grid md={6} rowkey={index} className={classes.outages}>
                          {!!item["subCategories"] &&
                            Array.isArray(item["subCategories"]) &&
                            item["subCategories"].map(
                              (subCatgeory, subCatIndex) => (
                                <Grid
                                  md={12}
                                  rowkey={index}
                                  className={classes.cardBox}
                                >
                                  <Grid
                                    md={12}
                                    className={classes.cardBoxContent}
                                  >
                                    <Grid
                                      md={12}
                                      className={classes.flexColumn}
                                    >
                                      <div className={classes.type}>
                                        {" "}
                                        {subCatgeory.subCategory}{" "}
                                      </div>
                                      <div className={classes.subCatdesc}>
                                        {" "}
                                        {subCatgeory.subCatDesc}{" "}
                                      </div>
                                    </Grid>
                                    {!!userSubcription.loading && categoryIndex === index &&
                                      subCatIndex === selectedIndex ? (
                                      <Spinner />
                                    ) : (
                                      <FormControlLabel
                                        control={
                                          <Switch
                                            name={subCatgeory.subCategory}
                                            checked={subCatgeory.isSubCribed}
                                            color="primary"
                                            onChange={(e) =>
                                              onChangeSubscription(
                                                e,
                                                subCatgeory,
                                                subCatIndex,
                                                index
                                              )
                                            }
                                            aria-label="login switch"
                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                          />
                                        }
                                      />
                                    )}
                                  </Grid>
                                </Grid>
                              )
                            )}
                        </Grid>
                      </Grid>
                    ))
                  ) : !subscription.loading && !!subscription.error ? (
                    <div className={classes.notif}> No data available </div>
                  ) : null}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default AlertSetting;
