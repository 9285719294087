export default (state = {
    innerHtml : ``
     }, action) => {

    switch (action.type) {

        case 'UPDATE_EDITOR_DATA':
            return action.payload;

        default:

            return state;

    }

};

