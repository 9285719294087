const defaultState = {
    embedToken: '',
    loading: true,
    defaultReponse: true,
    error: false,
    success: false
}
export default (state = defaultState, action) => {
    switch (action.type) {
        case 'GET_EMBED_TOKEN_REQUEST':
            return {
                embedToken: '',
                loading: true,
                defaultReponse: true,
                error: false,
                success: false
            };

        case 'GET_EMBED_TOKEN_SUCCESS':
            return {
                embedToken: action.payload.data.data.embedToken,
                error: false,
                loading: false,
                defaultReponse: false,
                success: true,
                groupId: action.payload.selections.groupId,
                reportId: action.payload.selections.reportId
            };

        case 'GET_EMBED_TOKEN_FAILURE':
            return {
                embedToken: '',
                error : true,
                loading: false,
                defaultReponse: false,
                success: false,
                message : action.payload.response.data.message,
                userMessage: action.payload.response.data['usermessage'],
            };

        default:
            return state;
    }

};
