import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { useStyles } from "./indexStyle";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import DotFlashing from "../../component/DotFlashing/index";
import { PlusCircleFill } from "../../assets/icons/Icons";
import { CloseIcon, TickIcon } from "../../assets/icons/Icons";
import EditIcon from "@material-ui/icons/Edit";
import {
    createNewsletter,
    toastTriggered,
    updatesidebarMenuList,
    editEditorData,
    // updateEditorData,
    deleteEditorModuleFeatures,
    getNewsletterData,
    updateEditorModuleFeatures
} from "../../redux/actions";
import utils from "../../utils/index";
import Modal from "../../component/modal";
import Operations from "../../component/curdOperations";
import ListAltOutlinedIcon from "@material-ui/icons/ListAltOutlined";
import toastProperties from "../../assets/data/toastProperty";

const MenuList = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { data, region, loading, error, activeQuarter, activeNewsletter , activeNewsletterId, } = props;

    const oktaAuth = useSelector((state) => state.oktaAuth);
    const { okta } = oktaAuth;

    const createNewsletterRes = useSelector((state) => state.createNewsletter);

    const [activeMenu, setActiveMenu] = useState(null);

    const [state, setState] = useState({});
    const [newOne, setCreateNew] = useState(false);
    const [open, setOpen] = useState(false);
    const [size, setSize] = useState("lg");
    const [title, setTitle] = useState('');

    useEffect(() => {
        const { responseDefault, error } = createNewsletterRes;
        if (!responseDefault && !!error) {
            utils.toastTriggered(createNewsletterRes, dispatch, true);
        }
    }, [dispatch, createNewsletterRes]);

    useEffect(() => {
        if (Array.isArray(data) && data.length > 0) {
            let defaultActiveMenu = activeNewsletter ? activeNewsletter : data[0].newsLetterName;
            setActiveMenu(defaultActiveMenu);
            dispatch(updatesidebarMenuList(data));
        } else if (Array.isArray(data) && data.length === 0) {
            dispatch(updatesidebarMenuList(data));
            setActiveMenu("");
        }
    }, [activeNewsletter, activeNewsletterId, data, dispatch, region ]);

    const trackUserActivities = (title) => {
        let trackingObject = {
          region: region,
          actionType: 'Click',
          pageName: "Other Feature",
          reportName: "Newsletter",
          reportSection:  'Newsletter Viewed',
          pageDetails : `${title}`,
        };
        utils.userTrackingInfo(trackingObject);
    };

    const selectActiveTab = (item) => {
        setActiveMenu(item.newsLetterName);
        let params = {
            enableEditor: false,
            newsletter: item.newsLetterName,
            newsLetterID: item.newsLetterID,
            description: item.newsLetterDescription,
            newOne: false,
            month: item.month,
            region: item.region,
        };
        dispatch(editEditorData(params));
        trackUserActivities(item.newsLetterName)
    };

    const handleInputChange = (e) => {
        let val = e.target.value;
        let name = e.target.name;
        setState({ ...state, [name]: val });
    };

    const createNew = () => {
        setCreateNew(true);
        setOpen(true);
        setSize("md");
        setTitle('Create Newsletter')
    };

    const Cancel = () => {
        setCreateNew(false);
    };


    const CreateNewsletter = (value) => {
        let params = {
            newsLetterDetails: {
                newsLetterName: value,
                region: region,
                month: activeQuarter,
            },
        };
        dispatch(createNewsletter(params)).then((res) => {
            setOpen(false);
            setActiveMenu(value);
            let trackingObject = {
                region: region,
                actionType: 'Click',
                pageName: "Other Feature",
                reportName: "Newsletter",
                reportSection:  'Newsletter Created',
                pageDetails : `${value}`,
              };
             utils.userTrackingInfo(trackingObject);
            let params2 = {
                enableEditor: false,
                newsletter: value,
                description: '',
                newOne: true,
                region: region,
                newsletterID: '',
                month: activeQuarter,
            };
            dispatch(editEditorData(params2));
            let newletterparams = {
                region: region,
                month: activeQuarter,
            };
            dispatch(getNewsletterData(newletterparams));
            setCreateNew(false);
            toastProperties.success["description"] = res.data.message;
            dispatch(toastTriggered([toastProperties.success]));
        });
    };

    const EditNewsletter = (item) => {
        let params = {
            region: item.region,
            newsletterID: item.newsLetterID,
            enableEditor: true,
            newsletter: item.newsLetterName,
            month: item.month,
            description: item.newsLetterDescription,
            newOne: false
        };
         dispatch(editEditorData(params));
    };

    const ManageNewsletter = () => {
        setSize("lg");
        setOpen(true);
        setCreateNew(false);
        setTitle('Manage Newsletter')
    };

    const addNewNewsletter = () => {
        return (
            <Grid item xs={12} style={{ borderBottom: 0 }}>
                {false ? (
                    <Grid item xs={12} className={classes.flexRow}>
                        <Grid item xs={9}>
                            <input
                                value={state["newsletter"]}
                                type={"text"}
                                name={"newsletter"}
                                className="form-control"
                                placeholder={`Please input newsletter name`}
                                onChange={(e) => handleInputChange(e)}
                                autoComplete="off"
                            />
                        </Grid>
                        <Grid item xs={3} className={"createCancel"}>
                            <div className="iconBox" onClick={Cancel}>
                                {" "}
                                <CloseIcon />{" "}
                            </div>
                            <div className="iconBox" onClick={CreateNewsletter}>
                                {" "}
                                <TickIcon />{" "}
                            </div>
                        </Grid>
                    </Grid>
                ) : (
                    <Grid item xs={12} className={classes.flexRow}>
                        <Grid
                            item
                            xs={6}
                            onClick={ManageNewsletter}
                            className={classes.newsletter}
                            style={{ marginRight: 5 }}
                        >
                            <ListAltOutlinedIcon style={{ marginTop: 0 }} /> Manage
                        </Grid>
                        <Grid
                            item
                            xs={6}
                            onClick={createNew}
                            className={classes.newsletter}
                            style={{ marginRight: 1 }}
                        >
                            <PlusCircleFill /> Create
                        </Grid>
                    </Grid>
                )}
            </Grid>
        );
    };

    const renderHtmlContent = () => {
        if (!!loading) {
            return <DotFlashing />;
        } else if (!loading && !error && data.length === 0) {
            return (
                <div className={classes.menuList}>{"No Newsletter available"}</div>
            );
        } else if (!loading && !!error && data.length === 0) {
            return (
                <div className={classes.menuList}>{"No Newsletter available"}</div>
            );
        } else if (!!data && Array.isArray(data) && data.length > 0) {
            return (
                <List className={classes.alignMent}>
                    {data.map((text, index) => (
                        <span className={classes.flexRow}>
                            <ListItem
                                 disableRipple
                                button
                                key={text + index}
                                className={
                                    activeMenu === text.newsLetterName
                                        ? classes.activeMenuList
                                        : classes.menuList
                                }
                                onClick={() => selectActiveTab(text, index)}
                            >
                                <div className={classes.menuHeader}>
                                    <ListItemText
                                        primary={text.newsLetterName}
                                        className={classes.primaryText}
                                    />
                                </div>
                            </ListItem>
                            {oktaAuth.adminType === "Contributor" &&
                                activeMenu === text.newsLetterName ? (
                                <div style={{ width: "10%", background: '#a9ccf2', cursor: 'pointer' }}>
                                    <EditIcon style={{ marginTop: 9 }} onClick={() => EditNewsletter(text)} />
                                </div>
                            ) : null}
                        </span>
                    ))}
                </List>
            );
        }
    };

    return (
        <Grid item xs={12}>
            <Modal
                open={open}
                bodyElement={
                    <Operations
                        data={data}
                        name={"newsLetterName"}
                        label={"Newsletter"}
                        create={newOne}
                        createNewsItems={CreateNewsletter}
                        region={region}
                        moduleID={"newsLetterID"}
                        module={"Newsletter"}
                        deleteAction={deleteEditorModuleFeatures}
                        updateAction={getNewsletterData}
                        updateFeatureName={updateEditorModuleFeatures}
                        updateParams={{ region: region, month: activeQuarter }}
                        hideSettingsWindow={(e) => setOpen(false)}
                    />
                }
                hideSettingsWindow={(e) => setOpen(false)}
                title={title}
                size={size}
            />
            <div className={classes.pageInfo}>{"Newsletter"}</div>
            {renderHtmlContent()}
            {oktaAuth.adminType === "Contributor" ? addNewNewsletter() : null}
        </Grid>
    );
};

export default MenuList;
