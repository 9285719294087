export default (state = { data: [], error: false, loading: false, defaultResponse: true }, action) => {

    switch (action.type) {

        case 'PUBLISH_RELEASE_NOTES_REQUEST':
            return {
                data: [],
                loading: true,
                error: false,
                defaultResponse: true
            };

        case 'PUBLISH_RELEASE_NOTES_SUCCESS':
            return {
                data: [],
                responseDefault: false,
                loading: false,
                success: true,
                error: false,
                message: action.payload['message'],
                defaultResponse: false
            };

        case 'PUBLISH_RELEASE_NOTES_FAILURE':
            return {
                data: [],
                error: true,
                responseDefault: false,
                loading: false,
                success: false,
                message: action.payload.response.data['message'],
                // userMessage: action.payload.response.data['usermessage'],
                defaultResponse: false
            };

        default:

            return state;

    }

};