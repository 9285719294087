export default (state = { data: [], error: false, loading: false, defaultResponse: true }, action) => {

    switch (action.type) {

        case 'CREATE_OPERATIONAL_ALERTS_REQUEST':
            return {
                data: [],
                loading: true,
                error: false,
                defaultResponse: true
            };

        case 'CREATE_OPERATIONAL_ALERTS_SUCCESS':
            return {
                data: action.payload.data,
                responseDefault: false,
                loading: false,
                success: true,
                error: false,
                message: action.payload['message'],
                defaultResponse: false
            };

        case 'CREATE_OPERATIONAL_ALERTS_FAILURE':
            return {
                data: [],
                error: true,
                responseDefault: false,
                loading: false,
                success: false,
                message: action.payload.response.data['message'],
                // userMessage: action.payload.response.data['usermessage'],
                defaultResponse: false
            };

            case 'UPDATE_CREATED_OPERATIONAL_ALERTS_REQUEST':
                return {
                    data: [],
                    loading: false,
                    defaultResponse: true,
                    error: false,
                    message: 'published'
            }

        default:

            return state;

    }

};