export default (
    state = {
        loading: true,
        error: false,
        responseDefault: true,
        success: false,
        message: '',
        data: [],
        schema: {
            APAC: {
                metadata: [
                    {
                        type: 'singleSelect',
                        label: 'Country',
                        key: "country",
                        next: "sector",
                    },
                    {
                        type: 'singleSelect',
                        label: 'Global Sector',
                        key: "sector",
                        next: "category",
                    },
                    {
                        type: 'multiSelect',
                        label: 'Global Category',
                        key: "category",
                        next: "domestic_export_interco",
                    },
                    {
                        type: 'multiSelect',
                        label: 'DOMESTIC/EXPORT/INTERCO',
                        previous:"category",
                        key: "domestic_export_interco",
                        next: null,
                    },
                ],
                filterList: {
                    country: {
                        "target": {
                            "table": "V_RGM_AP_RGM_PRICE",
                            "column": "Country"
                        },
                    },
                    category: {
                        "target": {
                            "table": "V_RGM_AP_RGM_PRICE",
                            "column": "Category",
                        },

                    },
                    sector: {
                        "target": {
                            "table": "V_RGM_AP_RGM_PRICE",
                            "column": "Sector",
                        },
                    },
                    domestic_export_interco: {
                        "target": {
                            "table": "V_RGM_AP_RGM_PRICE",
                            "column": "DOMESTIC_EXPORT_INTERCO",
                        },
                    }
                },
            },
            'APAC ASEAN': {
                metadata: [
                    {
                        type: 'singleSelect',
                        label: 'Country',
                        key: "country",
                        next: "sector",
                    },
                    {
                        type: 'singleSelect',
                        label: 'Global Sector',
                        key: "sector",
                        next: "category",
                    },
                    {
                        type: 'multiSelect',
                        label: 'Global Category',
                        key: "category",
                        next: "domestic_export_interco",
                    },
                    {
                        type: 'multiSelect',
                        label: 'DOMESTIC/EXPORT/INTERCO',
                        previous:"category",
                        key: "domestic_export_interco",
                        next: null,
                    },

                ],
                filterList: {
                    country: {
                        "target": {
                            "table": "V_RGM_AP_RGM_PRICE",
                            "column": "Country"
                        },
                    },
                    category: {
                        "target": {
                            "table": "V_RGM_AP_RGM_PRICE",
                            "column": "Category",
                        },
                    },
                    sector: {
                        "target": {
                            "table": "V_RGM_AP_RGM_PRICE",
                            "column": "Sector",
                        },
                    },
                    domestic_export_interco: {
                        "target": {
                            "table": "V_RGM_AP_RGM_PRICE",
                            "column": "DOMESTIC_EXPORT_INTERCO",
                        },
                    }
                },

            },
            'APAC ANZ': {
                metadata: [
                    {
                        type: 'singleSelect',
                        label: 'Country',
                        key: "country",
                        next: "sector",
                    },
                    {
                        type: 'singleSelect',
                        label: 'Global Sector',
                        key: "sector",
                        next: "category",
                    },
                    {
                        type: 'multiSelect',
                        label: 'Global Category',
                        key: "category",
                        next: "domestic_export_interco",
                    },
                    {
                        type: 'multiSelect',
                        label: 'DOMESTIC/EXPORT/INTERCO',
                        previous:"category",
                        key: "domestic_export_interco",
                        next: null,
                    },

                ],
                filterList: {
                    country: {
                        "target": {
                            "table": "V_RGM_AP_RGM_PRICE",
                            "column": "Country"
                        },
                    },
                    category: {
                        "target": {
                            "table": "V_RGM_AP_RGM_PRICE",
                            "column": "Category",
                        },

                    },
                    sector: {
                        "target": {
                            "table": "V_RGM_AP_RGM_PRICE",
                            "column": "Sector",
                        },
                    },
                    domestic_export_interco: {
                        "target": {
                            "table": "V_RGM_AP_RGM_PRICE",
                            "column": "DOMESTIC_EXPORT_INTERCO",
                        },
                    }
                },

            },
            'APAC CHINA': {
                metadata: [
                    {
                        type: 'singleSelect',
                        label: 'Country',
                        key: "country",
                        next: "sector",
                    },
                    {
                        type: 'singleSelect',
                        label: 'Global Sector',
                        key: "sector",
                        next: "category",
                    },
                    {
                        type: 'multiSelect',
                        label: 'Global Category',
                        key: "category",
                        next: "domestic_export_interco",
                    },
                    {
                        type: 'multiSelect',
                        label: 'DOMESTIC/EXPORT/INTERCO',
                        previous:"category",
                        key: "domestic_export_interco",
                        next: null,
                    },

                ],
                filterList: {
                    country: {
                        "target": {
                            "table": "V_RGM_AP_RGM_PRICE",
                            "column": "Country"
                        },
                    },
                    category: {
                        "target": {
                            "table": "V_RGM_AP_RGM_PRICE",
                            "column": "Category",
                        },

                    },
                    sector: {
                        "target": {
                            "table": "V_RGM_AP_RGM_PRICE",
                            "column": "Sector",
                        },
                    },
                    domestic_export_interco: {
                        "target": {
                            "table": "V_RGM_AP_RGM_PRICE",
                            "column": "DOMESTIC_EXPORT_INTERCO",
                        },
                    }
                },

            },
            'APAC INDIA': {
                metadata: [
                    {
                        type: 'singleSelect',
                        label: 'Country',
                        key: "country",
                        next: "sector",
                    },
                    {
                        type: 'singleSelect',
                        label: 'Global Sector',
                        key: "sector",
                        next: "category",
                    },
                    {
                        type: 'multiSelect',
                        label: 'Global Category',
                        key: "category",
                        next: "domestic_export_interco",
                    },
                    {
                        type: 'multiSelect',
                        label: 'DOMESTIC/EXPORT/INTERCO',
                        previous:"category",
                        key: "domestic_export_interco",
                        next: null,
                    },

                ],
                filterList: {
                    country: {
                        "target": {
                            "table": "V_RGM_AP_RGM_PRICE",
                            "column": "Country"
                        },
                    },
                    category: {
                        "target": {
                            "table": "V_RGM_AP_RGM_PRICE",
                            "column": "Category",
                        },

                    },
                    sector: {
                        "target": {
                            "table": "V_RGM_AP_RGM_PRICE",
                            "column": "Sector",
                        },
                    },
                    domestic_export_interco: {
                        "target": {
                            "table": "V_RGM_AP_RGM_PRICE",
                            "column": "DOMESTIC_EXPORT_INTERCO",
                        },
                    }
                },

            },
            'APAC HKTW': {
                metadata: [
                    {
                        type: 'singleSelect',
                        label: 'Country',
                        key: "country",
                        next: "sector",
                    },
                    {
                        type: 'singleSelect',
                        label: 'Global Sector',
                        key: "sector",
                        next: "category",
                    },
                    {
                        type: 'multiSelect',
                        label: 'Global Category',
                        key: "category",
                        next: "domestic_export_interco",
                    },
                    {
                        type: 'multiSelect',
                        label: 'DOMESTIC/EXPORT/INTERCO',
                        previous:"category",
                        key: "domestic_export_interco",
                        next: null,
                    },

                ],
                filterList: {
                    country: {
                        "target": {
                            "table": "V_RGM_AP_RGM_PRICE",
                            "column": "Country"
                        },
                    },
                    category: {
                        "target": {
                            "table": "V_RGM_AP_RGM_PRICE",
                            "column": "Category",
                        },

                    },
                    sector: {
                        "target": {
                            "table": "V_RGM_AP_RGM_PRICE",
                            "column": "Sector",
                        },
                    },
                    domestic_export_interco: {
                        "target": {
                            "table": "V_RGM_AP_RGM_PRICE",
                            "column": "DOMESTIC_EXPORT_INTERCO",
                        },
                    }
                },

            },
            'APAC KOREA': {
                metadata: [
                    {
                        type: 'singleSelect',
                        label: 'Country',
                        key: "country",
                        next: "sector",
                    },
                    {
                        type: 'singleSelect',
                        label: 'Global Sector',
                        key: "sector",
                        next: "category",
                    },
                    {
                        type: 'multiSelect',
                        label: 'Global Category',
                        key: "category",
                        next: "domestic_export_interco",
                    },
                    {
                        type: 'multiSelect',
                        label: 'DOMESTIC/EXPORT/INTERCO',
                        previous:"category",
                        key: "domestic_export_interco",
                        next: null,
                    },

                ],
                filterList: {
                    country: {
                        "target": {
                            "table": "V_RGM_AP_RGM_PRICE",
                            "column": "Country"
                        },
                    },
                    category: {
                        "target": {
                            "table": "V_RGM_AP_RGM_PRICE",
                            "column": "Category",
                        },

                    },
                    sector: {
                        "target": {
                            "table": "V_RGM_AP_RGM_PRICE",
                            "column": "Sector",
                        },
                    },
                    domestic_export_interco: {
                        "target": {
                            "table": "V_RGM_AP_RGM_PRICE",
                            "column": "DOMESTIC_EXPORT_INTERCO",
                        },
                    }
                },

            },
            EMEA: {
                metadata: [
                    {
                        type: 'singleSelect',
                        label: 'Country',
                        key: "country",
                        next: "sector",
                    },
                    {
                        type: 'singleSelect',
                        label: 'Global Sector',
                        key: "sector",
                        next: "category",
                    },
                    {
                        type: 'multiSelect',
                        label: 'Global Category',
                        key: "category",
                        next: null,
                    },
                    {

                    }

                ],
                filterList: {
                    country: {
                        "target": {
                            "table": "V_RGM_EU_UDM",
                            "column": "COUNTRY"
                        },
                    },
                    sector: {
                        "target": {
                            "table": "V_RGM_EU_UDM",
                            "column": "SECTOR",
                        },
                    },
                    category: {
                        "target": {
                            "table": "V_RGM_EU_UDM",
                            "column": "Sub-Category",
                        },
                    },

                },

            },
            LAO: {
                metadata: [
                    {
                        type: 'singleSelect',
                        label: 'Country',
                        key: "country",
                        next: "sector",
                    },
                    {
                        type: 'singleSelect',
                        label: 'Global Category',
                        key: "category",
                        next: null,
                    },
                ],
                filterList: {
                    country: {
                        "target": {
                            "table": "RGM_LAO_RGM_PRICE",
                            "column": "Country"
                        },
                    },
                    category: {
                        "target": {
                            "table": "RGM_LAO_RGM_PRICE",
                            "column": "Product Category",
                        },
                    },
                },

            },

            KCNA: {
                metadata: [
                ],
                filterList: {
                },

            },
        },
    }, action) => {

     switch (action.type) {

        case 'RESET_PLAYBOOKS_REGION_FILTER':
        return {
            ...state,
            loading: false,
            error: false,
            responseDefault: true,
            success: false,
            message: '',
            data: [],
        };

        case 'GET_PLAYBOOKS_REGION_FILTER':
            return {
                ...state,
                loading: true
            };

        case 'GET_PLAYBOOKS_REGION_FILTER_SUCCESS':

            return {
                ...state,
                data: action.payload.data.data,
                default: action.payload.data.data[0],
                responseDefault: false,
                loading: false,
                success: true,
            };

        case 'GET_PLAYBOOKS_REGION_FILTER_FAILURE':
            return {
                ...state,
                data: [],
                error: true,
                message: action.payload && action.payload.response.data && action.payload.response.data['message'],
                userMessage: action.payload && action.payload.response.data && action.payload.response.data['usermessage'],
                responseDefault: false,
                loading: false,
                success: false,
            }
                ;

        default:

            return state;

    }

};