export default (
    state = {
        loading: true,
                defaultResponse: true,
                Success: false,
                data: [],
                error: false,
                message: 'request'
    },
    action) => {
        switch (action.type) {
        case 'FETCH_SHAREABLE_PLAYBOOKS_LINK_REQUEST':
            return {
                ...state,
                loading: true,
                defaultResponse: true,
                Success: false,
                data: [],
                error: false,
                message: 'request'
            }

        case 'FETCH_SHAREABLE_PLAYBOOKS_LINK_SUCCESS':
            return {
                ...state,
                data: action.payload.data,
                Success: true,
                loading: false,
                defaultResponse: false,
                error: false,
                message: 'success'
             };

        case 'FETCH_SHAREABLE_PLAYBOOKS_LINK_FAILURE':
            return {
                ...state,
                // message: action.payload.response.data.message,
                // userMessage: action.payload.response.data['usermessage'],
                data: [],
                loading: false,
                defaultResponse: false,
                error: true,
                Success: false,
             };

        default:

            return state;

    }

};