export default (
  state = {
    loading: false,
    defaultResponse: true,
    Success: false,
    editData: [ {innerHtml : ''}]
  },
  action
) => {
  switch (action.type) {
    case "EDIT_RELEASE_DATA_REQUEST":
      return {
        ...state,
        loading: true,
        defaultResponse: true,
        editData: []
      };

    case "EDIT_RELEASE_DATA_SUCCESS":
      return {
        ...state,
        editData: action.payload.data,
        Success:true,
         loading: false,
        defaultResponse: false,
      };

    case "EDIT_RELEASE_DATA_FAILURE":
      return {
        Success: false,
        editData: [],
        error: true,
        // message: action.payload.response.data["message"],
        loading: false,
        defaultResponse: false,
      };

    default:
      return state;
  }
};
