export default (state = { updateName: [], error: false, loading: false, defaultResponse: true }, action) => {

    switch (action.type) {

        case 'UPDATE_EDITOR_MODULE_FEATURE_REQUEST':
            return {
                updateName: [],
                loading: true,
                error: false,
                defaultResponse: true
            };

        case 'UPDATE_EDITOR_MODULE_FEATURE_SUCCESS':
            return {
                updateName: [],
                responseDefault: false,
                loading: false,
                success: true,
                error: false,
                message: action.payload['message'],
                defaultResponse: false
            };

        case 'UPDATE_EDITOR_MODULE_FEATURE_FAILURE':
            return {
                updateName: [],
                error: true,
                responseDefault: false,
                loading: false,
                success: false,
                defaultResponse: false
            };


        default:

            return state;

    }

};