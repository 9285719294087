import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      background: "#ffffff",
      boxShadow: "0px 10px 30px 0px rgb(82 63 105 / 8%)",
      height: 80,
      paddingRight: 0
    },
    flexgrow: {
      flexGrow: 1,
    },
    appBar: {
      boxShadow: 'none',
      borderBottom: '1px solid #e5e5e5',
    },
    logoButton: {
      marginRight: theme.spacing(3),
      marginLeft: theme.spacing(10),
      outline: 'none !important',
      border: 'none'
    },
    HomeButton: {
      outline: 'none !important',
      border: 'none'
    },
    vertical: {
      borderRight: '2px solid #c6c6c6',
      height: '20px',
      marginLeft: 20
    },
    select: {
      width: 120,
      fontSize: '0.8rem',
      '& .MuiSelect-selectMenu': {
        fontSize: '12px',
      }
    },
    region: {
      display: 'none',
    },
    primaryText: {
      color: '#1363c0',
      borderBottom: '2px solid #1363c0',
      textTransform: 'capitalize'
    },
    navDisplayFlex: {
      display: `flex`,
      justifyContent: `space-between`,
    },
    linkText: {
      textDecoration: `none`,
      textTransform: `uppercase`,
      color: `#1363c0`,
    },
    fontSize: {
      fontSize: '1rem'
    },
    backdrop: {
      zIndex: 10000,
      color: '#fff',
    },
    wait: {
      position: 'relative',
      top: '29px',
      left: ' -55px',
      fontSize: '15px'
    },
    flexContainer: {
      display: 'flex',
      flexDirection: 'row',
    },
    bookmarkSection:{
      marginTop: '0px',
      marginRight: '10px'
    }
  }));

  export { useStyles };