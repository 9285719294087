import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import 'react-tabs/style/react-tabs.css';
import { updateViewBuilderJSON } from '../../../redux/actions';
import cloneDeep from 'lodash/cloneDeep';
class BiConfigurations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayColorPicker: false,
      colorCode: '',
      displayColorPickerId: '',
    }
  }

  handleViewBuilderJSONChange(e, type, keyName) {
    let tempViewBuilderObj = cloneDeep(this.props.viewBuilder);
    let id = this.props.id
    if (type === 'layout') {
      tempViewBuilderObj.viewBuilderJSON.htmlTemp[id][keyName] = e.target.value;
    }
    else if (type === 'configOptions') {
      tempViewBuilderObj.viewBuilderJSON.htmlTemp[id]['configOptions'][keyName] = e.target.value;
    }
    else if (type === 'style' || type === 'colorCode') {
      let targetVal = '';
      if (type === 'colorCode') {
        targetVal = e.hex;
      }
      else {
        targetVal = e.target.value;
      }
      tempViewBuilderObj.viewBuilderJSON.htmlTemp[id]['configOptions']['style'][keyName] = targetVal;
    }
    else if (type === 'biDashboard') {
      tempViewBuilderObj.viewBuilderJSON.htmlTemp[id][keyName] = e.target.value;
    }
    this.props.updateViewBuilderJSONAPI(tempViewBuilderObj)
  }

  renderlayoutOptions() {
    return (
      <div className="formContainer">
        <div className="form-group">
          <label for="exampleInputEmail1">Power BI Group Id</label>
          <input type="text" className="form-control"
              value={this.props.viewBuilder.viewBuilderJSON.htmlTemp[this.props.id]['groupId']}
              onChange={(e) => this.handleViewBuilderJSONChange(e, 'biDashboard', 'groupId')}
          />
        </div>
        <div className="form-group">
          <label for="exampleInputEmail1">Power BI Report Id</label>
          <input type="text" className="form-control"
            value={this.props.viewBuilder.viewBuilderJSON.htmlTemp[this.props.id]['reportId']}
            onChange={(e) => this.handleViewBuilderJSONChange(e, 'biDashboard', 'reportId')}
          />
        </div>
      </div>
    )
  }

  render() {
    console.log('@viewBuilderJSONStructure 222', this.props.viewBuilder, this.props.id);
    return (
      this.renderlayoutOptions()
    );
  }
}
function mapStateToProps(state) {
  return ({
    viewBuilder: state.viewBuilderObj,
  })
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    updateViewBuilderJSONAPI: updateViewBuilderJSON
  }, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(BiConfigurations);
