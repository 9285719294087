import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme, props) => ({
    container: {
      display: 'flex',
      justifyContent: 'right'
    },
    flexRow:{
        display: 'flex',
        flexDirection: "row",
    },
    button: {
        display: 'block',
        marginTop: theme.spacing(2),
    },
    formControl: {
        height: 35,
        minWidth: 200,
        borderRadius: '2rem',
        padding: 0,
        '& svg': {
            top: 'calc(50% - 10px)',
            color: '#f8f9fa',
            right: '0',
            position: 'absolute',
            pointerEvents: 'none',
            width: 30,
            height: 30
        },
    },
    inputLabel: {
        top: -10,
        left: 25,
        fontSize: 14,
        color: '#fff'
    },
    select:{
        '& .MuiSelect-selectMenu': {
            fontSize: 14,
            color: '#f8f9fa',
            top: 6,
            position: 'relative'
        },
        '& .MuiSelect-selectMenu:focus': {
            fontSize: 15,
            color: '#f8f9fa',
            background: 'transparent',
            borderBottom: 0,
            TextDecoration: 'none',
        },
        '& .MuiMenu-paper' : {
            maxHeight: 'calc(100% - 300px)'
        },
    },
    underline: {
        '&:before': {
            borderBottom: '0'
        },
        '&:after': {
            borderBottom: `0`
        },
        '&:hover:not($disabled):not($focused):not($error):before': {
            borderBottom: `0`
        },
    },
    '& .MuiInput-underline.Mui-focused:after':{
        borderBottom: '0 !important'
    }

}));

export { useStyles };