import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import {
    captureNewBookmark,
    updateBookmark,
    deleteBookmark,
    fetchBookmarks,
    toastTriggered
} from '../../redux/actions/index';
import toastProperties from "../../assets/data/toastProperty";
import './style.css';
import utils from '../../utils/index';

const useStyles = makeStyles((theme) => ({
    textField: {
        width: "100%",
        '& .MuiFilledInput-root': {
            margin: '5px 15px',

        },
        '& .MuiFilledInput-input': {
            padding: "7px 12px 10px",
            height: '15px'
        },
    }
}));

const BookMarks = (props) => {
    const classes = useStyles();
    let history = useHistory();
    const dispatch = useDispatch();
    const pathName = window.location.pathname;
    const bookmarks = useSelector((state) => state.getBookmarks);
    const captureBookmark = useSelector((state) => state.capturePlaybookData);
    const pbiReport = useSelector((state) => state.PowerBIReportPageSection);
    // const reportBookmark = useSelector((state) => state.PBIReportBookmark);
    const {  activeRegion, activeRoutes, activePage } = useSelector((state) => state.activeDynamicData);

    const [edit, setEdit] = useState(false);
    const [editName, setEditName] = useState(false);
    const [value, setValue] = useState('');
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('null');
    const [click, setClick] = useState(false);
    const [selectedBookmark, setSelectedBookmark] = useState(0);
    const [selectedIndex, setSelectedIndex] = useState(0);

    const onhandleChange = (e) => {
        let val = e.target.value;
        setValue(val);
    };

    const fontStyle = {
        fontSize: '13px',
        padding: '0 10px',
        margin: '10px 0 0'
    };

    const trackEvents = (bookmark, type, name) => {
        let trackingFilter = {};
        if (activePage && activePage.globalFilter) {
            const dataSet = !!bookmark.selectedFilters && JSON.parse(bookmark.selectedFilters)['dataSet']
            !!dataSet && Object.keys(dataSet).map((item) => trackingFilter[item] = dataSet[item]['activeItem']);
        }
        utils.userTrackingInfo({
            region: bookmark.region,
            subRegion: bookmark.subRegion,
            actionType: "Bookmark",
            pageName: activePage && activePage.pageName,
            reportName: bookmark.viewName,
            pageDetails: !!name ? name : bookmark.bookmarkName,
            globalFilters: JSON.stringify(trackingFilter),
            groupId: !bookmark.groupId ? bookmark.groupID : bookmark.groupId,
            reportId: !bookmark.reportId ? bookmark.reportID : bookmark.reportId,
            attributeName: type,
            reportSection: bookmark.reportSectionName,
            reportSectionId: bookmark.reportSectionId,
            reportLink: bookmark.reportLink,
        });
    }

    const editBookmark = (bookmark) => {
        setEdit(true);
        setSelectedBookmark(bookmark);
        props.editBookmark('Update Bookmark');
    };

    const deleteSelBookmark = (rowItem, index) => {
        setSelectedIndex(index)
        setClick(true);
        let params = { "bookmarkID": rowItem.bookmarkID };
        dispatch(deleteBookmark(params)).then((res) => {
            let sessionData = JSON.parse(sessionStorage.getItem('oktaRes'));
            trackEvents(rowItem, "Bookmark Deleted", null);
            toastProperties.success["description"] = res.data.message;
            dispatch(toastTriggered([toastProperties.success]));
            let params = {
                "userEmail": sessionData && sessionData.email,
                "pageType": "Playbooks"
            }
            dispatch(fetchBookmarks(params));
            setClick(false);
            props.hideSettingsWindow('e');
        }).catch((err) => {
            toastProperties.error["description"] = err.message;
            dispatch(toastTriggered([toastProperties.error]));
            setClick(false);
        });
    };

    const editBookmarkName = (bookmark) => {
        setEditName(true);
        setValue(bookmark.bookmarkName);
    };

    const updateBookmarkName = (bookmark) => {
        let appliedFilter;
        if (activePage && activePage.globalFilter) {
            const data = localStorage.getItem('reportFilter');
            appliedFilter = !!data ? JSON.parse(data) : bookmark.selectedFilters;
        }
        if (value.length > 60) {
            setClick(false);
            setError(true);
            setErrorMessage('Bookmark name must be less than 60 characters');
            return true;
        }
        else {
            setClick(true);
            setError(false);
            let updatedName = value !== '' ? value : bookmark.bookmarkName;
            let params = {
                "bookmarkDetails": {
                    "bookmarkName": updatedName,
                    "bookmarkID": bookmark.bookmarkID,
                    "userEmail": bookmark.userEmail,
                    "pageType": bookmark.pageType,
                    "pageName": bookmark.pageName,
                    "region": bookmark.region,
                    "subRegion": bookmark.subRegion === 'null' ? null : bookmark.subRegion,
                    "groupId": bookmark.groupID,
                    "reportId": bookmark.reportID,
                    "selectedFilters": appliedFilter,
                    "bookmarkState": bookmark.bookmarkState,
                    "viewName": bookmark.viewName,
                    "viewIndex": bookmark.viewIndex
                }
            }
            dispatch(updateBookmark(params)).then((res) => {
                toastProperties.success["description"] = res.data.message;
                dispatch(toastTriggered([toastProperties.success]));
                let sessionData = JSON.parse(sessionStorage.getItem('oktaRes'));
                trackEvents(bookmark, "Bookmark Updated", updatedName);
                let params = {
                    "userEmail": sessionData && sessionData.email,
                    "pageType": "Playbooks"
                }
                dispatch(fetchBookmarks(params));
                setClick(false);
                props.hideSettingsWindow('e');
            }).catch((err) => {
                setClick(false);
                toastProperties.error["description"] = err.message;
                dispatch(toastTriggered([toastProperties.error]));
            });
        }
    };

    const updateBookmarkState = (selBookmark) => {
        setClick(true);
        let params = { bookmarkDetails: {} };
        let appliedFilter;
        if (activePage && activePage.globalFilter) {
            const data = localStorage.getItem('reportFilter');
            appliedFilter = !!data ? JSON.parse(data) : null;
        }
        if (!!pbiReport && Object.keys(pbiReport).length > 0) {
            pbiReport.bookmarksManager.capture({ personalizeVisuals: true }).then(async (bookmark) => {
                if (selBookmark.reportSectionId !== captureBookmark.reportSectionId) {
                    params.bookmarkDetails["region"] = activeRegion;
                    params.bookmarkDetails["subRegion"] = captureBookmark === 'null' ? null : captureBookmark.subRegion;
                    params.bookmarkDetails["groupId"] = captureBookmark.groupId;
                    params.bookmarkDetails["reportId"] = captureBookmark.reportId;
                    params.bookmarkDetails["selectedFilters"] = appliedFilter;
                    params.bookmarkDetails["viewName"] = captureBookmark.viewName;
                    params.bookmarkDetails["viewIndex"] = captureBookmark.viewIndex;
                    params.bookmarkDetails["reportSectionId"] = captureBookmark.reportSectionId;
                    params.bookmarkDetails["reportSectionName"] = captureBookmark.reportSectionName;
                    params.bookmarkDetails["reportLink"] = captureBookmark.reportLink;
                }
                else {
                    params.bookmarkDetails["region"] = selBookmark.region;
                    params.bookmarkDetails["subRegion"] = selBookmark.subRegion;
                    params.bookmarkDetails["groupId"] = selBookmark.groupID;
                    params.bookmarkDetails["reportId"] = selBookmark.reportID;
                    params.bookmarkDetails["selectedFilters"] = selBookmark.selectedFilters;
                    params.bookmarkDetails["viewName"] = selBookmark.viewName;
                    params.bookmarkDetails["viewIndex"] = selBookmark.viewIndex;
                    params.bookmarkDetails["reportSectionId"] = selBookmark.reportSectionId;
                    params.bookmarkDetails["reportSectionName"] = selBookmark.reportSectionName;
                    params.bookmarkDetails["reportLink"] = selBookmark.reportLink;
                }
                if (value !== '' && value.length > 60) {
                    setClick(false);
                    setError(true);
                    setErrorMessage('Bookmark name must be less than 60 characters');
                    return true;
                }
                else if (value !== '') {
                    setClick(true);
                    setError(false);
                    params.bookmarkDetails.bookmarkName = value
                }
                params.bookmarkDetails["bookmarkID"] = selBookmark.bookmarkID;
                params.bookmarkDetails["userEmail"] = selBookmark.userEmail;
                params.bookmarkDetails["pageType"] = selBookmark.pageType;
                params.bookmarkDetails["pageName"] = selBookmark.pageName;
                params.bookmarkDetails["bookmarkState"] = bookmark.state;
                 dispatch(updateBookmark(params)).then((res) => {
                    let sessionData = JSON.parse(sessionStorage.getItem('oktaRes'));
                    let updateBookmark = selBookmark.reportSectionId !== captureBookmark.reportSectionId ? captureBookmark : selBookmark;
                    if (selBookmark.reportSectionId !== captureBookmark.reportSectionId) {
                        captureBookmark['selectedFilters'] = JSON.stringify(appliedFilter)
                        captureBookmark['bookmarkName'] = value !== '' ? value : selBookmark.bookmarkName
                    }
                    trackEvents(updateBookmark, "Bookmark Updated", null);
                    toastProperties.success["description"] = res.data.message;
                    dispatch(toastTriggered([toastProperties.success]));
                    let params = {
                        "userEmail": sessionData && sessionData.email,
                        "pageType": "Playbooks"
                    }
                    dispatch(fetchBookmarks(params));
                    setClick(false);
                    props.hideSettingsWindow('e');
                }).catch((err) => {
                    setClick(false);
                    toastProperties.error["description"] = err.message;
                    dispatch(toastTriggered([toastProperties.error]));
                });
            });
        }
    };

    const createNewBookmark = () => {
        if (value === '') {
            setClick(false);
            setError(true);
            setErrorMessage('Please input bookmark name');
            return true;
        }
        else if (value !== '' && value.length > 60) {
            setClick(false);
            setError(true);
            setErrorMessage('Bookmark name must be less than 60 characters');
            return true;
        }
        else {
            setClick(true);
            setError(false);
            let appliedFilter;
            if (activePage && activePage.globalFilter) {
                const data = localStorage.getItem('reportFilter');
                appliedFilter = !!data ? JSON.parse(data) : null;
            }
            let sessionData = JSON.parse(sessionStorage.getItem('oktaRes'));
            if (!!pbiReport && Object.keys(pbiReport).length > 0) {
                pbiReport.bookmarksManager.capture({ personalizeVisuals: true }).then(async (bookmark) => {
                    let params = {
                        "bookmarkDetails": {
                            "bookmarkName": value,
                            "userEmail": sessionData && sessionData.email,
                            "pageName": captureBookmark.pageName,
                            "pageType": captureBookmark.pageType,
                            "region": activeRegion,
                            "subRegion": props.activeSubRegion === 'null' ? null :  activeRoutes.key1 === pathName ? props.activeSubRegion : null,
                            "groupId": captureBookmark.groupId,
                            "reportId": captureBookmark.reportId,
                            "selectedFilters": appliedFilter,
                            "bookmarkState": bookmark.state,
                            "viewName": captureBookmark.viewName,
                            "viewIndex": captureBookmark.viewIndex,
                            "reportSectionId": captureBookmark.reportSectionId,
                            "reportSectionName": captureBookmark.reportSectionName,
                            "reportLink": captureBookmark.reportLink,
                            otherDetails: captureBookmark.otherDetails
                        }
                    };
                     dispatch(captureNewBookmark(params)).then((res) => {
                        let sessionData = JSON.parse(sessionStorage.getItem('oktaRes'));
                        toastProperties.success["description"] = res.data.message;
                        dispatch(toastTriggered([toastProperties.success]));
                        captureBookmark['selectedFilters'] = JSON.stringify(appliedFilter);
                        trackEvents(captureBookmark, 'Bookmark Created', value);
                        let params = {
                            "userEmail": sessionData && sessionData.email,
                            "pageType": "Playbooks"
                        }
                        dispatch(fetchBookmarks(params));
                        setClick(false);
                        props.hideSettingsWindow('e');
                    }).catch((err) => {
                         toastProperties.error["description"] = err.message;
                        dispatch(toastTriggered([toastProperties.error]));
                        setClick(false);
                    });
                });
            }
        }
    };

    const cancelBookmark = () => {
        setEdit(false);
        setEditName(false);
    };

    const createInitialBookmark = () => {
        let historyParams = {
            background: "#f4f5f6",
            pageType: "Playbooks",
            region: activeRegion,
            sideBar: "",
            subRegion: props.activeSubRegion,
            viewName: "Strategic Pricing",
            viewIndex: 0,
        };
        history.push({
            pathname: activeRoutes.key1,
            state: historyParams,
        });
        props.hideSettingsWindow('e');
    };

    return (
        <div className="container">
            <center>
                <div className="col-12 sections my-2" id="basic_screen">
                    <form className="form">
                        <div className="row">
                            <div className="col-12  col-xl-12 flexCol" style={{ marginTop: '20px' }}>
                                {!props.createBookmark ?
                                    <div>
                                        {
                                            !edit ?
                                                <div>
                                                    <ul className={"navbar"}>
                                                        <li style={{ width: '10%', textAlign: "justify", fontSize: '15px' }}>S.No.</li>
                                                        <li style={{ width: '75%', textAlign: "justify", fontSize: '15px' }}>Bookmark Name</li>
                                                        <li style={{ width: '15%' }}></li>
                                                    </ul>
                                                    {
                                                        !!bookmarks.loading && !!bookmarks && Array.isArray(bookmarks.data) && bookmarks.data.length === 0 ?
                                                            <div> ...Loading </div> :
                                                            !!bookmarks && Array.isArray(bookmarks.data) && bookmarks.data.length > 0 ?
                                                                <ul className={"mainContainer"}>
                                                                    {
                                                                        bookmarks.data.map((item, index) => {
                                                                            return (
                                                                                <div className={props.bookMarkIndex == index ? "activeflexContainer" : "flexContainer"} >
                                                                                    <li className="series">{index + 1 + "."}</li>
                                                                                    <li className={"bookmarks"} key={index}> {!!item.subRegion && item.subRegion !== 'null' ? item.region + '_' + item.subRegion : item.region} | {item.bookmarkName}</li>
                                                                                    <div className={"actionContainer"}>
                                                                                        <i onClick={() => editBookmark(item)} class="fas fa-edit" style={fontStyle}></i>
                                                                                        {
                                                                                            !!click && index === selectedIndex ?
                                                                                                <div className="delete">deleting...</div> :
                                                                                                <i onClick={() => deleteSelBookmark(item, index)} class="far fa-trash-alt" style={fontStyle}></i>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                </ul>
                                                                :
                                                                !!bookmarks.error && bookmarks.message === "No Data found" ?
                                                                    <div className={"flexContainer"} onClick={() => createInitialBookmark()}>
                                                                        {bookmarks.userMessage}
                                                                    </div>
                                                                    :
                                                                    bookmarks.userMessage
                                                    }
                                                </div>
                                                :
                                                //  ******* update section *********
                                                <div className="updateItem">
                                                    <div style={{ display: "flex", flexDirection: 'column', textAlign: 'justify', margin: '-25px 0 15px 15px' }}>
                                                        <div style={{ fontSize: "15px", }}> Region: {selectedBookmark.region}</div>
                                                        {selectedBookmark.subRegion && selectedBookmark.subRegion !== 'null' ?
                                                            <div style={{ fontSize: "15px" }}> Sub-Region: {selectedBookmark.subRegion}</div>
                                                            : null
                                                        }
                                                    </div>
                                                    {
                                                        !!editName ?
                                                            <div className={"bookmarkContainer"}>
                                                                <TextField id="filled-basic" label="" variant="filled" value={value} onChange={(e) => onhandleChange(e)} className={classes.textField} />
                                                            </div>
                                                            :
                                                            <div className="editBookmark">
                                                                {selectedBookmark.region}{!!selectedBookmark.subRegion && selectedBookmark.subRegion !== 'null' ? '_' + selectedBookmark.subRegion : ''}   | {selectedBookmark.bookmarkName}
                                                            </div>
                                                    }
                                                    {
                                                        error ?
                                                            <div className="error"> {errorMessage} </div>
                                                            : null
                                                    }
                                                    {
                                                        <div className={"updatebm"}>
                                                            {
                                                                !editName ?
                                                                    <button type="button" className="pBtn" onClick={() => editBookmarkName(selectedBookmark)}>Edit Name</button>
                                                                    :

                                                                    <button disabled={!!click} type="button" className="pBtn" onClick={() => updateBookmarkName(selectedBookmark)}>Update Name</button>
                                                            }
                                                            {
                                                                activePage && activePage.createBookmark ?
                                                                    <button disabled={!!click} type="button" className="pBtn" onClick={() => updateBookmarkState(selectedBookmark)}>Update Bookmark</button>
                                                                    : null
                                                            }
                                                            <button type="button" className="pBtn" onClick={() => cancelBookmark(selectedBookmark)}>Cancel</button>
                                                        </div>
                                                    }
                                                </div>
                                        }
                                    </div>
                                    :
                                    <div className={"bookmarkContainer"}>
                                        <div style={{ display: "flex", flexDirection: 'column', textAlign: 'justify', margin: '-25px 0 15px 0px' }}>
                                            <div style={{ fontSize: "14px", }}> Region: {activeRegion}</div>
                                            {selectedBookmark.subRegion && activeRoutes.key1 === pathName ?
                                                <div style={{ fontSize: "14px", }}> Sub-Region: {props.activeSubRegion}</div>
                                                : null
                                            }
                                        </div>

                                        <TextField id="filled-basic" label="Please enter bookmark name" variant="filled" value={value} onChange={(e) => onhandleChange(e)} style={{ width: '100%' }} />
                                        {
                                            error ?
                                                <div className="error"> {errorMessage} </div>
                                                : null
                                        }
                                        <button disabled={!!click} type="button" className="pBtn" onClick={() => createNewBookmark()} style={{ marginTop: '10px' }}>Create New Bookmark</button>
                                    </div>
                                }
                            </div>
                        </div>
                    </form>
                </div>
            </center>
        </div>
    )
};

export default BookMarks;


