import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UserAgentApplication } from "msal";
import { handleSilentLogin, loginRequest, msalConfig } from "../../component/msal/loginrequest";
import { Report } from 'powerbi-report-component';
import { models } from 'powerbi-client';
import cloneDeep from 'lodash/cloneDeep';
import { useStyles } from "./style.js";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import {
  updateMsalTokenFlag,
  toastTriggered,
  updatePowerBIReport,
  updatePlaybooksContentPages,
  capturePlaybooksData
} from '../../redux/actions/index';
import CircularProgress from '@material-ui/core/CircularProgress';
import utils from '../../utils/index';
import { useHistory } from "react-router-dom";
import toastProperties from "../../assets/data/toastProperty";
import './style.css';

const PreviewDashboard = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  let history = useHistory()

  const { pageType, navContentPaneEnabled ,  activeIndex} = props;

  const powerbiToken = useSelector((state) => state.getEmbedToken);
  const playBookData = useSelector((state) => state.viewBuilderData);
  const errorMessage = useSelector((state) => state.getErrorMessages);
  const captureBookmark = useSelector((state) => state.capturePlaybookData);

  console.log('re-render check PreviewDashboard', powerbiToken, playBookData,);


  const [noData, setNodata] = useState(false);
  const [embedError, setEmbedError] = React.useState(null);
  // const [noReports, setNoReport] = React.useState(null);
  const [reportIDs, setReportIDs] = useState([]);
  const [currentReport, setCurrentReport] = useState(null);


  const { activePage,  isMsalTokenExpired, activeMsalToken, activeRegion } = useSelector((state) => state.activeDynamicData);
  const msalInstance = new UserAgentApplication(msalConfig);

  useEffect(() => {
    const PBIDown = errorMessage?.data?.find(item => item.errorType === "PBI API Down")
    setEmbedError(PBIDown?.errorMessage);
  }, [errorMessage]);

  useEffect(() => {
    let tempViewBuilderObj = cloneDeep(playBookData);
    const htmlTemp = !!tempViewBuilderObj && !!tempViewBuilderObj.htmlTemp && Object.values(tempViewBuilderObj.htmlTemp)
    let reportsIds = utils.checkValidPBIds(htmlTemp, tempViewBuilderObj.customizableFlag);
    setReportIDs(reportsIds);
    if (reportsIds) {
      setReportIDs(reportsIds)
      setNodata(false);
    }
    else {
      setNodata(true);
      setReportIDs({})
    }
    console.log('reportsIds', htmlTemp, tempViewBuilderObj,);
  }, [dispatch, pageType, playBookData]);


  useEffect(() => {
    let tempViewBuilderObj = cloneDeep(playBookData);
    if (!!tempViewBuilderObj && Object.keys(tempViewBuilderObj).length > 0) {
      const htmlTemp = !!tempViewBuilderObj && !!tempViewBuilderObj.htmlTemp && Object.values(tempViewBuilderObj.htmlTemp)
      let reportsIds = utils.checkValidPBIds(htmlTemp, tempViewBuilderObj.customizableFlag);
      const reportLink = tempViewBuilderObj.linkForDocumentation;
      let params = {
        reportId: reportsIds['reportId'],
        groupId: reportsIds['groupId'],
        pageType: activePage['key'],
        pageName: activePage['parentModule'],
        viewName: playBookData.viewName,
        viewIndex: activeIndex,
        reportLink: reportLink,
        region: activeRegion,
        otherDetails: {parentId : activePage.parentId, childId : activePage.childId}
      };
      dispatch(capturePlaybooksData(params));
    }
  }, [dispatch, playBookData, powerbiToken, activeIndex, activeRegion, activePage]);


  useEffect(() => {
    if (!!captureBookmark) {
      currentReport && currentReport.on("rendered", function (event) {
        currentReport.getPages().then((pages) => {
          console.log('@CaptureShareLinkDetails', 222, pages, captureBookmark)
          let page = pages.filter(function (page) { return page.isActive })[0];
          if (!captureBookmark.reportSectionId && !captureBookmark.reportSectionName) {
            captureBookmark['reportSectionId'] = page.name;
            captureBookmark['reportSectionName'] = page.displayName;
            dispatch(capturePlaybooksData(captureBookmark));
          }
          else if (captureBookmark.reportSectionId !== page.name && !captureBookmark.reportSectionName !== page.displayName) {
            captureBookmark['reportSectionId'] = page.name;
            captureBookmark['reportSectionName'] = page.displayName;
            dispatch(capturePlaybooksData(captureBookmark));
          }
        })
      })
    }
    currentReport && currentReport.on("pageChanged", function (event) {
      let page = event.detail.newPage;
      if (!!captureBookmark && !captureBookmark.reportSectionId && !captureBookmark.reportSectionName) {
        captureBookmark['reportSectionId'] = page.name;
        captureBookmark['reportSectionName'] = page.displayName;
        dispatch(capturePlaybooksData(captureBookmark));
      }
    });

  }, [captureBookmark, currentReport,  dispatch]);


  const layoutSettings = {
    // layoutType: models.LayoutType.Custom,
    customLayout: {
      pageSize: {
        type: models.PageSizeType.Custom,
        width: 1200,
        height: 5900
      },
      displayOption: models.DisplayOption.FitToWidth,
      pagesLayout: {
        ReportSection1: {
          defaultLayout: {
            displayState: {
              mode: models.VisualContainerDisplayMode.Hidden,
            },
          },
          visualsLayout: {
            VisualContainer1: {
              x: 1,
              y: 1,
              z: 1,
              width: 900,
              height: 300,
              displayState: {
                mode: models.VisualContainerDisplayMode.Visible,
              },
            },
            VisualContainer2: {
              displayState: {
                mode: models.VisualContainerDisplayMode.Visible,
              },
            },
          },
        },
      },
    },
  };

  const extraSettings = {
    filterPaneEnabled: false, //true
    navContentPaneEnabled: navContentPaneEnabled, //true
    hideErrors: false, // ,
    ...layoutSettings,  //  layout config
  };

  console.log('PreviewDashboard-playBookData', extraSettings)

  const style = {
    height: "85vh",
    width: "inherit",
    fontSize: "80%",
  };

  const handlePageChange = (data, groupId, reportId) => {
    let trackingObject = {
      region: activeRegion,
      actionType: 'Click',
      pageName: pageType ? pageType : activePage && activePage.key,
      reportName: playBookData.viewName,
      reportSection: data.newPage.displayName,
      reportSectionId: data.newPage.name,
      groupId: groupId,
      reportId: reportId,
    };
    console.log('@handlePageChange', data);
    if (pageType === "Dashboard") {
      trackingObject.actionType = "Admin Activity";
      trackingObject.attributeName = 'Dashboard Embbeded Report Preview';
    }
    utils.userTrackingInfo(trackingObject);
  };

  const handleTokenExpireError = () => {
    if (process.env.REACT_APP_ENVIRONMENT === "LOCAL") {
      toastProperties.error["description"] = 'MSAL Access Token is expired, Please get a new token';
      dispatch(toastTriggered([toastProperties.error]));
    }
    else if (msalInstance.getAccount()) {
      dispatch(updateMsalTokenFlag(true, "default repo"))
      handleSilentLogin(msalInstance, loginRequest);
    } else {
      history.push({
        pathname: "/msalcallback",
        state: {},
      });
    }
  };

  const UpdateReportPages = (report) => {
    console.log();
    const { customizableFlag, htmlTemp, msalEnabled, hiddenPages, reportPages } = playBookData;
    console.log('UpdateReportPages', 111, playBookData, customizableFlag, htmlTemp, msalEnabled, hiddenPages, reportPages);

    dispatch(updatePowerBIReport(report));
    report.getPages().then((allPage) => {
      let data = Array.isArray(allPage) && allPage.length > 0 &&
        allPage.filter((pages) => {
          return pages.visibility === 0
        });
      data.forEach((el, index) => el.order = index);
      console.log('UpdateReportPages', 222, allPage, data);
      if (reportPages && hiddenPages) {
        if (Array.isArray(data) && data.length > 0) {
          let res = {
            data
          }
          let selections = {
            reportId: reportIDs['reportId'],
            groupId: reportIDs['groupId'],
            viewID: playBookData.viewId,
            pageType: activePage.key,
          }
          let payload = { ...res, selections };
          dispatch(updatePlaybooksContentPages(payload));
        }
      }
    })
  };

  const reportLoad = (report) => {
    dispatch(updatePowerBIReport(report));
    setCurrentReport(report);
  };


  const handleSelectData = (data) => {
    const { dataPoints } = data;
    const { table, column } = Array.isArray(dataPoints) && dataPoints[0].identity &&
        Array.isArray(dataPoints[0].identity) && dataPoints[0].identity[0].target;
    const value = Array.isArray(dataPoints) && dataPoints[0].identity &&
        Array.isArray(dataPoints[0].identity) && dataPoints[0].identity[0].equals;
    const visuals = data && data.visual;
    console.log('@onselectdata', dataPoints, table, column, value );
};

  const renderPowerBIDashboard = (obj) => {
    if (obj.type === 'dashboard' && obj.reportId !== "" && obj.groupId !== "") {
      if (!playBookData['msalEnabled']) {
        console.log('@CheckReportIDs', obj, reportIDs);

        if (obj && obj['reportId'] && reportIDs['reportId'] && obj['reportId'] === reportIDs['reportId']) {
          let embedUrl = `https://app.powerbi.com/reportEmbed?reportId=${obj.reportId}&groupId=${obj.groupId}`;
          console.log('renderChildrenOfId', 888, playBookData, extraSettings, powerbiToken, obj, embedUrl, reportIDs);
          return (
            <Report
              tokenType={'Embed'}
              embedType="report"
              accessToken={powerbiToken.embedToken}
              embedUrl={embedUrl}
              embedId={obj.reportId}
              reportMode="View"
              groupId={obj.groupId}
              extraSettings={extraSettings}
              permissions="All"
              style={style}
              onRender={(report) => {
                console.log('onRender', report);
                UpdateReportPages(report)
              }}
              onLoad={(report) => {
                reportLoad(report);
              }}
              onSelectData={(data) => handleSelectData(data)}
              onPageChange={(data) => handlePageChange(data, obj.reportId, obj.groupId)}
              onError={(data) => console.log('onError', data)}
            />
          )
        }
      }
      else if (playBookData['msalEnabled'] && obj.msalToken && obj.msalToken !== '') {
        if (obj && obj['reportId'] && reportIDs['reportId'] && obj['reportId'] === reportIDs['reportId']) {
          let embedUrl = `https://app.powerbi.com/reportEmbed?reportId=${obj.reportId}&groupId=${obj.groupId}`;
          console.log('renderChildrenOfId', 999, playBookData, embedUrl, extraSettings, obj, reportIDs);
          return (
            <Report
              tokenType="access"
              embedType="report"
              accessToken={activeMsalToken ? activeMsalToken : sessionStorage.getItem("msalAccessToken")}
              embedUrl={embedUrl}
              embedId={obj.reportId}
              reportMode="View"
              groupId={obj.groupId}
              extraSettings={extraSettings}
              permissions="All"
              style={style}
              onRender={(report) => {
                console.log('onRender', report);
                UpdateReportPages(report)
              }}
              onLoad={(report) => {
                reportLoad(report);
              }}
              onSelectData={(data) => handleSelectData(data)}
              onError={(data) => {
                console.log('onError', data);
                if (data['message'] === "TokenExpired") {
                  handleTokenExpireError()
                }
              }
              }
              onPageChange={(data) => handlePageChange(data, obj.reportId, obj.groupId)}
            />
          )
        }
      }
    }
  };

  const renderHtmlContent = (obj) => {
    if (obj.innerhtml !== '' && obj.type !== 'dashboard') {
      return (
        <div className="ql-snow">
          <span className="ql-editor" dangerouslySetInnerHTML={{ __html: obj.innerhtml }} />
        </div>
      )
    }
    else if (obj.innerhtml !== '' && obj.type === 'dashboard' && obj.reportId !== "" && obj.embedToken === '') {
      return (
        <div className="ql-snow">
          <span className="ql-editor" dangerouslySetInnerHTML={{ __html: obj.innerhtml }} />
        </div>
      )
    }
  };

  const renderChildrenOfId = (id) => {
    console.log('renderChildrenOfId', playBookData, powerbiToken);
    if (!!playBookData && Object.keys(playBookData).length > 0) {
      if (!playBookData['msalEnabled'] && !!powerbiToken.loading && powerbiToken.embedToken === '') {
        console.log('renderChildrenOfId', 333, powerbiToken);
        return (
          <div className={classes.position}>
            <CircularProgress color="primary" />
          </div>
        )
      }
      else if (!playBookData['msalEnabled'] && !powerbiToken.loading && !powerbiToken.responseDefault && !!powerbiToken.error && powerbiToken.embedToken === '') {
        console.log('renderChildrenOfId', 444, powerbiToken);
        return (
          <div className={classes.serverDown}>
            {/* <span><img src={NoData} alt="serverDown" className={pageType === "Dashboard" ? classes.smImgFit : classes.imgFit} /></span> */}
            <div className="noReports"></div>
            <div style={{ display: "flex", justifyContent: "center" }}>{embedError}</div>
            {pageType === "Dashboard" ? <div>Please Try with RLS Based Emedding</div> : null}
          </div>
        )
      }
      else if (playBookData.hasOwnProperty("htmlTemp")) {
        let newJson = playBookData.htmlTemp;
        if (newJson === 'NULL') {
          console.log('renderChildrenOfId', 666);
          return (
            <div className={classes.serverDown}>
              {/* <span><img src={NoData} alt="nodataFound" className={pageType === "Dashboard" ? classes.smImgFit : classes.imgFit} /></span> */}
              <div className="noReports"></div>
              <div style={{ display: "flex", justifyContent: "center" }}>{embedError}</div>
              {pageType === "Dashboard" ? <div>Please Try with RLS Based Emedding</div> : null}
            </div>
          )
        }
        else if (Object.keys(newJson).length > 0 && newJson !== 'NULL') {
          let obj = newJson[id];
          console.log('renderChildrenOfId', 777, obj, newJson, id, playBookData);
          const HtmlTag = obj && `${obj.element}`;
          if (HtmlTag)
            return (
              <HtmlTag
                className={obj.classDefault + ' ' + obj.classMobile + ' ' + obj.classTablet + ' ' + obj.classDesktop}
                id={obj.id} type={obj.type}
                style={obj.configOptions.style}
              >
                {renderHtmlContent(obj)}
                {renderPowerBIDashboard(obj)}
                {obj.children.length > 0 ? obj.children.map((id, index) => {
                  return (
                    <React.Fragment key={index}>
                      {renderChildrenOfId(id)}
                    </React.Fragment>
                  )
                }) : null}
              </HtmlTag>
            )
        }
      }
    }
  };

  return (
    <div className={classes.root}>
      <Paper className={!!noData ? classes.paper1 : classes.paper}>
        <Grid container item xs={12} spacing={3} className={classes.flexContainer}>
          {!isMsalTokenExpired ?
            renderChildrenOfId(0) :
            <div style={{
              width: '300px',
              margin: '9% auto auto',
              height: '200px',
              background: 'white',
              borderRadius: '10px'
            }}>
              <div style={{ position: "relative", top: "37%", fontSize: '14px' }}>
                Loading...
                <br />
                Please wait, loading Required Resources.
              </div>
            </div>}
        </Grid>
      </Paper>
    </div>
  );
}

export default PreviewDashboard;