import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        background: "#ffffff",
        boxShadow: "0px 10px 30px 0px rgb(82 63 105 / 8%)",
        height: 60,
        paddingRight: 0,
    },
    flexgrow: {
        flexGrow: 1,
    },
    appBar: {
        // boxShadow: 'none',
        // borderBottom: '1px solid #e5e5e5',
    },
    logoButton: {
        outline: 'none !important',
        border: 'none !important',
        backgroundColor: 'transparent !important',
        padding: '12px 18px 12px 0 !important'
    },
    HomeIcon:{
        width : '16px',
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
    },
    HomeButton: {
        outline: 'none !important',
        border: 'none !important',
        padding: '12px 6px 12px 12px !important'
    },
    vertical: {
        borderRight: '0.5px solid #c6c6c6',
        marginTop: 5,
        height: '16px'
    },
    select: {
        width: 120,
        fontSize: '0.8rem',
        '& .MuiSelect-selectMenu': {
            fontSize: '12px',
        }
    },
    primaryText: {
        color: '#1363c0',
        borderBottom: '2px solid #1363c0',
        textTransform: 'capitalize'
    },
    navDisplayFlex: {
        display: `flex`,
        justifyContent: `space-between`,
    },
    linkText: {
        textDecoration: `none`,
        textTransform: `uppercase`,
        color: `#1363c0`,
    },
    fontSize: {
        fontSize: '1rem'
    },
    backdrop: {
        zIndex: 10000,
        color: '#fff',
    },
    wait: {
        position: 'relative',
        top: '29px',
        left: ' -55px',
        fontSize: '15px'
    },
    flexContainer: {
        display: 'flex',
        flexDirection: 'row',
    },
    list: {
        paddingBottom: 2
    },
    menuListContainer: {
        maxHeight: 460,
        overflow: "auto"
    },
    label:{
    //   flexGrow: 0.15
      marginLeft : '6rem !important'
    },
    envlabel: {
        padding: '0px 14px',
        backgroundColor: '#3f51b5',
        position: 'absolute',
        top: 0,
        left: 0,
        color: '#fff',
        borderRadius: '2px',
        zIndex: 1
    },
    subRegionBox:{
        display: 'flex',
        alignItems: 'center',
        borderRadius: '8px',
        border: '1px solid  #7989A8',
        background: '#FFF',
    },
    regionBox:{
        display: 'flex',
        padding: '5px 12px',
        alignItems: 'center',

        borderRadius: '8px',
        border: '1px solid  #7989A8',
        background: '#FFF',
    },
    "&.MuiButtonBase-root:hover": {
        bgcolor: "transparent"
    },
    "&.MuiInput-underline:before": {
        content: 'none !important'
    },
    "&.MuiSvgIcon-root":{
        display: 'none !important'
    },
    "&.MuiNativeSelect-select":{
        color: '#001C45 !important',
        fontFamily: "Gilroy-Medium !important",
        fontSize: '13px !important',
        fontStyle: 'normal !important',
        fontWeight: '500 !important',
        lineHeight: '20px !important' /* 142.857% */,
    },
    "&.MuiTypography-subtitle2":{
        fontFamily: "Gilroy-Medium !important",
    },
    supportFeedback:{
        marginRight : '.6rem',
        display: 'flex'
    },
    supportFeedbackAlign:{
        margin : '0 1rem',
    },
    searchCenterAligned:{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: "center",
        alignItems: 'center',
        margin: '0 0 0 25px'
    },

}));

export { useStyles };