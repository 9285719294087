export default (
    state = {
        loading: false,
        defaultResponse: true,
        Success: false
    },
    action) => {

     switch (action.type) {

        case 'UPDATE_RELEASE_NOTES_FEATURE_REQUEST':
            return {
                ...state,
                loading: true,
                defaultResponse: true,
                Success: false
            }

        case 'UPDATE_RELEASE_NOTES_FEATURE_SUCCESS':
            return {
                ...state,
                Success: action.payload.Success,
                message: action.payload.message,
                loading: false,
                defaultResponse: false,
            };

        case 'UPDATE_RELEASE_NOTES_FEATURE_FAILURE':
            return {
                ...state,
                error: true,
                Success: false,
                message: action.payload.response.data.message,
                loading: false,
                defaultResponse: false,
            };

        default:

            return state;

    }

};