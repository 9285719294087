import checkWhite from "../../assets/icons/checkWhite.svg";
import errorWhite from "../../assets/icons/errorWhite.svg";

let success = {
    title: "Success",
    description: null,
    backgroundColor: "#5cb85c",
    percentageDownload: "100%",
    icon: checkWhite,
    status: "success",
    autoDeleteTime: 3000,
    autoDelete: true,
    trigger: true,
};

let error = {
    id: 4759834,
    title: "Failure",
    description: null,
    backgroundColor: "#d9534f",
    percentageDownload: "100%",
    icon: errorWhite,
    status: "error",
    autoDeleteTime: 7500,
    autoDelete: true,
    trigger: true,
    update: true,
};

let businessUnits = [
    {
      "parentId": 190,
      "regionBuId": 64,
      "parentModule": "Analytics",
      "image": "analytics",
      "key": "strategicPricing",
      "description": "RGM report across five levels.",
      "title": "Strategic Pricing",
    //   "background": "#1306C1",
      "background":   "#023E8A",

      "width": "75px",
      "height": "75px",
      "contentType": "fixed",
      "contentKey": "",
      "accessRestriction": false,
      "collapse": false,
      "collapseText": "Mitigate through massive data sets to discover, interpret and share new insights and knowledge.",
      "parentOrder": "0",
      "createdAt": "2023-12-20T15:51:58.700Z",
      "createdBy": null,
      "lastModifiedBy": "deepak.khateek@kcc.com",
      "lastModifiedAt": "2024-04-10T04:13:51.680Z",
      "viewStatus": "Active",
      "childModule": [],
      "subReport": true
    },
    
    {
      "parentId": 191,
      "regionBuId": 64,
      "parentModule": "Foundation Analytics",
      "image": "foundationAnalytics",
      "key": "pricePackArchitecture",
      "description": "Select RGM reports across five levers",
      "title": "Price Pack Architecture",
      "background": "#023E8A",
      "width": "75px",
      "height": "75px",
      "contentType": "dynamic",
      "contentKey": "Foundation Analytics",
      "accessRestriction": false,
      "collapse": false,
      "collapseText": "Leverage technology to predict sales trends and manage sales outcomes, increasing your sales.",
      "parentOrder": "0",
      "createdAt": "2023-12-20T15:51:58.700Z",
      "createdBy": null,
      "lastModifiedBy": "deepak.khateek@kcc.com",
      "lastModifiedAt": "2024-04-01T13:46:40.857Z",
      "viewStatus": "Active",
      "childModule": [],
      "subReport": true
    },
    {
      "parentId": 192,
      "regionBuId": 64,
      "parentModule": "Resources",
      "image": "resources",
      "key": "mixManagement",
      "description": "Repository for power bi reports, collaboration sites and knowledge sharing",
      "title": "Mix Management",
      "background": "#023E8A",
      "width": "75px",
      "height": "75px",
      "contentType": "fixed",
      "contentKey": "",
      "accessRestriction": false,
      "collapse": false,
      "collapseText": "Additional resources to understand RGM command centre, such as, case studies, videos and etc.",
      "parentOrder": "0",
      "createdAt": "2023-12-20T15:51:58.700Z",
      "createdBy": null,
      "lastModifiedBy": "deepak.khateek@kcc.com",
      "lastModifiedAt": "2024-04-01T13:46:40.877Z",
      "viewStatus": "Active",
      "childModule": [],
      "subReport": true
    },
    {
      "parentId": 127,
      "regionBuId": 43,
      "parentModule": "Analytics",
      "image": "analytics",
      "key": "tradePromotions",
      "description": "Select RGM reports across five levers",
      "title": "Trade Promotions",
      "background": "#023E8A",
      "width": "75px",
      "height": "75px",
      "contentType": "fixed",
      "contentKey": "",
      "accessRestriction": true,
      "collapse": false,
      "collapseText": "Mitigate through massive data sets to discover, interpret and share new insights and knowledge.",
      "parentOrder": "4",
      "createdAt": "2023-12-20T15:51:58.700Z",
      "createdBy": null,
      "lastModifiedBy": "deepak.khateek@kcc.com",
      "lastModifiedAt": "2024-04-10T04:13:51.680Z",
      "viewStatus": "Active",
      "childModule": [  ],
      "subReport": true
    },
    {
      "parentId": 128,
      "regionBuId": 43,
      "parentModule": "Foundation Analytics",
      "image": "foundationAnalytics",
      "key": "commercialPolicy",
      "description": "Select RGM reports across five levers",
      "title": "Commercial Policy",
      "background": "#023E8A",
      "width": "75px",
      "height": "75px",
      "contentType": "dynamic",
      "contentKey": "Foundation Analytics",
      "accessRestriction": false,
      "collapse": false,
      "collapseText": "",
      "parentOrder": "5",
      "createdAt": "2023-12-20T15:51:58.700Z",
      "createdBy": null,
      "lastModifiedBy": "deepak.khateek@kcc.com",
      "lastModifiedAt": "2024-04-01T13:46:40.857Z",
      "viewStatus": "Active",
      "childModule": [ ],
      "subReport": true
    },
    {
      "parentId": 129,
      "regionBuId": 43,
      "parentModule": "Resources",
      "image": "resources",
      "key": "customizedReports",  
      "description": "Repository for power bi reports, collaboration sites and knowledge sharing",
      "title": "Customized Reports",
      "background": "#4436F9",
      "width": "75px",
      "height": "75px",
      "contentType": "fixed",
      "contentKey": "",
      "accessRestriction": false,
      "collapse": false,
      "collapseText": "",
      "parentOrder": "0",
      "createdAt": "2023-12-20T15:51:58.700Z",
      "createdBy": null,
      "lastModifiedBy": "deepak.khateek@kcc.com",
      "lastModifiedAt": "2024-04-01T13:46:40.877Z",
      "viewStatus": "Active",
      "childModule": [  ],
      "subReport": true
    },
    {
        "parentId": 129,
        "regionBuId": 43,
        "parentModule": "Resources",
        "image": "resources",
        "key": "controlTower",  
        "description": "Repository for power bi reports, collaboration sites and knowledge sharing",
        "title": "Control Tower",
        "background": "#023E8A",
        "width": "75px",
        "height": "75px",
        "contentType": "fixed",
        "contentKey": "",
        "accessRestriction": false,
        "collapse": false,
        "collapseText": "",
        "parentOrder": "0",
        "createdAt": "2023-12-20T15:51:58.700Z",
        "createdBy": null,
        "lastModifiedBy": "deepak.khateek@kcc.com",
        "lastModifiedAt": "2024-04-01T13:46:40.877Z",
        "viewStatus": "Active",
        "childModule": [  ],
        "subReport": true
      }
  ]

let strategicPricing = [
    {
        "name": "ReportSection1b10a397543eba420003",
        "displayName": "Profit Parabola by Sub-Brand",
        "order": 9,
        "parentModule" : "Strategic Pricing",
        "key" : "Strategic Pricing",
        "childId": 696,
        "parentId": 469,
        "path": "/analytics/playbooks-reports",
        "description": "Review category trends by assessing the evolution of market shares by retail sales and volume over time to understand K-C's competitive position.",
        "sideBarKey": "Strategic Pricing",
        "background": "#1306C1",
        "pageName": "Strategic Pricing",
        "pbiLandingPageNavigation": true,
        "multipleMenuList": true,
        "embedReport": true,
        "viewBuilderReport": false,
        "sidebar": false,
        "createBookmark": true,
        "embedLinkType": false,
        "exportFeature": true,
        "shareFeature": true,
        "globalFilter": false,
        "childOrder": 1,
        "createdAt": "2024-03-18T13:21:43.830Z",
        "createdBy": "deepak.khateek@tredence.com",
        "lastModifiedBy": "deepak.khateek@tredence.com",
        "lastModifiedAt": null,
        "accessRestriction": false
    },
    {
        "name": "ReportSection7dde69b43b90dca03243",
        "displayName": "Price Index Against Market Share",
        "order": 8,
        "parentModule" : "Strategic Pricing",
        "key" : "Strategic Pricing",
        "childId": 696,
        "parentId": 469,
        "path": "/analytics/playbooks-reports",
        "description": "Review category trends by assessing the evolution of market shares by retail sales and volume over time to understand K-C's competitive position.",
        "sideBarKey": "Strategic Pricing",
        "background": "#1306C1",
        "pageName": "Strategic Pricing",
        "pbiLandingPageNavigation": true,
        "multipleMenuList": true,
        "embedReport": true,
        "viewBuilderReport": false,
        "sidebar": false,
        "createBookmark": true,
        "embedLinkType": false,
        "exportFeature": true,
        "shareFeature": true,
        "globalFilter": false,
        "childOrder": 1,
        "createdAt": "2024-03-18T13:21:43.830Z",
        "createdBy": "deepak.khateek@tredence.com",
        "lastModifiedBy": "deepak.khateek@tredence.com",
        "lastModifiedAt": null,
        "accessRestriction": false

    },
    {
        "name": "ReportSection73c32586782029e39ebd",
        "displayName": "GP$ Evaluation",
        "order": 3,
        "parentModule" : "Strategic Pricing",
        "key" : "Strategic Pricing",
        "childId": 696,
        "parentId": 469,
        "path": "/analytics/playbooks-reports",
        "description": "Review category trends by assessing the evolution of market shares by retail sales and volume over time to understand K-C's competitive position.",
        "sideBarKey": "Strategic Pricing",
        "background": "#1306C1",
        "pageName": "Strategic Pricing",
        "pbiLandingPageNavigation": true,
        "multipleMenuList": true,
        "embedReport": true,
        "viewBuilderReport": false,
        "sidebar": false,
        "createBookmark": true,
        "embedLinkType": false,
        "exportFeature": true,
        "shareFeature": true,
        "globalFilter": false,
        "childOrder": 1,
        "createdAt": "2024-03-18T13:21:43.830Z",
        "createdBy": "deepak.khateek@tredence.com",
        "lastModifiedBy": "deepak.khateek@tredence.com",
        "lastModifiedAt": null,
        "accessRestriction": false

    },

    {
        "name": "ReportSection06da7157dcdd4e2ebbe8",
        "displayName": "Market Share by Brand",
        "order": 1,
        "parentModule" : "Strategic Pricing",
        "key" : "Strategic Pricing",
        "childId": 696,
        "parentId": 469,
        "path": "/analytics/playbooks-reports",
        "description": "Review category trends by assessing the evolution of market shares by retail sales and volume over time to understand K-C's competitive position.",
        "sideBarKey": "Strategic Pricing",
        "background": "#1306C1",
        "pageName": "Strategic Pricing",
        "pbiLandingPageNavigation": true,
        "multipleMenuList": true,
        "embedReport": true,
        "viewBuilderReport": false,
        "sidebar": false,
        "createBookmark": true,
        "embedLinkType": false,
        "exportFeature": true,
        "shareFeature": true,
        "globalFilter": false,
        "childOrder": 1,
        "createdAt": "2024-03-18T13:21:43.830Z",
        "createdBy": "deepak.khateek@tredence.com",
        "lastModifiedBy": "deepak.khateek@tredence.com",
        "lastModifiedAt": null,
        "accessRestriction": false

    },
    {
        "name": "ReportSection",
        "displayName": "Category P&L Trend",
        "order": 2,
        "parentModule" : "Strategic Pricing",
        "key" : "Strategic Pricing",
        "childId": 696,
        "parentId": 469,
        "path": "/analytics/playbooks-reports",
        "description": "Review category trends by assessing the evolution of market shares by retail sales and volume over time to understand K-C's competitive position.",
        "sideBarKey": "Strategic Pricing",
        "background": "#1306C1",
        "pageName": "Strategic Pricing",
        "pbiLandingPageNavigation": true,
        "multipleMenuList": true,
        "embedReport": true,
        "viewBuilderReport": false,
        "sidebar": false,
        "createBookmark": true,
        "embedLinkType": false,
        "exportFeature": true,
        "shareFeature": true,
        "globalFilter": false,
        "childOrder": 1,
        "createdAt": "2024-03-18T13:21:43.830Z",
        "createdBy": "deepak.khateek@tredence.com",
        "lastModifiedBy": "deepak.khateek@tredence.com",
        "lastModifiedAt": null,
        "accessRestriction": false

    },
    {
        "name": "ReportSection0b0f8cb9800bdc04502e",
        "displayName": "Brand Performance",
        "order": 4,
        "parentModule" : "Strategic Pricing",
        "key" : "Strategic Pricing",
        "childId": 696,
        "parentId": 469,
        "path": "/analytics/playbooks-reports",
        "description": "Review category trends by assessing the evolution of market shares by retail sales and volume over time to understand K-C's competitive position.",
        "sideBarKey": "Strategic Pricing",
        "background": "#1306C1",
        "pageName": "Strategic Pricing",
        "pbiLandingPageNavigation": true,
        "multipleMenuList": true,
        "embedReport": true,
        "viewBuilderReport": false,
        "sidebar": false,
        "createBookmark": true,
        "embedLinkType": false,
        "exportFeature": true,
        "shareFeature": true,
        "globalFilter": false,
        "childOrder": 1,
        "createdAt": "2024-03-18T13:21:43.830Z",
        "createdBy": "deepak.khateek@tredence.com",
        "lastModifiedBy": "deepak.khateek@tredence.com",
        "lastModifiedAt": null,
        "accessRestriction": false

    },
    {
        "name": "ReportSectionb23d316d644805902a76",
        "displayName": "Price Trend Comparison",
        "order": 5,
        "parentModule" : "Strategic Pricing",
        "key" : "Strategic Pricing",
        "childId": 696,
        "parentId": 469,
        "path": "/analytics/playbooks-reports",
        "description": "Review category trends by assessing the evolution of market shares by retail sales and volume over time to understand K-C's competitive position.",
        "sideBarKey": "Strategic Pricing",
        "background": "#1306C1",
        "pageName": "Strategic Pricing",
        "pbiLandingPageNavigation": true,
        "multipleMenuList": true,
        "embedReport": true,
        "viewBuilderReport": false,
        "sidebar": false,
        "createBookmark": true,
        "embedLinkType": false,
        "exportFeature": true,
        "shareFeature": true,
        "globalFilter": false,
        "childOrder": 1,
        "createdAt": "2024-03-18T13:21:43.830Z",
        "createdBy": "deepak.khateek@tredence.com",
        "lastModifiedBy": "deepak.khateek@tredence.com",
        "lastModifiedAt": null,
        "accessRestriction": false

    },
    {
        "name": "ReportSection05e1add08ec3e03dd02c",
        "displayName": "Price Index Over Time",
        "order": 6,
        "parentModule" : "Strategic Pricing",
        "key" : "Strategic Pricing",
        "childId": 696,
        "parentId": 469,
        "path": "/analytics/playbooks-reports",
        "description": "Review category trends by assessing the evolution of market shares by retail sales and volume over time to understand K-C's competitive position.",
        "sideBarKey": "Strategic Pricing",
        "background": "#1306C1",
        "pageName": "Strategic Pricing",
        "pbiLandingPageNavigation": true,
        "multipleMenuList": true,
        "embedReport": true,
        "viewBuilderReport": false,
        "sidebar": false,
        "createBookmark": true,
        "embedLinkType": false,
        "exportFeature": true,
        "shareFeature": true,
        "globalFilter": false,
        "childOrder": 1,
        "createdAt": "2024-03-18T13:21:43.830Z",
        "createdBy": "deepak.khateek@tredence.com",
        "lastModifiedBy": "deepak.khateek@tredence.com",
        "lastModifiedAt": null,
        "accessRestriction": false

    },
    {
        "name": "ReportSection1f1181802ca01b1a35b3",
        "displayName": "Price Index Against Category Average",
        "order": 7,
        "parentModule" : "Strategic Pricing",
        "key" : "Strategic Pricing",
        "childId": 696,
        "parentId": 469,
        "path": "/analytics/playbooks-reports",
        "description": "Review category trends by assessing the evolution of market shares by retail sales and volume over time to understand K-C's competitive position.",
        "sideBarKey": "Strategic Pricing",
        "background": "#1306C1",
        "pageName": "Strategic Pricing",
        "pbiLandingPageNavigation": true,
        "multipleMenuList": true,
        "embedReport": true,
        "viewBuilderReport": false,
        "sidebar": false,
        "createBookmark": true,
        "embedLinkType": false,
        "exportFeature": true,
        "shareFeature": true,
        "globalFilter": false,
        "childOrder": 1,
        "createdAt": "2024-03-18T13:21:43.830Z",
        "createdBy": "deepak.khateek@tredence.com",
        "lastModifiedBy": "deepak.khateek@tredence.com",
        "lastModifiedAt": null,
        "accessRestriction": false

    }
];

let pricePackArchitecture = [
    {
        "name": "ReportSection2059b06157342c0e7304",
        "displayName": "Price Against Market Share by Pack Count",
        "order": 7,
         "parentModule" : "Price Pack Architecture",
          "key" : "Price Pack Architecture",

          "childId": 701,
          "parentId": 469,
          "title": "Price Pack Architecture",
          "path": "/analytics/playbooks-reports",
          "description": "Evaluate price premium between products and against competition to identify opportunities to refine pack / price configurations.",
          "sideBarKey": "Price Pack Architecture",
          "background": "#1306C1",
          "pageName": "Price Pack Architecture",
          "pbiLandingPageNavigation": true,
          "multipleMenuList": true,
          "embedReport": true,
          "viewBuilderReport": false,
          "sidebar": false,
          "createBookmark": true,
          "embedLinkType": false,
          "exportFeature": true,
          "shareFeature": true,
          "globalFilter": false,
          "childOrder": 2,
          "createdAt": "2024-03-18T13:22:01.320Z",
          "createdBy": "deepak.khateek@tredence.com",
          "lastModifiedBy": "deepak.khateek@tredence.com",
          "lastModifiedAt": null,
          "accessRestriction": false
    },
    {
        "name": "ReportSection4a491bce88b399bc9598",
        "displayName": "NSV/SU Underperforming Cohorts",
        "order": 6,
         "parentModule" : "Price Pack Architecture",
          "key" : "Price Pack Architecture",
          "childId": 701,
          "parentId": 469,
          "title": "Price Pack Architecture",
          "path": "/analytics/playbooks-reports",
          "description": "Evaluate price premium between products and against competition to identify opportunities to refine pack / price configurations.",
          "sideBarKey": "Price Pack Architecture",
          "background": "#1306C1",
          "pageName": "Price Pack Architecture",
          "pbiLandingPageNavigation": true,
          "multipleMenuList": true,
          "embedReport": true,
          "viewBuilderReport": false,
          "sidebar": false,
          "createBookmark": true,
          "embedLinkType": false,
          "exportFeature": true,
          "shareFeature": true,
          "globalFilter": false,
          "childOrder": 2,
          "createdAt": "2024-03-18T13:22:01.320Z",
          "createdBy": "deepak.khateek@tredence.com",
          "lastModifiedBy": "deepak.khateek@tredence.com",
          "lastModifiedAt": null,
          "accessRestriction": false
    },
    {
        "name": "ReportSection3ab85265d5c946a5d406",
        "displayName": "Price Premium Dashboard",
        "order": 1,
         "parentModule" : "Price Pack Architecture",
          "key" : "Price Pack Architecture",
          "childId": 701,
          "parentId": 469,
          "title": "Price Pack Architecture",
          "path": "/analytics/playbooks-reports",
          "description": "Evaluate price premium between products and against competition to identify opportunities to refine pack / price configurations.",
          "sideBarKey": "Price Pack Architecture",
          "background": "#1306C1",
          "pageName": "Price Pack Architecture",
          "pbiLandingPageNavigation": true,
          "multipleMenuList": true,
          "embedReport": true,
          "viewBuilderReport": false,
          "sidebar": false,
          "createBookmark": true,
          "embedLinkType": false,
          "exportFeature": true,
          "shareFeature": true,
          "globalFilter": false,
          "childOrder": 2,
          "createdAt": "2024-03-18T13:22:01.320Z",
          "createdBy": "deepak.khateek@tredence.com",
          "lastModifiedBy": "deepak.khateek@tredence.com",
          "lastModifiedAt": null,
          "accessRestriction": false
    },
    {
        "name": "ReportSection13d1d77fb35008025c78",
        "displayName": "De-Sheet & Down Count Analysis",
        "order": 5,
         "parentModule" : "Price Pack Architecture",
          "key" : "Price Pack Architecture",
          "childId": 701,
          "parentId": 469,
          "title": "Price Pack Architecture",
          "path": "/analytics/playbooks-reports",
          "description": "Evaluate price premium between products and against competition to identify opportunities to refine pack / price configurations.",
          "sideBarKey": "Price Pack Architecture",
          "background": "#1306C1",
          "pageName": "Price Pack Architecture",
          "pbiLandingPageNavigation": true,
          "multipleMenuList": true,
          "embedReport": true,
          "viewBuilderReport": false,
          "sidebar": false,
          "createBookmark": true,
          "embedLinkType": false,
          "exportFeature": true,
          "shareFeature": true,
          "globalFilter": false,
          "childOrder": 2,
          "createdAt": "2024-03-18T13:22:01.320Z",
          "createdBy": "deepak.khateek@tredence.com",
          "lastModifiedBy": "deepak.khateek@tredence.com",
          "lastModifiedAt": null,
          "accessRestriction": false
    },

    {
        "name": "ReportSection3bc130c08a75c9110378",
        "displayName": "Price Curves",
        "order": 2,
         "parentModule" : "Price Pack Architecture",
          "key" : "Price Pack Architecture",
          "childId": 701,
          "parentId": 469,
          "title": "Price Pack Architecture",
          "path": "/analytics/playbooks-reports",
          "description": "Evaluate price premium between products and against competition to identify opportunities to refine pack / price configurations.",
          "sideBarKey": "Price Pack Architecture",
          "background": "#1306C1",
          "pageName": "Price Pack Architecture",
          "pbiLandingPageNavigation": true,
          "multipleMenuList": true,
          "embedReport": true,
          "viewBuilderReport": false,
          "sidebar": false,
          "createBookmark": true,
          "embedLinkType": false,
          "exportFeature": true,
          "shareFeature": true,
          "globalFilter": false,
          "childOrder": 2,
          "createdAt": "2024-03-18T13:22:01.320Z",
          "createdBy": "deepak.khateek@tredence.com",
          "lastModifiedBy": "deepak.khateek@tredence.com",
          "lastModifiedAt": null,
          "accessRestriction": false
    },
    {
        "name": "ReportSectionab643ad559008da030a1",
        "displayName": "Sales Dispersion by Pack Size",
        "order": 3,
         "parentModule" : "Price Pack Architecture",
          "key" : "Price Pack Architecture",
          "childId": 701,
          "parentId": 469,
          "title": "Price Pack Architecture",
          "path": "/analytics/playbooks-reports",
          "description": "Evaluate price premium between products and against competition to identify opportunities to refine pack / price configurations.",
          "sideBarKey": "Price Pack Architecture",
          "background": "#1306C1",
          "pageName": "Price Pack Architecture",
          "pbiLandingPageNavigation": true,
          "multipleMenuList": true,
          "embedReport": true,
          "viewBuilderReport": false,
          "sidebar": false,
          "createBookmark": true,
          "embedLinkType": false,
          "exportFeature": true,
          "shareFeature": true,
          "globalFilter": false,
          "childOrder": 2,
          "createdAt": "2024-03-18T13:22:01.320Z",
          "createdBy": "deepak.khateek@tredence.com",
          "lastModifiedBy": "deepak.khateek@tredence.com",
          "lastModifiedAt": null,
          "accessRestriction": false
    },
    {
        "name": "ReportSection3ee07c7d646ab09a58bc",
        "displayName": "Market Map by Pack Size",
        "order": 4,
         "parentModule" : "Price Pack Architecture",
          "key" : "Price Pack Architecture",
          "childId": 701,
          "parentId": 469,
          "title": "Price Pack Architecture",
          "path": "/analytics/playbooks-reports",
          "description": "Evaluate price premium between products and against competition to identify opportunities to refine pack / price configurations.",
          "sideBarKey": "Price Pack Architecture",
          "background": "#1306C1",
          "pageName": "Price Pack Architecture",
          "pbiLandingPageNavigation": true,
          "multipleMenuList": true,
          "embedReport": true,
          "viewBuilderReport": false,
          "sidebar": false,
          "createBookmark": true,
          "embedLinkType": false,
          "exportFeature": true,
          "shareFeature": true,
          "globalFilter": false,
          "childOrder": 2,
          "createdAt": "2024-03-18T13:22:01.320Z",
          "createdBy": "deepak.khateek@tredence.com",
          "lastModifiedBy": "deepak.khateek@tredence.com",
          "lastModifiedAt": null,
          "accessRestriction": false
    }
]

let mixManagement = [
    {
        "name": "ReportSection9afa569bb02f5edd215b",
        "displayName": "GP Constatation Curve",
        "order": 5,
         "parentModule" : "Mix Management",
         "key" : "Mix Management",

         "childId": 706,
         "parentId": 469,
         "title": "Mix Management",
         "path": "/analytics/playbooks-reports",
         "description": "Evaluate P&L line items to help understand investment mix and identify opportunities to re-shift resources from poor performing to better performing accounts.",
         "sideBarKey": "Mix Management",
         "background": "#003E9C",
         "pageName": "Mix Management",
         "pbiLandingPageNavigation": true,
         "multipleMenuList": true,
         "embedReport": true,
         "viewBuilderReport": false,
         "sidebar": false,
         "createBookmark": true,
         "embedLinkType": false,
         "exportFeature": true,
         "shareFeature": true,
         "globalFilter": false,
         "childOrder": 3,
         "createdAt": "2024-03-18T13:22:14.093Z",
         "createdBy": "deepak.khateek@tredence.com",
         "lastModifiedBy": "deepak.khateek@tredence.com",
         "lastModifiedAt": null,
         "accessRestriction": false
    },
    {
        "name": "ReportSectione71b47deea7020387022",
        "displayName": "Mix Dashboard",
        "order": 4,
         "parentModule" : "Mix Management",
         "key" : "Mix Management",
         "childId": 706,
         "parentId": 469,
         "title": "Mix Management",
         "path": "/analytics/playbooks-reports",
         "description": "Evaluate P&L line items to help understand investment mix and identify opportunities to re-shift resources from poor performing to better performing accounts.",
         "sideBarKey": "Mix Management",
         "background": "#1306C1",
         "pageName": "Mix Management",
         "pbiLandingPageNavigation": true,
         "multipleMenuList": true,
         "embedReport": true,
         "viewBuilderReport": false,
         "sidebar": false,
         "createBookmark": true,
         "embedLinkType": false,
         "exportFeature": true,
         "shareFeature": true,
         "globalFilter": false,
         "childOrder": 3,
         "createdAt": "2024-03-18T13:22:14.093Z",
         "createdBy": "deepak.khateek@tredence.com",
         "lastModifiedBy": "deepak.khateek@tredence.com",
         "lastModifiedAt": null,
         "accessRestriction": false
    },
    {
        "name": "ReportSection90b00fc3f365becca1fd",
        "displayName": "Sales and Volume Breakdown",
        "order": 3,
         "parentModule" : "Mix Management",
         "key" : "Mix Management",
         "childId": 706,
         "parentId": 469,
         "title": "Mix Management",
         "path": "/analytics/playbooks-reports",
         "description": "Evaluate P&L line items to help understand investment mix and identify opportunities to re-shift resources from poor performing to better performing accounts.",
         "sideBarKey": "Mix Management",
         "background": "#1306C1",
         "pageName": "Mix Management",
         "pbiLandingPageNavigation": true,
         "multipleMenuList": true,
         "embedReport": true,
         "viewBuilderReport": false,
         "sidebar": false,
         "createBookmark": true,
         "embedLinkType": false,
         "exportFeature": true,
         "shareFeature": true,
         "globalFilter": false,
         "childOrder": 3,
         "createdAt": "2024-03-18T13:22:14.093Z",
         "createdBy": "deepak.khateek@tredence.com",
         "lastModifiedBy": "deepak.khateek@tredence.com",
         "lastModifiedAt": null,
         "accessRestriction": false
    },

    {
        "name": "ReportSectionb23d316d644805902a76",
        "displayName": "Customer Sales Growth and Trade Investment",
        "order": 1,
         "parentModule" : "Mix Management",
         "key" : "Mix Management",
         "childId": 706,
         "parentId": 469,
         "title": "Mix Management",
         "path": "/analytics/playbooks-reports",
         "description": "Evaluate P&L line items to help understand investment mix and identify opportunities to re-shift resources from poor performing to better performing accounts.",
         "sideBarKey": "Mix Management",
         "background": "#1306C1",
         "pageName": "Mix Management",
         "pbiLandingPageNavigation": true,
         "multipleMenuList": true,
         "embedReport": true,
         "viewBuilderReport": false,
         "sidebar": false,
         "createBookmark": true,
         "embedLinkType": false,
         "exportFeature": true,
         "shareFeature": true,
         "globalFilter": false,
         "childOrder": 3,
         "createdAt": "2024-03-18T13:22:14.093Z",
         "createdBy": "deepak.khateek@tredence.com",
         "lastModifiedBy": "deepak.khateek@tredence.com",
         "lastModifiedAt": null,
         "accessRestriction": false
    },
    {
        "name": "ReportSection44c13a066138c22e613e",
        "displayName": "Share Breakdown ",
        "order": 2,
         "parentModule" : "Mix Management",
         "key" : "Mix Management",
         "childId": 706,
         "parentId": 469,
         "title": "Mix Management",
         "path": "/analytics/playbooks-reports",
         "description": "Evaluate P&L line items to help understand investment mix and identify opportunities to re-shift resources from poor performing to better performing accounts.",
         "sideBarKey": "Mix Management",
         "background": "#1306C1",
         "pageName": "Mix Management",
         "pbiLandingPageNavigation": true,
         "multipleMenuList": true,
         "embedReport": true,
         "viewBuilderReport": false,
         "sidebar": false,
         "createBookmark": true,
         "embedLinkType": false,
         "exportFeature": true,
         "shareFeature": true,
         "globalFilter": false,
         "childOrder": 3,
         "createdAt": "2024-03-18T13:22:14.093Z",
         "createdBy": "deepak.khateek@tredence.com",
         "lastModifiedBy": "deepak.khateek@tredence.com",
         "lastModifiedAt": null,
         "accessRestriction": false
    }
]

let tradePromotions = [
    {
        "name": "ReportSectionfc9987ca09de1ba67e96",
        "displayName": "Price and Volume",
        "order": 1,
        "parentModule" : "Trade Promotion",
        "key" : "Trade Promotion",
        "childId": 711,
        "parentId": 469,
        "title": "Trade Promotion",
        "path": "/analytics/playbooks-reports",
        "description": "Sensitivity of volume to price changes.        ",
        "sideBarKey": "Trade Promotion",
        "background": "#1306C1",
        "pageName": "Trade Promotion",
        "pbiLandingPageNavigation": true,
        "multipleMenuList": true,
        "embedReport": true,
        "viewBuilderReport": false,
        "sidebar": false,
        "createBookmark": true,
        "embedLinkType": false,
        "exportFeature": true,
        "shareFeature": true,
        "globalFilter": false,
        "childOrder": 4,
        "createdAt": "2024-03-18T13:22:55.147Z",
        "createdBy": "deepak.khateek@tredence.com",
        "lastModifiedBy": "deepak.khateek@tredence.com",
        "lastModifiedAt": null,
        "accessRestriction": false
    },

]

let commercialPolicy = [

    {
        "name": "ReportSectionb23d316d644805902a76",
        "displayName": "Internal vs External Price Comparison",
        "order": 1,
        "parentModule" : "Commercial Policy",
        "key" : "Commercial Policy",
        "childId": 716,
        "parentId": 469,
        "title": "Commercial Policy",
        "path": "/analytics/playbooks-reports",
        "description": "Compare non-subsidized base, promo base and incremental units across products to determine share of promoted products​.",
        "sideBarKey": "Commercial Policy",
        "background": "#1306C1",
        "pageName": "Commercial Policy",
        "pbiLandingPageNavigation": true,
        "multipleMenuList": true,
        "embedReport": true,
        "viewBuilderReport": false,
        "sidebar": false,
        "createBookmark": true,
        "embedLinkType": false,
        "exportFeature": true,
        "shareFeature": true,
        "globalFilter": false,
        "childOrder": 5,
        "createdAt": "2024-03-18T13:23:08.850Z",
        "createdBy": "deepak.khateek@tredence.com",
        "lastModifiedBy": "deepak.khateek@tredence.com",
        "lastModifiedAt": null,
        "accessRestriction": false
    }
]





let customizedReports = [
    {
        "displayName": "Leadership Reports",
        "order": 1,
        "parentModule" : "customizedReports",
        "key" : "Customized Reports",
        "childId": 716,
        "parentId": 469,
        "title": "Customized Reports",
        "path": "/analytics/customized-reports",
        "description": "Customized Reports",
        "sideBarKey": "Customized Reports",
        "background": "#4436F9",  

        "pageName": "Customized Reports",
        "pbiLandingPageNavigation": true,
        "multipleMenuList": true,
        "embedReport": true,
        "viewBuilderReport": false,
        "sidebar": false,
        "createBookmark": true,
        "embedLinkType": false,
        "exportFeature": true,
        "shareFeature": true,
        "globalFilter": false,
        "childOrder": 5,
        "createdAt": "2024-03-18T13:23:08.850Z",
        "createdBy": "deepak.khateek@tredence.com",
        "lastModifiedBy": "deepak.khateek@tredence.com",
        "lastModifiedAt": null,
        "accessRestriction": false
    },
    {
        "displayName": "Customer - Product P&L",
        "order": 1,
        "parentModule" : "customizedReports",
        "key" : "Customized Reports",
        "childId": 716,
        "parentId": 469,
        "title": "Customized Reports",
        "path": "/analytics/customized-reports",
        "description": "Customized Reports",
        "sideBarKey": "Customized Reports",
        "background": "#4436F9",
        "pageName": "Customized Reports",
        "pbiLandingPageNavigation": true,
        "multipleMenuList": true,
        "embedReport": true,
        "viewBuilderReport": false,
        "sidebar": false,
        "createBookmark": true,
        "embedLinkType": false,
        "exportFeature": true,
        "shareFeature": true,
        "globalFilter": false,
        "childOrder": 5,
        "createdAt": "2024-03-18T13:23:08.850Z",
        "createdBy": "deepak.khateek@tredence.com",
        "lastModifiedBy": "deepak.khateek@tredence.com",
        "lastModifiedAt": null,
        "accessRestriction": false
    },
    {
        "displayName": "Market Map",
        "order": 1,
        "parentModule" : "customizedReports",
        "key" : "Customized Reports",
        "childId": 716,
        "parentId": 469,
        "title": "Customized Reports",
        "path": "/analytics/customized-reports",
        "description": "Customized Reports",
        "sideBarKey": "Customized Reports",
        "background": "#4436F9",
        "pageName": "Customized Reports",
        "pbiLandingPageNavigation": true,
        "multipleMenuList": true,
        "embedReport": true,
        "viewBuilderReport": false,
        "sidebar": false,
        "createBookmark": true,
        "embedLinkType": false,
        "exportFeature": true,
        "shareFeature": true,
        "globalFilter": false,
        "childOrder": 5,
        "createdAt": "2024-03-18T13:23:08.850Z",
        "createdBy": "deepak.khateek@tredence.com",
        "lastModifiedBy": "deepak.khateek@tredence.com",
        "lastModifiedAt": null,
        "accessRestriction": false
    },
    {
        "displayName": "GtN Analysis Dashboard",
        "order": 1,
        "parentModule" : "customizedReports",
        "key" : "Customized Reports",
        "childId": 716,
        "parentId": 469,
        "title": "Customized Reports",
        "path": "/analytics/customized-reports",
        "description": "Customized Reports",
        "sideBarKey": "Customized Reports",
        "background": "#4436F9",
        "pageName": "Customized Reports",
        "pbiLandingPageNavigation": true,
        "multipleMenuList": true,
        "embedReport": true,
        "viewBuilderReport": false,
        "sidebar": false,
        "createBookmark": true,
        "embedLinkType": false,
        "exportFeature": true,
        "shareFeature": true,
        "globalFilter": false,
        "childOrder": 5,
        "createdAt": "2024-03-18T13:23:08.850Z",
        "createdBy": "deepak.khateek@tredence.com",
        "lastModifiedBy": "deepak.khateek@tredence.com",
        "lastModifiedAt": null,
        "accessRestriction": false
    },
    {
        "displayName": "  Quick Strikes Negative SKU",
        "order": 1,
        "parentModule" : "customizedReports",
        "key" : "Customized Reports",
        "childId": 716,
        "parentId": 469,
        "title": "Customized Reports",
        "path": "/analytics/customized-reports",
        "description": "Customized Reports",
        "sideBarKey": "Customized Reports",
        "background": "#4436F9",
        "pageName": "Customized Reports",
        "pbiLandingPageNavigation": true,
        "multipleMenuList": true,
        "embedReport": true,
        "viewBuilderReport": false,
        "sidebar": false,
        "createBookmark": true,
        "embedLinkType": false,
        "exportFeature": true,
        "shareFeature": true,
        "globalFilter": false,
        "childOrder": 5,
        "createdAt": "2024-03-18T13:23:08.850Z",
        "createdBy": "deepak.khateek@tredence.com",
        "lastModifiedBy": "deepak.khateek@tredence.com",
        "lastModifiedAt": null,
        "accessRestriction": false
    },
    {
        "displayName": "RGM GP Report",
        "order": 1,
        "parentModule" : "customizedReports",
        "key" : "Customized Reports",
        "childId": 716,
        "parentId": 469,
        "title": "Customized Reports",
        "path": "/analytics/customized-reports",
        "description": "Customized Reports",
        "sideBarKey": "Customized Reports",
        "background": "#4436F9",
        "pageName": "Customized Reports",
        "pbiLandingPageNavigation": true,
        "multipleMenuList": true,
        "embedReport": true,
        "viewBuilderReport": false,
        "sidebar": false,
        "createBookmark": true,
        "embedLinkType": false,
        "exportFeature": true,
        "shareFeature": true,
        "globalFilter": false,
        "childOrder": 5,
        "createdAt": "2024-03-18T13:23:08.850Z",
        "createdBy": "deepak.khateek@tredence.com",
        "lastModifiedBy": "deepak.khateek@tredence.com",
        "lastModifiedAt": null,
        "accessRestriction": false
    },
    {
        "displayName": "Pricing Ladder",
        "order": 1,
        "parentModule" : "customizedReports",
        "key" : "Customized Reports",
        "childId": 716,
        "parentId": 469,
        "title": "Customized Reports",
        "path": "/analytics/customized-reports",
        "description": "Customized Reports",
        "sideBarKey": "Customized Reports",
        "background": "#4436F9",
        "pageName": "Customized Reports",
        "pbiLandingPageNavigation": true,
        "multipleMenuList": true,
        "embedReport": true,
        "viewBuilderReport": false,
        "sidebar": false,
        "createBookmark": true,
        "embedLinkType": false,
        "exportFeature": true,
        "shareFeature": true,
        "globalFilter": false,
        "childOrder": 5,
        "createdAt": "2024-03-18T13:23:08.850Z",
        "createdBy": "deepak.khateek@tredence.com",
        "lastModifiedBy": "deepak.khateek@tredence.com",
        "lastModifiedAt": null,
        "accessRestriction": false
    },
    {
        "displayName": "Channel & Sector Profitability Dashboard",
        "order": 1,
        "parentModule" : "customizedReports",
        "key" : "Customized Reports",
        "childId": 716,
        "parentId": 469,
        "title": "Customized Reports",
        "path": "/analytics/customized-reports",
        "description": "Customized Reports",
        "sideBarKey": "Customized Reports",
        "background": "#4436F9",
        "pageName": "Customized Reports",
        "pbiLandingPageNavigation": true,
        "multipleMenuList": true,
        "embedReport": true,
        "viewBuilderReport": false,
        "sidebar": false,
        "createBookmark": true,
        "embedLinkType": false,
        "exportFeature": true,
        "shareFeature": true,
        "globalFilter": false,
        "childOrder": 5,
        "createdAt": "2024-03-18T13:23:08.850Z",
        "createdBy": "deepak.khateek@tredence.com",
        "lastModifiedBy": "deepak.khateek@tredence.com",
        "lastModifiedAt": null,
        "accessRestriction": false
    }
]

 

let controlTower = [

    {
        "name": "ReportSectionb23d316d644805902a76",
        "displayName": "Customer Data Quality Dashboard  ",
        "order": 1,
        "parentModule" : "Control Tower",
        "key" : "Control Tower ",
        "childId": 716,
        "parentId": 469,
        "title": "Customer Data Quality Dashboard ",
        "path": "/resources/control-tower",
        "description": "Control Tower",
        "sideBarKey": "Control Tower",
        "background": "#0C2041",
        "pageName": "Control Tower",
        "pbiLandingPageNavigation": true,
        "multipleMenuList": true,
        "embedReport": true,
        "viewBuilderReport": false,
        "sidebar": false,
        "createBookmark": true,
        "embedLinkType": false,
        "exportFeature": true,
        "shareFeature": true,
        "globalFilter": false,
        "childOrder": 5,
        "createdAt": "2024-03-18T13:23:08.850Z",
        "createdBy": "deepak.khateek@tredence.com",
        "lastModifiedBy": "deepak.khateek@tredence.com",
        "lastModifiedAt": null,
        "accessRestriction": false
    }
    , {
        "name": "ReportSectionb23d316d644805902a76",
        "displayName": "   Product Data Quality Dashboard",
        "order": 1,
        "parentModule" : "Control Tower",
        "key" : "Control Tower",
        "childId": 716,
        "parentId": 469,
        "title": "Product Data Quality Dashboard",
        "path": "/resources/control-tower",
        "description": "Control Tower",
        "sideBarKey": "Control Tower",
        "background": "#0C2041",
        "pageName": "Control Tower",
        "pbiLandingPageNavigation": true,
        "multipleMenuList": true,
        "embedReport": true,
        "viewBuilderReport": false,
        "sidebar": false,
        "createBookmark": true,
        "embedLinkType": false,
        "exportFeature": true,
        "shareFeature": true,
        "globalFilter": false,
        "childOrder": 5,
        "createdAt": "2024-03-18T13:23:08.850Z",
        "createdBy": "deepak.khateek@tredence.com",
        "lastModifiedBy": "deepak.khateek@tredence.com",
        "lastModifiedAt": null,
        "accessRestriction": false
    }
]












export default { success, error, strategicPricing, pricePackArchitecture, mixManagement, tradePromotions, commercialPolicy, customizedReports, controlTower, businessUnits };
