export default (state = [
    {
        id : 4759834,
        title: `Toast is in pause state`,
        description: 'pause',
        backgroundColor: '#d9534f',
        percentageDownload: '100%',
        icon: 'pause',
        status: 'error',
        autoDeleteTime : 7500,
        autoDelete: true,
        trigger: false,
    }
], action) => {

    switch (action.type) {

        case 'TOAST_TRIGGERED':
            return action.payload;

        default:

            return state;

    }

};