export default (state = { alerts: [], loading: false }, action) => {
  switch (action.type) {
    case "GET_OPERATIONAL_ALERTS_REQUEST":
      return {
        alerts: [],
        loading: true,
      };

    case "GET_OPERATIONAL_ALERTS_SUCCESS":
      return {
        alerts: action.payload,
        responseDefault: false,
        loading: false,
        success: true,
        error: false,
      };

    case "GET_OPERATIONAL_ALERTS_FAILURE":
      return {
        alerts: [],
        error: true,
        responseDefault: false,
        loading: false,
        success: false,
        userMessage: action.payload.response.data["usermessage"],
      };

    default:
      return state;
  }
};
