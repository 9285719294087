export default (state = { field: [],  loading: false, }, action) => {
    switch (action.type) {

        case 'FETCH_ALERT_FIELD_REQUEST':
            return {
                field: [],
                loading: true,
            };

        case 'FETCH_ALERT_FIELD_SUCCESS':
            return {
                field: action.payload,
                responseDefault: false,
                loading: false,
                success: true,
                error: false
            };

        case 'FETCH_ALERT_FIELD_FAILURE':
            return {
                field: [],
                error: true,
                responseDefault: false,
                loading: false,
                success: false,
                // userMessage: action.payload.response.data['usermessage'],
            };

        case 'UPDATE_ALERT_FIELD_DATA':
        return {
            field: action.payload,
            responseDefault: false,
            loading: false,
            success: true,
            error: false
        }

        default:

            return state;

    }

};