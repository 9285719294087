import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 0,
  },
  paper: {
    padding: theme.spacing(0.3, 0),
    width: "100%",
    height: "auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    boxShadow: "none",
    background: "#f4f5f6",
  },
  flexContainer: {
    height: "100%",
    width: "100%",
  },
  documentation: {
    width: "50%",
    textAlign: "justify",
    flex: 4,
    "& h5": {
      paddingLeft: "7.5rem",
    },
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
  },
  header: {
    textAlign: "justify",
    paddingLeft: "7rem",
  },
  editorTitle: {
    textAlign: "initial",
    fontSize: "2rem",
    marginBottom: "10px",
    marginleft: "2rem",
  },
  title: {
    flexGrow: "0.2",
  },
  release: {
    flexGrow: "1",
  },
  sidebar: {
    height: "78vh",
    maxHeight: "78vh",
    overflowY: "scroll",
  },
  alignMent: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
  leftAlignMent: {
    display: "block",
    justifyContent: "start",
    flexDirection: "column",
  },
  blockAlignment: {
    display: "block",
  },
  releaseNotesData: {
    textAlign: "left",
    marginLeft: 0,
    marginTop: 1,
  },
  menuSection: {
    marginLeft: "0rem",
    borderRight: "1px solid #d3d3d3",
  },
  rightAlignment: {
    display: "flex",
    justifyContent: "right",
    flexDirection: "column",
  },
  emptyReleaseData: {
    lineHeight: 2,
    justifyContent: "center",
    display: "block",
    verticalAlign: "middle",
    position: "absolute",
    top: "50%",
    right: "30%",
  },
  fontStyle: {
    fontSize: 15,
    fontWeight: 600,
    fontStyle: "normal",
    fontKerning: "inherit",
    fontFeatureSettings: "initial",
    color: "#555",
  },
  ml05: {
    marginLeft: "0.5rem",
  },
  loaderPosition: {
    position: "relative",

    top: "50%",
  },
  editor: {
    textAlign: "left",
  },
  editorInnerhtml: {
    background: "#ffffff",
    padding: "20px",
    textAlign: "justify",
  },
  EditorData: {
    textAlign: "left",
  },
  "& .MuiList-root": {
    width: "100% !important",
  },
  "& .MuiList-padding": {
    paddingBottom: 0,
    width: "100% !important",
  },
  activeMenuList: {
    background: "#a9ccf2",
    borderLeft: "5px solid #0054ba",
    padding: "6px 0px 0",
    color: "#505559",
    display: "flex",
    flexDirection: "row",
    width: '90%',
    "&:hover": {
      background: "#a9ccf2",
      borderLeft: "5px solid #0054ba",
    },
    "& .MuiSvgIcon-root": {
      marginTop: 8,
    },
    "& .MuiListItemText-root": {
      padding: "0px 16px",
      width: "100%",
    },
    "& .MuiList-padding": {
      paddingBottom: 0,
    },
    "& .MuiTypography-body1": {
      fontSize: "13px",
      color: "#000",
      fontWeight: "600",
    },
    "& .MuiTypography-body2": {
      fontSize: "13px",
      color: "#000",
      fontWeight: "500",
    },
    "& .MuiTypography-colorTextSecondary": {
      padding: "0 1rem",
      textAlign: "left",
      fontSize: 13,
      "& p": {
        padding: 0,
      },
    },
  },
  activeSubMenuList: {
    background: "#a9ccf2",
    borderLeft: "3px solid #0054ba",
    padding: "6px 0 0",
    color: "#505559",
    display: "flex",
    flexDirection: "column",
    "& .MuiTypography-body2": {
      fontSize: "0.875rem",
      color: "#000",
      fontWeight: "500",
    },
  },
  menuList: {
    background: "",
    borderLeft: "0px solid #0054ba",
    padding: "6px 16px",
    color: "#505559",
    display: "flex",
    flexDirection: "row",
    "& .MuiListItemText-root": {
      width: "100%",
    },
    "& .MuiTypography-body1": {
      fontSize: "13px",
      color: "#000",
      fontWeight: "500",
    },
  },
  menuHeader: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },
  subMenuList: {
    padding: " 0 1rem",
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  linearProgress: {
    position: "relative",
    top: "40px",
    zIndex: "10000",
  },
  pageInfo: {
    background: "#fff",
    width: "100%",
    padding: "14px",
    borderBottom: "1px solid #281faa",
    color: "#281faa",
    fontWeight: "800",
    fontSize: "1.2rem",
  },
  newsletter: {
      padding: '5px 0',
      margin: '5px 5px 0 0',
      backgroundColor: '#f4f5f6',
      color:'#0f059e',
      cursor: 'pointer',
      border: '1px solid #0f059e',
      fontSize: '1rem',
      textAlign: 'center'
  }
}));

export { useStyles };
