import React from "react";
import { withStyles } from '@material-ui/core/styles';
import { Dialog, Typography, IconButton } from "@material-ui/core";
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';
import '../businessUnit/style.css';

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;

    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

const ContentDialog = (props) => {

    const { closeDialog, moduleContent, contentTitle,   openDialog } = props;

    const handleModalClose = () => {
         closeDialog()
    };

    return (
        <Dialog
            open={openDialog}
            onClose={handleModalClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth={'sm'}
            maxWidth={'sm'}
        >
            <DialogTitle id="customized-dialog-title" className={'tile-new'} onClose={handleModalClose}>
                {
                    contentTitle
                }
            </DialogTitle>
            <DialogContent dividers>
                {
                    moduleContent
                }
            </DialogContent>
            <DialogActions>

            </DialogActions>
        </Dialog>
    );
};

export default ContentDialog;
