import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import {
    toastTriggered,
} from "../../redux/actions/index";
import "react-toastify/dist/ReactToastify.css";
import toastProperties from "../../assets/data/toastProperty";
import utils from "../../utils";

const useStyles = makeStyles((theme) => ({
    textField: {
        width: "100%",
        "& .MuiFilledInput-root": {
            margin: "5px 15px",
        },
        "& .MuiFilledInput-input": {
            padding: "7px 12px 10px",
            height: "15px",
        },
    },
}));

const Operations = (props) => {
    const classes = useStyles();

    const dispatch = useDispatch();

    const apiFailure = useSelector((state) => state.apiFailure);

    const { data, name, label, create, region, moduleID, module, deleteAction, updateFeatureName, updateAction, updateParams, releaseID } = props;

    const [edit, setEdit] = useState(false);
    const [value, setValue] = useState("");
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("null");
    const [click, setClick] = useState(false);
    const [selectedItem, setSelectedItem] = useState(0);
    const [selectedIndex, setSelectedIndex] = useState(0);

    const onhandleChange = (e) => {
        let val = e.target.value;
        setValue(val);
    };

    const fontStyle = {
        fontSize: "13px",
        padding: "0 10px",
        margin: "10px 0 0",
    };

    const trackEvents = (item, type, name) => {
         let trackingObject = {
            region: region,
            actionType: 'Click',
            pageName: "Other Feature",
            reportName: module === 'Release Note' ? 'Release Notes' : module,
            reportSection: `${module === 'Release Note' ? 'Release Notes' : module} ${type}`,
            pageDetails: `${item[name]}`,
        };
        utils.userTrackingInfo(trackingObject);
    };

    const createNew = () => {
        props.createNewsItems(value);
        setClick(true);
    }

    const editItem = (item) => {
        setEdit(true);
        setSelectedItem(item);
        setValue(item[name]);
    };

    const deleteItem = (rowItem, index) => {
         let params = {};
        setSelectedIndex(index);
        setClick(true);
        if (module === 'Newsletter' || module === 'Release Note') {
            params.deleteID = rowItem[moduleID];
            params.region = rowItem.region;
            params.module = module
        }
        else if (module === "Release Feature") {
            params.releaseID = releaseID;
            params.region = region;
            params.subCategory = rowItem[name];
            params.releaseVersionID = rowItem.releaseVersionID
        }
        dispatch(deleteAction(params))
            .then((res) => {
                trackEvents(rowItem, `Deleted`, name);
                toastProperties.success["description"] = res.data.message;
                dispatch(toastTriggered([toastProperties.success]));
                setClick(false);
                props.hideSettingsWindow("e");
                dispatch(updateAction(updateParams));
            })
            .catch((err) => {
                toastProperties.error["description"] = err.message;
                dispatch(toastTriggered([toastProperties.error]));
                setClick(false);
                props.hideSettingsWindow("e");
            });
    };

    const updateItemName = (item) => {
        let params = {};
        if (value.length > 40) {
            setClick(false);
            setError(true);
            setErrorMessage(`${label} name must be less than 40 characters`);
            return true;
        } else {
            setClick(true);
            setError(false);
            if (module === 'Newsletter' || module === 'Release Note') {
                params.ID = item[moduleID];
                params.region = item.region;
                params.module = module;
                params.newName = value;
                params.oldName = module === 'Release Note' ? item.releaseName : item.newsLetterName
            }
            else if (module === "Release Feature") {
                params.releaseID = releaseID;
                params.releaseVersionID = item.releaseVersionID;
                params.region = region;
                params.subCategory = item[name];
                params.newName = value;
            }
            dispatch(updateFeatureName(params))
                .then((res) => {
                    toastProperties.success["description"] = res.data.message;
                    dispatch(toastTriggered([toastProperties.success]));
                    trackEvents(item, `Updated`, name);
                    setClick(false);
                    props.hideSettingsWindow("e");
                    dispatch(updateAction(updateParams));
                })
                .catch((err) => {
                    setClick(false);
                    toastProperties.error["description"] = err.message;
                    dispatch(toastTriggered([toastProperties.error]));
                    props.hideSettingsWindow("e");
                });
        }
    };

    const cancel = () => {
        setEdit(false);
    };

    return (
        <div className="container">
            <center>
                <div className="col-12 sections my-2" id="basic_screen">
                    <form className="form">
                        <div className="row">
                            <div
                                className="col-12  col-xl-12 flexCol"
                                style={{ marginTop: "20px" }}
                            >
                                {!create ? (
                                    <div>
                                        {!edit ? (
                                            <div>
                                                <ul className={"navbar"}>
                                                    <li
                                                        style={{
                                                            width: "10%",
                                                            textAlign: "justify",
                                                            fontSize: "15px",
                                                        }}
                                                    >
                                                        S.No.
                                                    </li>
                                                    <li
                                                        style={{
                                                            width: "75%",
                                                            textAlign: "justify",
                                                            fontSize: "15px",
                                                        }}
                                                    >
                                                        Name
                                                    </li>
                                                    <li style={{ width: "15%" }}></li>
                                                </ul>
                                                {!!data.loading &&
                                                    !!data &&
                                                    Array.isArray(data) &&
                                                    data.length === 0 ? (
                                                    <div> ...Loading </div>
                                                ) : !!data && Array.isArray(data) && data.length > 0 ? (
                                                    <ul className={"mainContainer"}>
                                                        {data.map((item, index) => {
                                                            return (
                                                                <div
                                                                    className={'flexContainer'}
                                                                    key={index}
                                                                >
                                                                    <li className="series">{index + 1 + "."}</li>
                                                                    <li className={"itemName"} key={index}>
                                                                        {" "}
                                                                        {item[name]}
                                                                    </li>
                                                                    <div className={"actionContainer"}>
                                                                        <i
                                                                            onClick={() => editItem(item)}
                                                                            className="fas fa-edit"
                                                                            style={fontStyle}
                                                                        ></i>
                                                                        {!!click && index === selectedIndex ? (
                                                                            <div className="delete">deleting...</div>
                                                                        ) : (
                                                                            <i
                                                                                onClick={() => deleteItem(item, index)}
                                                                                className="far fa-trash-alt"
                                                                                style={fontStyle}
                                                                            ></i>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            );
                                                        })}
                                                    </ul>
                                                ) : null}
                                            </div>
                                        ) : (
                                            //  ******* update section *********
                                            <div className="updateItem">
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        textAlign: "justify",
                                                        margin: "-25px 0 15px 15px",
                                                    }}
                                                >
                                                    <div style={{ fontSize: "15px" }}>
                                                        {" "}
                                                        Region: {selectedItem.region}
                                                    </div>
                                                </div>
                                                {!!edit ? (
                                                    <div className={"Container"}>
                                                        <TextField
                                                            id="filled-basic"
                                                            label=""
                                                            variant="filled"
                                                            value={value}
                                                            onChange={(e) => onhandleChange(e)}
                                                            className={classes.textField}
                                                        />
                                                    </div>
                                                ) : (
                                                    <div className="editItem">
                                                        {selectedItem[name]}
                                                    </div>
                                                )}
                                                {error ? (
                                                    <div className="error"> {errorMessage} </div>
                                                ) : null}
                                                {
                                                    <div className={"updatebm"}>
                                                        <button
                                                            disabled={!!click}
                                                            type="button"
                                                            className="pBtn"
                                                            onClick={() => updateItemName(selectedItem)}
                                                        >
                                                            Update Name
                                                        </button>
                                                        <button
                                                            type="button"
                                                            className="pBtn"
                                                            onClick={() => cancel(selectedItem)}
                                                        >
                                                            Cancel
                                                        </button>
                                                    </div>
                                                }
                                            </div>
                                        )}
                                    </div>
                                ) : (
                                    <div className={"Container"}>
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                textAlign: "justify",
                                                margin: "-25px 0 15px 0px",
                                            }}
                                        >
                                            <div style={{ fontSize: "14px" }}>
                                                {" "}
                                                Region: {region}
                                            </div>
                                        </div>

                                        <TextField
                                            id="filled-basic"
                                            label={`Please enter ${label} name`}
                                            variant="filled"
                                            value={value}
                                            onChange={(e) => onhandleChange(e)}
                                            style={{ width: "100%" }}
                                        />
                                        {error ? (
                                            <div className="error"> {errorMessage} </div>
                                        ) : null}
                                        <button
                                            disabled={!!click}
                                            type="button"
                                            className="pBtn"
                                            onClick={() => createNew()}
                                            style={{ marginTop: '10px' }}
                                        >Create {label}</button>
                                    </div>
                                )}
                            </div>
                        </div>
                    </form>
                </div>
            </center>
        </div>
    );
};

export default Operations;
