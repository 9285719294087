const defaultState = {
  parentBuMapping: [],
  loading: false,
  success: false,
  defaultResponse: true,
  error: null,
  message: null,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case "PARENT_BUSINESS_MAPPED_UNIT_REQUEST":
      return {
        ...state,
        loading: true,
        success: false,
        error: false,
        parentBuMapping:[]
      };

    case "PARENT_BUSINESS_MAPPED_UNIT_SUCCESS":
      return {
        ...state,
        parentBuMapping: action.payload.data.data,
        defaultResponse: false,
        success: true,
        loading: false,
      };

    case "PARENT_BUSINESS_MAPPED_UNIT_FAILURE":
      return {
        ...state,
        parentBuMapping: [],
        defaultResponse: false,
        message: action.payload.response.data["message"],
        userMessage: action.payload.response.data["usermessage"],
        loading: false,
        success: false,
        error: true,
      };

    default:
      return state;
  }
};
