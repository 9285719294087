export default (state = { releaseData: {}, field: [], releaseType: [],  loading: false }, action) => {
    switch (action.type) {
      case "RELEASE_NOTES_DATA_REQUEST":
        return {
          ...state,
          releaseData: [],
          field: [],
          loading: true,
        };

      case "RELEASE_NOTES_DATA_SUCCESS":
        return {
          category: action.payload.releaseData,
          field: action.payload.releaseField,
          releaseType: action.payload.releaseType,
          responseDefault: false,
          loading: false,
          success: true,
          error: false,
        };

      case "RELEASE_NOTES_DATA_FAILURE":
        return {
          subscription: false,
          error: true,
          responseDefault: false,
          loading: false,
          success: false,
        //   userMessage: action.payload.response.data["usermessage"],
        };

      default:
        return state;
    }
  };