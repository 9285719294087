import React from "react";
import { Provider } from "react-redux";
import store from "./redux/store";
import { BrowserRouter as Router } from "react-router-dom";
import CustomRoute from "./routes/index";
import "./App.css";
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles'


if (process.env.NODE_ENV === "production") {
  console.log = function () {};
  //console.error = function () {};
  console.warn = function () {};
  console.info = function () {};
}

const THEME = createTheme({
  typography: {
   "fontFamily": `"Gilroy-Medium" !important`,
  }
});

function App() {
  return (
    <div className="App">
       <MuiThemeProvider theme={THEME}>
      <Provider store={store}>
        <Router>
          <CustomRoute />
        </Router>
      </Provider>
      </MuiThemeProvider>
    </div>
  );
}

export default App;
