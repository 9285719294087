export default (
  state = {
    loading: false,
    defaultResponse: true,
    newsletter: [],
    message: "",
    userMessage: "",
    error: false,
  },
  action
) => {
  switch (action.type) {
    case "NEWSLETTER_DATA_LIST_REQUEST":
      return {
        ...state,
        loading: true,
        defaultResponse: false,
        newsletter: [],
        message: "",
        userMessage: "",
        error: false,
      };

    case "NEWSLETTER_DATA_LIST_SUCCESS":
      return {
        ...state,
        newsletter: action.payload,
        Success: true,
        loading: false,
        defaultResponse: false,
        message: "",
        userMessage: "",
        error: false,
      };

    case "NEWSLETTER_DATA_LIST_FAILURE":
      return {
        ...state,
        Success: false,
        error: true,
        loading: false,
        newsletter: [],
      };

    default:
      return state;
  }
};
