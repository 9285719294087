import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { withStyles } from '@material-ui/core/styles';
import { Dialog, Typography, IconButton, Button } from "@material-ui/core";
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';
import check from '../../assets/icons/check.svg';
import exclamation from '../../assets/icons/exclamation.svg';
import information from '../../assets/icons/information.svg';
import error from '../../assets/icons/error.svg';

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;

    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

const Alert = (props) => {
    // const classes = useStyles();
    const [info, setInfo] = useState('');
    const { open, message, alertType, handleClose, userMessage , errorType , apiName} = props;
    const errorMessage = useSelector((state) => state.getErrorMessages);

    /** Here dynamic table columns should be there**/
    // 1. Get columns keys check for data is an array or obejct or array without keys
    // 2. render the columns keys as column & render the data Object[columnKeys]

    useEffect(() => {
        const notFoundErr = errorMessage?.data?.find(item => item.errorType === "API Not Found");
        if (!!notFoundErr && notFoundErr.errorType === "API Not Found" && message === 'Request failed with status code 404') {
            setInfo(notFoundErr.errorMessage);
        }
    }, [errorMessage, message]);


    const handleModalClose = () => {
        handleClose();
    };

    return (
        <React.Fragment>
            <Dialog
                open={open}
                onClose={handleModalClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={'sm'}
                maxWidth={'sm'}
            >
                <DialogTitle id="customized-dialog-title" onClose={handleModalClose}>
                    {
                        alertType === 'Success' ?
                            <div style={{ color: '#4CAF50', fontSize: '20px' }}>
                                <span>
                                    <img src={check} alt="Success" style={{ width: '15px', height: '15px', margin: '0 10px' }} />
                                    {'Success'}
                                </span>
                            </div>
                            :
                            alertType === 'Warning' ?
                                <div style={{ color: '#ff9800', fontSize: '20px' }} >
                                    <span>
                                        <img src={exclamation} alt="warning" style={{ width: '15px', height: '15px', margin: '0 10px' }} />
                                        {'Warning'}
                                    </span>
                                </div> :
                                alertType === 'Info' ?
                                    <div style={{ color: '#2196F3', fontSize: '20px' }}>
                                        <span>
                                            <img src={information} alt="Info" style={{ width: '15px', height: '15px', margin: '0 10px' }} />
                                            {'Info'}
                                        </span>
                                    </div> :
                                    <div style={{ color: '#f44336', fontSize: '20px' }}>
                                        <span>
                                            <img src={error} alt="Error" style={{ width: '15px', height: '15px', margin: '0 10px' }} />
                                            {'Error'}
                                        </span>
                                    </div>
                    }
                </DialogTitle>
                <DialogContent dividers>
                    {
                        !!info || !!userMessage ?
                            <div style={{ fontSize: '15px', fontWeight: '600', textAlign: 'justify', lineHeight: '25px', marginBottom: 10 }}>
                                {info ? info : userMessage}
                                <br></br>
                                {errorType === "ApiSlow" ?  apiName + ' is slow' : null}
                            </div>
                            : null
                    }
                    <div style={{ fontSize: '13px', fontWeight: '500', textAlign: 'justify', lineHeight: '25px', marginBottom: 10 , fontStyle : 'italic'}}>
                        {!!message ? message : null}
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        onClick={handleModalClose}
                        color="primary"
                        autoFocus
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
};

export default Alert;
