import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TabConfiguration from './tabConfiguration';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 0,
    },
    paper: {
        padding: '0 24px 24px',
        margin: '0 0 3rem 0',
        width: 'auto',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: "center",
        boxShadow: 'none',
        background: '#f4f6f8'
    },
    flexRow: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%'
    },
    flexContainer: {
        display: 'flex',
        flexDirection: 'row',
    },
    flexItem: {
        display: 'flex',
        flexDirection: 'row',
        border: '1px solid #e5e5e5',
        borderRadius: 15,
        boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
        width: '375px',
        margin: '1rem 3.2rem',
        textAlign: 'center',
        lineHeight: '75px',
        fontSize: '30px',
        padding: '12px'
    },
}));

const SelfService = (props) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            {
                <Paper className={classes.paper}>
                    <Grid container item xs={12} spacing={3} className={classes.flexContainer}>
                        <Grid container item xs={12} spacing={3}>
                            <div style={{ margin: '2rem', width: 'inherit' }}>
                                <TabConfiguration />
                            </div>
                        </Grid>
                    </Grid>
                </Paper>
            }
        </div>
    );
}

export default SelfService;