import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import cloneDeep from 'lodash/cloneDeep';
import { insertNewPage, updateSelectedView, getSelectedView, updateViewBuilderJSON, getAllPagesForAdmin, getDashboardStatus, updateCustomizedHtmlTemp } from '../../redux/actions';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import utils from '../../utils/index';
import BarLoader from "react-spinners/BarLoader";
import TagsInput from '../../component/tagsInput';

class DashboardBasicDetails extends Component {
	constructor(props) {
		super(props);
		this.state = {
			name: this.props.viewBuilderJSON.viewName,
			description: this.props.viewBuilderJSON.viewDescription,
			tagsKeyword: this.props.viewBuilderJSON.tagsKeyword,
			category: this.props.viewBuilderJSON.buType,
			viewStatus: this.props.viewBuilderJSON.viewStatus,
			enableDatepicker: this.props.viewBuilderJSON.datePicker,
			linkForDocumentation: this.props.viewBuilderJSON.linkForDocumentation,
			htmlTemp: this.props.viewBuilder.viewBuilderJSON.htmlTemp,
			error: "",
			updateView: false,
			viewId: this.props.viewBuilderJSON.viewId,
			reportEmbedType: this.props.viewBuilderJSON.grouped ? true : this.props.viewBuilderJSON.reportPages,
			subRegion: this.props.viewBuilderJSON.subRegion,
			msalEnabled: this.props.viewBuilderJSON.msalEnabled,
			globalFilter: this.props.viewBuilderJSON.globalFilter,
			hiddenPages: this.props.viewBuilderJSON.hiddenPages,
			grouped: this.props.viewBuilderJSON.grouped,
			order: this.props.viewBuilderJSON.order,
			fetchPages: this.props.viewBuilderJSON.grouped ? 2 : this.props.viewBuilderJSON.hiddenPages && this.props.viewBuilderJSON.reportPages ? 1 : 0,
			style: {
				PointerEvent: 'none',
				opacity: '0.1'
			}
		}
	};

	componentDidMount() {
		const { region } = this.props;
		let params = { region: region }
		this.props.getDashboardStatusAPI(params);
	};

	trackingInfo = (region, name, obj, link, type) => {
		let trackingObject = {
			region: region,
			actionType: 'Admin Activity',
			pageName: 'Dashboard',
			reportName: name,
			pageDetails: JSON.stringify(obj),
			reportLink: link,
			attributeName: type
		};
		utils.userTrackingInfo(trackingObject);
	}

	validatePowerBiLink(e) {
		const { region } = this.props;
		const link = e.target.value.trim();
		const word = 'groups';
		const word2 = 'reports';
		if (link !== '') {
			  if (link.includes(word) && link.includes(word2)) {
				const values = link.split('/');
				console.log('@validatePowerBiLink', values);
				if ( Array.isArray(values) && values.length > 6) {
					if(values[6] !== ''){
						const validLink = values[6].split('?');
						let groupId = values[4];
						let reportId = Array.isArray(validLink) && validLink[0];
						let htmlTemp = {
							"reportId": reportId,
							"groupId": groupId,
							"workspace": `${region} RGM PLAYBOOKS - ${process.env.REACT_APP_REPORT_WORKSPACE}`,
							"type": "default"
						}
						if (htmlTemp && Object.keys(htmlTemp).length > 0) {
							if (htmlTemp.reportId !== '' && htmlTemp.groupId !== '') {
								this.setState({ linkForDocumentation: link, htmlTemp: htmlTemp });
								this.props.updateCustomizedHtmlTempAPI(htmlTemp);
								return false;
							}
						}
					}
					else if(values[6] === ''){
						let message = 'Please enter a Power Bi Link';
						toast.error(message, { position: toast.POSITION.RIGHT_TOP });
						this.setState({ linkForDocumentation: '' });
						return true;
					  }
				}
			}
		}
	}

	updateReportTokenType(val) {
		let value = val;
		this.setState({
			msalEnabled: value,
		});
		if (value) {
			this.setState({
				hiddenPages: true
			})
		}
		else if (!value) {
			this.setState({
				hiddenPages: false
			})
		}
	}

	updateReportPageNavigation(val) {
		let value = val;
		this.setState({
			reportEmbedType: value
		})
		if (!value) {
			this.setState({
				hiddenPages: false,
				grouped: false
			})
		}
	}

	selectModule(e) {
		let value = e.target.value;
		this.setState({ category: value });

	}

	selectSubRegion(e) {
		let value = e.target.value;
		this.setState({ subRegion: value });
	}

	enableGlobalFilter(val) {
		const { category } = this.state;
		if (category === 'Playbooks') {
			this.setState({
				globalFilter: val
			})
		}
	}

	reportPageListMethod(e) {
		let val = e.currentTarget.value;
		if (val ==  0) {
			this.setState({
				hiddenPages: false,
				grouped: false,
				fetchPages: 0
			})
		}
		else if (val ==  1) {
			this.setState({
				hiddenPages: true,
				grouped: false,
				fetchPages: 1
			})
		}
		else if (val ==  2) {
			this.setState({
				hiddenPages: false,
				grouped: true,
				fetchPages: 2
			})
		}
	};

	saveNewPageDetails() {
		const { subRegion, name, description, category, viewStatus, enableDatepicker, linkForDocumentation, htmlTemp, reportEmbedType, hiddenPages, globalFilter, msalEnabled, grouped, order , tagsKeyword} = this.state;
		const { region, customizedReports } = this.props;
		const word = 'groups';
		const word2 = 'reports';
		let reportOrder = Array.isArray(customizedReports.data) && customizedReports.data.filter((el) => el.customizableFlag)
		if (!name || !description || !category ) {
			toast.error("Please enter all values", { position: toast.POSITION.RIGHT_TOP });
			return false;
		}
		if (linkForDocumentation === null && !this.state.grouped) {
			if (linkForDocumentation && !linkForDocumentation.includes(word) && !linkForDocumentation.includes(word2)) {
				let message = 'Please enter a Valid Power Bi Link';
				toast.error(message, { position: toast.POSITION.RIGHT_TOP });
				 return true;
			}
			if (htmlTemp && Object.keys(htmlTemp).length > 0 && htmlTemp.type !== 'default') {
				this.setState({
					linkForDocumentation: htmlTemp.reportLink
				})
			}
			else {
				toast.error("Please enter a Power Bi Link", { position: toast.POSITION.RIGHT_TOP });
				return false;
			}
		}
		else {
			if (this.state.viewId && this.state.viewId !== "") {
				let obj = {
					viewId: this.state.viewId,
					buType: category,
					viewName: name,
					viewDescription: description,
					viewStatus: viewStatus,
					region: region,
					linkForDocumentation: linkForDocumentation ? linkForDocumentation : htmlTemp && Object.keys(htmlTemp).length > 0 && htmlTemp.reportLink ? htmlTemp.reportLink : '',
					subRegion: subRegion,
					htmlTemp: htmlTemp,
					order: order,
					grouped: grouped,
					reportPages: grouped ? false : reportEmbedType,
					hiddenPages: hiddenPages,
					globalFilter: globalFilter,
					msalEnabled: msalEnabled,
					customizableFlag: htmlTemp.type === 'default' ? false : true,
					tagsKeyword: tagsKeyword && Array.isArray(tagsKeyword) ? tagsKeyword : []
				}
				this.props.updateSelectedViewAPI(obj).then((res,) => {
					if (res) {
						toast.success(res.data.message, { position: toast.POSITION.RIGHT_TOP });
						let tempViewBuilderObj = cloneDeep(this.props.viewBuilder);
						tempViewBuilderObj.viewBuilderJSON.categoryName = category;
						tempViewBuilderObj.viewBuilderJSON.viewName = name;
						tempViewBuilderObj.viewBuilderJSON.description = description;
						tempViewBuilderObj.viewBuilderJSON.viewStatus = viewStatus;
						tempViewBuilderObj.viewBuilderJSON.datePicker = enableDatepicker;
						tempViewBuilderObj.viewBuilderJSON.linkForDocumentation = linkForDocumentation;
						this.props.updateViewBuilderJSONAPI(tempViewBuilderObj)
						this.props.getSelectedViewAPI({ viewId: this.state.viewId, region: region, });
						this.props.hideSettingsWindow('e');
					}
					this.trackingInfo(region, name, obj, linkForDocumentation, 'Dashboard Updated')
				})
					.catch((err) => {
						toast.error(err.response.data.userMessage, { position: toast.POSITION.RIGHT_TOP });
					});
			} else {
				let tempViewBuilderObj = cloneDeep(this.props.viewBuilder);
				let obj = {
					viewName: name,
					viewDescription: description,
					linkForDocumentation: linkForDocumentation,
					buType: category,
					region: region,
					subRegion: subRegion,
					msalEnabled: msalEnabled,
					grouped: grouped,
					reportPages: grouped ? false : reportEmbedType,
					hiddenPages: hiddenPages,
					globalFilter: globalFilter,
					order: Array.isArray(reportOrder) ? reportOrder.length + 1 : 9999,
					htmlTemp: htmlTemp ? htmlTemp : {
						"reportId": '',
						"groupId": '',
						"workspace": '',
						"type": "default"
					  },
					customizableFlag: htmlTemp && Object.keys(htmlTemp).length > 0 && htmlTemp.type === 'default' ? false : true,
					tagsKeyword: tagsKeyword && Array.isArray(tagsKeyword) ? tagsKeyword : []
				}
				this.props.insertNewPageAPI(obj).then((res, err) => {
					if (res) {
						toast.success(res.data.message, { position: toast.POSITION.RIGHT_TOP });
						let newId = res.data.data[0].inserted_id;
						this.props.getSelectedViewAPI({ viewId: newId, region: region, });
						this.props.updateViewBuilderJSONAPI(tempViewBuilderObj);
						console.log('viewBuilderAdmin--basicDetail', tempViewBuilderObj, region);
						this.props.getAllPagesForAdminAPI({ viewStatus: 'Draft', region: region, pageType: 'ViewBuilder' });
						this.props.hideSettingsWindow('e');
						this.trackingInfo(region, name, obj, linkForDocumentation, 'Dashboard Created');
						this.props.selectedModule(category);
						console.log('viewBuilderAdmin--insertNewPageAPI', res.data);
					}
				})
					.catch((err) => {
						console.log('viewBuilderAdmin--insertNewPageAPI', err.response.data);
						toast.error(err.response.data.message, { position: toast.POSITION.RIGHT_TOP });
					});
			}
		}
	}

	renderDashboardStatus() {
		if (this.state.viewId && this.state.viewId !== '') {
			return (
				<div className="col-12  col-xl-12 form-group tile-new">
					<label className="">
						Status
					</label>
					<select value={this.state.viewStatus} className="form-control" onChange={(e) => { this.setState({ viewStatus: e.target.value }) }}>
						<option disabled="">Select</option>
						<option value="Active">Active</option>
						<option value="Inactive">Inactive</option>
						<option value="Draft">Draft</option>
					</select>
				</div>
			)
		}
	};

	renderSubRegion() {
		const { selectedSubRegion } = this.props;
		if (selectedSubRegion && Object.keys(selectedSubRegion).length > 0 && this.state.category === 'Playbooks') {
			let { subRegionList } = selectedSubRegion;
			if (Array.isArray(subRegionList) && subRegionList.length > 0) {
				return (
					<div className="col-12  col-xl-12 form-group tile-new">
						<label className=""> Sub Region </label>
						<br></br>
						<select value={this.state.subRegion} className="form-control " onChange={(e) => this.selectSubRegion(e)}>
							<option value={'Select'}>{'Select'}</option>
							{
								subRegionList.map((item, index) =>
									<option value={item} rowkey={index}>{item}</option>
								)
							}
						</select>
					</div>
				)
			}
		}
	}

	renderGlobalFilter() {
		const { region } = this.props;
		if (this.state.category === 'Playbooks' && region !== 'KCNA') {
			return (
				<div className="col-12  col-xl-12 form-group tile-new">
					<label className=""> Global Filter (*** Mark it only if report is applicable for global filter)</label>
					<br></br>
					<input className='px-radio' type={'radio'} name="globalFilter" checked={this.state.globalFilter} onChange={(e) => this.enableGlobalFilter(true)} /> Yes
					<br></br>
					<input className='px-radio' type={'radio'} name="globalFilter" checked={!this.state.globalFilter} onChange={(e) => this.enableGlobalFilter(false)} /> No
				</div>
			)
		}
	}

	renderCategories() {
		const { activeRegionSpecificConfigs } = this.props.activeDynamicData;
		if (Array.isArray(activeRegionSpecificConfigs.buType) && activeRegionSpecificConfigs.buType.length > 0) {
			return (
				<div className="col-12  col-xl-12 form-group tile-new">
					<label>
						Select Category	for the Dashboard
					</label>
					<select value={this.state.category} className="form-control tile-new" onChange={(e) => this.selectModule(e)}>
						<option value={'Select'}>{'Select'}</option>
						{ 
							Array.isArray(activeRegionSpecificConfigs.buType) &&
							activeRegionSpecificConfigs.buType.length > 0 &&
							activeRegionSpecificConfigs.buType.filter(el => el.embedReport && !el.subReport).map((item, index) =>
								<option value={item.key} rowkey={index}>{item.title}</option>
							)
						}
					</select>
				</div>
			)
		}
	};

	renderLinkDocumentation() {
		if (this.state.fetchPages !== 2 || !this.state.grouped) {
			return (
				<div className="col-12  col-xl-12 form-group tile-new">
					<label className="">
						Report Link <small>(Ensure the link has http(s) included)</small>
					</label>
					<input value={this.state.linkForDocumentation} type="text" className="form-control tile-new" placeholder="Enter a link" onChange={(e) => this.validatePowerBiLink(e)} />
				</div>
			)
		}
	};

	SelectedTags(tags) { 
		this.setState({ tagsKeyword: tags }) 
	};

	render() {
		return (
			<div className="container">
				<ToastContainer />
				<div className="col-12 sections my-2" id="basic_screen">
					<form className="form">
						<div className="row">
							<div className="col-12  col-xl-12 form-group tile-new">
								<label className="tile-new">
									Dashboard Name
								</label>
								<input value={this.state.name} type="text" className="form-control tile-new" placeholder="Label for dashboard" onChange={(e) => { this.setState({ name: e.target.value }) }} />
							</div>
							<div className="col-12  col-xl-12 form-group tile-new">
								<label className="tile-new">
									Description
								</label>
								<input value={this.state.description} type="text" className="form-control tile-new" placeholder="Enter short description for the dashboard." onChange={(e) => { this.setState({ description: e.target.value }) }} />
							</div>
							<div className="col-12  col-xl-12 form-group tile-new">
								<label className="tile-new">
									Reports Tags/Keyword
								</label>
							   <TagsInput  className="form-control tile-new" placeholder="Enter the tags/keyword for the reports." selectedTags={(e)=>this.SelectedTags(e)}/>
							</div>
							{
								this.renderCategories()
							}
							{
								this.renderSubRegion()
							}
							{
								this.renderGlobalFilter()
							}
							<div className="col-12  col-xl-12 form-group tile-new">
								<label className="">Report Embed Type :</label>
								<br></br>
								<input className='px-radio' type={'radio'} name="NonRLS" checked={!this.state.msalEnabled} onChange={(e) => this.updateReportTokenType(false)} />  Non RLS
								<br></br>
								<input className='px-radio' type={'radio'} name="RLS" checked={this.state.msalEnabled} onChange={(e) => this.updateReportTokenType(true)} />  RLS
							</div>
							<div className="col-12  col-xl-12 form-group tile-new">
								<label className="">Report Page Navigation :</label>
								<br></br>
								<input className='px-radio ' type={'radio'} name="reportType" checked={this.state.reportEmbedType} onChange={(e) => this.updateReportPageNavigation(true)} /> Yes
								<br></br>
								<input className='px-radio ' type={'radio'} name="reportType" checked={!this.state.reportEmbedType} onChange={(e) => this.updateReportPageNavigation(false)} />  No
							</div>
							{
								this.state.reportEmbedType ?
									<div className="col-12  col-xl-12 form-group tile-new">
										<label className="">Report Page List :</label>
										{
											!this.state.msalEnabled ?
												<React.Fragment>
													<br></br>
													<input className='px-radio' type={'radio'} name="apiMethod" value={0} checked={this.state.fetchPages === 0} onChange={(e) => this.reportPageListMethod(e)} />
													API Method
												</React.Fragment>
												: null
										}
										<br></br>
										<input className='px-radio' type={'radio'} name="hiddenPages" value={1} checked={this.state.fetchPages === 1} onChange={(e) => this.reportPageListMethod(e)} /> Report Load Method (Hidden Pages will not be displayed)
										<br></br>
										<input className='px-radio' type={'radio'} name="grouped" value={2} checked={this.state.fetchPages === 2} onChange={(e) => this.reportPageListMethod(e)} />  Grouping of Indepdent Single PBIX File
									</div>
									: null
							}
							{
								this.renderLinkDocumentation()
							}
							{this.renderDashboardStatus()}
							<div>{this.state.error}</div>
							{
								this.props.InsertNewDashBoard.loading ?
									<div className="waiting_Loader">
										<div className="prettier">Please wait...</div>
										<BarLoader
											size={150}
											color={"#123abc"}
											height={4}
											width={100}
											loading={true}
										/>
									</div>
									:
									<div className="col-12  col-xl-12 form-group  flex mt-0" style={{ justifyContent: "flex-end" }}>
										<button type="button" className="btn btn-apply" onClick={(e) => { e.preventDefault(); this.saveNewPageDetails() }}>Save</button>
									</div>
							}
						</div>
					</form>
				</div>
			</div>
		);
	}
}


function mapStateToProps(state) {
	return ({
		viewBuilder: state.viewBuilderObj,
		dashBoardStatus: state.dashBoardStatus,
		selectedRegion: state.getBusinessUnitsRegion,
		regionlist: state.regions,
		customizedReports: state.getAdminViews,
		selectedSubRegion: state.updatedSubRegion,
		activeDynamicData: state.activeDynamicData,
		InsertNewDashBoard: state.InsertNewDashBoard
	})
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		insertNewPageAPI: insertNewPage,
		updateSelectedViewAPI: updateSelectedView,
		getSelectedViewAPI: getSelectedView,
		updateViewBuilderJSONAPI: updateViewBuilderJSON,
		getAllPagesForAdminAPI: getAllPagesForAdmin,
		getDashboardStatusAPI: getDashboardStatus,
		updateCustomizedHtmlTempAPI: updateCustomizedHtmlTemp
	}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardBasicDetails);

