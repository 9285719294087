const defaultHtmlTemp = {
    "0": {
        id: 0,
        type: 'section',
        element: 'div',
        classDefault: 'container-fluid',
        classMobile: '',
        classTablet: '',
        classDesktop: '',
        text: 'Section Area',
        innerhtml: '',
        configOptions: {
            label: '',
            desc: '',
        },
        children: [],
    }
};

export default (
    state = {
        viewBuilderJSON: {},
        success: false,
        message: '',
        responseDefault: true,
        loading: false,
        type: 'selectedView'
    },
    action) => {

    switch (action.type) {

        case 'UPDATE_VIEW_BUILDER_JSON':
            return state;

        case 'GET_SELECTED_VIEW_REQUEST':
            return {
                ...state,
                data: [],
                success: false,
                message: '',
                responseDefault: true,
                loading: true,
                error: false,
            };

        case 'GET_SELECTED_VIEW_SUCCESS':
            let tempObj = {}
            if (action.payload && action.payload.data.data && Array.isArray(action.payload.data.data) && action.payload.data.data.length > 0 && action.payload.data.message === "Success") {
                if (action.payload.data.data[0]['htmlTemp'] === "NULL") {
                    tempObj = {
                        viewBuilderJSON: action.payload.data.data[0],
                        responseDefault: false,
                        success: true,
                        loading: false,
                     }
                    tempObj.viewBuilderJSON.htmlTemp = defaultHtmlTemp
                 }
                else if (!!action.payload.data.data[0]['htmlTemp'] && Object.keys(action.payload.data.data[0]['htmlTemp']).length > 0) {
                     let viewBuilderJSON = {};
                    if(!!action.payload.data.data[0].customizableFlag){
                        viewBuilderJSON = action.payload.data.data[0]
                    }
                    else if(!action.payload.data.data[0].customizableFlag){
                        viewBuilderJSON = action.payload.data.data[0]
                    }
                    else if(action.payload.data.data[0].viewCreated === "Default"){
                        viewBuilderJSON = action.payload.data.data[0]
                        viewBuilderJSON.htmlTemp =  defaultHtmlTemp;
                     }
                    else {
                        viewBuilderJSON = action.payload.data.data[0];
                     }
                    tempObj = {
                        viewBuilderJSON: viewBuilderJSON,
                        responseDefault: false,
                        success: true,
                        loading: false,
                    }
                }
                return tempObj;
            }
            return {
                ...state,
                tempObj,
                error: false,
            };

        case 'GET_SELECTED_VIEW_FAILURE':
            return {
                ...state,
                data: action.payload,
                message: action.payload.response.data['message'],
                userMessage: action.payload.response.data['usermessage'],
                success: false,
                responseDefault: false,
                error: true
            };

        default:
            return state;
    }
};
