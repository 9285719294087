export default (
    state = {
        loading: true,
        defaultResponse: true,
        Success: false
    },
    action) => {

     switch (action.type) {

        case 'CREATE_NEW_BOOKMARK_REQUEST':
            return {
                ...state,
                loading: true,
                defaultResponse: true,
                Success: false
            }

        case 'CREATE_NEW_BOOKMARK_SUCCESS':
            return {
                ...state,
                Success: true,
                message: 'bookmark is created successfully',
                loading: false,
                defaultResponse: false,
            };

        case 'CREATE_NEW_BOOKMARK_FAILURE':
            return {
                ...state,
                loading: false,
                defaultResponse: false,
                Success: 'false',
                message: action.payload.response.data.message,
                userMessage: action.payload.response.data['usermessage'],
            };

        default:

            return state;

    }

};