import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    width: 105,
    borderBottom: '1px solid'
  },
}));

export default function DatePickers(props) {
  const classes = useStyles();
  const { startdate, type, endDate, hanldleDateChange , minFromDate, minToDate} = props;

  const handleDateChange = (e, type) => {
    let date = e.target.value;
    hanldleDateChange(date, type);
  };

  return (
    <div className={classes.container} noValidate>
      <TextField
        id="date"
        type="date"
        value={type === "Alert Date" ? startdate : endDate}
        className={classes.textField}
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{ inputProps: { min: type === "Alert Date" ? minFromDate : minToDate } }}
        onChange={(e) => handleDateChange(e, type)}
      />
    </div>
  );
}