import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { updateViewBuilderJSON } from "../../../redux/actions";
import cloneDeep from "lodash/cloneDeep";
import ReactQuill from "react-quill";
class Editor extends Component {
  handleViewBuilderJSONChange(data) {
    let tempViewBuilderObj = cloneDeep(this.props.viewBuilder);
    let id = this.props.id;
    tempViewBuilderObj.viewBuilderJSON.htmlTemp[id]["innerhtml"] = data;
    console.log("@viewBuilderJSONStructure", tempViewBuilderObj);
    this.props.updateViewBuilderJSONAPI(tempViewBuilderObj);
  }
  renderEditor() {
    if (this.props.currentObj.type === "text") {
      return (
        <div className="formContainer">
          <ReactQuill
            theme="snow"
            value={
              this.props.viewBuilder.viewBuilderJSON.htmlTemp[this.props.id][
                "innerhtml"
              ]
            }
            onChange={(value) => {
              if (!!value) {
                this.handleViewBuilderJSONChange(value);
              }
            }}
            placeholder={"Write something awesome..."}
          />
        </div>
      );
    }
  }

  render() {
    return this.renderEditor();
  }
}
function mapStateToProps(state) {
  return {
    viewBuilder: state.viewBuilderObj,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateViewBuilderJSONAPI: updateViewBuilderJSON,
    },
    dispatch
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(Editor);
