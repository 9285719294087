export default (state = {}, action) => {

    switch (action.type) {

        case 'UPDATE_POWER_BI_REPORT_REQUEST':
            return action.payload;

        default:

            return state;

    }

};