export default (state = { presist : false }, action) => {

    switch (action.type) {

    case 'UPDATE_PRESIST_FEATURE':
    return action.payload;

    default:

    return state;

    }

    };