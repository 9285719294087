export default (state = {}, action) => {

    switch (action.type) {

    case 'UPDATE_REPORT_SECTION_PARAMS':
    return action.payload;

    default:

    return state;

    }

    };