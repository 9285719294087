export default (state = { clearNotification: [] , loading: false,   defaultResponse: true, error: false}, action) => {
  switch (action.type) {
    case "CLEAR_NOTIFICATION_REQUEST":
      return {
        clearNotification: [],
        loading: true,
        defaultResponse: true
      };

    case "CLEAR_NOTIFICATION_SUCCESS":
      return {
        clearNotification: action.payload,
        defaultResponse: false,
        loading: false,
        success: true,
        error: false,
      };

    case "CLEAR_NOTIFICATION_FAILURE":
      return {
        clearNotification: [],
        error: true,
        defaultResponse: false,
        loading: false,
        success: false,
        userMessage: action.payload.response.data["usermessage"],
      };

    default:
      return state;
  }
};
