import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 0,
    },
    paper: {
        padding: theme.spacing(0, 0),
        margin: '10px 0 0',
        width: 'auto',
        height: '91vh',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: "center",
        boxShadow: 'none',
        background: '#fff'
    },
    flexRow: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%'
    },
    flexContainer: {
        display: 'flex',
        flexDirection: 'row',
        height: '100%'
    },
    justifyContentRight:{
          justifyContent :'right',
          display: 'flex',
          flexDirection: 'row',
          width: '100%'
    },
    flexItem: {
        display: 'flex',
        flexDirection: 'row',
        border: '1px solid #e5e5e5',
        borderRadius: 15,
        boxShadow: ' rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
        width: '375px',
        margin: '1rem 3.2rem',
        textAlign: 'center',
        lineHeight: '75px',
        fontSize: '30px',
        padding: '12px'
    },
    title: {
        padding: theme.spacing(1, 0),
        textAlign: 'justify',
        fontFamily: 'Gilroy-Medium',
        fontSize: 14
    },
    folderList: {
        margin: '0 20px'
    },
    addNew: {
        background: '#ffffff',
        color: '#0f059e',
        border: '1px solid',
        margin: '0 20px 20px 0',
        fontSize: 14,
        lineHeight: 2
    },
    addCustomUser: {
          background: '#0f059e !important',
          borderRadius: '4px',
          fontWeight: 'normal',
          fontSize: '10px',
          lineHeight: '10px',
          padding: '10px',
          color: '#ffffff',
          margin: '5px 0px 0'
      }
}));

export { useStyles };