export default (state = { data: [] ,  loading: false, defaultResponse: true, message:'', userMessage: ''}, action) => {
  switch (action.type) {
    case "RELEASE_LIST_DATA_REQUEST":
      return {
        data: [],
        loading: true,
        message:'',
        userMessage: '',
        defaultResponse: false
      };

    case "RELEASE_LIST_DATA_SUCCESS":
      return {
        data: action.payload,
        defaultResponse: false,
        loading: false,
        success: true,
        error: false,
        message:'',
        userMessage: ''
      };

    case "RELEASE_LIST_DATA_FAILURE":
      return {
        data: [],
        error: true,
        defaultResponse: false,
        loading: false,
        success: false,
        message: action.payload.response.data["message"],
        userMessage: action.payload.response.data["usermessage"],
      };

    default:
      return state;
  }
};
