const defaultState = {
  data: {},
  loading: false,
  success: false,
  defaultResponse: true,
  error: null,
  message: null,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case "GET_ALL_PAGE_CONTENT_REQUEST":
      return {
        ...state,
        loading: true,
        success: false,
        error: false,
        data:{}
      };

    case "GET_ALL_PAGE_CONTENT_SUCCESS":
      return {
        ...state,
        data: action.payload.data.data,
        defaultResponse: false,
        success: true,
        loading: false,
      };

    case "GET_ALL_PAGE_CONTENT_FAILURE":
      return {
        ...state,
        data: [],
        defaultResponse: false,
        message: action.payload.response.data["message"],
        userMessage: action.payload.response.data["usermessage"],
        loading: false,
        success: false,
        error: true,
      };

    default:
      return state;
  }
};
