import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { createNewResources, updateBusinessUnitRegion, getAllPagesContent } from "../../redux/actions";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import utils from "../../utils/index";
import validUrl from 'valid-url';

class AddReportLinks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userInfo: "",
      name: "",
      description: "",
      linkForDocumentation: "",

    };
  }

  saveNewPageDetails() {
    const { name, description,   linkForDocumentation } = this.state;
    const { activePage, activeRegion } = this.props.activeDynamicData;
    if (!name || !description || !activeRegion || !linkForDocumentation) {
      toast.error("Please enter all values", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      const valid = validUrl.isUri(linkForDocumentation.trim());
      if (!valid) {
        toast.error(
          "Please enter a valid URL (Ensure the link has http(s) included)",
          { position: toast.POSITION.TOP_RIGHT }
        );
      } else {
        let obj = {
          folderName: name,
          folderDescription: description,
          folderLink: linkForDocumentation.trim(),
          pageType: activePage && activePage.key,
          region: activeRegion,
        };
        this.props
          .createNewResourcesAPI(obj)
          .then((res) => {
            if (res) {
              const params = { region: activeRegion }
              this.props.updateBusinessUnitRegionAPI(params);
              const pageContent = {
                pageType: activePage && activePage.key,
                region: activeRegion,
              }
              this.props.getAllPagesContentAPI(pageContent);
              toast.success(res.data.message, { position: toast.POSITION.TOP_RIGHT, });
              this.props.hideSettingsWindow("e");
              let trackingObject = {
                region: activeRegion,
                actionType: 'Click',
                pageName: activePage && activePage.key,
                attributeName: 'Resource Creation',
                reportName: name,
                pageDetails: JSON.stringify(obj),
                reportLink: linkForDocumentation,
              };
              utils.userTrackingInfo(trackingObject);
            }
          })
          .catch((err) => {
            let message = err instanceof TypeError ? err.message : err.response.message
            toast.error(message, { position: toast.POSITION.TOP_RIGHT });
          });
      }
    }
  }

  render() {
    return (
      <div className="container">
        <ToastContainer />
        <div className="col-12 sections my-2" id="basic_screen">
          <form className="form">
            <div className="row">
              <div className="col-12  col-xl-12 form-group ">
                <label className="">Folder Name</label>
                <input
                  value={this.state.name}
                  type="text"
                  className="form-control "
                  placeholder="Label for Resources"
                  onChange={(e) => {
                    this.setState({ name: e.target.value });
                  }}
                />
              </div>
              <div className="col-12  col-xl-12 form-group ">
                <label className="">Description</label>
                <input
                  value={this.state.description}
                  type="text"
                  className="form-control "
                  placeholder="Enter short description for the Resources."
                  onChange={(e) => {
                    this.setState({ description: e.target.value });
                  }}
                />
              </div>
              <div className="col-12  col-xl-12 form-group ">
                <label className="">
                  Link for Folder{" "}
                  <small>(Ensure the link has http(s) included)</small>
                </label>
                <input
                  value={this.state.linkForDocumentation}
                  type="text"
                  className="form-control "
                  placeholder="Enter a link"
                  onChange={(e) => {
                    this.setState({ linkForDocumentation: e.target.value });
                  }}
                />
              </div>
              <div>{this.state.error}</div>
              <div
                className="col-12  col-xl-12 form-group  flex mt-0"
                style={{ justifyContent: "flex-end" }}
              >
                <button
                  type="button"
                  className="btn btn-apply"
                  onClick={(e) => {
                    e.preventDefault();
                    this.saveNewPageDetails();
                  }}
                >
                  Save
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    resources: state.newResources,
    activeDynamicData : state.activeDynamicData
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createNewResourcesAPI: createNewResources,
      updateBusinessUnitRegionAPI: updateBusinessUnitRegion,
      getAllPagesContentAPI: getAllPagesContent
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(AddReportLinks);
