import React from "react";
import './style.css';

export default function Spinner(props) {
  return (
    <div style={{textAlign: props.align}}>
      <div className="loading">
        <div></div>
        <div></div>
      </div>
    </div>
  );
}