export default (state = {}, action) => {

    switch (action.type) {

    case 'CAPTURE_SHARE_LINK_IMAGE':
    return action.payload;

    default:

    return state;

    }

    };