export default (state = [], action) => {

    switch (action.type) {
        case 'GET_DASHBOARD_STATUS_REQUEST':
            return { data: [], error: null, loading: true };

        case 'GET_DASHBOARD_STATUS_SUCCESS':
            return { data: action.payload.data.data, error: null, loading: false };

        case 'GET_DASHBOARD_STATUS_FAILURE':
            return {
                data: [], error: true,
                message: action.payload.response.data.message,
                userMessage: action.payload.response.data['usermessage'],
                loading: false
            };

        default:
            return state;
    }

};
