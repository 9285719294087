export default (state =
    {
        loading: true,
        error: null,
        data: []
    }
, action) => {
    switch (action.type) {

        case 'REGION_MAPPING_DATA_REQUEST':
            return {
                ...state,
                loading: true,
                error: null,
                data: []
            };

        case 'REGION_MAPPING_DATA_SUCCESS':
            return {
                data: action.payload.data.data,
                loading: false,
                error: null,
            };

        case 'REGION_MAPPING_DATA_FAILURE':
            return {
                data: [],
                loading: false,
                error: true,
                message: action.payload.response.data.message,
                userMessage: action.payload.response.data['usermessage'],
            };

        default:
            return state;

    }

};