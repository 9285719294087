import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Menu from '@material-ui/core/Menu';
import ExpandMoreOutlinedIcon from '@material-ui/icons/ExpandMoreOutlined';
import { useSelector } from "react-redux";


const useStyles = makeStyles((theme) => ({
    list: {
        paddingBottom: 2
    },
    menuListContainer: {
        maxHeight: 460,
        overflow: "auto"
    },
    "&.MuiButtonBase-root:hover": {
        bgcolor: "transparent"
    }
}));

const CCIconButton = (props) => {
    const classes = useStyles();

    const { ariaLabel, TypoLabel, menuId, menuItem, prefixIcon,  renderIcon, affixIcon,   closeAnchorEl, disabled, ExpandMoreOutlined, padding} = props;


    const [anchorEl, setAnchorEl] = React.useState(closeAnchorEl);

    const menuItemViewState = useSelector((state)=> state.updateMenuItemViewState)

    const open = Boolean(anchorEl);

    useEffect(()=>{
        if(!!closeAnchorEl || !!menuItemViewState.closeAnchorEl){
            setAnchorEl(null);
        }
    },[closeAnchorEl, menuItemViewState]);

    const handleBookMarkMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const typoStyle = {
        color: '#050050', //var(--text-primary, #050050),
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: '500',
        lineHeight: '16px', /* 133.333% */
        letterSpacing: '0.5px',
        padding: '0.5rem 0rem',
        cursor : 'pointer'
    };

    const typoStyle1 = {
        color: '#050050', //var(--text-primary, #050050),
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: '500',
        lineHeight: '16px', /* 133.333% */
        letterSpacing: '0.5px',
        padding: '0 1rem',  
        cursor : 'pointer'
    };

    const renderIconButton = () => {
        return (
            <div>
                <IconButton
                    disableRipple
                    aria-label={ariaLabel}
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleBookMarkMenu}
                    color="default"
                    style={{ outline: 'none', padding: 0 , cursor : 'pointer'}}
                    className="material-icons"
                    disabled={disabled}
                >
                    {affixIcon ? renderIcon : null}
                    <Typography color="inherit" style={padding ? typoStyle1 : typoStyle}>
                        {prefixIcon ? renderIcon : null}
                        {TypoLabel}
                    </Typography>
                   { ExpandMoreOutlined ? <ExpandMoreOutlinedIcon /> : null }
                </IconButton>
                <Menu
                    id={menuId}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={open}
                    onClose={handleClose}
                    classes={{ list: classes.list }}
                >
                    {menuItem}
                </Menu>
            </div>
        )
    }

     let IconStyle =
        {width : 'auto', height: 'fit-content'}

    return (
        <div id="IconButton"  style={IconStyle}>
            {renderIconButton()}
        </div>
    );
}

export default CCIconButton;
