const defaultState = {
  success: false,
  responseDeafult: true,
  data:[],
  message: '',
  loading: false
}

export default (state = defaultState, action) => {

  switch (action.type) {

    case 'NEW_MODULE_REQUEST':
      return { ...defaultState , loading: true,   responseDeafult: true,}

    case 'NEW_MODULE_SUCCESS':
      return {
        ...defaultState,
        success: true,
        message: action.payload.data.message,
        data: action.payload.data.data,
        responseDeafult: false,
        loading: false
      }

    case ' NEW_MODULE_FAILURE':
      return {
        ...defaultState,
        message: action.payload.response.data.message,
        userMessage: action.payload.response.data['usermessage'],
        data: action.payload.data,
        responseDeafult: false,
        loading: false
      }

    default:
      return {
        ...state,
      };
  }
};
