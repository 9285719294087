import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import { useSelector, useDispatch } from "react-redux";
import { updateOperationalAlertsFilterData } from "../../redux/actions";
import cloneDeep from 'lodash/cloneDeep';

const useStyles = makeStyles((theme) => ({
  formControl: {
    // margin: theme.spacing(1),
    minWidth: "80%",
    maxWidth: "85%",
    borderBottom: "1px solid",
    width: "80%",
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

export default function MultipleSelect(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const alertFilter = useSelector((state) => state.alertFilter);

  const { tag, data, name, getFilterItem } = props;
  const [optionList, setOptionList] = useState([]);
  const [currentVal, setCurrentVal] = useState([]);

  function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  useEffect(() => {
    let filters = cloneDeep(data);
    if (Array.isArray(filters) && filters.length > 0) {
      let renderDataList = data.map((item) => {
        return Object.values(item);
      });
      let filteredData = renderDataList.flat();
      var unique = filteredData.filter(onlyUnique);
      alertFilter.metaData.map((filter) => {
        if (filter.key === name) {
          if (name === "region" && Array.isArray(unique) && unique.length === 2) {
            let data = cloneDeep(unique);
            let region = data.splice(0, 1);
            filter.appliedFilter = region;
            filter.handleChangeCall = false;
          }
          else{
            filter.appliedFilter = unique;
            filter.handleChangeCall = false;
          }
           return filter;
        }
        return filter;
      });
       setOptionList(unique);
      if (name === "region" && Array.isArray(unique) && unique.length === 2) {
        let data = cloneDeep(unique);
        let region = data.splice(0, 1);
        setCurrentVal(region);
      }
      else {
        setCurrentVal(unique);
      }
      dispatch(updateOperationalAlertsFilterData(alertFilter));
    }
  }, [alertFilter, alertFilter.metaData, data, dispatch, name]);

  const handleChange = (event) => {
    let activeItem = "";
    let name = event.target.name;
    activeItem = event.target.value;
    let index = alertFilter.metaData.findIndex((el) => el.key === name);
    alertFilter.metaData[index].handleChangeCall = true;
    if (activeItem[activeItem.length - 1] === "all") {
      let values = activeItem.length - 1 === optionList.length ? [] : optionList;
      setCurrentVal(values);
      getFilterItem(name, values);
      if (index > -1) {
        alertFilter.metaData[index].appliedFilter = values;
        dispatch(updateOperationalAlertsFilterData(alertFilter));
      }
    } else {
      setCurrentVal(activeItem);
      getFilterItem(name, activeItem);
      if (index > -1) {
        alertFilter.metaData[index].appliedFilter = activeItem;
        dispatch(updateOperationalAlertsFilterData(alertFilter));
      }
    }
  };

  function capitalize(str) {
    const lower = str.toLowerCase();
    return str.charAt(0).toUpperCase() + lower.slice(1);
  };

  const renderOptions = () => {
    return (
      Array.isArray(optionList) &&
      optionList.length > 0 &&
      optionList.map((option) => {
        return (
          <MenuItem
            key={option}
            value={option}
            className={classes.menuItemOption}
          >
            <Checkbox
              checked={currentVal.indexOf(option) > -1}
              color={"primary"}
            />
            <ListItemText primary={option} />
          </MenuItem>
        );
      })
    );
  };

  const handleMultiSelect = (selected, optionList) => {
    if (Array.isArray(selected) && Array.isArray(optionList)) {
        if (selected.length > 1 && selected.length < optionList.length) {
        return "Multiple Sections";
      } else if (selected.length === 1) {
        return selected.join(", ");
      } else if (optionList.length === selected.length) {
        return "All";
      }
    }
  };

  const renderMultipleSelect = () => {
    return (
      <Select
        labelId={`select_label_${tag}`}
        id={`select_${tag}`}
        value={Array.isArray(currentVal) ? currentVal : []}
        renderValue={(selected) => handleMultiSelect(selected, optionList)}
        multiple
        name={name}
        defaultValue="All"
        style={{ maxWidth: "220px", width: "100%" }}
        onChange={(e) => handleChange(e, name, tag)}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          getContentAnchorEl: null,
        }}
      >
        <MenuItem value="all" className={classes.filterCheckbox}>
          <Checkbox
            checked={
              optionList.length > 0 && currentVal.length === optionList.length
            }
            indeterminate={
              currentVal.length > 0 && currentVal.length < optionList.length
            }
            color={"primary"}
          />
          <ListItemText primary="Select All" />
        </MenuItem>
        {renderOptions()}
      </Select>
    );
  };

  return (
    <FormControl className={classes.formControl}>
      <InputLabel id="demo-mutiple-checkbox-label">
        {capitalize(tag)}
      </InputLabel>
      {renderMultipleSelect()}
    </FormControl>
  );
}
