import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { updateViewBuilderJSON } from '../../../redux/actions';
import ContentOptions from './ContentOptions';
import DesignOptions from './DesignOptions';
import BiConfigurations from './BiConfigurations';
class BiDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
          displayColorPicker:false,
          colorCode:'',
          displayColorPickerId:'',
          tabIndex: 1
        }
    }
 renderBiConfigurations(){
   return(<BiConfigurations currentObj = {this.props.currentObj} id={this.props.id} />)
  }

 renderContentOptions(){
   return(<ContentOptions currentObj = {this.props.currentObj} id={this.props.id} />)
 }
  renderDesignOptions(){
    return(<DesignOptions currentObj = {this.props.currentObj} id={this.props.id} />)
  }
    render() {
     const {tabIndex} = this.state;
  let alignMent = {
    textAlign: 'initial'
  }
        return (
            <Tabs  selectedIndex={tabIndex} onSelect={index => this.setState({tabIndex :index})}>
              <TabList style={alignMent}>
               <Tab>Content</Tab>
               <Tab>Configurations</Tab>
               <Tab>Design</Tab>
              </TabList>
              <TabPanel>
                {this.renderContentOptions()}
              </TabPanel>
              <TabPanel>
                {this.renderBiConfigurations()}
              </TabPanel>
              <TabPanel>
                {this.renderDesignOptions()}
              </TabPanel>
              </Tabs>
      );
    }
}
function mapStateToProps(state) {
	return ({
		viewBuilder:state.viewBuilderObj,

	})
}
function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		updateViewBuilderJSONAPI:updateViewBuilderJSON
	}, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(BiDashboard);
