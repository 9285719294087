import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 0,
    },
    paper: {
        padding: theme.spacing(0.3, 0),
        // margin: '10px 0 0',
        width: 'auto',
        height: 'auto',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: "center",
        boxShadow: 'none',
        background: '#fff'
    },
    flexRow: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%'
    },
    flexContainer: {
        display: 'flex',
        flexDirection: 'row',
        height: '100%'
    },
    flexItem: {
        display: 'flex',
        flexDirection: 'row',
        border: '1px solid #e5e5e5',
        borderRadius: 15,
        boxShadow: ' rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
        width: '375px',
        margin: '1rem 3.2rem',
        textAlign: 'center',
        lineHeight: '75px',
        fontSize: '30px',
        padding: '12px'
    },
    title: {
        margin: '3rem 20px 20px',
        textAlign: 'justify',
        fontFamily: 'Gilroy-Medium',
        fontSize: 14
    },
    subtitle: {
        margin: '25px 0 20px 20px',
        textAlign: 'justify',
        fontFamily: 'Gilroy-Medium',
        fontSize: '14px',
        fontWeight: 500
    },
    toolList: {
        display: "flex",
        flexDirection: "column",
        boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
        background: '#f8f9fa',
        padding: '20px',
        margin: '0 20px'
    },
    analyticsTool: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: "flex-start",
        paddingBottom: '10px',
        border: '1px solid #fff',
        background: "#fff",
        width: '30%',
        margin: '1rem 0.5rem',
        borderRadius: '5%'
    },
    avtar: {
        width: '50px',
        height: '50px',
        borderRadius: '0%',
        padding: '5px',
        background: '#fff',
        margin: '10px 0 0px 10px',
    },
    toolName: {
        width: '100%',
        fontSize: '17px',
        color: '#0070c0',
        display: 'flex',
        justifyContent: 'left',
        margin: '24px 0 0 20px',
        textDecoration: 'underline',
    },
    ESFeature: {
        background: '#ffffff',
         textAlign: "right",
        marginTop: '10px',
    },
    position: {
        position: 'absolute',
        top: '60%',
        left: '60%',
        textAlign: 'center'
    },
    errorMessage:{
        display: "flex", justifyContent: "center" , fontSize : '20px'
    },
    serverDown: {
        display: 'flex',
        flexDirection: 'column',
        alignItems : 'center',
        justifyContent: 'center',
        textAlign: 'center',
        width: 'inherit',
        fontSize: '20px',
        fontWeight: '600',
        '& span': {
            marginBottom: 12
        }
    }
}));


export { useStyles };