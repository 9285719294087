import React, { useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';

const SharePlaybookLink = (props) => {
     const { authState } = useOktaAuth();

    useEffect(() => {
        const pathName = window.location.href;
         if (pathName.length > 0) {
            let urlParams = pathName.split('?');

            if (Array.isArray(urlParams) && urlParams.length > 1) {
                let share_id = urlParams[1].split('&')[0];
                let hash_id = urlParams[1].split('&')[1];
                let share_id_val = share_id.split('=')[1];
                let hash_id_val = hash_id.split('=')[1];

                let shareState = {
                    shareId: share_id_val,
                    hashId: hash_id_val,
                    type: 'share'
                };
                sessionStorage.setItem('presistReport', JSON.stringify(shareState));
            }
        }
    }, []);

    useEffect(() => {
        if (!!authState && !authState.isAuthenticated) {
            window.location.replace('/');
        }
        else if (!!authState && !!authState.isAuthenticated) {
            window.location.replace('/');
            sessionStorage.removeItem('oktaRes');
        }
    }, [authState, props, props.history]);

    return (
        <div className="share">
               <div style={{fontSize: '20px', textAlign: 'center', marginTop: '20rem'}}>
                    Please wait, we are processing the command center share report request
                </div>
         </div>
    );
};

export default SharePlaybookLink;