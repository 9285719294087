import React, {   useEffect,   useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import QuillEditor from "../textEditor/editor";
import Select from "../../component/Select";
import MenuList from "../../component/menuList";
import Modal from "../../component/modal";
import { useStyles } from "./indexStyle";
import NewRelease from "./newRelease";
import {
  releaseVersionData,
  releaseNotesFieldData,
  fetchReleaseDataList,
  publishReleaseNotes,
  toastTriggered,
  updateEditorData,
  editEditorData,
  updatesidebarMenuList,
  deleteEditorModuleFeatures,
  updateEditorModuleFeatures
} from "../../redux/actions";
import Button from "@material-ui/core/Button";
import releaseBook from "../../assets/icons/book.svg";
import checkWhite from "../../assets/icons/checkWhite.svg";
import utils from "../../utils/index";
import "react-quill/dist/quill.snow.css";
import { PlusCircleFill } from "../../assets/icons/Icons";
import CircularProgress from '@material-ui/core/CircularProgress';
import CurdOperations from "../../component/curdOperations/index";


let toastProperties = {
  title: "Success",
  description: null,
  backgroundColor: "#5cb85c",
  percentageDownload: "100%",
  icon: checkWhite,
  status: "success",
  autoDeleteTime: 3000,
  autoDelete: true,
  trigger: true,
};

const ReleaseNotes = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  let history = useHistory();

  const [open, setOpen] = useState(false);
  const [menuData, setMenuData] = useState([]);
  const [enableEditor, setEnableEditor] = useState(false);
  const [region, setRegion] = useState(null);
  const [activeRelease, setActiveRelease] = useState({});
  const [activeReleaseName, setActiveReleaseName] = useState(null);
  const [activeReleaseFeature, setActiveReleaseFeature] = useState('');
  const [activeReleaseAlertID, setActiveReleaseAlertID] = useState('');
  const [innerHtml, setInnerHtml] = useState("");
  const [newOne, setCreateNew] = useState(false);
  const [modalTitle, setModalTitle] = useState('');

  const editorData = useSelector((state) => state.editorReleaseData);
  const editRelEditorData = useSelector((state) => state.editEditorData);
  const releaseVersions = useSelector((state) => state.releaseVersions);
  const releaseDataList = useSelector((state) => state.releaseDataList);
  const selectedRegion = useSelector((state) => state.getBusinessUnitsRegion);
  const publishRelease = useSelector((state) => state.publishRelease);
  const notificationRegion = useSelector((state) => state.notificationRegion);
  const oktaAuth = useSelector((state) => state.oktaAuth);

  useEffect(() => {
    const { error, responseDefault } = publishRelease;
    if (!responseDefault && !!error) {
      utils.toastTriggered(publishRelease, dispatch, true);
    }
  }, [dispatch, publishRelease]);

  useEffect(() => {
    if (!!notificationRegion && Object.keys(notificationRegion).length > 0) {
      setRegion(notificationRegion.region);
      setActiveReleaseName(notificationRegion.activeRelease);   //release name
      setActiveReleaseFeature(notificationRegion.activeReleaseFeature); // release feature name
      setActiveReleaseAlertID(notificationRegion.notificationId);
    }
    else if (!!selectedRegion && Object.keys(selectedRegion).length > 0) {
      setRegion(selectedRegion.region);
      setActiveReleaseName(null);
    }
  }, [selectedRegion, notificationRegion]);

  useEffect(() => {
    let params = []
    dispatch(updatesidebarMenuList(params));
  }, [dispatch, region, activeRelease, activeReleaseName,]);

  useEffect(() => {
    if (editRelEditorData) {
      setEnableEditor(editRelEditorData.enableEditor);
    }
  }, [editRelEditorData]);

  useEffect(() => {
    if (!!region) {
      let params = { region: region};
      let activeRelease = {};
      dispatch(releaseVersionData(params)).then((res) => {
        if(notificationRegion.activeRelease){
           activeRelease = res.data.data.find(el => el.releaseNoteID === notificationRegion.activeRelease);
        }else{
           activeRelease = res.data.data[0];
        }
        if (activeRelease) {
          params.releaseVersionID = activeRelease.releaseVersionID;
          params.releaseName = activeRelease.releaseName;
          dispatch(fetchReleaseDataList(params));
          dispatch(updatesidebarMenuList(params));
          setActiveRelease(activeRelease);
          setActiveReleaseName(activeRelease.releaseNoteID);   //release name
        }
      });
      dispatch(releaseNotesFieldData(params));
    }
  }, [dispatch, region, notificationRegion.activeRelease ]);

  const selectedRelease = (release) => {
    setActiveReleaseName(release);
    setActiveReleaseFeature(null);
    history.push({ source: 'release Notes' });
     if(!!activeReleaseName && release !== activeReleaseName && Array.isArray(releaseVersions.data) && releaseVersions.data.length>0){
      let activeRelease = releaseVersions.data.find(el => el.releaseNoteID === release);
      let params = {
      region : region,
      releaseVersionID : activeRelease.releaseVersionID,
      releaseName : activeRelease.releaseName, }
      setActiveRelease(activeRelease);
      dispatch(fetchReleaseDataList(params));
      dispatch(updatesidebarMenuList(params));
   }
  };

  useEffect(() => {
    const { releaseData } = releaseDataList;
    if (Array.isArray(releaseData) && releaseData.length > 0) {
      let category = releaseData[0].category;
      Array.isArray(category) &&
        category.map((element, index) => {
          if (index === 0) {
            element.isOpen = true;
          } else {
            element.isOpen = false;
          }
          return category;
        });
      setMenuData(category);
    }
  }, [releaseDataList]);

  useEffect(() => {
    const { releaseData } = releaseDataList;
    if (editorData.innerHtml !== "") {
      setInnerHtml(editorData.innerHtml);
    } else if (Array.isArray(releaseData) && releaseData.length > 0) {
       let category = releaseData[0].category;
      if (Array.isArray(category) && category.length > 0) {
        let subCategory = category[0].subCategory;
        if (
          Array.isArray(subCategory) &&
          subCategory.length > 0 &&
          subCategory.subCategory === editorData.name
        ) {
          let data = subCategory[0].releaseDescription;
          let params = { innerHtml: data };
          setInnerHtml(data);
          dispatch(updateEditorData(params));
        } else {
          setInnerHtml(editorData.innerHtml);
        }
      }
    }
  }, [dispatch, editorData.innerHtml, editorData.name, releaseDataList]);

  const renderHtmlContent = () => {
    const { loading, defaultResponse } = releaseDataList;
    if (!!innerHtml && innerHtml !== "") {
      return (
        <div className="ql-snow">
          <span className="ql-editor" dangerouslySetInnerHTML={{ __html: innerHtml }} />
        </div>
      )
    } else if (!loading && !defaultResponse) {
      return (
        <div className={classes.emptyReleaseData}>
          <div className={classes.fontStyle}>
            No release notes available for the {activeRelease && activeRelease.releaseName}
          </div>
          <div className={classes.fontStyle}>Please Create Release Notes</div>
        </div>
      );
    }
  };

  const CreateNewVersionRelease = () => {
    setOpen(true);
    setCreateNew(true);
    setModalTitle("Create New Release")
  };

  const manageReleaseNotes = () => {
    setOpen(true);
    setCreateNew(false);
    setModalTitle("Manage Releases")
  };

  const trackUserActivities = (title) => {
    let trackingObject = {
      region: region,
      actionType: 'Click',
      pageName: 'Other Feature',
      reportName: 'Release Notes',
      reportSection:  'Release Notes Published',
      pageDetails : `${title}`,
    };
    utils.userTrackingInfo(trackingObject);
  }

  const PublishRelease = () => {
    if (Array.isArray(menuData) && menuData.length > 0) {
      let index = menuData.findIndex(
        (el) => el.releaseCategoryID === editRelEditorData.releaseCategoryID
      );
      if (index > -1) {
        let subCategory = menuData[index].subCategory;
        let index2 = subCategory.findIndex(
          (el) => el.subCategory === editRelEditorData.subCategory
        );
        subCategory[index2].releaseDescription = editorData.innerHtml;
      }
    }
    let params = {
      releaseID: editRelEditorData.releaseNoteID,
      updateDetails: {
        releaseName: editRelEditorData.releaseName,
        releaseVersionID: editRelEditorData.releaseVersionID,
        releaseDescription: menuData,
      },
    };
    dispatch(publishReleaseNotes(params)).then((res) => {
      trackUserActivities(editRelEditorData.releaseName)
      toastProperties["description"] = res.data.message;
      dispatch(toastTriggered([toastProperties]));
      setEnableEditor(false);
      let params = {
        enableEditor: false,
      };
      dispatch(editEditorData(params));
    });
  };

  const CancelRelease = () => {
    setEnableEditor(false);
  };

  const renderBodyElement = () => {
    if (!!newOne) {
      return (
        <NewRelease
          hideSettingsWindow={(e) => setOpen(false)}
          region={region}
        />
      )
    }
    else {
      return (
        <CurdOperations
          hideSettingsWindow={(e) => setOpen(false)}
          region={region}
          data={releaseVersions.data}
          value={'releaseVersionID'}
          name={'releaseName'}
          label={'Manage Release Notes'}
          create={false}
          moduleID={'releaseVersionID'}
          module={'Release Note'}
          deleteAction={deleteEditorModuleFeatures}
          updateFeatureName={updateEditorModuleFeatures}
          updateAction={releaseVersionData}
          updateParams={{
            region: region,
          }}
        />
      );
    }
  };

  return (
    <div className={classes.root}>
      <Modal
        open={open}
        bodyElement={renderBodyElement()}
        hideSettingsWindow={(e) => setOpen(false)}
        title={modalTitle}
        size={'md'}
      />
      <AppBar position="static">
        <Toolbar style={{ minHeight: '48px' }}>
          <div className={classes.documentation}>
            {
              activeRelease && Object.keys(activeRelease).length > 0 ?
                <Typography variant="h6" className={classes.header}>
                  Documentation for {activeRelease && activeRelease.releaseName}
                </Typography>
                :
                null
            }
          </div>
          <Box className={classes.release}>
            <Select
              label={"Release"}
              backGround={"#3cd458"}
              activeValue={selectedRelease}
              divider={true}
              dividerLabel2={"Create"}
              dividerLabel1={"Manage"}
              createNewVersion={CreateNewVersionRelease}
              manageReleaseNotes={manageReleaseNotes}
              data={releaseVersions.data}
              loading={releaseVersions.loading}
              error={releaseVersions.error}
              defaultRes={releaseVersions.responseDefault}
              activeDropdownValue={activeRelease && activeRelease.releaseNoteID}
              releaseNoteID={activeRelease && activeRelease.releaseNoteID}
              value={'releaseNoteID'}
              value2={'releaseVersionID'}
              name={'releaseName'}
            />
          </Box>
        </Toolbar>
      </AppBar>
      <Paper className={classes.paper}>
        <Grid container item xs={12}>
          <Grid container item xs={2} className={classes.sidebar}>
            <Grid container item xs={12} className={classes.menuSection}>
              <MenuList
                data={menuData}
                loading={releaseDataList.loading}
                defaultResponse={releaseDataList.defaultResponse}
                error={releaseDataList.error}
                message={releaseDataList.message}
                userMessage={releaseDataList.userMessage}
                region={region}
                Releases={releaseVersions.data}
                activeReleaseVersion={activeRelease && activeRelease.releaseVersionID}
                activeReleaseName={activeRelease && activeRelease.releaseName}
                activeReleaseFeature={activeReleaseFeature}
                releaseNoteID={activeRelease && activeRelease.releaseNoteID}
                activeReleaseAlertID={activeReleaseAlertID}
              />
            </Grid>
          </Grid>
          <Grid container item xs={10} className={classes.blockAlignment}>
            {!!releaseVersions.loading || !!releaseDataList.loading ?
              <div className={classes.loaderPosition}>
                <CircularProgress color="primary" />
                <div className="waitAlignment">Please Wait...</div>
              </div>
              :
              Array.isArray(releaseVersions.data) && releaseVersions.data.length > 0 ?

                <Grid
                  container
                  item
                  xs={12}
                  className={classes.alignMent}
                  style={{ padding: "10px 20px 0" }}
                >
                  {enableEditor ? (
                    <div className={classes.editorTitle}>
                      {" "}
                      <span>
                        {" "}
                        <img
                          src={releaseBook}
                          alt=""
                          width="40px"
                          height="40px"
                        />{" "}
                        Release Editor{" "}
                      </span>{" "}
                    </div>
                  ) : null}
                  <div className={innerHtml !== '' && !enableEditor ? classes.editorInnerhtml : classes.editor}>
                    {!!enableEditor ? <QuillEditor /> : renderHtmlContent()}
                  </div>
                </Grid>

                :
                <div className={classes.emptyReleaseData}>
                  <div className={classes.fontStyle}>
                    No release notes available for the selected region
                  </div>
                  {
                    oktaAuth.adminType === "Contributor" ?
                      <div>
                        <div className={classes.fontStyle}>Please Create Release Notes </div>

                        <div className={classes.fontStyle} >
                          <button className="btn addNewBoard" onClick={CreateNewVersionRelease}>
                            <PlusCircleFill /> Create Release
                          </button>
                        </div>
                      </div>
                      :
                      null
                  }

                </div>
            }
            {enableEditor ? (
              <Grid
                item
                xs={12}
                className={classes.flexRow}
                style={{ padding: "1rem 1rem 0 0", }}
              >
                <Grid item xs={9} className={classes.leftAlignMent}></Grid>
                <Grid item xs={3} className={classes.flexRow}>
                  <Button
                    variant="contained"
                    color="primary"
                    className={""}
                    onClick={PublishRelease}
                  >
                    Publish Release
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    className={classes.ml05}
                    onClick={CancelRelease}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            ) : null}
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default ReleaseNotes;
