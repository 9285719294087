import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 0,
    },
    paper: {
        padding: theme.spacing(0.3, 0),
        margin: '10px 0 0',
        width: '350px',
        height: 'auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: "center",
        boxShadow: 'none',
        background: '#fff'
    },
    flexRow: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%'
    },
    flexColumn: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
    },
    flexItem: {
        display: 'flex',
        flexDirection: 'row',
        border: '1px solid #e5e5e5',
        borderRadius: 15,
        boxShadow: ' rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
        width: '375px',
        margin: '1rem 3.2rem',
        textAlign: 'center',
        lineHeight: '75px',
        fontSize: '30px',
        padding: '12px'
    },
    flexContainer: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        margin: '0.5rem 0 0 1.9rem',
        width: '90%'
    },
    notification: {
        width: '100%',
        padding: '0 0.2rem 0 0.2rem',
        backgroundColor: '#e5e5e5',
        marginBottom: '5rem'
    },
    cardBox: {
        background: 'white',
        margin: '0.3rem 0',
        boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
    },
    title: {
        textAlign: 'justify',
        fontFamily: 'Gilroy-Medium',
        fontSize: 20,
        width: '90%',
        paddingLeft: 5
    },
    subtitle: {
        textAlign: 'justify',
        fontFamily: 'Gilroy-Medium',
        fontSize: 9,
        width: '90%',
        paddingLeft: 5
    },
    clearAll: {
        width: 'inherit',
        display:'flex',
        justifyContent: 'end',
        height: '29px',
        position: 'fixed',
        bottom: 0,
        background: '#fff',
        zIndex: 1000,
    },
    clearAllbtn: {
        border: '1px solid #c2bfbf',
        padding: '5px  12px',
        color: '#000',
        marginRight: '12px',
    },
    type: {
        textAlign: 'left',
        fontFamily: 'Gilroy-Medium',
        fontSize: 14,
        width: '90%',
        padding: 5,
    },
    date: {
        textAlign: 'left',
        fontFamily: 'Gilroy-Medium',
        fontSize: 12,
        width: '90%',
        marginLeft: '1.8rem',
        padding: '0 0 5px 0'
    },
    desc: {
        textAlign: 'left',
        fontFamily: 'Gilroy-Medium',
        fontSize: 12,
        width: '90%',
        marginLeft: '1.8rem',
        padding: '0.5rem 0'
    },
    triangle: {
        width: 0,
        height: 0,
        borderTop: '0px solid transparent',
        borderBottom: '15px solid transparent',
    },
    OperateAlert: {
        margin: '.3rem',
        background: 'gray',
        width: '24%',
        textAlign: 'left',
        padding: '5px 10px',
        boxShadow: '2px 2px 8px #888',
        color: 'white'
    },
    notifSetting: {
        paddingTop: '15px',
        display: 'contents',
    },
    noDataNotif: {
        position: 'absolute',
        top: '50%',
        left: '18%'
    },
    LoadDataNotif: {
        position: 'absolute',
        top: '50%',
        left: '44%'
    },
    center: {
        textAlign: 'center'
    },
    removeNotifications: {
        cursor: 'pointer'
    },
    releaseNotes: {
        color: '#3f51b5',
        paddingRight: '1rem',
        lineHeight: 2,
        textAlign: 'end',
        textDecoration: 'underline',
        cursor: 'pointer'
    }
}));

export { useStyles };