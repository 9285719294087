import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Input from '@material-ui/core/Input';
import Select from "@material-ui/core/Select";
import { PlusCircleFill } from "../../assets/icons/Icons";
import { useStyles } from "./style";
import ListAltOutlinedIcon from '@material-ui/icons/ListAltOutlined';
import { useHistory } from "react-router-dom";

export default function ControlledOpenSelect(props) {
  const classes = useStyles();
  const {
    label,
    backGround,
    divider,
    dividerLabel1,
    dividerLabel2,
    createNewVersion,
    manageReleaseNotes,
    data,
    loading,
    error,
    activeDropdownValue,
    value,
    name,
  } = props;

  let backgroundColor = "transparent";
  backgroundColor = backGround;

  const [open, setOpen] = useState(false);
  const [inputLabel, setInputLabel] = useState("");
  const [selectedValue, setValue] = useState('...loading');

  const oktaAuth = useSelector((state) => state.oktaAuth);
  let history = useHistory();

  useEffect(() => {
    if (!!activeDropdownValue) {
      setValue(activeDropdownValue)
    }
    else {
      setValue('No Release Available')
    }
  }, [activeDropdownValue])

  useEffect(() => {
    setInputLabel(label);
  }, [label]);

  const handleChange = (event) => {
    let val = event.target.value;
     if (!!val) {
      setValue(val);
      props.activeValue(val)
    }
    else {
      setValue(activeDropdownValue);
      props.activeValue(activeDropdownValue);
    }
    setInputLabel("");
  };

  const handleClose = () => {
    setOpen(false);
    setValue(activeDropdownValue)
  };

  const handleOpen = () => {
    setOpen(true);
    setValue(activeDropdownValue);
    history.push({
      state: {
        source: label
      }
    })
  };

  const createVersion = () => {
    createNewVersion(true);
  };

  const manageVersion = () => {
    manageReleaseNotes(true);
  };


  const dividerElement = () => {
    if (!!divider && oktaAuth.adminType === "Contributor") {
      return (
        <div className={classes.flexRow}>
          <MenuItem onClick={() => manageVersion()} >
            {" "}
            <span>
              {" "}
              <ListAltOutlinedIcon style={{ marginTop: 0 }} /> {dividerLabel1}{" "}
            </span>
          </MenuItem>
          <MenuItem onClick={() => createVersion()} >
            {" "}
            <span>
              {" "}
              <PlusCircleFill /> {dividerLabel2}{" "}
            </span>
          </MenuItem>
        </div>
      );
    }
  };

  const renderOptions = () => {
   return (
      !!loading ? (
        <MenuItem value={"...loading"}>{"...loading"} </MenuItem>
      ) : data.length > 0 ? (
        data.map((item) => (
          <MenuItem value={item[value]}>
            {item[name]}{" "}
          </MenuItem>
        ))
      ) : (
        !loading &&
        !!error && (
          <MenuItem value={selectedValue}>
            {`No ${label}`}{" "}
          </MenuItem>
        )
      )
    )
  };

  return (
    <div className={classes.container}>
      <FormControl
        className={classes.formControl}
        style={{ background: backgroundColor }}
      >
        <Select
          labelId={`select_label_${inputLabel}`}
          id={`select_${inputLabel}`}
          open={open}
          value={selectedValue}
          onClose={handleClose}
          onOpen={handleOpen}
          onChange={handleChange}
          className={classes.select}
          input={<Input classes={{
            underline: classes.underline,
          }} />}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left"
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left"
            },
            getContentAnchorEl: null,
            disableUnderline: true
          }}
        >
          {renderOptions()}
          {dividerElement()}
        </Select>
      </FormControl>
    </div>
  );
}
