export default (state = { data: [], responseDefault: true }, action) => {
  switch (action.type) {
    case "FETCH_USER_SUBSCRIPTION_DATA_REQUEST":
      return {
        data: [],
        loading: true,
        responseDefault: true
      };

    case "FETCH_USER_SUBSCRIPTION_DATA_SUCCESS":
      return {
        data: action.payload,
        responseDefault: false,
        loading: false,
        success: true,
        error: false,
      };

    case "FETCH_USER_SUBSCRIPTION_DATA_FAILURE":
      return {
        data: [],
        error: true,
        responseDefault: false,
        loading: false,
        success: false,
        userMessage: action.payload.response.data["usermessage"],
      };

    default:
      return state;
  }
};
