export default (state = [], action) => {

    switch (action.type) {

    case 'UPDATE_CUSTOMIZED_HTML_TEMP':
    return action.payload;

    default:

    return state;

    }

    };