let defaultState = {
  data: [],
  loading: true,
  error: false,
  responseDefault: true,
  success: false,
}

export default (state = defaultState, action) => {

  switch (action.type) {

    case 'GET_PLAYBOOKS_CONTENT_REQUEST':
      return {
        data: [],
        loading: true,
        error: false,
        responseDefault: true,
        success: false,
      }

      case 'GET_PLAYBOOKS_CONTENT_UPDATE':
        return {
          data: [],
          loading: false,
          error: false,
          responseDefault: true,
          success: false,
        }

    case 'GET_PLAYBOOKS_CONTENT_SUCCESS':
      return {
        data: action.payload.data.data,
        loading: false,
        error: false,
        responseDefault: false,
        success: true,
      }

    case 'GET_PLAYBOOKS_CONTENT_FAILURE':
      return {
        ...defaultState,
        error: true,
        data: [],
        loading: false,
        responseDefault: false,
        // message: action.payload.response.data['message'],
        // userMessage: action.payload.response.data['usermessage'],
        success: false,
      }

    default:
      return state;

  }

};