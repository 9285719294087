export const defaultRoutes = {
  key1: "/playbooks-reports",
  key2: "/self-service",
  key3: "/foundation-analytics",
  key4: "/control-tower",
  key5: "/additional-reports",
  key6: "/resources",
  key7: "/admin",
  key8: "/admin/dashboard_builder",
  key9: "/share-playbooks-report",
  key10: "/alerts",
  key11: "/alert-setting",
  key12: "/create-alert",
  key13: "/release-notes",
  key14: "/newsletter",
};


// APAC  , LAO
export const disabledScreen = [
  {"key1": "/analytics/playbooks-reports", "share" : true, "export" : true , "screen"  :[{"region":"KCNA","key":"Customized Reports","path":"/analytics/customized-reports"}]},
  {"key2": "/analytics/self-service" , "share" : false, "export" : false,  "screen" :[{"region":"KCNA","key":"Customized Reports","path":"/analytics/customized-reports"}] },
  {"key3": "/foundationAnalytics", "share" : false, "export" : false ,  "screen" :[{"region":"KCNA","key":"Customized Reports","path":"/analytics/customized-reports"}] },
  {"key4": "/analytics/control-tower" , "share" : true, "export" : true ,  "screen" :[{"region":"KCNA","key":"Customized Reports","path":"/analytics/customized-reports"}]},
  {"key5": "/analytics/customized-reports" , "share" : true, "export" : true ,  "screen" :[{"region":"KCNA","key":"Customized Reports","path":"/analytics/customized-reports"}] },
  {"key6": "/resources/links-trainings", "share" : false, "export" : false,  "screen" :[{"region":"KCNA","key":"Customized Reports","path":"/analytics/customized-reports"}] },
  {"key7": "/admin" , "share" : false, "export" : false ,  "screen" :[{"region":"KCNA","key":"Customized Reports","path":"/analytics/customized-reports"}]},
  {"key8": "/admin/dashboard_builder" , "share" : false, "export" : false,   "screen" :[{"region":"KCNA","key":"Customized Reports","path":"/analytics/customized-reports"}]},
  {"key9": "/share-playbooks-report", "share" : false, "export" : false,   "screen" :[{"region":"KCNA","key":"Customized Reports","path":"/analytics/customized-reports"}] },
  {"key10": "/alerts", "share" : false, "export" : false,   "screen" :[{"region":"KCNA","key":"Customized Reports","path":"/analytics/customized-reports"}] },
  {"key11": "/alert-setting", "share" : false, "export" : false,   "screen" :[{"region":"KCNA","key":"Customized Reports","path":"/analytics/customized-reports"}] },
  {"key12": "/create-alert", "share" : false, "export" : false  ,  "screen" :[{"region":"KCNA","key":"Customized Reports","path":"/analytics/customized-reports"}]},
  {"key13": "/release-notes" , "share" : false, "export" : false, "screen" :[{"region":"KCNA","key":"Customized Reports","path":"/analytics/customized-reports"}]},
  {"key14": "/newsletter", "share" : false, "export" : false , "screen" :[{"region":"KCNA","key":"Customized Reports","path":"/analytics/customized-reports"}]},
  {"key15": "/dynamicReports", "share" : true, "export" : true , "screen" :[{"region":"KCNA","key":"Customized Reports","path":"/analytics/customized-reports"}]},
  {"key16": "/dynamicReportLinks" , "share" : true, "export" : true, "screen" :[{"region":"KCNA","key":"Customized Reports","path":"/analytics/customized-reports"}]},
];

// EMEA  
 
export const EMEAdisabledScreen = [
    {"key1": "/analytics/playbooks-reports", "share" : true, "export" : true , "screen"  : [{"region":"KCNA","key":"Customized Reports","path":"/analytics/customized-reports"}]},
    {"key2": "/analytics/self-service" , "share" : false, "export" : false,  "screen" : [{"region":"KCNA","key":"Customized Reports","path":"/analytics/customized-reports"}] },
    {"key3": "/foundationAnalytics/tpa-reports", "share" : false, "export" : false ,  "screen" : [{"region":"KCNA","key":"Customized Reports","path":"/analytics/customized-reports"}] },
    {"key4": "/analytics/control-tower" , "share" : true, "export" : true ,  "screen" : [{"region":"KCNA","key":"Customized Reports","path":"/analytics/customized-reports"}]},
    {"key5": "/analytics/customized-reports" , "share" : true, "export" : true ,  "screen" : [{"region":"KCNA","key":"Customized Reports","path":"/analytics/customized-reports"}] },
    {"key6": "/resources/links-trainings", "share" : false, "export" : false,  "screen" : [{"region":"KCNA","key":"Customized Reports","path":"/analytics/customized-reports"}] },
    {"key7": "/admin" , "share" : false, "export" : false ,  "screen" : [{"region":"KCNA","key":"Customized Reports","path":"/analytics/customized-reports"}]},
    {"key8": "/admin/dashboard_builder" , "share" : false, "export" : false,   "screen" : [{"region":"KCNA","key":"Customized Reports","path":"/analytics/customized-reports"}]},
    {"key9": "/share-playbooks-report", "share" : false, "export" : false,   "screen" : [{"region":"KCNA","key":"Customized Reports","path":"/analytics/customized-reports"}] },
    {"key10": "/alerts", "share" : false, "export" : false,   "screen" : [{"region":"KCNA","key":"Customized Reports","path":"/analytics/customized-reports"}] },
    {"key11": "/alert-setting", "share" : false, "export" : false,   "screen" : [{"region":"KCNA","key":"Customized Reports","path":"/analytics/customized-reports"}] },
    {"key12": "/create-alert", "share" : false, "export" : false  ,  "screen" : [{"region":"KCNA","key":"Customized Reports","path":"/analytics/customized-reports"}]},
    {"key13": "/release-notes" , "share" : false, "export" : false, "screen" : [{"region":"KCNA","key":"Customized Reports","path":"/analytics/customized-reports"}]},
    {"key14": "/newsletter", "share" : false, "export" : false , "screen" : [{"region":"KCNA","key":"Customized Reports","path":"/analytics/customized-reports"}]},
    {"key15": "/dynamicReports", "share" : true, "export" : true , "screen" : [{"region":"KCNA","key":"Customized Reports","path":"/analytics/customized-reports"}]},
    {"key16": "/dynamicReportLinks" , "share" : true, "export" : true, "screen" : [{"region":"KCNA","key":"Customized Reports","path":"/analytics/customized-reports"}]},
  ];

//KCNA
 
export const KCNAdisabledScreen = [
    {"key1": "/analytics/dartboards", "share" : true, "export" : true , "screen"  : [{"region":"KCNA","key":"Customized Reports","path":"/analytics/customized-reports"}]},
    {"key2": "/analytics/developer-template" , "share" : false, "export" : false,  "screen" : [{"region":"KCNA","key":"Customized Reports","path":"/analytics/customized-reports"}] },
    {"key3": "/foundationAnalytics/self-service", "share" : false, "export" : false ,  "screen" : [{"region":"KCNA","key":"Customized Reports","path":"/analytics/customized-reports"}] },
    {"key4": "/resources/control-tower" , "share" : true, "export" : true ,  "screen" : [{"region":"KCNA","key":"Customized Reports","path":"/analytics/customized-reports"}]},
    {"key5": "/analytics/ad-hoc-reports" , "share" : true, "export" : true ,  "screen" : [{"region":"KCNA","key":"Customized Reports","path":"/analytics/customized-reports"}] },
    {"key6": "/resources/links-trainings", "share" : false, "export" : false,  "screen" : [{"region":"KCNA","key":"Customized Reports","path":"/analytics/customized-reports"}] },
    {"key7": "/admin" , "share" : false, "export" : false ,  "screen" : [{"region":"KCNA","key":"Customized Reports","path":"/analytics/customized-reports"}]},
    {"key8": "/admin/dashboard_builder" , "share" : false, "export" : false,   "screen" : [{"region":"KCNA","key":"Customized Reports","path":"/analytics/customized-reports"}]},
    {"key9": "/share-playbooks-report", "share" : false, "export" : false,   "screen" : [{"region":"KCNA","key":"Customized Reports","path":"/analytics/customized-reports"}] },
    {"key10": "/alerts", "share" : false, "export" : false,   "screen" : [{"region":"KCNA","key":"Customized Reports","path":"/analytics/customized-reports"}] },
    {"key11": "/alert-setting", "share" : false, "export" : false,   "screen" : [{"region":"KCNA","key":"Customized Reports","path":"/analytics/customized-reports"}] },
    {"key12": "/create-alert", "share" : false, "export" : false  ,  "screen" : [{"region":"KCNA","key":"Customized Reports","path":"/analytics/customized-reports"}]},
    {"key13": "/release-notes" , "share" : false, "export" : false, "screen" : [{"region":"KCNA","key":"Customized Reports","path":"/analytics/customized-reports"}]},
    {"key14": "/newsletter", "share" : false, "export" : false , "screen" : [{"region":"KCNA","key":"Customized Reports","path":"/analytics/customized-reports"}]},
    {"key15": "/dynamicReports", "share" : true, "export" : true , "screen" : [{"region":"KCNA","key":"Customized Reports","path":"/analytics/customized-reports"}]},
    {"key16": "/dynamicReportLinks" , "share" : true, "export" : true, "screen" : [{"region":"KCNA","key":"Customized Reports","path":"/analytics/customized-reports"}]},
  ];
