import { defaultRoutes } from "../../assets/data/defaultRoutes";

let defaultState = {
  activeRoutes: defaultRoutes,
  disabledFeatures: [],
  activeBusinessUnit: [],
  activePage: {},
  activeRegionSpecificConfigs: {
    showFeedback: false,
    msalTokenEnabled: true,
  },
  firstTimeAppLoad: true,
  isMsalTokenExpired: false,
  activeMsalToken: false,
  activeRegion: ""
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case "ACTIVE_BUSINESS_UNITS":
      return {
        ...state,
        activeBusinessUnit: action.payload,
      };

    case "ACTIVE_ROUTES":
      return {
        ...state,
        activeRoutes: action.payload,
      };

      case "DISABLE_FEATURES":
        return {
          ...state,
          disabledFeatures: action.payload,
        };

    case "ACTIVE_REGION_SPECIFIC_CONFIGS":
      return {
        ...state,
        activeRegionSpecificConfigs: action.payload,
      };

    case "ACTIVE_REGION":
      return {
        ...state,
        activeRegion: action.payload,
      };

    case "ACTIVE_PAGE":
      return {
        ...state,
        activePage: action.payload,
      };

    case "FIRST_TIME_APP_LOAD":
      return {
        ...state,
        firstTimeAppLoad: action.payload,
      };

    case "ACTIVE_MSAL_TOKEN":
      return {
        ...state,
        activeMsalToken: action.payload,
      };

    case "IS_MSAL_TOKEN_EXPIRED":
      return {
        ...state,
        isMsalTokenExpired: action.payload,
      };
    default:
      return state;
  }
};
