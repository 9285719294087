import React from "react";
import Modal from "react-bootstrap/Modal";
import { useStyles } from "./style";

const UiModal = (props) => {
    const classes = useStyles();

    const { open, hideSettingsWindow , size } = props;

    let { title, bodyElement } = props;

    const closeModal = () => {
        hideSettingsWindow(true);
    };

    function renderAddNewDashboardModal() {
        return (
            <Modal
                size={size}
                show={open}
                onHide={() => closeModal()}
                id="setting_modal"
            >
                <Modal.Header closeButton>
                    <h5 className={classes.title} id="modalForWidgetLabel">
                        {" "}
                        {title}
                    </h5>
                </Modal.Header>
                <Modal.Body className="bg-gray">
                    {bodyElement}
                </Modal.Body>
            </Modal>
        );
    }

    return <div>{renderAddNewDashboardModal()}</div>;
};

export default UiModal;
