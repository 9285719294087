export default (
    state = {
        loading: false,
        error: false,
        responseDefault: true,
        success: false,
        message: '',
        data: {}
    }, action) => {
    switch (action.type) {



        case 'GET_REGIONS_REQUEST':
            return {
                ...state,
                loading: true,
                error: false

            };

        case 'GET_REGIONS_SUCCESS':
            return {
                ...state,
                data : action.payload.data.data,
                responseDefault: false,
                loading: false,
                success: true,
            };

            case 'UPDATE_GLOBAL_REGIONS':
                return {
                    ...state,
                    data : action.payload,
                    responseDefault: false,
                    loading: false,
                    success: true,
                };

        case 'GET_REGIONS_FAILURE':
            return {
                    ...state,
                    data: {},
                    error: true,
                    message : action.payload.response.data.message,
                    userMessage: action.payload.response.data['usermessage'],
                    responseDefault: false,
                    loading: false,
                    success: false,
                }
                ;

        default:

            return state;

    }

};