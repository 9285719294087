export default (
    state = {
        payload: {
            APAC: {
                'ANZ': {
                    pageType: 'Playbooks',
                    region: 'APAC',
                    subRegion: 'ANZ',
                },
                'ASEAN': {
                    pageType: 'Playbooks',
                    region: 'APAC',
                    subRegion: 'ASEAN',
                },
                'HKTW': {
                    pageType: 'Playbooks',
                    region: 'APAC',
                    subRegion: 'HKTW',
                },
                'INDIA': {
                    pageType: 'Playbooks',
                    region: 'APAC',
                    subRegion: 'INDIA',
                },
                'CHINA': {
                    pageType: 'Playbooks',
                    region: 'APAC',
                    subRegion: 'CHINA',
                },
                'KOREA': {
                    pageType: 'Playbooks',
                    region: 'APAC',
                    subRegion: 'KOREA',
                },
            },
            'EMEA': {
                pageType: 'Playbooks',
                region: 'EMEA',
                subRegion: null,
            },
            'KCNA': {
                pageType: 'Playbooks',
                region: 'KCNA',
                subRegion: null,
            },
            LAO: {
                'BRAZIL': {
                    pageType: 'Playbooks',
                    region: 'LAO',
                    subRegion: 'BRAZIL',
                },
                'SLAO': {
                    pageType: 'Playbooks',
                    region: 'LAO',
                    subRegion: 'SLAO',
                },
                'NLAO': {
                    pageType: 'Playbooks',
                    region: 'LAO',
                    subRegion: 'NLAO',
                },
            }
        }
    }, action) => {

    switch (action.type) {

        case 'PLAYBOOK_REGION_VALIDATE':
            return action.payload;

        default:

            return state;

    }

};