export default (
    state = {
        loading: false,
        defaultResponse: true,
        Success: false
    },
    action) => {

    switch (action.type) {

        case 'ADD_NEW_RESOURCES_REQUEST':
            return {
                ...state,
                loading: true,
                defaultResponse: true,
                Success: false
            }

        case 'ADD_NEW_RESOURCES_SUCCESS':
            return {
                ...state,
                Success: action.payload.Success,
                message: action.payload.message,
                loading: false,
                defaultResponse: false,
            };

        case 'ADD_NEW_RESOURCES_FAILURE':
            return {
                ...state,
                loading: false,
                Success: false,
                defaultResponse: false,
                error: true,
                message: action.payload.response.data.message,
                userMessage: action.payload.response.data['usermessage'],
            };

        default:

            return state;

    }

};