import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  title: {
    textAlign: "justify",
    fontFamily: "Gilroy-Medium",
    fontSize: 16,
    fontWeight: 600
  },
  margin: {
    margin: "0 20px",
  },
}));
export { useStyles };
