import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 0,
  },
  paper: {
    padding: theme.spacing(0.3, 0),
    width: "100%",
    height: "auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    boxShadow: "none",
    background: "#f4f5f6",
  },
  flexContainer: {
    height: "100%",
    width: "100%",
  },
  documentation: {
    width: "50%",
    textAlign: "justify",
    flex: 4,
    "& h5": {
      paddingLeft: "7.5rem",
    },
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "end",
  },
  header: {
    textAlign: "justify",
    paddingLeft: "7rem",
  },
  editorTitle: {
    textAlign: "initial",
    fontSize: "2rem",
    marginBottom: "10px",
    marginleft: "2rem",
  },
  title: {
    flexGrow: "0.2",
  },
  release: {
    flexGrow: "1",
  },
  sidebar: {
    height: "78vh",
    maxHeight: "78vh",
    overflowY: "scroll",
  },
  alignMent: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
  leftAlignMent: {
    display: "block",
    justifyContent: "start",
    flexDirection: "column",
  },
  blockAlignment: {
    display: "block",
  },
  releaseNotesData: {
    textAlign: "left",
    marginLeft: 0,
    marginTop: 1,
  },
  menuSection: {
    marginLeft: "0rem",
    borderRight: "1px solid #d3d3d3",
  },
  rightAlignment: {
    display: "flex",
    justifyContent: "right",
    flexDirection: "column",
  },
  emptyReleaseData: {
    lineHeight: 2,
    justifyContent: "center",
    display: "block",
    verticalAlign: "middle",
    position: "absolute",
    top: "50%",
    right: "30%",
  },
  fontStyle: {
    fontSize: 15,
    fontWeight: 600,
    fontStyle: "normal",
    fontKerning: "inherit",
    fontFeatureSettings: "initial",
    color: "#555",
  },
  ml05: {
    marginLeft: "0.5rem",
  },
  loaderPosition: {
    position: "relative",

    top: "50%"
  },
  editor: {
    textAlign: "left",
  },
  editorInnerhtml:{
    background: '#ffffff',
    padding: '20px',
    textAlign: 'justify'
  },
  EditorData:{
    textAlign: 'left',
  },

}));

export { useStyles };
