export default (state = {}, action) => {

    switch (action.type) {

    case 'UPDATE_ACTIVE_SUBMENU_LIST':
    return action.payload;

    default:

    return state;

    }

    };