import React, { useState } from "react";
import { useSelector } from "react-redux";
import MenuItem from '@material-ui/core/MenuItem';
import IconMenuItem from '../../component/IconButton';
import Modal from "../../component/modal";
import ExportFile from "./exportFile";

const Export = (props) => {
    const { playBookData, subRegion  } = props;

    const { activeRegionSpecificConfigs, activeRegion } = useSelector((state) => state.activeDynamicData);

    const pbiReport = useSelector((state) => state.PowerBIReportPageSection);
    const [open, setExportOpen] = useState(false);
    const [exportType, setExportType] = useState('PDF');
    const [closeAnchorEl, setCloseAnchorEl] = useState(false);

    const handleModalClose = () =>{
        setExportOpen(false);
        setCloseAnchorEl(false);
    }

    function renderExportFileModal() {
        return (
            <Modal
                open={open}
                bodyElement={
                    <ExportFile
                        hideSettingsWindow={(e) => handleModalClose(e)}
                        playBookData={playBookData}
                        pageType={activeRegionSpecificConfigs['key']}
                        region={activeRegion}
                        exportType={exportType}
                        pbiReport={pbiReport}
                        subRegion={subRegion}
                    />
                }
                title={'Export'}
                size={'md'}
                hideSettingsWindow={handleModalClose}
            />
        )
    };

    const ExportData = (val) => {
        setExportOpen(true);
        setExportType(val);
        setCloseAnchorEl(true);
    };

    const renderMenuItem = () => {
        return (
            <span>
                <MenuItem onClick={() => ExportData('PDF')}>{'PDF'}</MenuItem>
                <MenuItem onClick={() => ExportData('PPTX')}>{'Power Point'}</MenuItem>
            </span>
        )
    };
    
    return (
        <div>
            {renderExportFileModal()}
            <IconMenuItem
                ariaLabel={'Power Bi Report Export Feature'}
                TypoLabel={'Export'}
                fontSize={'1.1rem'}
                menuId={'menu-appbar'}
                menuItem={renderMenuItem()}
                prefixIcon={false}
                closeAnchorEl={closeAnchorEl}
                disabled={!!pbiReport && Object.keys(pbiReport).length === 0 ? true : false }
                ExpandMoreOutlined={false}
            />
        </div>
    );
}
export default Export;