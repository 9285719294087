import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    width: 105,
    borderBottom: '1px solid'
  },
}));

export default function TimePickers(props) {
  const classes = useStyles();

  let { type, startTime, endTime, handleTimeChange, } = props;

  const onChangehandleTime = (e, type) => {
    let date = e.target.value;
    handleTimeChange(date, type);
  };

  return (
    <div className={classes.container} noValidate>
      <TextField
        id="time"
        type="time"
        required
        defaultValue={type === "Start Time" ? startTime : endTime}
        className={classes.textField}
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{
          step: 3600,
          inputProps: { min: startTime  },
          disableMinutes:true
        }}
        onChange={(e) => onChangehandleTime(e, type)}
      />
    </div>
  );
}