import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import BarLoader from "react-spinners/BarLoader";
import Modal from "../../component/modal";
import PreviewDashboard from './previewDashboard';
import ViewBuilder from './ViewBuilder';
import DashboardBasicDetails from './DashboardBasicDetails';
import { LeftArrowIcon, EditIcon } from '../../assets/icons/Icons';
import {
    updateJsonForView,
    getSelectedView,
    deleteDashboard,
    getAllPagesForAdmin,
    updateSelectedView,
    saveViewBuilder,
    updateCustomizedViewBuilderJSON,
    getEmbedToken,
    updateCustomizedHtmlTemp
} from '../../redux/actions';
import Alert from '../../component/Alert/index';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import utils from '../../utils/index';

class DashboardBuilder extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            showTablesModal: false,
            selectedTab: "Preview",
            showSettingsWindow: false,
            sidebarOptions: [
                { icon: "fas fa-info-circle", title: "Basic Details" },
                { icon: "fas fa-layer-group", title: "Page Builder" },
                { icon: "far fa-image", title: "Preview" }
            ],
            viewType: 'Desktop',
            tablename: "",
            tables: [],
            modified: false,
            showAlert: false,
            reportType: {
                msalEnabled: false,
                NonRLS: true,
                RLS: false
            }
        }
    };

    //lifecycle methods
    componentDidMount() {
        const viewId = localStorage.getItem("viewId");
        const state = this.props.history.location.state;
        let region = state['region']
        this.props.getSelectedViewAPI({ viewId: viewId, region: region });
    };

    componentDidUpdate(prevProps, prevState) {
        if (this.props.viewBuilder !== prevProps.viewBuilder) {
            this.setState({ viewBuilder: this.props.viewBuilder });
            let { viewBuilderJSON } = this.props.viewBuilder;
            let { activeMsalToken } = this.props;
            let { htmlTemp } = viewBuilderJSON;
            if (Object.keys(viewBuilderJSON).length > 0) {
                const { reportType } = this.state;
                reportType.msalEnabled = viewBuilderJSON.msalEnabled;
                if (!htmlTemp.hasOwnProperty('msalToken')) {
                    htmlTemp['msalToken'] = activeMsalToken ? activeMsalToken : sessionStorage.getItem("msalAccessToken");
                 }
                if (viewBuilderJSON.msalEnabled) {
                    reportType.RLS = true;
                    reportType.NonRLS = false;
                }
                else if (!viewBuilderJSON.msalEnabled) {
                    reportType.RLS = false;
                    reportType.NonRLS = true;
                }
            }
            console.log('ViewReportType', this.state.reportType, viewBuilderJSON, htmlTemp,)
        }
    }

    setSelectedTab = (tab) => {
        this.setState({ selectedTab: tab });
        let { activeMsalToken } = this.props;
        if (tab === "Preview") {
            let { viewBuilderJSON } = this.state.viewBuilder;
            if (this.state.viewBuilder.hasOwnProperty("viewBuilderJSON") && this.state.viewBuilder.viewBuilderJSON.hasOwnProperty("htmlTemp")) {
                viewBuilderJSON['msalEnabled'] = this.state.reportType.msalEnabled;
                console.log('SelectedPreviewReport', viewBuilderJSON, this.state.reportType.msalEnabled);
                let { htmlTemp } = this.state.viewBuilder.viewBuilderJSON
                const element = !!htmlTemp && Object.keys(htmlTemp).length > 0 && Object.keys(htmlTemp).filter(key => htmlTemp[key] && htmlTemp[key].type === "dashboard");
                console.log('PreviewDashboard-TokenParams', 111, element)
                if (this.state.reportType.msalEnabled && element) {
                    htmlTemp[element]['msalToken'] = activeMsalToken ? activeMsalToken : sessionStorage.getItem("msalAccessToken");
                }
                else if (this.state.reportType.msalEnabled && !element) {
                    htmlTemp['msalToken'] = activeMsalToken ? activeMsalToken : sessionStorage.getItem("msalAccessToken");
                }
                else if (!this.state.reportType.msalEnabled) {
                    const groupId = htmlTemp[element].groupId;
                    const reportId = htmlTemp[element].reportId;
                    if (!!groupId && !!reportId) {
                        const params = {
                            groupId: groupId,
                            reportId: reportId
                        }
                        this.props.getReportEmbedToken(params);
                    }
                }

            };
            this.props.updateViewBuilderJSONAPI(viewBuilderJSON);
            this.props.updateCustomizedHtmlTempAPI(viewBuilderJSON);
        }
    }

    renderSettingsWindow() {
        const { viewBuilder } = this.state;
        const state = this.props.history.location.state;
        let region = state['region']
        return (
            <Modal
                open={this.state.showSettingsWindow}
                bodyElement={
                    <DashboardBasicDetails
                        hideSettingsWindow={(e) => { this.setState({ showSettingsWindow: false }) }}
                        viewBuilderJSON={viewBuilder.viewBuilderJSON}
                        region={region}
                        status={viewBuilder.viewBuilderJSON.viewStatus}
                    />
                }
                hideSettingsWindow={(e) => { this.setState({ showSettingsWindow: false }) }}
                title={'Edit Dashboard'}
                size={"md"}
            />
        )
    };

    renderDashboardTab() {
        if (this.state.selectedTab === "Page Builder") {
            return (
                <ViewBuilder windowType={this.state.viewType} />
            )
        }
        else if (this.state.selectedTab === "Preview") {
            return (
                <PreviewDashboard pageType={'Dashboard'} />
            )
        }
    };

    updateReportEmbedType = (bol) => {
        const { reportType } = this.state;
        let { activeMsalToken } = this.props;
        reportType.msalEnabled = bol;
        if (!bol) {
            reportType.NonRLS = !bol;
            reportType.RLS = bol;

        }
        else if (!!bol) {
            reportType.RLS = bol;
            reportType.NonRLS = !bol;

        }
        this.setState({ reportType });
        if (this.state.viewBuilder.hasOwnProperty("viewBuilderJSON")) {
            let { viewBuilderJSON } = this.state.viewBuilder;
            viewBuilderJSON['msalEnabled'] = bol;
            let { htmlTemp } = this.state.viewBuilder.viewBuilderJSON
            const element = !!htmlTemp && Object.keys(htmlTemp).length > 0 && Object.keys(htmlTemp).filter(key => htmlTemp[key] && htmlTemp[key].type === "dashboard");
            console.log('updateReportEmbedType', bol, 'element', element, viewBuilderJSON, htmlTemp)
            if (bol && Array.isArray(element) && element.length > 0) {
                console.log('updateReportEmbedType', 111, bol, reportType,)
                htmlTemp[element]['msalToken'] = activeMsalToken ? activeMsalToken : sessionStorage.getItem("msalAccessToken");
            }
            else if (bol && Array.isArray(element) && element.length === 0) {
                console.log('updateReportEmbedType', 222, bol, reportType,)
                htmlTemp['msalToken'] = activeMsalToken ? activeMsalToken : sessionStorage.getItem("msalAccessToken");
            }
            else if (this.state.reportType.msalEnabled && !element) {
                console.log('updateReportEmbedType', 333, bol, reportType,)
                htmlTemp['msalToken'] = activeMsalToken ? activeMsalToken : sessionStorage.getItem("msalAccessToken");
            }
            this.props.updateViewBuilderJSONAPI(viewBuilderJSON);
            this.props.updateCustomizedHtmlTempAPI(viewBuilderJSON);

            console.log('updateReportEmbedType', 444, bol, reportType, viewBuilderJSON, htmlTemp)
        }
    }

    handleSavingJson = () => {
        const state = this.props.history.location.state;
        let region = state['region']
        this.setState({ loading: true });
        let { viewBuilderJSON } = this.state.viewBuilder;
        let validation = {};
        if (!!viewBuilderJSON && viewBuilderJSON.hasOwnProperty('htmlTemp')) {
            let keys = Object.keys(!!viewBuilderJSON && viewBuilderJSON.htmlTemp);
            keys.map(item => {
                // if (!!viewBuilderJSON && viewBuilderJSON.htmlTemp[item].type !== "dashboard") {
                //     this.setState({
                //         showAlert: true,
                //         loading: false,
                //         validation : validation
                //     });
                //     validation.reportId = '';
                //     validation.groupId = '';
                //     validation.validate = false;
                //     validation.message = 'Please Embed a Power BI Report';
                // }
                // else
                if (!!viewBuilderJSON && viewBuilderJSON.htmlTemp[item].type === "dashboard") {
                    const reportId = viewBuilderJSON.htmlTemp[item].reportId;
                    const groupId = viewBuilderJSON.htmlTemp[item].groupId;
                    if (reportId === '' && groupId === '') {
                        validation.validate = false;
                        validation.reportId = '';
                        validation.groupId = '';
                        validation.message = 'Please Enter Report Group & Report Id';
                        this.setState({ showAlert: true, validation: validation });
                    }
                    else if (reportId && groupId) {
                        validation.validate = true;
                        validation.reportId = viewBuilderJSON.htmlTemp[item].reportId;
                        validation.groupId = viewBuilderJSON.htmlTemp[item].groupId;
                        validation.message = 'Report Id Received';
                        this.setState({ showAlert: false, validation: validation });
                    }
                }
                else if (!!viewBuilderJSON && viewBuilderJSON.htmlTemp) {
                    const reportId = viewBuilderJSON.htmlTemp.reportId;
                    const groupId = viewBuilderJSON.htmlTemp.groupId;
                    if (reportId === '' && groupId === '') {
                        validation.validate = false;
                        validation.reportId = '';
                        validation.groupId = '';
                        validation.message = 'Please Enter Report Group & Report Id';
                        this.setState({ showAlert: true, validation: validation });
                    }
                }
                return viewBuilderJSON;
            });
            console.log('validatingViewBuilder', validation.validate);
            if (validation.validate) {
                const reportId = validation.reportId;
                const groupId = validation.groupId;
                this.setState({ showAlert: false });
                let obj = {
                    viewId: viewBuilderJSON.viewId,
                    htmlTemp: viewBuilderJSON.htmlTemp,
                    region: region,
                    viewCreated: 'viewBuilder',
                    msalEnabled: this.state.reportType.msalEnabled,
                    viewName: viewBuilderJSON.viewName,
                }
                console.log('validatingViewBuilder', validation, obj, viewBuilderJSON);
                this.props.updateJsonForViewAPI(obj, (res, err) => {
                    if (res) {
                        this.props.getSelectedViewAPI({ viewId: viewBuilderJSON.viewId, region: region });
                        toast.success(res.data.message, { position: toast.POSITION.RIGHT_TOP });
                        this.setState({ loading: false });
                        let trackingObject = {
                            region: region,
                            actionType: 'Admin Activity',
                            pageName: 'Dashboard',
                            reportName: viewBuilderJSON.viewName,
                            pageDetails: JSON.stringify(obj),
                            reportId: reportId,
                            groupId: groupId,
                            attributeName: 'Dashboard Report Embbeded'
                        };
                        utils.userTrackingInfo(trackingObject);
                    } else if (err) {
                        toast.error(err.response.data.message, { position: toast.POSITION.RIGHT_TOP });
                        this.setState({ loading: false });
                    }
                });
            }
        }
    };

    renderLoader() {
        if (this.state.loading) {
            return (
                <div className="loader-wraper">
                    <BarLoader
                        size={150}
                        color={"#123abc"}
                        height={4}
                        width={100}
                        loading={this.state.loading}
                    />
                </div>
            )
        }
    };

    deleteDashboard() {
        if (Object.keys(this.state.viewBuilder).length > 0) {
            if (window.confirm(`Are you sure, you want to delete Dashboard -  ${this.state.viewBuilder.viewBuilderJSON.viewName}  ?`)) {
                this.setState({ loading: true })
                this.props.deleteDashboardAPI({ viewName: this.state.viewBuilder.viewBuilderJSON.viewName, viewId: this.state.viewBuilder.viewBuilderJSON.viewId }, (res, err) => {
                    this.props.getAllPagesForAdminAPI({});
                    this.props.history.push(`/${this.props.project}/${this.props.project}/${this.props.activeRoutes.key7}`)
                }
                ).catch(function (errors) {
                    this.setState({ loading: false })
                    toast.error(errors, { position: toast.POSITION.TOP_CENTER })
                });
            }
        }
    };

    discardChanges() {
        if (Object.keys(this.state.viewBuilder).length > 0) {
            if (window.confirm(`Are you sure, you want to discard unsaved change(s)`)) {
                this.props.history.push(`/${this.props.project}/${this.props.subproject}/${this.props.activeRoutes.key7}`)
            }
        }
    };

    redirectToAdmin = () => {
        const state = this.props.history.location.state;
        this.props.history.push({
            pathname: this.props.activeRoutes.key7,
            state: state
        });

    };

    handleModalClose = () => {
        this.setState({
            showAlert: false
        })
    };

    render() {
        const { viewBuilder, validation, showAlert } = this.state;
        const userMessage = !!viewBuilder && viewBuilder.userMessage;
        const message = !!viewBuilder && viewBuilder.message;
        const alertMessage = validation && validation.message;
        return (
            <div>
                <ToastContainer />
                <Alert
                    open={showAlert}
                    message={alertMessage}
                    handleClose={this.handleModalClose}
                    alertType={'Warning'}
                />
                <div className='view_builder_default'>
                    <div className="container-fluid pl-5 pr-5 px-0 height_max">
                        <div className="col-12 sections height_100" id="builder_screen_header">
                            <div className="row">

                                {!!viewBuilder && viewBuilder.viewBuilderJSON && !viewBuilder.error ?
                                    <div className="col-12">
                                        <div className="col-12 py-2 d-flex justify-content-between">
                                            <div onClick={this.redirectToAdmin} className="projectName tile-new"><LeftArrowIcon /> Dashboards :  {viewBuilder.viewBuilderJSON.viewName}  </div>
                                            <div><button className="btn btn-apply" onClick={(e) => {
                                                this.setState({ showSettingsWindow: true });
                                            }}> <EditIcon /> Edit</button></div>
                                        </div>
                                        <div className="col-12 py-2 ">
                                            <div className="row">
                                                <div className="col-1">
                                                    {/* <button
                                                        onClick={() => this.setSelectedTab('Page Builder')} className={`btn btn-cta ${this.state.selectedTab === 'Page Builder' ? "active-view" : null}`}
                                                    >Layout</button> */}
                                                    <button
                                                        onClick={() => this.setSelectedTab('Preview')} className={`btn btn-cta tile-new ${this.state.selectedTab === 'Preview' ? "active-view" : null}`}
                                                    >Preview</button>
                                                </div>
                                                <div className="col-11 bg-white d-flex justify-content-between align-items-center">
                                                    <div>
                                                        <span className="bold mr-3 tile-new">Details : </span>
                                                        <span className="px-4 tile-new">Category : {viewBuilder.viewBuilderJSON.buType}</span>
                                                        <span className="px-4 tile-new">Grouped Report : {viewBuilder.viewBuilderJSON.grouped ? 'Yes' : 'No'}</span>
                                                        <span className="px-4 tile-new">Status : {viewBuilder.viewBuilderJSON.viewStatus}</span>
                                                        <span className="px-4 tile-new">Workspace : {viewBuilder.viewBuilderJSON.htmlTemp && viewBuilder.viewBuilderJSON.htmlTemp.workspace}</span>
                                                        <span className="px-4 tile-new">Report Type :
                                                            <input className='px-radio tile-new' type={'radio'} name="reportType" checked={this.state.reportType.NonRLS} onChange={() => this.updateReportEmbedType(false)} /> <label>Non RLS</label>
                                                            <input className='px-radio tile-new' type={'radio'} name="reportType" checked={this.state.reportType.RLS} onChange={() => this.updateReportEmbedType(true)} /> <label>RLS</label>
                                                        </span>
                                                    </div>
                                                    {/* <div style={{ display: 'none' }}>
                                                        <button className="btn btn-cta"
                                                            onClick={(e) => {
                                                                this.setState({ showTablesModal: true });
                                                            }}> <ServerIcon /> Edit Tables</button>
                                                        <button className="btn btn-cta" onClick={(e) => {
                                                            this.setState({ showSettingsWindow: true });
                                                        }}> <EditIcon /> Edit</button>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <h5> {!!userMessage ? userMessage : message} </h5>
                                }
                            </div>
                            {!!viewBuilder && viewBuilder.viewBuilderJSON && !viewBuilder.error ?
                                <div className="col-12 py-2 px-0 builder_window height_100">
                                    {this.renderLoader()}
                                    {this.renderSettingsWindow()}
                                    {this.renderDashboardTab()}
                                </div>
                                : null
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

function mapStateToProps(state) {
    return ({
        viewBuilder: state.viewBuilderObj,
        selectedRegion: state.getBusinessUnitsRegion,
        activeRoutes: state.activeDynamicData.activeRoutes,
        activeMsalToken: state.activeDynamicData.activeMsalToken,
        activeRegionSpecificConfigs: state.activeRegionSpecificConfigs,

    })
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        updateJsonForViewAPI: updateJsonForView,
        getSelectedViewAPI: getSelectedView,
        deleteDashboardAPI: deleteDashboard,
        getAllPagesForAdminAPI: getAllPagesForAdmin,
        updateSelectedViewAPI: updateSelectedView,
        saveViewBuilder: saveViewBuilder,
        updateViewBuilderJSONAPI: updateCustomizedViewBuilderJSON,
        updateCustomizedHtmlTempAPI: updateCustomizedHtmlTemp,
        getReportEmbedToken: getEmbedToken
    }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardBuilder);






