import React from "react";
import { useEffect , useState } from "react";

import { useSelector } from "react-redux";

export default function PageNotFound() {

    const {  activeRegion} = useSelector((state) => state.activeDynamicData);
   const pathName = window.location.pathname;

    const [pageName, setPageName] = useState('');

    useEffect(()=>{
        let activeScreenPath = pathName !== '/' ? pathName.split('/') : null;
        let length = Array.isArray(activeScreenPath) && activeScreenPath.length;
        let parentPath =  length === 3  ? activeScreenPath[length-2] : null;
        let childPath = length === 3  ? activeScreenPath[length-1] : null;
        let childPageName =  childPath ? childPath.split('-') : null;
        let clength = Array.isArray(childPageName) && childPageName.length;
        let childPathName = clength >= 2  ? childPageName[0].charAt(0).toUpperCase() + childPageName[0].slice(1)  + ' ' + childPageName[1].charAt(0).toUpperCase() + childPageName[1].slice(1)  : childPath;
        let activePage = parentPath.charAt(0).toUpperCase() + parentPath.slice(1)  + ' ' +  childPathName  ;
        setPageName(activePage);
    },[pathName]);

    return (
        <div>
            <div style={{ fontSize: '20px', textAlign: 'center', marginTop: '20rem' }}>
                {pageName} is not applicable for  {activeRegion} Region
            </div>
        </div>
    );
}