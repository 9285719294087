import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import cloneDeep from 'lodash/cloneDeep';
import utils from '../../utils/index';
import { addNewParentModule, updateParentModule, toastTriggered, getRegions } from '../../redux/actions';
import BarLoader from "react-spinners/BarLoader";
import toastProperties from "../../assets/data/toastProperty";

const ParentModule = (props) => {

    const dispatch = useDispatch();

    const { activeBusinessUnit, activeRegion, } = useSelector((state) => state.activeDynamicData);
    const RegionMappingData = useSelector((state) => state.getRegionMappingData);
    const parentMappedIds = useSelector((state) => state.parentBuMapped);
    const childParentMapping = useSelector((state) => state.childParentMapping);


    const [formInput, setFormInput] = useState({});
    const [title, setTitle] = useState('');
    const [collapseText, setCollapseText] = useState('');
    const [image, setImage] = useState('');
    const [background, setBackground] = useState('');
    const [customMessage, setCustomMessage] = useState({});
    const [reportEmbed, setReportEmbed] = useState(true);
    const [moduleEmbed, setModuleEmbed] = useState(false);
    
    const { moduleData, closeDailog } = props;

    useEffect(() => {
        const { parentModuleData } = moduleData;
        if (parentModuleData && Object.keys(parentModuleData).length > 0) {
            setTitle(parentModuleData.title);
            setImage(parentModuleData.image);
            setCollapseText(parentModuleData.collapseText);
            setBackground(parentModuleData.background);
        }
    }, [formInput, moduleData]);

    const UpdateFormInput = (e) => {
        let data = activeBusinessUnit;

        var name = e.target.name;
        var value = e.target.value;
        if (name === 'title') {
            setTitle(value);
            formInput['key'] = value;
            formInput['title'] = value;
            formInput['parentModule'] = value;
            formInput['contentkey']  = value;
        }
        else if (name === 'collapseText') {
            setCollapseText(value);
            formInput['collapse'] = false;
            formInput['collapseText'] = value;
            formInput['description'] = value;
        }
        else if (name === 'image') {
            setImage(value)
            formInput['image'] = value;
        }
        else if (name === 'background') {
            setBackground(value)
            formInput['background'] = value;
        }
        formInput['region'] = activeRegion;
        formInput['parentOrder'] = data.length + 1;
        formInput['viewStatus'] = 'Draft';
        formInput['accessRestriction'] = false;
        formInput['contentType'] = 'module';
        setFormInput(formInput)
    };

    
    const onhandleCheckbox = (e) => {
        var name = e.target.name;
        var value = e.target.checked;
        if (name === 'report') {
            setReportEmbed(value);
            setModuleEmbed(false);
            formInput['contentType'] = 'subReport';
            setFormInput(formInput);
        }
        else if (name === 'module') {
            setReportEmbed(false);
            setModuleEmbed(value);
            formInput['contentType'] = 'module';
            setFormInput(formInput);
        }
    }

    const trackEvents = (title, type) => {
        utils.userTrackingInfo({
            region: activeRegion,
            actionType: 'Landing Page Admin Activity',
            pageName: 'Admin Dashboard',
            reportName: title,
            pageDetails: type,
        });
    }

    const saveParentModuleDetails = () => {
        const parentId = [];
        const { parentModuleData } = moduleData;
        const { data } = RegionMappingData;
        const { parentBuMapping } = parentMappedIds;
        const { childParentIds } = childParentMapping;
        let customObj = {};
        customObj.loading = true;
        setCustomMessage(customObj);
        if (Array.isArray(data) && data.length > 0) {
            let usergroup = data.filter((element) => element.userGroup.includes(process.env.REACT_APP_OKTA_ENV));
            let uniqueIds = utils.uniqueAdGroup(usergroup, 'userGroup')
            formInput['regionBuId'] = uniqueIds;
            uniqueIds.forEach((el) => {
                if (Array.isArray(parentBuMapping) && parentBuMapping.length > 0) {
                    let parentModule = parentBuMapping.find((parent) => parent.regionBuId === el.regionBuId);
                    el.parentId = parentModule && parentModule.parentId;
                    let childModule = childParentIds.find((child) => child.parentId === el.parentId);
                    el.childParentModule = childModule && childModule.parentModule;
                }
            })
            if (Array.isArray(parentBuMapping) && parentBuMapping.length >= 4) {
                parentBuMapping.forEach((el) => {
                    parentId.push(el.parentId)
                    let childModule = childParentIds.find((child) => child.parentId === el.parentId);
                    el.childParentModule = childModule && childModule.parentModule;
                    formInput['childParentModule'] = childModule && childModule.parentModule;
                })
            }
        }
        if (title === '' && collapseText === '' && image === '' && background === '') {
            customObj.error = true;
            customObj.customMessage = `All Input value Can't be Empty, Please Enter the Value`;
            setCustomMessage(customObj);
        }
        else if (title === '') {
            customObj.error = true;
            customObj.customMessage = `Title value Can't be Empty, Please Enter the Value`;
            setCustomMessage(customObj);
        }
        else if (collapseText === '') {
            customObj.error = true;
            customObj.customMessage = `Description value Can't be Empty, Please Enter the Value`;
            setCustomMessage(customObj);
        }
        else if (image === '') {
            customObj.error = true;
            customObj.customMessage = `Image value Can't be Empty, Please Enter the Value`;
            setCustomMessage(customObj);
        }
        else if (background === '') {
            customObj.error = true;
            customObj.customMessage = `background value Can't be Empty, Please Enter the Value`;
            setCustomMessage(customObj);
        }
        else if (parentModuleData.parentId) {
            formInput['parentId'] = parentId;
            formInput['key'] = title;
            formInput['title'] = title;
            formInput['parentModule'] = parentModuleData.parentModule;;
            formInput['collapse'] = false;
            formInput['collapseText'] = collapseText;
            formInput['description'] = collapseText;
            formInput['image'] = image;
            formInput['background'] = background;
            formInput['region'] = activeRegion;
            formInput['parentOrder'] = parentModuleData.order;
            formInput['viewStatus'] = parentModuleData.viewStatus;
            formInput['accessRestriction'] = false;
            formInput['contentkey'] = parentModuleData.contentkey; 
            dispatch(updateParentModule(formInput))
                .then((res) => {
                    toastProperties.success["description"] = res.data.message;
                    dispatch(toastTriggered([toastProperties.success]));
                    dispatch(getRegions());
                    closeDailog(true);
                    customObj.loading = false;
                    setCustomMessage(customObj);
                    trackEvents(title, `${title} module created for ${activeRegion}`)
                })
                .catch(() => {
                    toastProperties.error["description"] = `There is some problem while updating ${title} Child module in region ${activeRegion}. Please try again in some time.`;
                    dispatch(toastTriggered([toastProperties.error]));
                    closeDailog(true);
                    customObj.loading = false;
                    setCustomMessage(customObj);
                });
        }
        else {
            dispatch(addNewParentModule(formInput)).then((res) => {
                toastProperties.success["description"] = res.data.message;
                dispatch(toastTriggered([toastProperties.success]));
                dispatch(getRegions());
                closeDailog(true);
                customObj.loading = false;
                setCustomMessage(customObj);
                trackEvents(title, `${title} module created for ${activeRegion}`)
            })
                .catch(() => {
                    toastProperties.error["description"] = 'There is some error creating a new parent module, Please try again in sometime.';
                    dispatch(toastTriggered([toastProperties.error]));
                    closeDailog(true)
                    setCustomMessage(customObj);
                    customObj.loading = false;
                    setCustomMessage(customObj);
                });
        }
    };

    const renderEventMessage = () => {
        if (customMessage.loading) {
            return (
                <div className="waiting_Loader">
                    <div className="prettier">Please wait...</div>
                    <BarLoader
                        size={150}
                        color={"#123abc"}
                        height={4}
                        width={100}
                        loading={true}
                    />
                </div>
            )
        }
        else if (customMessage.error) {
            return (
                <div className="col-12  col-xl-12 flex mt-0" style={{ justifyContent: "flex-start" }}>
                    <div className="col-12  col-xl-12 error"> Error : {customMessage.customMessage}</div>
                </div>
            )
        }
    }

    const renderModuleContentType = () => {
        return (
            <div className="col-12  col-xl-12 form-group tile-new">
                <input className='px-radio' type={'checkbox'} name="report" value={reportEmbed} checked={reportEmbed} onChange={(e) => onhandleCheckbox(e)} />  Sub Report 
                <br></br>
                <input className='px-radio' type={'checkbox'} name="module" value={moduleEmbed} checked={moduleEmbed} onChange={(e) => onhandleCheckbox(e)} />  New Module ( Report Embedding / Links)
            </div>
        )
    };

    return (
        <div className="container">
            <div className="col-12 sections my-2" id="basic_screen">
                <form className="form">
                    <div className="row">
                        <div className="col-12  col-xl-12 form-group tile-new">
                            <label className="tile-new">
                                Module Name
                            </label>
                            <input value={title} name={'title'} type="text" className="form-control tile-new" placeholder="Enter the module name" onChange={(e) => UpdateFormInput(e)} />
                        </div>
                        <div className="col-12  col-xl-12 form-group tile-new">
                            <label className="tile-new">
                                Module Description <small>(This will not be shown in the modules)</small>
                            </label>
                            <input value={collapseText} name={'collapseText'} type="text" className="form-control tile-new" placeholder="Enter short description for the module." checked={true} onChange={(e) => UpdateFormInput(e)} />
                        </div>

                        <div className="col-12  col-xl-12 form-group tile-new">
                            <label className="">
                                Image Link <small>(Ensure the Image link has http(s) included)</small>
                            </label>
                            <input value={image} name={'image'} type="text" className="form-control tile-new" placeholder="Enter the image link" onChange={(e) => UpdateFormInput(e)} />
                        </div>


                        <div className="col-12  col-xl-12 form-group tile-new">
                            <label className="">
                                Background Color
                            </label>
                            <input value={background} name={'background'} type="text" className="form-control tile-new" placeholder="Enter the parent background color" onChange={(e) => UpdateFormInput(e)} />
                        </div>

                        <div className="col-12  col-xl-12 form-group tile-new">
                            <label className="">
                                Content Type
                            </label>
                            {renderModuleContentType()}
                         </div>


                        {renderEventMessage()}

                        <div className="col-12  col-xl-12 form-group  flex mt-0" style={{ justifyContent: "flex-end" }}>
                            <button type="button" className="btn btn-apply" onClick={(e) => { e.preventDefault(); saveParentModuleDetails() }}>Save</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default ParentModule;

