import React, { useEffect, useState  } from "react";
import { useDispatch, useSelector } from "react-redux";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import {
    getReportContent,
    updateCustomizedViewBuilderJSON,
    updateActivePage
} from '../../redux/actions/index';
import Report from '../reports/report';
import DefaultReport from '../reports/defaultReport';
import { useStyles } from "./style.js";
import cloneDeep from 'lodash/cloneDeep';
import CircularProgress from '@material-ui/core/CircularProgress';
import './style.css';

const ControlTower = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();


    const { activeMsalToken, activeRegion, activePage } = useSelector((state) => state.activeDynamicData);
    const errorMessage = useSelector((state) => state.getErrorMessages);
    const { activeIndex, activeSubMenu, activeSubMenuIndex, activeSubMenuHtmlTemp, activeSubMenuName, } = props;

    const reportContent = useSelector((state) => state.getReportContent);

    const activeReport = useSelector((state) => state.activePBIReport);

    const [noReports, setNoReport] = React.useState(null);
    const [navContentPaneEnabled, SetNavContentPaneEnabled] = useState(true);

    
    useEffect(() => {
        let selectedRegion; 
        if (activeRegion && activePage) {
            if(activePage.description.startsWith('Global')){
                selectedRegion = "Global"
            }
            else{
                selectedRegion = activeRegion;
            }
            const params = {
                pageType: activePage.key,
                region: selectedRegion   
            }
            dispatch(getReportContent(params));
        }
    }, [activePage, activeRegion, dispatch]);

    useEffect(() => {
        let page =  JSON.parse(sessionStorage.getItem('ActivePage'));
        if (Object.keys(activePage).length === 0) {
            dispatch(updateActivePage(page))
        }
    }, [dispatch, activePage]);

    useEffect(() => {
        const noReport = errorMessage?.data?.find(item => item.errorType === "No Reports Available")
        setNoReport(noReport?.errorMessage)
    }, [errorMessage]);

    useEffect(() => {
        if (Object.keys(activeReport).length > 0) {
            if (activeReport) {
                let { msalEnabled, customizableFlag, grouped, reportPages, } = activeReport;
                let tempViewBuilderObj = cloneDeep(activeReport);
                if (msalEnabled && customizableFlag) {
                    let { htmlTemp } = tempViewBuilderObj;
                    let element = !!htmlTemp && Object.keys(htmlTemp).length > 0 && Object.keys(htmlTemp).filter(key => htmlTemp[key].type === "dashboard");
                    if (!!htmlTemp && htmlTemp !== "NULL") {
                        htmlTemp[element]['msalToken'] = activeMsalToken ? activeMsalToken : sessionStorage.getItem("msalAccessToken");
                    }
                }
                dispatch(updateCustomizedViewBuilderJSON(tempViewBuilderObj));
                if (grouped) {
                    SetNavContentPaneEnabled(true)
                }
                else if (reportPages) {
                    SetNavContentPaneEnabled(false)
                }
           }
        }
        else {
            dispatch(updateCustomizedViewBuilderJSON({}));
        }
    }, [dispatch, activeMsalToken, activeReport]);

    console.log('controlTowerReporys',activeReport , activePage)

    const reportEmbedding = () => {
        let { loading, responseDefault, error } = reportContent;
        if (loading && responseDefault) {
            return (
                <div className={classes.position}>
                    <CircularProgress color="primary" />
                </div>
            )
        }
        else if (!loading && !responseDefault && !!error) {
             return (
                <div className={classes.serverDown}>
                     <div className="noReports"></div>
                    <div className={classes.errorMessage} >{noReports}</div>
                </div>
            )
        }
        if (Object.keys(activeReport).length > 0) {
             if (!activeReport.customizableFlag || (activeReport.htmlTemp.hasOwnProperty('type') && activeReport.htmlTemp.type === "default")) {
                return (
                    <DefaultReport
                        activeIndex={activeIndex}
                        activeSubMenuHtmlTemp={activeSubMenuHtmlTemp}
                        reportSectionId={activeSubMenu}
                        activeSubMenuIndex={activeSubMenuIndex}
                        activeSubMenuName={activeSubMenuName}
                        navContentPaneEnabled={navContentPaneEnabled ? navContentPaneEnabled:  !activeReport.activeReportPage ? true : false}
                    />
                )
            }
            else if (activeReport.customizableFlag) {
                return (
                    <Report
                        navContentPaneEnabled={navContentPaneEnabled ? navContentPaneEnabled:  !activeReport.activeReportPage ? true : false}
                        pageType={'Control Tower'}
                        activeIndex={activeIndex}
                    />
                )
            }
        }
    }

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <Grid container item xs={12}  className={classes.flexContainer}>
                      {reportEmbedding()}
                </Grid>
            </Paper>
        </div>
    );
}
export default ControlTower;