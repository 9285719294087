import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Grid from "@material-ui/core/Grid";
import ListAltOutlinedIcon from '@material-ui/icons/ListAltOutlined';
import EditIcon from "@material-ui/icons/Edit";
import {
  createNewReleaseTitle,
  editEditorData,
  updatesidebarMenuList,
  updateEditorData,
  toastTriggered,
  deleteReleaseNotesFeature,
  updateReleaseNotesFeature,
  fetchReleaseDataList
} from "../../redux/actions/index";
import { useStyles } from "./subMenuStyle";
import { PlusCircleFill } from "../../assets/icons/Icons";
import { CloseIcon, TickIcon } from "../../assets/icons/Icons";
import Operations from "../curdOperations/index";
import DotFlashing from "../../component/DotFlashing/index";
import utils from "../../utils";
import Modal from "../../component/modal";
import toastProperties from "../../assets/data/toastProperty";


const SubMenuList = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const {
    data,
    loading,
    categoryId,
    category,
    releaseVersionID,
    releaseName,
    error,
    region,
    releaseNoteID,
    activeReleaseFeature,
    activeReleaseAlertID,
    Releases
  } = props;

  const [newSubCat, setNewSubCat] = useState(false);
  const [activeMenu, setActiveMenu] = useState("");
  const [newCategory, setNewCategory] = useState("");
  const [open, setOpen] = useState(false);
  const [size, setSize] = useState('md');
  const [newOne, setNewOne] = useState(false);
  const [state, setState] = useState(false);
  const [title, setTitle] = useState('');

  const updatedSidebarMenu = useSelector((state) => state.updatedSidebarMenu);
  const releaseTitle = useSelector((state) => state.newReleaseTitle);
  const oktaAuth = useSelector((state) => state.oktaAuth);
  const { okta } = oktaAuth;

  useEffect(() => {
    const { responseDefault, error } = releaseTitle;
    if (!responseDefault && !!error) {
      utils.toastTriggered(releaseTitle, dispatch, true);
    }
  }, [dispatch, releaseTitle]);

  useEffect(() => {
    if (Array.isArray(data) && data.length > 0) {
      let findSubCat;
      if (activeReleaseFeature) {
        let element = data.find((el) => el.subCategoryId == activeReleaseFeature);
        findSubCat = element && element.subCategory;
      }
      let defaultActiveMenu = !!newCategory ? newCategory : activeReleaseFeature ? findSubCat : data[0].subCategory;
      setActiveMenu(defaultActiveMenu);
      dispatch(updatesidebarMenuList(data));
    } else if (Array.isArray(data) && data.length === 0) {
      dispatch(updatesidebarMenuList(data));
      setActiveMenu("");
    }
  }, [activeReleaseFeature, categoryId, data, dispatch, region, releaseVersionID, activeReleaseAlertID, newCategory]);

  useEffect(() => {
    if (Array.isArray(updatedSidebarMenu) && updatedSidebarMenu.length > 0) {
      let findSubCat;
      if (activeReleaseFeature) {
        let element = updatedSidebarMenu.find((el) => el.subCategoryId == activeReleaseFeature);
        findSubCat = element && element.subCategory;
      }
      let find = !!newCategory ? newCategory : findSubCat;
      let selectedReleaseData = updatedSidebarMenu.find((el) => el.subCategory === find);
      let data1 = selectedReleaseData ? selectedReleaseData.releaseDescription : updatedSidebarMenu[0].releaseDescription;
      let params = {
        innerHtml: data1,
        name: updatedSidebarMenu[0].subCategory,
        category: category,
      };
      dispatch(updateEditorData(params));
    } else if (Array.isArray(updatedSidebarMenu) && updatedSidebarMenu.length === 0) {
      let params = { innerHtml: "", name: "", category: category };
      dispatch(updateEditorData(params));
    }
  }, [categoryId, category, updatedSidebarMenu, dispatch, activeReleaseFeature, newCategory]);

  const handleInputChange = (e) => {
    let val = e.target.value;
    let name = e.target.name;
    setState({ ...state, [name]: val });
  };

  const selectActiveSubMenu = (text) => {
    setActiveMenu(text.subCategory);
    let index = updatedSidebarMenu.findIndex(
      (el) => el.subCategory === text.subCategory
    );
    let data = updatedSidebarMenu[index].releaseDescription;
    let params = {
      innerHtml: data,
      name: updatedSidebarMenu[index].subCategory,
      category: category,
    };
    dispatch(updateEditorData(params));
    let params2 = {
      enableEditor: false,
    };
    dispatch(editEditorData(params2));
    let trackingObject = {
      region: region,
      actionType: 'Click',
      pageName: "Other Feature",
      reportName: "Release Notes",
      reportSection: 'Release Notes Viewed',
      pageDetails: `${text.subCategory}`,
    };
    utils.userTrackingInfo(trackingObject);
  };

  const editReleaseData = (subCategory) => {
    let params = {
      region: region,
      releaseVersionID: releaseVersionID,
      releaseCategoryID: categoryId,
      subCategory: subCategory,
      enableEditor: true,
      releaseName: releaseName,
      releaseNoteID: releaseNoteID,
    };
    dispatch(editEditorData(params));
  };

  const createNewSubCat = () => {
    setNewSubCat(false);
    setOpen(true);
    setSize('md');
    setNewOne(true);
    setTitle('Create New Feature')
  };

  const trackUserActivities = (title) => {
    let trackingObject = {
      region: region,
      actionType: 'Click',
      pageName: "Other Feature",
      reportName: "Release Notes",
      reportSection: 'Release Notes Created',
      pageDetails: `${title}`,
    };
    utils.userTrackingInfo(trackingObject);
  };

  const CreateSubCat = (value) => {
    let params = {
      releaseVersionID: releaseVersionID,
      releaseCategoryID: categoryId,
      subCategory: value,
      region: region,
      releaseName: releaseName,
    };
    dispatch(createNewReleaseTitle(params)).then((res) => {
      setNewSubCat(false);
      setNewCategory(value);
      trackUserActivities(value);
      let releaseDataListparams = {
        region: region,
        releaseVersionID: releaseVersionID,
        releaseName: releaseName,
      };
      dispatch(fetchReleaseDataList(releaseDataListparams));
      let params2 = {
        enableEditor: false,
      };
      dispatch(editEditorData(params2));
      setOpen(false);
      let params = { innerHtml: "", name: "", category: value };
      dispatch(updateEditorData(params));
      toastProperties.success["description"] = res.data.message;
      dispatch(toastTriggered([toastProperties.success]));
    });
  };

  const Cancel = () => {
    setNewSubCat(false);
  };

  const ManageNewsletter = () => { setOpen(true); setNewOne(false); setNewSubCat(false); setSize('lg'); setTitle('Manage Feature List') };

  const addNewSubMenuItem = () => {
    return (
      <Grid item xs={12} style={{ borderBottom: 0 }}>
        {!!newSubCat ? (
          <Grid item xs={12} className={classes.flexRow}>
            <Grid item xs={9}>
              <input
                value={state["subCategory"]}
                type={"text"}
                name={"subCategory"}
                className="form-control"
                placeholder={`Please input Sub Category Name`}
                onChange={(e) => handleInputChange(e, "text")}
                autoComplete="off"
              />
            </Grid>
            <Grid item xs={3} className={"createCancel"}>
              <div className="iconBox" onClick={Cancel}>
                {" "}
                <CloseIcon />{" "}
              </div>
              <div className="iconBox" onClick={CreateSubCat}>
                {" "}
                <TickIcon />{" "}
              </div>
            </Grid>
          </Grid>
        ) : (
          <Grid item xs={12} className={classes.flexRow} style={{ background: 'white' }}>
            <Grid
              item
              xs={6}
              onClick={ManageNewsletter}
              className={classes.btn}
            >
              <ListAltOutlinedIcon style={{ marginTop: 0 }} /> Manage
            </Grid>
            <Grid
              item
              xs={6}
              onClick={createNewSubCat}
              className={classes.btn}
            >
              <PlusCircleFill /> Create
            </Grid>
          </Grid>
        )}

      </Grid>
    );
  };

  const renderEditButton = (item) => {
    if (oktaAuth.adminType === "Contributor" && item === activeMenu) {
      return (
        <div
          style={{
            marginTop: 0,
            background: "#a9ccf2"
          }}
        >
          <EditIcon
            onClick={() => editReleaseData(item)}
          />
        </div>
      )
    }
  };

  const renderHtmlContent = () => {
    if (!!loading) {
      return (
        <DotFlashing />
      )
    }
    else if (!loading && !!error) {
      return (
        <div className={classes.menuList}>
          {"No release feature available"}
        </div>
      )
    }
    else if (!loading && !error && data.length === 0) {
      return (
        <div className={classes.menuList}>
          {"No release feature available"}
        </div>
      )
    }
    else if (Array.isArray(updatedSidebarMenu) && updatedSidebarMenu.length > 0 && !error) {
      return (
        <List>
          {
            updatedSidebarMenu.map((text, index) => (
              <span className={classes.flexRow}>
                <ListItem
                  disableRipple
                  button
                  key={text + index}
                  className={
                    text.subCategory === activeMenu
                      ? classes.activeSubMenuList
                      : classes.menuList
                  }
                  onClick={() => selectActiveSubMenu(text, index)}
                >
                  <ListItemText secondary={text.subCategory} />
                </ListItem>
                {renderEditButton(text.subCategory)}
              </span>
            ))
          }
        </List>
      )
    }
  };

  return (
    <Grid item xs={12}>
      <Modal
        open={open}
        bodyElement={
          <Operations
            data={data}
            name={'subCategory'}
            label={'Release Feature'}
            region={region}
            createNewsItems={CreateSubCat}
            create={newOne}
            moduleID={'releaseVersionID'}
            module={'Release Feature'}
            deleteAction={deleteReleaseNotesFeature}
            updateFeatureName={updateReleaseNotesFeature}
            releaseID={releaseNoteID}
            hideSettingsWindow={(e) => setOpen(false)}
            updateAction={fetchReleaseDataList}
            updateParams={{
              region: region,
              releaseVersionID: releaseVersionID,
              releaseName: releaseName,
            }}
          />
        }
        hideSettingsWindow={(e) => setOpen(false)}
        title={title}
        size={size}
      />
      <div className={classes.root}>{renderHtmlContent()}</div>
      {oktaAuth.adminType === "Contributor" && Array.isArray(Releases) && Releases.length > 0 ? addNewSubMenuItem() : null}
    </Grid>
  );
};

export default SubMenuList;
