export default (state = { closeAnchorEl : false}, action) => {

    switch (action.type) {

    case 'UPDATE_MENU_ITEM_VIEW_STATE':
    return action.payload;

    default:

    return state;

    }

    };