import React, {   useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import cloneDeep from "lodash/cloneDeep";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Collapse from "@material-ui/core/Collapse";
import LinearProgress from "@material-ui/core/LinearProgress";
import SubMenuList from "./subMenuList";
import { useStyles } from "./menuStyle.js";
import utils from '../../utils/index';
import {
  updatePlaybooksContentPages,
  updatesidebarMenuList,
  updateActiveMenuList,
  updatePresistReport,
  getPlayBooksContentPages,
  getEmbedToken,
  getMsalBasedPlaybookContentPages,
  updateActiveSubMenuList
} from '../../redux/actions/index';

const MenuList = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    menuList,
    subRegion,
    getActiveMenu,
    getActiveSubMenu,
    activeDafaultMenu,
    activeSubMenu,
    activeSubMenuID
  } = props;

  const updatedSidebarMenu = useSelector((state) => state.updatedSidebarMenu);
  const sideBarMenu = useSelector((state) => state.sideBarMenu);
  const playBooksContent = useSelector((state) => state.playbookContent);
  const playbookContentPages = useSelector((state) => state.playbookContentPages);
  const activeReport = useSelector((state) => state.activePBIReport);
  const { activeRegion, activePage } = useSelector((state) => state.activeDynamicData);
  const activeReportSection = useSelector((state) => state.activeSideBarMenu);

  const [activeIndex, setActiveIndex] = useState(0);
 
  const getSubMenuList = (value, index, name, htmlTemp) => {
    const data = {
      activeSubMenu: value,
      activeSubMenuIndex: index,
      activeSubMenuName: name,
      activeSubMenuHtmlTemp: htmlTemp,
    };
    getActiveSubMenu(data);
  };

  const selectActiveMenu = async (menu, index, flag) => {
    dispatch({ type: "UPDATE_SIDEBAR_MENU_LIST" });
    dispatch(updatePresistReport({ presist: false }));
    dispatch(updateActiveMenuList(menu));

    sessionStorage.setItem('presistReport', null);
    let { viewName, grouped, msalEnabled, reportPages, hiddenPages, subReport, htmlTemp, customizableFlag } = menu;
    console.log('selectActiveTab', activeReport, menu, index, flag, subReport, htmlTemp, viewName, msalEnabled, reportPages, hiddenPages, activeReportSection, activeReport);
    const menus = {
      activeMenu: viewName,
      activeIndex: index,
    };
    setActiveIndex(index);
    getActiveMenu(menus, true);
    if (!msalEnabled && !!htmlTemp && !htmlTemp.hasOwnProperty('token')) {
      let reportId = utils.checkValidPBIds(htmlTemp, customizableFlag);
      console.log('selectActiveTab', 222, reportId, htmlTemp);
        if (reportId.reportId === htmlTemp.reportId) {
           dispatch(getEmbedToken(reportId));
        }
    }
    if (grouped && subReport.length === 0) {
      let reportId = utils.checkValidPBIds(htmlTemp, customizableFlag);
      console.log('selectActiveTab', 333, reportId);
      const params = {
        ...reportId,
        viewID: menu['viewId'],
        pageType: activePage.key
      };
      dispatch(getMsalBasedPlaybookContentPages(params));
       dispatch(updateActiveSubMenuList({}));
    }
    if (reportPages && !hiddenPages && subReport.length === 0) {
      console.log('selectActiveTab', 444, reportPages, hiddenPages, subReport);
      const params = {
        reportId: htmlTemp.reportId,
        groupId: htmlTemp.groupId,
      };
      dispatch(getPlayBooksContentPages(params));
    }
    else if (reportPages && !hiddenPages && !grouped && subReport.length > 0) {
      console.log('selectActiveTab', 555, reportPages, hiddenPages, subReport);
      dispatch({ type: "GET_PLAYBOOKS_CONTENT_PAGES_REQUEST" });
    }
    else if (!reportPages && !grouped && !hiddenPages) {
      console.log('selectActiveTab', 666, reportPages, hiddenPages, subReport);
      dispatch({ type: "GET_PLAYBOOKS_CONTENT_PAGES_REQUEST" });

    }
    console.log('selectedMenu', menu, index, flag, menus,);
    if ((reportPages || grouped) && Array.isArray(menu.subReport) && menu.subReport.length > 0) {
      let selections = {
        reportId: menu['htmlTemp']['reportId'],
        groupId: menu['htmlTemp']['groupId'],
        viewID: menu.viewId,
        pageType: activePage.key,
      }
      let data = menu.subReport
      let res = { data }
      let payload = { ...res, selections };
      dispatch(updatePlaybooksContentPages(payload));
      console.log('selectActiveTab', 777, menu, activeDafaultMenu, updatedSidebarMenu)
    }
    else if ((reportPages || grouped) && Array.isArray(menu.subReport) && menu.subReport.length === 0) {
      const { data } = playbookContentPages;
      data.forEach((el) =>  el.activeReportPage = true);
      menu.subReport = data;
      dispatch(updatesidebarMenuList(updatedSidebarMenu));
      console.log('selectActiveTab', 888, menu, playbookContentPages, updatedSidebarMenu, menu.subReport);
    }
  };


  const renderSingleMenuList = () => {
    const sidebarData = Array.isArray(updatedSidebarMenu) && updatedSidebarMenu.length > 0 ? cloneDeep(updatedSidebarMenu) : cloneDeep(menuList);
    if (!!sidebarData && Array.isArray(sidebarData) && sidebarData.length > 0) {
      console.log('renderlefthandMenuList', 333, activeRegion, sidebarData, menuList, updatedSidebarMenu, 'iam here');
      return (
        <List style={{ width: "100%" }}>
          {
            sidebarData.map((text, index) => (
              <ListItem
                button
                key={text + index}
                className={
                  activeIndex === index || text.isReportActive
                    ? classes.activeMenuList
                    : classes.menuList
                }
                onClick={() => selectActiveMenu(text, index)}
              >
                <ListItemText primary={text.viewName} />
              </ListItem>
            ))
          }
        </List>
      );
    }
    else if (!!sidebarData && Array.isArray(sidebarData) && sidebarData.length === 0) {
      console.log('renderlefthandMenuList', 555, sideBarMenu, ' iam here');
      return (
        <div className={classes.activeUserMessage}>{sideBarMenu.userMessage}</div>
      )
    }
  };

  const expandMenu = (menu) => {
    if (!!menu) {
      const { /*customizableFlag,*/ reportPages, grouped, openMenu } = menu;
      if (reportPages || grouped) {
        console.log('renderlefthandMenuList', 666, sideBarMenu,);
        if (!!openMenu) {
          return (
            <ExpandLess />
          )
        }
        else {
          return (
            <ExpandMore />
          )
        }
      }
    }
  };

  const renderSubMenu = (menu, index) => {
    if (!!menu) {
      const { customizableFlag, viewId, subReport, openMenu } = menu;
      console.log('renderlefthandMenuList', 4444, menu, customizableFlag, viewId, subReport, openMenu);
      return (
        <Collapse
          in={!!openMenu}
          timeout="auto"
          unmountOnExit
          style={{ width: "100%" }}
        >
          <SubMenuList
            getMenuList={getSubMenuList}
            loading={playbookContentPages.loading}
            defaultResponse={playbookContentPages.responseDefault}
            error={playbookContentPages.error}
            playbookContentPages={subReport}
            playbookData={menu}
            subRegion={subRegion}
            leverIndex={index}
            errorMessage={playbookContentPages.userMessage}
            viewId={viewId}
            activeSubMenuID={activeSubMenuID}
            activeSubMenuDisplayName={activeSubMenu}
          />
        </Collapse>
      )
    }
  };

  const renderLinearProgress = (menu, index) => {
    if (menu.reportPages && menu.openMenu && activeIndex === index && !!playbookContentPages.loading) {
      return (
        <LinearProgress className={classes.linearProgress} />
      )
    }
  };

  const renderMenuList = () => {
    const data = cloneDeep(updatedSidebarMenu);
    if (!!data && !playBooksContent.error && Array.isArray(data) && data.length > 0) {
      console.log('renderlefthandMenuList 111', data, playbookContentPages, activeReport);
      return (
        <List style={{ width: "100%" }}>
          {data.map((menu, index) => (
            <>
              {renderLinearProgress(menu, index)}
              <ListItem
                disableRipple
                button
                key={menu + index}
                className={
                  menu.isReportActive
                    ? classes.activeMenuList
                    : classes.menuList
                }
                onClick={() => selectActiveMenu(menu, index)}
              // disabled={playbookContentPages.loading}
              >
                <div className={classes.menuHeader}>
                  <ListItemText
                    primary={menu.viewName}
                    className={classes.primaryText}
                  />
                  {expandMenu(menu)}
                </div>
                {renderSubMenu(menu, index)}
              </ListItem>
            </>
          ))
          }
        </List>
      );
    }
    else if (data && Array.isArray(data) && data.length === 0) {
      const sidebarData = cloneDeep(menuList);
      if (!!sidebarData && Array.isArray(sidebarData) && sidebarData.length > 0) {
        console.log('renderlefthandMenuList', 222, activeRegion, sidebarData, menuList, updatedSidebarMenu, 'iam here');
        return (
          <List style={{ width: "100%" }}>
            {
              sidebarData.map((text, index) => (
                <ListItem
                  button
                  key={text + index}
                  className={
                    activeIndex === index || text.isReportActive
                      ? classes.activeMenuList
                      : classes.menuList
                  }
                  onClick={() => selectActiveMenu(text, index)}
                >
                  <ListItemText primary={text.viewName} />
                </ListItem>
              ))
            }
          </List>
        );
      }

    }
  };

  console.log('UpdatePlaybookActivePages', 888, activePage)

  return (
    <div className={classes.root} id="leftHandPanel">
      <Paper className={classes.paper}>
        <Grid
          container
          item
          xs={12}
          spacing={3}
          className={classes.flexContainer}
        >
          <div className={classes.pageInfo}>{activePage && activePage.pageName}</div>
          {
            activePage && activePage.multipleMenuList ?
              renderMenuList()
              :
              renderSingleMenuList()
          }
        </Grid>
      </Paper>
    </div>
  );
};

export default MenuList;
