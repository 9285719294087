import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { useStyles } from "./styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Collapse from "@material-ui/core/Collapse";
import SubMenuList from "./subMenuList";
import DotFlashing from "../../component/DotFlashing/index";

const MenuList = (props) => {
  const classes = useStyles();
  const { data, region, activeReleaseVersion, activeReleaseName, releaseNoteID, loading, error, defaultRes, message, userMessage , activeReleaseFeature, activeReleaseAlertID, Releases } = props;
  const [activeMenu, setActiveMenu] = useState("");
  const [activeMenuID, setActiveMenuID] = useState("");

  useEffect(() => {
    if (Array.isArray(data) && data.length > 0) {
      let firstElement = data[0]
      let defaultActiveMenu = firstElement.releaseCategory;
      let defaultactiveMenuID = firstElement.releaseCategoryID;
      setActiveMenu(defaultActiveMenu);
      setActiveMenuID(defaultactiveMenuID)
    }
  }, [data]);

  const selectActiveTab = (item) => {
    setActiveMenu(item.releaseCategory);
    setActiveMenuID(item.releaseCategoryID);
    data.map((category) => {
      if (category.releaseCategory === item.releaseCategory) {
        category.isOpen = true;
      }
      else if (category.releaseCategory !== item.releaseCategory) {
        category.isOpen = false
      }
      return category;
    });
  };

  const renderMenuList = () => {
    return (
        <List style={{ width: "100%" }}>
          {
           !!data && Array.isArray(data) && data.length > 0
            ? data.map((text, index) => (
                <ListItem
                disableRipple
                  button
                  key={text + index}
                  className={
                    activeMenu === text.releaseCategory
                      ? classes.activeMenuList
                      : classes.menuList
                  }
                  onClick={() => selectActiveTab(text, index)}
                >
                  <div className={classes.menuHeader}>
                    <ListItemText
                      primary={text.releaseCategory}
                      className={classes.primaryText}
                    />
                    {!!text.isOpen ? <ExpandLess /> : <ExpandMore />}
                  </div>
                  {!!text.isOpen ? (
                    <Collapse
                      in={!!text.isOpen ? true : false}
                      timeout="auto"
                      unmountOnExit
                      style={{ width: "100%" }}
                    >
                      <SubMenuList
                        data={text.subCategory}
                        category={activeMenu}
                        categoryId={activeMenuID}
                        region={region}
                        releaseVersionID={activeReleaseVersion}
                        releaseName={activeReleaseName}
                        releaseNoteID={releaseNoteID}
                        loading={loading}
                        error={error}
                        defaultRes={defaultRes}
                        message={message}
                        userMessage={userMessage}
                        Releases={Releases}
                        activeReleaseFeature={activeReleaseFeature}
                        activeReleaseAlertID={activeReleaseAlertID}
                      />
                    </Collapse>
                  ) : null}
                </ListItem>
             ))
            :
             !!error ?
               null
              : <DotFlashing />
            }
        </List>
    )
  };

  return (
    <Grid item xs={12}>
      {renderMenuList()}
    </Grid>
  );
};

export default MenuList;
