import axios from 'axios';

const apiInstance = axios.create({
  baseURL:  process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
  }
});


apiInstance.interceptors.request.use(
  function (config) {
    const oktaTokenStorage = JSON.parse(localStorage.getItem('okta-token-storage'));
    if (oktaTokenStorage && oktaTokenStorage.hasOwnProperty("accessToken")) {
       let accessToken = oktaTokenStorage.accessToken.accessToken;
       let idToken = oktaTokenStorage.idToken.idToken;
       let token = process.env.REACT_APP_ENVIRONMENT === "LOCAL" ? idToken : accessToken
       config.headers.access_token = process.env.REACT_APP_TOKEN_TYPE + ' ' +  token;
       config.headers.common['Access-Control-Allow-Origin'] = '*';
    }
    return config;
  },



  function (error) {
    return Promise.reject(error);
  }

);

export default apiInstance;

