import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 0,
      width: '100%'
    },
    paper: {
      padding: theme.spacing(3, 0),
      margin: '10px 0 0',
      width: 'auto',
      height: '93vh',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: "center",
      boxShadow: 'none',
      background: '#fff'
    },
    flexRow: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%'
    },
    flexContainer: {
      display: 'flex',
      flexDirection: 'row',
      // border: '1px dotted red'
    },
    flexItem: {
      display: 'flex',
      flexDirection: 'row',
      border: '1px solid #e5e5e5',
      borderRadius: 15,
      boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
      width: '375px',
      margin: '1rem 3.2rem',
      textAlign: 'center',
      lineHeight: '75px',
      padding: '12px'
    },
    activeSubMenuList: {
      background: '#a9ccf2',
      borderLeft: '5px solid #0054ba',
      padding: '6px 0 0',
      color: '#505559',
      display: 'flex',
      flexDirection: 'row',
      "&:hover": {
        background: "#a9ccf2",
        borderLeft: "5px solid #0054ba",
      },
      '& .MuiTypography-colorTextSecondary': {
        color: '#000000',
        padding: '0 1rem',
        textAlign: 'left',
        fontWeight: 500,
        fontSize: 13,
        '& p': {
          padding: '0 1rem !important'
        }
      },
    },
    menuList: {
      background: '#ffffff',
      borderLeft: '0px solid #0054ba',
      padding: '6px 0 0',
      color: '#505559',
      display: 'flex',
      flexDirection: 'row',
      '& .MuiSvgIcon-root': {
        marginTop: 8
      },
      '& .MuiListItemText-root': {
        padding: '6px 0 0px',
        width: '100%',
      },
      '& .MuiList-padding': {
        paddingBottom: 0
      },
      '& .MuiTypography-colorTextSecondary': {
        color: '#000000',
        padding: '0 1rem',
        textAlign: 'left',
        fontWeight: 500,
        fontSize: 13,
        '& p': {
          padding: '0 1rem !important'
        }
      },
      '& .MuiCollapse-container': {
        background: 'white'
      }
    },
    '& .MuiListItemText-root': {
      padding: ' 0 1rem',
    },
    btn:{
      padding: '5px 0',
      margin: '5px 5px 0 0',
      backgroundColor: '#f4f5f6',
      color:'#0f059e',
      cursor: 'pointer',
      border: '1px solid #0f059e',
      fontSize: '1rem',
      textAlign: 'center'
    }
  }));

export {useStyles};