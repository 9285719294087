export default (state = {
    error: false,
    message: '',
    userMessage: '',
    alertTrigger: false,
    apiName: ''
}, action) => {
    switch (action.type) {

        case 'GLOBAL_API_SLOW':
            return {
                error: true,
                userMessage: `😞 Oops! We seem to be having some trouble right now. We are working on resolving this. Please try again in a little time.`,
                message: `Do you know we have donated 270 million Huggies Diapers in Last 10 years!", "Do you know 25% of Globe use one of our products everyday`,
                alertTrigger: true,
                type: "ApiSlow",
                trigger: action.payload.trigger,
                apiName: action.payload.apiName,
            }

        case 'GLOBAL_API_FAILURE':
            return {
                error: true,
                message: action.payload.message,
                userMessage: action.payload["userMessage"],
                alertTrigger: action.payload.alertTrigger,
                type: "ApiError"
            }

        case 'GLOBAL_NETWORK_FAILURE':
            return {
                error: true,
                message: action.payload.message,
                alertTrigger: action.payload.alertTrigger,
                type: "NetworkError"
            }

        case 'API_NOT_FOUND_FAILURE':
            return {
                error: true,
                message: action.payload.message,
                alertTrigger: action.payload.alertTrigger,
                type: "404NotFound"
            }

        case 'GLOBAL_SLOW_API_SUCCESS':
            return {
                error: false,
                alertTrigger: false,
                type: action.payload.type,
                trigger: action.payload.trigger,
            }


        default:

            return state;

    }

};