import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 0,
    },
    paper: {
      padding: theme.spacing(0, 0),
      margin: '1px 0 0',
      width: 'auto',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: "center",
      boxShadow: 'none',
      background: '#ffffff'
    },
    flexRow: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%'
    },
    flexContainer: {
      display: 'flex',
      flexDirection: 'row',
      height: '100%',
      overflow: 'hidden',
    },
    flexItem: {
      display: 'flex',
      flexDirection: 'row',
      border: '1px solid #e5e5e5',
      borderRadius: 15,
      boxShadow: ' rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
      width: '375px',
      margin: '1rem 3.2rem',
      textAlign: 'center',
      lineHeight: '75px',
      fontSize: '30px',
      padding: '12px'
    },
    sidebarContainer: {
      //   borderRight: '1px solid #e5e5e5',
      padding: 0
    }
  }));

  export { useStyles };