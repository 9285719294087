import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
// import { useStyles } from "./style.js";
import IconMenuItem from '../../component/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import { fetchFaqData } from '../../redux/actions/index';
import Alert from '../../component/Alert/index';
import utils from '../../utils/index';

function Feedback(props) {
    // const classes = useStyles();
    const dispatch = useDispatch();

    const [showAlert, setShowAlert] = React.useState(false);
    const [linkType, setLinkType] = React.useState('');

    const fetchFaqLink = useSelector((state) => state.faqLink);

    useEffect(() => {
        if (props.region !== "loading..." && props.region !== "Global") {
            const params = {
                pageType: 'FAQ',
                region: props.region,
            }
            dispatch(fetchFaqData(params))
        }
    }, [dispatch, props.region]);

    const requestSupport = () => {
        let provideSupportLink = fetchFaqLink.data['description']['Support'];
        if (!provideSupportLink || provideSupportLink === '') {
            setShowAlert(true);
            setLinkType('Request Support')
        }
        else {
            setShowAlert(false);
            window.open(provideSupportLink);
            let trackingObject = {
                region: props.region,
                actionType: 'Click',
                pageName: "Help & Feedback",
                reportName: "Request Support",
                reportLink: provideSupportLink,
            };
            utils.userTrackingInfo(trackingObject);
        }
    };

    const provideFeedback = () => {
        let provideFeedbackLink = fetchFaqLink.data['description']['Feedback'];
        if (!provideFeedbackLink || provideFeedbackLink === '') {
            setShowAlert(true);
            setLinkType('Provide Feedback')
        }
        else {
            setShowAlert(false);
            window.open(provideFeedbackLink);
            let trackingObject = {
                region: props.region,
                actionType: 'Click',
                pageName: "Help & Feedback",
                reportName: "Provide Feedback",
                reportLink: provideFeedbackLink,
            };
            utils.userTrackingInfo(trackingObject);
        }
    };

    const faq = () => {
        let faqLink = fetchFaqLink.data['description']['FAQ'];
        if (!faqLink || faqLink === '') {
            setShowAlert(true);
            setLinkType('Help & Feedback')
        } else {
            setShowAlert(false);
            window.open(faqLink);
            let trackingObject = {
                region: props.region,
                actionType: 'Click',
                pageName: "Help & Feedback",
                reportName: "FAQ",
                reportLink: faqLink,
            };
            utils.userTrackingInfo(trackingObject);
        }
    };

    const renderMenuItem = () => {
        return (
            <span>
                <MenuItem onClick={() => requestSupport()}> Request Support </MenuItem>
                <MenuItem onClick={() => provideFeedback()}> Provide Feedback </MenuItem>
                <MenuItem onClick={() => faq()}> FAQ </MenuItem>
            </span>
        )
    }

    const renderFeedback = () => {
        return (
            <div>
                <IconMenuItem
                    ariaLabel={'help and feedback'}
                    TypoLabel={'Help & Feedback'}
                    fontSize={'1rem'}
                    menuId={'menu-appbar-helpFeedback'}
                    menuItem={renderMenuItem()}
                    affixIcon={false}
                    style={{
                        top: '35px',
                        width: '500px'
                    }}
                    disabled={false}
                    ExpandMoreOutlined={false}
                />
            </div>
        )
    };

    const handleModalClose = () => {
        setShowAlert(false);
    };

    return (
        <div id="bmodal">
            <Alert
                open={showAlert}
                message={`No ${linkType} link is available for the ${props.region} region`}
                handleClose={handleModalClose}
                alertType={'Warning'}
                userMessage={fetchFaqLink.userMessage}
            />
            {renderFeedback()}
        </div>
    );
}

export default Feedback;

