export default (
  state = { users: [], loading: false, defaultResponse: true },
  action
) => {
  switch (action.type) {
    case "FETCH_REGION_SPECIFIC_USER_REQUEST":
      return {
        users: [],
        loading: true,
        defaultResponse: true,
      };

    case "FETCH_REGION_SPECIFIC_USER_SUCCESS":
      return {
        users: action.payload,
        responseDefault: false,
        loading: false,
        success: true,
        error: false,
        defaultResponse: false,
      };

    case "FETCH_REGION_SPECIFIC_USER_FAILURE":
      return {
        users: [],
        error: true,
        responseDefault: false,
        loading: false,
        success: false,
        userMessage: action.payload.response.data["usermessage"],
        defaultResponse: false,
      };

    default:
      return state;
  }
};
