import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 0,
    },
    paper: {
        padding: theme.spacing(0.3, 0),
        width: '100%',
        height: 'auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: "center",
        boxShadow: 'none',
        background: '#f4f5f6'
    },
    flexItem: {
        display: 'flex',
        flexDirection: 'row',
        border: '1px solid #e5e5e5',
        borderRadius: 15,
        boxShadow: ' rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
        width: '375px',
        margin: '1rem 3.2rem',
        textAlign: 'center',
        lineHeight: '75px',
        fontSize: '30px',
        padding: '12px'
    },
    flexRow: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%'
    },
    flexColumn: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
    },
    rightAlignMent: {
        display: 'block',
        justifyContent: 'end',
        flexDirection: 'column'
    },
    manageAlerts: {
        fontSize: 15,
        lineHeight: 4,
        fontWeight: 500,
        cursor: 'pointer',
        '& span':{
            backgroundColor: '#ffffff',
            color: '#0f059e',
            border: '1px solid '
        }
    },
    flexContainer: {
        display: 'flex',
        flexDirection: 'row',
        height: '100%',
        // marginLeft: '9.5rem',
        width: '100%',
        textAlign: 'left'
    },
    container: {
        maxWidth: '100%',
        width: '100%',
        display: 'block',
        margin: 'auto'
    },
    subscribeContainer: {
        background: '#fff',
        padding: '0',
        width: '100%',
        maxWidth: '100%',
        margin: 'auto'
    },
    optAlert: {
        background: '#f9f9f9',
        paddingTop: '10px',
        paddingBottom: ' 20px',
        paddingLeft: '115px',
        paddingRight: '40px',
        display: 'flex',
    },
    notification: {
        padding: '0 40px'
    },
    subscriptionType: {
        width: '100%',
        padding: '0 40px',
    },
    alertCategory: {
        background: '#f6f6f6',
        margin: '10px 0',
        borderRadius: '10px',
        padding: '10px 30px 30px',
    },
    outages: {
        // boxShadow: '4px 1px 2px 1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
        marginLeft: 40
    },
    cardBox: {
        background: 'white',
        borderRadius: '10px',
        margin: '10px 0'
    },
    cardBoxContent: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        padding: '5px 0px 5px 40px'
    },
    notif: {
        textAlign: 'justify',
        fontFamily: 'Gilroy-Medium',
        fontSize: 20,
        width: '90%',
        marginTop: '10px',
        fontWeight: 'bold'
    },
    title: {
        textAlign: 'justify',
        fontFamily: 'Gilroy-Medium',
        fontSize: 20,
        width: '90%',
        marginTop: '10px',
        fontWeight: 500
    },
    subtitle: {
        textAlign: 'justify',
        fontFamily: 'Gilroy-Medium',
        fontSize: 9,
        width: '90%',
    },
    clearAll: {
        width: '98%',
        height: '100%',
        display: 'flex',
        justifyContent: 'end'
    },
    type: {
        textAlign: 'left',
        fontFamily: 'Gilroy-Medium',
        fontSize: 14,
        width: '90%',
        padding: 5,
        fontWeight: 500
    },
    date: {
        textAlign: 'left',
        fontFamily: 'Gilroy-Medium',
        fontSize: 12,
        width: '90%',
        marginLeft: '1.8rem'
    },
    desc: {
        textAlign: 'left',
        fontFamily: 'Gilroy-Medium',
        fontSize: 12,
        width: '90%',
        marginBottom: '1rem',
        color: '#555'
    },
    subCatdesc: {
        textAlign: 'left',
        fontFamily: 'Gilroy-Medium',
        fontSize: 12,
        width: '90%',
        marginLeft: '0.5rem',
        marginBottom: '0rem',
        color: '#555'
    },
    triangle: {
        width: 0,
        height: 0,
        borderTop: '0px solid transparent',
        borderBottom: '15px solid transparent',
    },
    OperateAlert: {
        width: '12%',
        display: 'flex',
        justifyContent: 'start',
        cursor: 'pointer',
        '& span': {
            width: '95%',
            justifyContent: 'center',
            lineHeight: '2',
            marginTop: '1rem',
            padding: '5px 10px',
            boxShadow: '2px 2px 8px #888',
            color: 'white',
            background: '#3f51b5',
            display: 'flex',
        }
    },
    DQalerts: {
        width: '10%',
        display: 'none',
        justifyContent: 'end',
        pointerEvents: 'none',
        '& span': {
            width: '100%',
            background: 'gray',
            display: 'flex',
            justifyContent: 'center',
            lineHeight: '2',
            marginTop: '1rem',
            padding: '5px 10px',
            boxShadow: '2px 2px 8px #888',
            color: 'white',
        }
    },
    notifSetting: {
        paddingTop: '15px',
        display: 'flex',
        textAlign: 'left'
    },
    paddingLr: {
        padding: '0 0 0 6rem'
    }
}));

export { useStyles };