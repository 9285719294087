let defaultState = {
  data: [],
  loading: false,
  responseDefault: true,
  error: false,
  success: false,
};

export default (state = defaultState, action) => {

 switch (action.type) {

    case 'GET_REPORT_CONTENT_REQUEST':
      return {
        data: [],
        loading: true,
        error: false,
        responseDefault: true,
        success: false,
      }

    case 'UPDATE_REPORT_CONTENT' :
    return {
      data: [],
      loading: true,
      error: false,
      responseDefault: true,
      success: false,
    }

    case 'GET_REPORT_CONTENT_SUCCESS':
      return {
        data: action.payload.data.data,
        loading: false,
        error: false,
        responseDefault: false,
        success: true,
        region: action.payload.params.region,
        pageType: action.payload.params.pageType
      }

    case 'GET_REPORT_CONTENT_FAILURE':
      return {
        ...defaultState,
        data: [],
        message: action.payload.response.data['message'],
        userMessage: action.payload.response.data['usermessage'],
        responseDefault: false,
        success: false,
        error: true
      }

    default:
      return state;

  }

};
