
import { makeStyles } from '@material-ui/core/styles';

const drawerWidth = 280;
// const drawerPaperHeight = '91vh';
const drawerHeight = '87vh';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: '100%'
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    boxShadow: "0 0 20px rgba(0,0,0,.25),0 10px 10px rgba(0,0,0,.22)!important",
    zIndex: "2",
    display: "block",
  },
  drawerHidden: {
    display: "none"
  },
  drawerPaper: {
    width: drawerWidth,
    height: drawerHeight,
    top: 61,
    position: 'absolute',
    zIndex: 1000,
    overflowX: 'hidden',
    overflowY: 'auto',
   '&::-webkit-scrollbar': {
    width: '12px'
  },
  },
  // drawerPaperHeight: {
  //   width: drawerWidth,
  //   height: drawerPaperHeight,
  //   top: 51,
  //   position: 'absolute',
  //   zIndex: 1000
  // },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(0),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 'auto',
    width: '100%',
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
    width: '100%',
    // background: '#ffffff',
    marginTop: '0px'
  },
  toggleLeft: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
    position: 'absolute',
    top: '66px',
    left: '242px',
    minHeight: '25px !important',
    backgroundColor: 'white',
    border: 'none',
    height: 40,
    width: 40,
    borderRadius: '4px',
    transform: 'translateX(-50%)',
    cursor: 'pointer',
    outline: 'none',
    zIndex: 1000
  },
  toggleRight: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
    position: 'absolute',
    top: '68px',
    left: '21px',
    minHeight: '25px !important',
    backgroundColor: 'white',
    border: 'none',
    height: 40,
    width: 40,
    borderRadius: '0px',
    boxShadow: '0px 1px 4px 1px rgb(0 0 0 / 30%)',
    transform: 'translateX(-50%)',
    cursor: 'pointer',
    outline: 'none',
    zIndex: 1000
  },
}));


export {useStyles}