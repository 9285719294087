export default (
  state = { data: [], error: false, loading: false, defaultResponse: true },
  action
) => {
  switch (action.type) {
    case "CREATE_NEWSLETTER_REQUEST":
      return {
        data: [],
        loading: true,
        error: false,
        defaultResponse: true,
        createNew: false
      };

    case "CREATE_NEWSLETTER_SUCCESS":
      return {
        data: action.payload.data,
        responseDefault: false,
        loading: false,
        success: true,
        error: false,
        message: action.payload["message"],
        defaultResponse: false,
        createNew: true
      };

    case "CREATE_NEWSLETTER_FAILURE":
      return {
        data: [],
        error: true,
        responseDefault: false,
        loading: false,
        success: false,
        message: action.payload.response.data["message"],
        defaultResponse: false,
        createNew: false
      };

    default:
      return state;
  }
};
