import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import 'react-tabs/style/react-tabs.css';
import { updateViewBuilderJSON } from '../../../redux/actions';
import cloneDeep from 'lodash/cloneDeep';
import { SketchPicker  } from 'react-color'
class DesignOptions extends Component {
    constructor(props) {
        super(props);
        this.state = {
          displayColorPicker:false,
          colorCode:'',
          displayColorPickerId:'',
        }
    }
    handleClick(type){
      this.setState({ displayColorPicker: !this.state.displayColorPicker,  displayColorPickerId:type})
    };
    handleChangeComplete(e, keyName){
     this.handleViewBuilderJSONChange(e, 'colorCode', keyName)
   };
    handleClose = () => {
    this.setState({ displayColorPicker: false })
  };


  handleViewBuilderJSONChange(e, type, keyName){
      let tempViewBuilderObj = cloneDeep(this.props.viewBuilder);
      let id =  this.props.id
      if(type==='layout'){
        tempViewBuilderObj.viewBuilderJSON.htmlTemp[id][keyName]=e.target.value;
      }
      else if(type==='configOptions'){
        tempViewBuilderObj.viewBuilderJSON.htmlTemp[id]['configOptions'][keyName] = e.target.value;
      }
      else if(type==='style' || type==='colorCode'){
            let targetVal = '';
            if(type==='colorCode'){
               targetVal = e.hex;
            }
            else{
              targetVal = e.target.value;
            }
          tempViewBuilderObj.viewBuilderJSON.htmlTemp[id]['configOptions']['style'][keyName] = targetVal;
      }
      console.log('@viewBuilderJSONStructure', tempViewBuilderObj);
      this.props.updateViewBuilderJSONAPI(tempViewBuilderObj)
  }
  renderColorPallet(containerId, keyName){
    if(this.state.displayColorPicker && (containerId === this.state.displayColorPickerId)){
      const popover = {
        position: 'absolute',
        zIndex: '2',
      }
      const cover = {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
      }
      return(
        <div style={popover}>
             <div style={cover} onClick={ this.handleClose }/>
             <SketchPicker  color={ this.state.colorCode } onChangeComplete={(e)=> this.handleChangeComplete(e,keyName) }  />
        </div>
      )
    }
  }

   renderConfigOptions(){
     //if(this.props.currentObj.type=='column'){
       return(
            <div className="formContainer">
                <div className="form-group">
                <label for="inlineFormInputGroup">Background</label>
                 <div className="input-group mb-2">
                    { this.renderColorPallet('bg_1', 'background')}
                   <div className="input-group-prepend" onClick={(e)=> this.handleClick('bg_1') }>
                     <div className="input-group-text"><i className="fas fa-eye-dropper"></i></div>
                   </div>
                   <input type="text" className="form-control"  value={this.props.viewBuilder.viewBuilderJSON.htmlTemp[this.props.id]['configOptions']['style']['background']}  onChange={(e)=>this.handleViewBuilderJSONChange(e,'style', 'background')} />
                 </div>
                </div>
                <div className="form-group">
                <label>Border</label>
                 <div className="input-group mb-2">
                    { this.renderColorPallet('bg_2', 'border-color')}
                   <div className="input-group-prepend" onClick={(e)=> this.handleClick('bg_2') }>
                     <div className="input-group-text"><i className="fas fa-eye-dropper"></i></div>
                   </div>
                    <input type="text" className="form-control"  value={this.props.viewBuilder.viewBuilderJSON.htmlTemp[this.props.id]['configOptions']['style']['border-color']}  onChange={(e)=>this.handleViewBuilderJSONChange(e,'style', 'border-color')} />
                    <select className="custom-select"  value={this.props.viewBuilder.viewBuilderJSON.htmlTemp[this.props.id]['configOptions']['style']['border-style']}  onChange={(e)=>this.handleViewBuilderJSONChange(e,'style', 'border-style')} >
                        <option selected value=''>Select</option>
                        <option value="solid">Solid</option>
                        <option value="dotted">Dotted</option>
                        <option value="dashed">Dashed</option>
                        <option value="double">Double</option>
                        <option value="groove">Groove</option>
                        <option value="inset">Inset</option>
                     </select>
                 </div>
                </div>
                <div className="form-group">
                <label>Border Width</label>
                 <div className="input-group mb-2">

                  <div className="input-group-prepend">
                    <div className="input-group-text">Left</div>
                  </div>
                  <input type="text" className="form-control" placeholder='0px'  value={this.props.viewBuilder.viewBuilderJSON.htmlTemp[this.props.id]['configOptions']['style']['borderLeftWidth']}  onChange={(e)=>this.handleViewBuilderJSONChange(e,'style', 'borderLeftWidth')} />
                  <div className="input-group-prepend">
                    <div className="input-group-text">Right</div>
                  </div>
                  <input type="text" className="form-control" placeholder='0px'  value={this.props.viewBuilder.viewBuilderJSON.htmlTemp[this.props.id]['configOptions']['style']['borderRightWidth']}  onChange={(e)=>this.handleViewBuilderJSONChange(e,'style', 'borderRightWidth')} />
                  <div className="input-group-prepend">
                      <div className="input-group-text">Top</div>
                  </div>
                  <input type="text" className="form-control" placeholder='0px' value={this.props.viewBuilder.viewBuilderJSON.htmlTemp[this.props.id]['configOptions']['style']['borderTopWidth']}  onChange={(e)=>this.handleViewBuilderJSONChange(e,'style', 'borderTopWidth')} />
                  <div className="input-group-prepend">
                       <div className="input-group-text">Bottom</div>
                  </div>
                  <input type="text" className="form-control" placeholder='0px' value={this.props.viewBuilder.viewBuilderJSON.htmlTemp[this.props.id]['configOptions']['style']['borderBottomWidth']}  onChange={(e)=>this.handleViewBuilderJSONChange(e,'style', 'borderBottomWidth')} />
                 </div>
                </div>

                <div className="form-group">
                <label>Padding</label>
                 <div className="input-group mb-2">
                  <div className="input-group-prepend">
                    <div className="input-group-text">Left</div>
                  </div>
                  <input type="text" className="form-control" placeholder='0px' value={this.props.viewBuilder.viewBuilderJSON.htmlTemp[this.props.id]['configOptions']['style']['paddingLeft']}  onChange={(e)=>this.handleViewBuilderJSONChange(e,'style', 'paddingLeft')} />
                  <div className="input-group-prepend">
                    <div className="input-group-text">Right</div>
                  </div>
                  <input type="text" className="form-control" placeholder='0px' value={this.props.viewBuilder.viewBuilderJSON.htmlTemp[this.props.id]['configOptions']['style']['paddingRight']}  onChange={(e)=>this.handleViewBuilderJSONChange(e,'style', 'paddingRight')}  />
                  <div className="input-group-prepend">
                      <div className="input-group-text">Top</div>
                  </div>
                  <input type="text" className="form-control" placeholder='0px'  value={this.props.viewBuilder.viewBuilderJSON.htmlTemp[this.props.id]['configOptions']['style']['paddingTop']}  onChange={(e)=>this.handleViewBuilderJSONChange(e,'style', 'paddingTop')} />
                  <div className="input-group-prepend">
                       <div className="input-group-text">Bottom</div>
                  </div>
                  <input type="text" className="form-control" placeholder='0px' value={this.props.viewBuilder.viewBuilderJSON.htmlTemp[this.props.id]['configOptions']['style']['paddingBottom']}  onChange={(e)=>this.handleViewBuilderJSONChange(e,'style', 'paddingBottom')} />
                 </div>
                </div>
            </div>
       )
     //}
   }

    render() {
        return (
                this.renderConfigOptions()
         );
    }
}
function mapStateToProps(state) {
	return ({
		viewBuilder:state.viewBuilderObj,

	})
}
function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		updateViewBuilderJSONAPI:updateViewBuilderJSON
	}, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(DesignOptions);
