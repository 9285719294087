export default (
  state = {
    loading: false,

    defaultResponse: true,

    Success: false,
  },

  action
) => {
   switch (action.type) {
    case "UPDATE_ERROR_MESSAGES_REQUEST":
      return {
        ...state,

        loading: true,

        defaultResponse: true,

        Success: false,
      };

    case "UPDATE_ERROR_MESSAGES_SUCCESS":
      return {
        ...state,

        Success: action.payload.Success,

        message: action.payload.message,

        loading: false,

        defaultResponse: false,
      };

    case "UPDATE_ERROR_MESSAGES_FAILURE":
      return {
        ...state,
        Success: false,
        error: true,
        message: action.payload.response.data.message,
        loading: false,
        defaultResponse: false,
      };

    default:
      return state;
  }
};
