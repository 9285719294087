import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../../component/modal";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { generateShareablePlayBooksLinks } from "../../redux/actions/index";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Typography from "@material-ui/core/Typography";
import utils from "../../utils/index";

const Share = (props) => {
  // const classes = useStyles();
  const dispatch = useDispatch();

  const captureShareLink = useSelector((state) => state.capturePlaybookData);
  const pbiReport = useSelector((state) => state.PowerBIReportPageSection);
  // const reportBookmark = useSelector((state) => state.PBIReportBookmark);
  const playbooksSelectedRegionFilter = useSelector((state) => state.playbooksSelectedRegionFilter);
  const { activePage, activeRegion, } = useSelector((state) => state.activeDynamicData);
  // const activeReport = useSelector((state) => state.activePBIReport);


  const [open, setShareModalOpen] = useState(false);
  const [sharableLink, setSharableLink] = useState("...loading");
  const [copy, setCopy] = useState(false);
  const [error, setError] = useState(false);

  const traceEvents = (link, type) => {
    let trackingFilter = {};
    activePage && activePage.globalFilter && !!playbooksSelectedRegionFilter && Object.keys(playbooksSelectedRegionFilter["dataSet"]).map((item) => {
      trackingFilter[item] =
        playbooksSelectedRegionFilter.dataSet[item]["activeItem"];
    });
    utils.userTrackingInfo({
      region: activeRegion,
      subRegion: props.subRegion,
      actionType: "Share",
      pageName: captureShareLink.pageName,
      reportName: captureShareLink.viewName,
      globalFilters: JSON.stringify(trackingFilter),
      groupId: captureShareLink.groupId,
      reportId: captureShareLink.reportId,
      reportSectionId: captureShareLink.reportSectionId,
      reportSection: captureShareLink.reportSectionName,
      reportLink: link,
      attributeName: `Share link ${type}`,
    });
  };

  const handleCopyLink = () => {
    var textBox = document.getElementById("outlined-basic");
    textBox.select();
    navigator.clipboard.writeText(textBox.value);
    setCopy(true);
    traceEvents(textBox.value, "Copied");
  };

  const generateShareableCcLink = () => {
    setCopy(false);
    let sessionData = JSON.parse(sessionStorage.getItem("oktaRes"));
    const data = localStorage.getItem("reportFilter");
    let appliedFilter = !!data ? JSON.parse(data) : null;
    if (!!pbiReport && Object.keys(pbiReport).length > 0) {
      pbiReport.bookmarksManager
        .capture({ personalizeVisuals: true })
        .then(async (report) => {
          let params = {
            shareLinkDetails: {
              userEmail: sessionData && sessionData.email,
              pageType: captureShareLink.pageType,
              pageName: captureShareLink.pageName,
              region: activeRegion,
              subRegion: props.subRegion,
              groupId: captureShareLink.reportId,
              reportId: captureShareLink.groupId,
              selectedFilters: appliedFilter,
              shareLinkState: report.state,
              viewName: captureShareLink.viewName,
              viewIndex: captureShareLink.viewIndex,
              reportSectionId: captureShareLink.reportSectionId,
              reportSectionName: captureShareLink.reportSectionName,
              reportLink: captureShareLink.reportLink,
              otherDetails: captureShareLink.otherDetails
            },
          };
          dispatch(generateShareablePlayBooksLinks(params))
            .then((res) => {
              let shareableLink = `${process.env.REACT_APP_APP_URL}/share-playbooks-report?uuid=${res.data.data[0]["shareLinkID"]}&linkid=${res.data.data[0]["hashedId"]}`;
              setSharableLink(shareableLink);
              traceEvents(shareableLink, "Created");
            })
            .catch(() => {
              setError(
                "There are some problem with the server side, we are not able to generate the share report link, please try again"
              );
            });
        });
    }
  };

  // Creation of Modal
  const renderBodyElement = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            marginRight: "15px",
            flexDirection: "column",
          }}
        >
          {copy && (
            <div>
              <CheckCircleIcon
                style={{ color: "green", marginLeft: "209px" }}
                fontSize="large"
              />
              <h3
                style={{
                  fontSize: "16px",
                  fontWeight: "normal",
                  marginLeft: "189px",
                }}
              >
                Link Copied
              </h3>
            </div>
          )}
        </div>
        {!error ? (
          <div
            style={{
              display: "flex",
              alignContent: "center",
              justifyContent: "space-between",
            }}
          >
            <div style={{ width: "33.5rem" }}>
              <TextField
                fullWidth="true"
                size="small"
                id="outlined-basic"
                variant="outlined"
                value={sharableLink}
              />
            </div>
            <div>
              <Button
                onClick={() => handleCopyLink()}
                style={{
                  marginLeft: "2px",
                  backgroundColor: "#F2C811",
                  fontSize: "0.9rem",
                  padding: "7px 22px",
                }}
                size="large"
                variant="contained"
              >
                {!!copy ? "Copied" : "Copy"}
              </Button>
            </div>
          </div>
        ) : (
          <div style={{ color: "red", fontSize: "16px", textAlign: "center" }}>
            {error}
          </div>
        )}
      </div>
    );
  };

  let shareBtnStyle = {
    fontSize: '1.1rem',
    padding: '0.5rem 0.3rem',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#050050', //var(--text-primary, #050050),
    fontFamily: 'Gilroy-Medium',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '16px', /* 133.333% */
    letterSpacing: '0.5px',
    cursor : 'pointer'
  }

  const handleModal = () => {
    if (!!pbiReport && Object.keys(pbiReport).length > 0) {
      generateShareableCcLink();
      setShareModalOpen(true);
      setSharableLink("...loading");
      setError(false);
    }
  };

  const handleClose = () => {
    setShareModalOpen(false);
    setCopy(false);
    setSharableLink("");
  };

  return (
    <div>
      <Modal
        open={open}
        bodyElement={renderBodyElement()}
        hideSettingsWindow={(e) => handleClose(e)}
        title={"Share Playbooks Link"}
        size={"md"}
      />
      <Typography onClick={handleModal}   variant="h5" color="inherit" style={shareBtnStyle}>
        / Share
      </Typography>
    </div>
  );
};

export default Share;
