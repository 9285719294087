export default (
  state = {
    error: false,
    message: "",
    userMessage: "",
    alertTrigger: false,
    apiName: "",
  },
  action
) => {
  switch (action.type) {
    case "GLOBAL_API_ERROR":
      return {
        error: true,
        message: action.payload.message,
        userMessage: action.payload["userMessage"],
        alertTrigger: action.payload.alertTrigger,
        type: "ApiError",
      };

    default:
      return state;
  }
};
