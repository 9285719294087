export default (state = {
    error: false,
    message: '',
    userMessage: '',
    alertTrigger: false,
    embedReportSaved: false
}, action) => {

    switch (action.type) {
    case 'GLOBAL_API_SUCCESS':
    return {
        error: false,
        message: 'Success',
        alertTrigger: false,
        type: "ApiSuccess"
    }


    default:

        return state;

}

};