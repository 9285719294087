export default (state = { subscription: false,  loading: false , responseDefault : true }, action) => {
  switch (action.type) {
    case "USER_SUBSCRIPTION_REQUEST":
      return {
        subscription: false,
        loading: true,
        responseDefault: true
      };

    case "USER_SUBSCRIPTION_SUCCESS":
      return {
        subscription: true,
        data: action.payload,
        responseDefault: false,
        loading: false,
        success: true,
        error: false,
      };

    case "USER_SUBSCRIPTION_FAILURE":
      return {
        subscription: false,
        error: true,
        responseDefault: false,
        loading: false,
        success: false,
        userMessage: action.payload.response.data["usermessage"],
      };

    default:
      return state;
  }
};
