import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Columns from './ViewBuilderComponents/Columns';
import Rows from './ViewBuilderComponents/Rows';
import BiDashboard from './ViewBuilderComponents/BiDashboard';
import TextContent from './ViewBuilderComponents/TextContent';
class TabsCongigurationOptions extends Component {
    constructor(props) {
        super(props);
        this.state = {
          currentObj:this.props.currentObj,
          displayColorPicker:false,
          background:'#fff',
          displayColorPickerId:'',
        }
    }
    discardChanges(e){
      this.props.discardChanges(e)
    }
   renderConfigOptions(){
     if(this.props.currentObj.type==='column'){
       return(
         <Columns  currentObj = {this.props.currentObj}  id={this.props.id}/>
       )
     }
     else if(this.props.currentObj.type==='row'){
       return(
         <Rows  currentObj = {this.props.currentObj}  id={this.props.id}/>
       )
     }
     else if(this.props.currentObj.type==='dashboard'){
       return(
         <BiDashboard  currentObj = {this.props.currentObj}  id={this.props.id}/>
       )
     }

     else if(this.props.currentObj.type==='text'){
       return(
         <TextContent  currentObj = {this.props.currentObj}  id={this.props.id}/>
       )
     }
     else{
       return(<p>This is the outermost container, no configurations required for this.</p>)
     }

   }

   renderDeleteIcon(){
     if(this.props.id===0){
       return null;
     }
     return (
       <button type="button" className="btn btn-secondary btn-cancel" onClick={(e)=>{this.props.deleteWidgetBlock()}}><i className=" fas fa-trash"></i> Delete Block</button>
     )
   }

    render() {

        return (
            <aside className="side-nav-container">
               <div className="side-nav-block-type">
                  <small>Block Type - </small><b>{this.props.currentObj.type}</b>
               </div>
               {this.renderConfigOptions()}
              <div className="tabsConfigFooter">
                   {this.renderDeleteIcon()}
                   <button type="button" className="btn btn-primary btn-done" onClick={(e)=>this.props.doneEditing()}>Done Editing</button>
              </div>
            </aside>
        );
    }
}
function mapStateToProps(state) {
	return ({
		viewBuilder:state.viewBuilderObj
	})
}
function mapDispatchToProps(dispatch) {
	return bindActionCreators({

	}, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(TabsCongigurationOptions);
