import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useStyles } from "./style.js";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { Typography } from "@material-ui/core";
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import { getReportContent } from '../../redux/actions/index';
import Spinner from '../../component/Spinner/index';
import utils from '../../utils/index';
import DefaultReport from '../reports/defaultReport';
import Report from '../reports/report';
import CircularProgress from '@material-ui/core/CircularProgress';
import './style.js';


const FoundationAnalytics = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const getPageContent = useSelector((state) => state.getPageContent);
    const activeSideBarMenu = useSelector((state) => state.activeSideBarMenu);
    const activeReport = useSelector((state) => state.activePBIReport);

    const [noReports, setNoReport] = React.useState(null);
    const reportContent = useSelector((state) => state.getReportContent);

    const { activeRegion, activePage } = useSelector((state) => state.activeDynamicData);

    const { activeIndex } = props;

    const [reportEmbed, setReportEmbed] = useState(true);

    const reportMessage = useSelector((state) => state.getErrorMessages);

    useEffect(() => {
        const noReport = reportMessage?.data?.find(item => item.errorType === "No Reports Available")
        setNoReport(noReport?.reportMessage)
    }, [reportMessage]);

    useEffect(() => {
        if (activeRegion && activePage) {
            const params = {
                pageType: activePage.key,
                region: activeRegion,
            }
            dispatch(getReportContent(params));
        }
    }, [activeRegion, dispatch, activePage,]);

    const trackingEvents = (rowData) => {
        let trackObj = {};
        trackObj.region = activeRegion;
        trackObj.actionType = 'Click';
        trackObj.pageName = activePage.pageName;
        trackObj.pageDetails = rowData.description;
        trackObj.reportLink = rowData.link;
        trackObj.reportName = rowData.display_name;
        utils.userTrackingInfo(trackObj);
    };

    const renderPageContent = () => {
        let { loading, data, error, message, userMessage } = getPageContent;
        if (loading) {
            return (
                <Spinner align={'inherit'} />
            )
        }
        else if (!!data && data.description && Array.isArray(data.description.table) && data.description.table.length > 0) {
            return (
                data.description.table.map((item, index) =>
                (
                    <div className={classes.analyticsTool} rowKey={index}>
                        <Avatar alt={item.display_name} src={item.image} className={classes.avtar} />
                        <Typography variant="subtitle2" className={classes.toolName}>
                            <a onClick={() => trackingEvents(item)} href={item.link} target=" _blank">{item.display_name}</a>
                        </Typography>
                    </div>
                ))
            )
        }
        else if (!!data && data.description && Array.isArray(data.description.table) && data.description.table.length === 0) {
            return (<div style={{ fontSize: '20px' }}>{data.description.userMessage}</div>)

        }
        else if (error) {
            return (
                <div style={{ fontSize: '20px' }}>{userMessage ? userMessage : message}</div>
            )
        }
    }

    const renderFAVisual = () => {
        if (reportEmbed) {
            let { loading, responseDefault, error } = reportContent;
            if (loading && responseDefault && Object.keys(activeReport).length === 0) {
                return (
                    <div className={classes.position}>
                        <CircularProgress color="primary" />
                    </div>
                )
            }
            else if (!loading && !responseDefault && !!error && Object.keys(activeReport).length === 0) {
                return (
                    <div className={classes.serverDown}>
                        <div className="noReports"></div>
                        <div className={classes.errorMessage}>{noReports}</div>
                    </div>
                )
            }
            else if (Object.keys(activeReport).length > 0) {
                if (!activeReport.customizableFlag || (activeReport.htmlTemp.hasOwnProperty('type') && activeReport.htmlTemp.type === "default")) {
                    return (
                        <Grid container item xs={12}>
                            <DefaultReport
                                reportSectionId={activeSideBarMenu.activeSubMenu}
                                activeSubMenuName={activeSideBarMenu.activeSubMenuName}
                                activeSubMenuIndex={activeSideBarMenu.activeSubMenuIndex}
                                activeIndex={activeIndex}
                                navContentPaneEnabled={false}
                            />
                        </Grid>
                    )
                }
                else if (activeReport.customizableFlag) {
                    return (
                        <Report
                            navContentPaneEnabled={false}
                            pageType={'TPA'}
                        />
                    )
                }
            }
        }
        else if (!reportEmbed) {
            let { data } = getPageContent;
            return (
                <Grid item xs={12}>
                    <Typography variant="subtitle1" display="block" align="justify" className={classes.title} >
                        <b>
                            {data && data.description && data.description.header1}
                        </b>
                    </Typography>
                    <Typography variant="subtitle1" display="block" align="center" className={classes.subtitle}>
                        {data && data.description && data.description.header2}
                    </Typography>
                    <div style={{ width: '100%' }}>
                        <Box p={1} className={classes.toolList}>
                            {
                                renderPageContent()
                            }
                        </Box>
                    </div>
                </Grid>
            )
        }
    }

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <Grid container item xs={12} className={classes.flexContainer}>
                    {renderFAVisual()}
                </Grid>
            </Paper>
        </div>
    );
}

export default FoundationAnalytics;