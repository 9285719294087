export default (state = { data: [] }, action) => {
  switch (action.type) {

    case "GLOBAL_SEARCH_QUERY_RESULT_RESET":
      return {
        data: [],
        loading: false,
        responseDefault: false,
      };

    case "GLOBAL_SEARCH_QUERY_RESULT_REQUEST":
      return {
        data: [],
        loading: true,
        responseDefault: true,
      };

    case "GLOBAL_SEARCH_QUERY_RESULT_SUCCESS":
      return {
        data: action.payload.data.data,
        responseDefault: false,
        loading: false,
        success: true,
        error: false,
      };

    case "GLOBAL_SEARCH_QUERY_RESULT_FAILURE":
      return {
        data: [],
        error: true,
        responseDefault: false,
        loading: false,
        success: false,
        userMessage: action.payload.response.data["usermessage"],
      };

    default:
      return state;
  }
};
