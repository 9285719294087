import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import 'react-tabs/style/react-tabs.css';
import { updateViewBuilderJSON } from '../../../redux/actions';
import cloneDeep from 'lodash/cloneDeep';
class Layout extends Component {
    constructor(props) {
        super(props);
        this.state = {
          displayColorPicker:false,
          colorCode:'',
          displayColorPickerId:'',
        }
    }


  handleViewBuilderJSONChange(e, type, keyName){
      let tempViewBuilderObj = cloneDeep(this.props.viewBuilder);
      let id =  this.props.id
      if(type==='layout'){
        tempViewBuilderObj.viewBuilderJSON.htmlTemp[id][keyName]=e.target.value;
      }
      else if(type==='configOptions'){
        tempViewBuilderObj.viewBuilderJSON.htmlTemp[id]['configOptions'][keyName] = e.target.value;
      }
      else if(type==='style' || type==='colorCode'){
            let targetVal = '';
            if(type==='colorCode'){
               targetVal = e.hex;
            }
            else{
              targetVal = e.target.value;
            }
          tempViewBuilderObj.viewBuilderJSON.htmlTemp[id]['configOptions']['style'][keyName] = targetVal;
      }
      console.log('@viewBuilderJSONStructure', tempViewBuilderObj);
      this.props.updateViewBuilderJSONAPI(tempViewBuilderObj);
  }

  renderlayoutOptions(){
    //if(this.props.currentObj.type=='column'){
      return(
           <div className="formContainer">

              <div className="form-group">
               <label>Column Size</label>
                <div className="input-group mb-2">
                  <div className="input-group-prepend">
                    <div className="input-group-text">Mobile</div>
                  </div>
                   <select className="custom-select" value={this.props.viewBuilder.viewBuilderJSON.htmlTemp[this.props.id]['classMobile']} onChange={(e)=>this.handleViewBuilderJSONChange(e,'layout', 'classMobile')}>
                       <option selected>Select</option>
                       <option value="col-1">col-1</option>
                       <option value="col-2">col-2</option>
                       <option value="col-3">col-3</option>
                       <option value="col-4">col-4</option>
                       <option value="col-5">col-5</option>
                       <option value="col-6">col-6</option>
                       <option value="col-7">col-7</option>
                       <option value="col-8">col-8</option>
                       <option value="col-9">col-9</option>
                       <option value="col-10">col-10</option>
                       <option value="col-11">col-11</option>
                       <option value="col-12">col-12</option>
                    </select>
                    <div className="input-group-prepend">
                      <div className="input-group-text">Tablet</div>
                    </div>
                     <select className="custom-select" value={this.props.viewBuilder.viewBuilderJSON.htmlTemp[this.props.id]['classTablet']}  onChange={(e)=>this.handleViewBuilderJSONChange(e, 'layout','classTablet')}>
                         <option selected>Select</option>
                         <option value="col-md-1">col-1</option>
                         <option value="col-md-2">col-2</option>
                         <option value="col-md-3">col-3</option>
                         <option value="col-md-4">col-4</option>
                         <option value="col-md-5">col-5</option>
                         <option value="col-md-6">col-6</option>
                         <option value="col-md-7">col-7</option>
                         <option value="col-md-8">col-8</option>
                         <option value="col-md-9">col-9</option>
                         <option value="col-md-10">col-10</option>
                         <option value="col-md-11">col-11</option>
                         <option value="col-md-12">col-12</option>
                      </select>
                      <div className="input-group-prepend">
                        <div className="input-group-text">Desktop</div>
                      </div>
                       <select className="custom-select" value={this.props.viewBuilder.viewBuilderJSON.htmlTemp[this.props.id]['classDesktop']} onChange={(e)=>this.handleViewBuilderJSONChange(e,  'layout', 'classDesktop')}>
                           <option selected>Select</option>
                           <option value="col-lg-1">col-1</option>
                           <option value="col-lg-2">col-2</option>
                           <option value="col-lg-3">col-3</option>
                           <option value="col-lg-4">col-4</option>
                           <option value="col-lg-5">col-5</option>
                           <option value="col-lg-6">col-6</option>
                           <option value="col-lg-7">col-7</option>
                           <option value="col-lg-8">col-8</option>
                           <option value="col-lg-9">col-9</option>
                           <option value="col-lg-10">col-10</option>
                           <option value="col-lg-11">col-11</option>
                           <option value="col-lg-12">col-12</option>
                        </select>
                </div>
               </div>
               <small><b>Note-</b>
                 <ul className="note-list">
                   <li>In a grid layout, content must be placed within columns and only columns may be immediate children of rows</li>
                   <li>Column classes indicate the number of columns you’d like to use out of the possible 12 per row. So, if you want three equal-width columns across, you can use .col-4</li>
                   <li>Column widths are set in percentages, so they’re always fluid and sized relative to their parent element</li>
                 </ul>
               </small>
           </div>
      )
    //}
  }

    render() {
        return (
             this.renderlayoutOptions()
      );
    }
}
function mapStateToProps(state) {
	return ({
		viewBuilder:state.viewBuilderObj,

	})
}
function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		updateViewBuilderJSONAPI:updateViewBuilderJSON
	}, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(Layout);
