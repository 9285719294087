import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createNewReleaseVersion, toastTriggered, releaseVersionData } from "../../redux/actions";
import toastProperties from "../../assets/data/toastProperty";
import utils from '../../utils';

const CreateNewRelease = (props) => {
  const dispatch = useDispatch();
  const [state, setState] = useState({});
  const [fields, setFields] = useState([]);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const releaseNotes = useSelector((state) => state.relNotesFieldData);

  const { hideSettingsWindow, region } = props;

  const handleInputChange = (e) => {
    let val = e.target.value;
    let name = e.target.name;
    setState({ ...state, [name]: val });
  };

  let errorStyle = {
    color: "red",
    width: "auto",
    fontSize: 13,
    padding: "0px 5px",
  };

  useEffect(() => {
    releaseNotes.field.map((fieldType) => {
      if (fieldType.inputType === "select") {
        fieldType[fieldType.name] = releaseNotes[fieldType.name];
      }
      return releaseNotes;
    });
    setFields(releaseNotes.field);
  }, [releaseNotes, releaseNotes.field]);

  const renderInput = (fieldType, index) => {
    if (fieldType.display) {
      if (fieldType.inputType === "input") {
        return (
          <div className="col-12 col-xl-12 form-group" key={index}>
            <label className="label-flex-sm">{fieldType.label}</label>
            <input
              value={state[fieldType.name]}
              type={fieldType.type}
              name={fieldType.name}
              className="form-control"
              placeholder={`Please input ${fieldType.label}`}
              onChange={(e) => handleInputChange(e, fieldType)}
              autoComplete="off"
            />
            {
              !!error ?
              <div style={errorStyle}>{errorMessage}</div>
              : null
            }
          </div>
        );
      } else if (fieldType.inputType === "select") {
        return (
          <div className="col-12  col-xl-12 form-group" key={index}>
            <label className="label-flex-sm">{fieldType.label}</label>
            <select
              value={state[fieldType.name]}
              className="form-control"
              name={fieldType.name}
              onChange={(e) => handleInputChange(e, fieldType)}
            >
              <option value={""}>{"Select"}</option>
              {Array.isArray(fieldType[fieldType.name]) &&
                fieldType[fieldType.name].length > 0 &&
                fieldType[fieldType.name].map((item, index) => (
                  <option key={index} value={item[fieldType.value]}>
                    {item[fieldType.displayName]}
                  </option>
                ))}
            </select>
          </div>
        );
      }
    }
  };

  const trackUserActivities = (title) => {
    let trackingObject = {
      region: region,
      actionType: 'Click',
      pageName: "Other Feature",
      reportName: "Release Notes",
      reportSection:  'Release Notes Created',
      pageDetails : `${title}`,
    };
    utils.userTrackingInfo(trackingObject);
  }

  const createNewRelease = () => {
    if (!state.hasOwnProperty('releaseTitle')){
      setError(true);
      setErrorMessage("Please enter release title name");
      return true;
    }
    else if (!!state.hasOwnProperty('releaseTitle') && state.releaseTitle.length > 25) {
       setError(true);
      setErrorMessage("Release Title name must be less than 25 characters");
      return true;
    } else {
       setError(false);
      let params = {
        releaseDetails: {
          releaseName: state.releaseTitle,
          releaseType: state.releaseType,
          region: region,
        },
      };
      dispatch(createNewReleaseVersion(params))
        .then((res) => {
          toastProperties.success["description"] = res.data.message;
          dispatch(toastTriggered([toastProperties.success]));
          hideSettingsWindow("e");
          dispatch(releaseVersionData( {
            region: region,
          }));
          trackUserActivities(state.releaseTitle)
        })
        .catch((err) => {
          toastProperties.error["description"] = err.message;
          dispatch(toastTriggered([toastProperties.error]));
          hideSettingsWindow("e");
        });
    }
  };

  const cancel = () => {
    hideSettingsWindow("e");
  };

  return (
    <div className="container">
      <div className="col-12 sections my-2" id="basic_screen">
        <form className="form">
          <div className="row">
            {Array.isArray(fields) &&
              fields.length > 0 &&
              fields.map((inputType, index) => {
                return renderInput(inputType, index);
              })}
          </div>
        </form>
      </div>
      <div
        className="col-12 col-xl-12 form-group flex"
        style={{ justifyContent: "flex-end", bottom: 0 }}
      >
        <button
          style={{ margin: "0.4rem" }}
          type="button"
          className="btn btn-success "
          onClick={createNewRelease}
        >
          Create New Release
        </button>

        <button
          style={{ margin: "0.4rem" }}
          type="button"
          className="btn btn-danger"
          onClick={cancel}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default CreateNewRelease;
