export default (
    state = {
        loading: true,
        defaultState:true,
        error: false,
        message: '',
        dataSet:{},
        success: false,

    }, action) => {
    switch (action.type) {


        case 'SET_REGION_FILTER':
           return {
                ...state,
                dataSet : {...action.payload},
                responseDefault: false,
                loading: false,
                success: true,
            };
        default:

            return state;

    }

};