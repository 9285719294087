export default (state = [], action) => {

    switch (action.type) {

    case 'UPDATE_CUSTOMIZED_VIEW_BUILDER_JSON':
    return action.payload;

    default:

    return state;

    }

    };