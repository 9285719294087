import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateErrorMessages, toastTriggered, getErrorMessages } from '../../redux/actions/index';
import checkWhite from '../../assets/icons/checkWhite.svg';
import errorWhite from '../../assets/icons/errorWhite.svg';
import utils from '../../utils/index';

function UpdateErrorMessage(props) {
    const dispatch = useDispatch();
    const [type, setType] = useState('');
    const [previousMessage, setPreviousMessage] = useState('');
    const [newMessage, setNewMessage] = useState('');
    const [error, setError] = useState(false);

    const errorMessage = useSelector((state) => state.getErrorMessages);

    useEffect(() => {
        let error = Array.isArray(errorMessage.data) && errorMessage.data.length > 0 && errorMessage.data[0];
        let message = error.errorMessage;
        let type = error.errorType
        setPreviousMessage(message);
        setType(type)
    }, [errorMessage.data]);

    const onSelectChange = (e) => {
        let val = e.target.value;
        setType(val);
        let index = Array.isArray(errorMessage.data) && errorMessage.data.length > 0 && errorMessage.data.findIndex(item => item.errorType === val);
        let previousMessage = Array.isArray(errorMessage.data) && errorMessage.data.length > 0 && errorMessage.data[index].errorMessage;
        setPreviousMessage(previousMessage);
    };

    const saveNewPageDetails = async () => {
        if (!type || !newMessage) {
            setError({ error: true });
        }
        else {
            let obj = {
                errorType: type,
                message: newMessage,
            };
            dispatch(updateErrorMessages(obj)).then((res) => {
                if(res){
                    let error = {
                        "errorMessageId": 1,
                        "createdAt": "",
                        "createdBy": "",
                        "updatedBy": "DEFAULT",
                        "updatedAt": null,
                        "errorMessage": newMessage,
                        "infoMessage" : `😞 Oops! We seem to be having some trouble right now. We are working on resolving this. Please try again in a little time.`,
                        "errorType": "Unresponsive Server",
                        "buType": null
                    }
                    if(type === "Unresponsive Server"){
                        localStorage.setItem('NodeDown', JSON.stringify(error));
                    }
                }
                dispatch(getErrorMessages())
               let toastProperties = [{
                    title: 'Success',
                    description: res.data.message,
                    backgroundColor: '#5cb85c',
                    percentageDownload: '100%',
                    icon: checkWhite,
                    status: 'success',
                    autoDeleteTime: 3000,
                    autoDelete: true,
                    trigger: true
                }];
                dispatch(toastTriggered(toastProperties));
                props.hideSettingsWindow('e');
                let trackingObject = {
                    region: props.region,
                    actionType: 'Admin Activity',
                    pageName: 'Updated Error Message',
                    attributeName: type,
                    pageDetails: newMessage,
                  };
                  utils.userTrackingInfo(trackingObject);
            }).catch((err) => {
                let toastError = [{
                    id: 4759834,
                    title: 'Failure',
                    description: err.message,
                    backgroundColor: '#d9534f',
                    percentageDownload: '100%',
                    icon: errorWhite,
                    status: 'error',
                    autoDeleteTime: 7500,
                    autoDelete: true,
                    trigger: true
                }];
                dispatch(toastTriggered(toastError));
                props.hideSettingsWindow('e');
            });
        };
    };

    const updateMessages = (e) => {
        let val = e.target.value;
        setNewMessage(val);
    };

    return (
        <div className="container">
            <div className="col-12 sections my-2" id="basic_screen">
                <form className="form">
                    <div className="row">
                        <div className="col-12  col-xl-12 form-group ">
                            <label className="">Error Message Type</label>
                            <select
                                value={type}
                                className="form-control "
                                onChange={(e) => onSelectChange(e)}
                            >
                                {Array.isArray(errorMessage.data) && errorMessage.data.length > 0 ? (
                                    errorMessage.data.map((item, index) => (
                                        <option rowkey={index} value={item.errorType} >{item.errorType}</option>
                                    ))
                                ) : (
                                    <option value="Select" disabled>Select</option>
                                )}
                            </select>
                        </div>
                        <div className="col-12  col-xl-12 form-group ">
                            <label className="">Previous Message </label>
                            <textarea
                                value={previousMessage}
                                type="text"
                                className="form-control"
                                placeholder="previos error message"
                                style={{ height: '100px' }}
                            />
                        </div>
                        <div className="col-12  col-xl-12 form-group ">
                            <label className="">Update Message</label>
                            <textarea
                                value={newMessage}
                                type="text"
                                className="form-control"
                                placeholder="Enter short description for the message."
                                onChange={(e) => updateMessages(e)}
                                style={{ height: '100px' }}
                            />
                            {!!error ? 'Please enter input' : null}
                        </div>
                        <div>{error}</div>
                        <div
                            className="col-12  col-xl-12 form-group  flex mt-0"
                            style={{ justifyContent: "flex-end" }}
                        >
                            <button
                                type="button"
                                className="btn btn-apply"
                                onClick={(e) => {
                                    e.preventDefault();
                                    saveNewPageDetails();
                                }}
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default UpdateErrorMessage;

