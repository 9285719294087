import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { CloseIcon } from '../../assets/icons/Icons';
import './style.css';

const Toast = (props) => {
    const { toastList, position, autoDelete, dismissTime , exportTriggerd} = props;

    const [list, setList] = useState(toastList);

    useEffect(() => {
        setList([...toastList]);
        // eslint-disable-next-line
    }, [toastList]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (autoDelete && toastList.length && list.length) {
               deleteToast(toastList[0].id);
            }
        }, dismissTime);

        return () => {
            clearInterval(interval);
        }

        // eslint-disable-next-line
    }, [toastList, autoDelete, dismissTime, list]);

    const deleteToast = id => {
         const listItemIndex = list.findIndex(e => e.id === id);
        const toastListItem = toastList.findIndex(e => e.id === id);
        list.splice(listItemIndex, 1);
        toastList.splice(toastListItem, 1);
        setList([...list]);
    }

    return (
           <div className={`notification-container ${position}`}>
                {
                  !!exportTriggerd && list.map((toast, i) =>
                        <div
                            key={i}
                            className={`notification toast ${position}`}
                            style={{ backgroundColor: toast.backgroundColor }}
                        >
                            <div className="notification-image">
                              {  toast.status === "downloaded" ||  toast.status === "success" ?
                                <div className="checkWhiteImage"/>
                                 :
                                toast.status === "error" ?
                                <div className="errorWhiteImage"/>
                                 :
                                 toast.icon
                               }
                                <p className="notification-title">{toast.title}</p>
                                <button onClick={() => deleteToast(toast.id)} style={{color: '#000'}} className={'closeToast'}>
                                  <CloseIcon />
                                </button>
                            </div>
                            <div className="notification-message">
                            {toast.description}
                            </div>
                            {  toast.status !== "downloaded"  &&   toast.status !== 'triggered' &&  toast.status !== "success" && toast.status !== 'error'?
                            <div className="notification-message">
                                Percentage Download:{ toast.percentageDownload}
                            </div>
                            : null
                            }
                        </div>
                    )
                }
            </div>
     );
}

Toast.propTypes = {
    toastList: PropTypes.array.isRequired,
    position: PropTypes.string,
    autoDelete: PropTypes.bool,
    dismissTime: PropTypes.number
}

export default Toast;